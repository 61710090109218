<template>
  <div class="pages">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>账户管理</el-breadcrumb-item>
          <el-breadcrumb-item>角色管理</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">角色管理</template>
      </main-header>
    </div>
    <div class="btnContainer">
      <div class="addItem ease btn-btn" @click="dialogTableVisible = true;">
        <i class="el-icon-circle-plus-outline"></i>创建角色
      </div>
      <div v-if="noEditList">
        <div
          class="save ease btn-btn"
          @click="saveData"
          v-if="act!=noEditList[0]&&act!=noEditList[1]&&act!=noEditList[2]"
        >保存</div>
      </div>
    </div>

    <div class="content-container">
      <div class="content-left">
        <div class="content-left-default">系统默认角色</div>
        <div
          class="content-left-item"
          v-for="item in defaultList"
          :data-index="item.id"
          :key="item.id"
          :class="{addAct:item.id==act}"
          @click="selectAdd(item.id)"
        >{{item.name}}</div>
        <div class="content-left-default">自定义角色</div>
        <div class="roleListOver" id="chatRecord">
          <div
            class="content-left-item"
            v-for="item in diyList"
            :key="item.id"
            :data-index="item.id"
            :class="{addAct:item.id==act}"
            @click="selectAdd(item.id)"
          >
            <span class="toLongName" :title="item.name">{{item.name}}</span>
            <div class="el-icon-container">
              <i class="el-icon-edit-outline"></i>
              <i class="el-icon-delete" @click="roleTotalListdelete(item.id)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div>
          <div class="checkGround checkGroundAdd">
            <div class="checkGroundS" v-for="item in roleCheckInfo" :key="item.id">
              <div class="checkItem checkGroundS-left">
                <el-checkbox
                  v-model="item.isSelect"
                  :disabled="isDisabled"
                  @change="changeIndex(item)"
                >{{item.name}}</el-checkbox>
              </div>
              <div class="checkItemWidth" v-for="log in item.childs" :key="log.id">
                <el-checkbox
                  class="checkItem"
                  v-model="log.isSelect"
                  :disabled="item.isSelect&&act!=noEditList[0]&&act!=noEditList[1]&&act!=noEditList[2]?indexCheck:!indexCheck"
                >{{log.name}}</el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 创建角色 -->
    <transition name="fades">
      <div class="containerMask" v-if="dialogTableVisible" @click="dialogTableVisible=false"></div>
    </transition>
    <transition name="fadee">
      <add-role
        v-if="dialogTableVisible"
        @closeAddBox="closeAddBox"
        @addRoleSuccess="addRoleSuccess"
      ></add-role>
    </transition>
  </div>
</template>

<script>
import {
  roleTotalList,
  roleTotalListDetail,
  roleTotalListDelete,
  roleTotalListChange,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";

import addRole from "./components/addRole";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    addRole,
  },

  data() {
    return {
      roleCheckInfo: null,
      act: 1,
      defaultList: [],
      diyList: [],
      indexCheck: false,
      dialogTableVisible: false,
      noEditList: null,
      saveChildId: [],
      saveChildIdPush: [],
      toSaveTwo: true,
      addRoleBoolean: false,
      // 开始检测ID,项目ID,客户ID
      toCheckId: "",
      itemListId: "",
      customListId: "",
    };
  },

  mounted() {
    this.roleTotalList();
  },

  computed: {
    // 系统默认角色不允许编辑
    isDisabled() {
      return this.act == this.noEditList[0] ||
        this.act == this.noEditList[1] ||
        this.act == this.noEditList[2]
        ? !this.indexCheck
        : this.indexCheck;
    },
  },

  methods: {
    // 添加角色成功后
    addRoleSuccess() {
      this.addRoleBoolean = true;
      this.roleTotalList();
      this.dialogTableVisible = false;
    },
    // 关闭角色弹窗
    closeAddBox() {
      this.dialogTableVisible = false;
    },
    // 选择父项，子项自动选中
    changeIndex(item) {
      this.checkItem(item);
      if (item.isSelect && item.childs) {
        for (let i = 0; i < item.childs.length; i++) {
          item.childs[i].isSelect = true;
        }
      }
      if(!item.isSelect && item.childs) {
        for (let i = 0; i < item.childs.length; i++) {
          item.childs[i].isSelect = false;
        }
      }
    },
    // 选中开始检测,默认选中项目和客户,选中项目,默认选中客户
    checkItem(item) {
      if (item.id == this.toCheckId && item.isSelect) {
        for (let i = 0; i < this.roleCheckInfo.length; i++) {
          if (this.roleCheckInfo[i].id == this.itemListId) {
            this.roleCheckInfo[i].isSelect = true;
            if (
              this.roleCheckInfo[i].isSelect &&
              this.roleCheckInfo[i].childs
            ) {
              for (let j = 0; j < this.roleCheckInfo[i].childs.length; j++) {
                this.roleCheckInfo[i].childs[j].isSelect = true;
              }
            }
          }
          if (this.roleCheckInfo[i].id == this.customListId) {
            this.roleCheckInfo[i].isSelect = true;
            if (
              this.roleCheckInfo[i].isSelect &&
              this.roleCheckInfo[i].childs
            ) {
              for (let j = 0; j < this.roleCheckInfo[i].childs.length; j++) {
                this.roleCheckInfo[i].childs[j].isSelect = true;
              }
            }
          }
        }
      }
      if (item.id == this.itemListId && item.isSelect) {
        for (let i = 0; i < this.roleCheckInfo.length; i++) {
          if (this.roleCheckInfo[i].id == this.customListId) {
            this.roleCheckInfo[i].isSelect = true;
            if (
              this.roleCheckInfo[i].isSelect &&
              this.roleCheckInfo[i].childs
            ) {
              for (let j = 0; j < this.roleCheckInfo[i].childs.length; j++) {
                this.roleCheckInfo[i].childs[j].isSelect = true;
              }
            }
          }
        }
      }
    },

    // 将开始检测，项目清单，客户清单ID保存下来
    saveDataId(item) {
      for (let i = 0; i < item.length; i++) {
        if (item[i].name == "开始检测") {
          this.toCheckId = item[i].id;
        }
        if (item[i].name == "项目清单") {
          this.itemListId = item[i].id;
        }
        if (item[i].name == "客户清单") {
          this.customListId = item[i].id;
        }
      }
    },
    // 删除角色
    roleTotalListdelete(id) {
      this.$confirm("是否删除该角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: id,
          };
          roleTotalListDelete(params).then((res) => {
            if (res.msgCode == 1) {
              this.notify("删除角色成功！");
              this.roleTotalList();
            } else {
              this.errorTip(res.msgInfo + ":" + res.data.toString());
            }
          });
        })
        .catch(() => {});
    },
    // 选择角色
    selectAdd(id) {
      this.act = id;
      let params = {
        id: id,
      };
      this.saveChildId = [];
      roleTotalListDetail(params).then((res) => {
        if (res.msgCode == 1) {
          this.roleCheckInfo = res.data;
          this.saveDataId(this.roleCheckInfo);
          for (let i = 0; i < this.roleCheckInfo.length; i++) {
            if (!this.roleCheckInfo[i].childs) continue;
            for (let j = 0; j < this.roleCheckInfo[i].childs.length; j++) {
              this.saveChildId.push(this.roleCheckInfo[i].childs[j].id);
            }
          }
          this.addRoleBoolean = false;
        } else {
          this.errorTip(res.msgInfo);
        }
      });
    },
    // 角色列表
    roleTotalList() {
      let params = {
        startTime: "",
        endTime: "",
        pageNo: 0,
        pageSize: 1000,
      };
      roleTotalList(params, localStorage.getItem("authorization")).then(
        (res) => {
          if (res.msgCode == 1) {
            this.defaultList = res.data.content.filter((item) => {
              return item.remark;
            });
            this.diyList = res.data.content.filter((item) => {
              return !item.remark;
            });
            this.noEditList = this.defaultList.map((item) => {
              return item.id;
            });
            this.diyEditList = this.diyList.map((item) => {
              return item.id;
            });
            this.act = this.noEditList[0];
            if (this.addRoleBoolean && this.diyEditList.length > 0) {
              this.act = this.diyEditList[this.diyEditList.length - 1];
            }
            this.selectAdd(this.act);
          } else {
            this.errorTip(res.msgInfo);
          }
        }
      );
    },
    // 保存角色权限
    saveData() {
      let isSelectList = [];
      this.toSaveTwo = true;
      for (let i = 0; i < this.roleCheckInfo.length; i++) {
        if (this.roleCheckInfo[i].isSelect) {
          this.saveChildIdPush.push(this.roleCheckInfo[i].id);
          isSelectList.push({
            id: this.roleCheckInfo[i].id,
            pid: this.roleCheckInfo[i].pid,
          });
          if (!this.roleCheckInfo[i].childs) continue;
          for (let j = 0; j < this.roleCheckInfo[i].childs.length; j++) {
            if (this.roleCheckInfo[i].childs[j].isSelect) {
              this.saveChildIdPush.push(this.roleCheckInfo[i].childs[j].id);
              isSelectList.push({
                id: this.roleCheckInfo[i].childs[j].id,
                pid: this.roleCheckInfo[i].childs[j].pid,
              });
            }
          }
        }
      }

      if (!this.checkItemIsselect()) return;

      // 判断是否都不选择二级权限
      for (let i = 0; i < this.saveChildIdPush.length; i++) {
        if (this.saveChildId.indexOf(this.saveChildIdPush[i]) > -1) {
          this.toSaveTwo = false;
        }
      }

      this.saveChildIdPush = [];
      if (this.toSaveTwo) {
        this.$confirm("存在二级功能未选中情况，是否继续保存？", "保存失败", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.roleTotalListchange(isSelectList);
          })
          .catch(() => {});
      } else {
        this.roleTotalListchange(isSelectList);
      }
    },

    // 判断
    // 情况1：如果选中开始检测，那么必须配套客户以及项目权限，如果子项没选中，则不给保存
    // 情况2：如果选中项目清单，那么必须配套客户权限，如果子项没选中，则不给保存
    // 情况3：客户权限，子项可随意配置
    checkItemIsselect() {
      let arrList = this.roleCheckInfo.filter((item) => {
        return item.isSelect;
      });
      let findCheckId = arrList.findIndex((item) => {
        return this.toCheckId == item.id;
      });
      let finditemListId = arrList.findIndex((item) => {
        return this.itemListId == item.id;
      });
      let findcustomListId = arrList.findIndex((item) => {
        return this.customListId == item.id;
      });

      //  选中开始检测，没选中项目
      if (findCheckId > -1 && finditemListId < 0) {
        this.$alert(
          "选中开始检测权限，需要全选客户清单和项目清单权限！",
          "保存失败",
          {
            confirmButtonText: "确定",
            callback: (action) => {},
          }
        );
        return false;
      }
      //  选中开始检测，没选中客户
      if (findCheckId > -1 && findcustomListId < 0) {
        this.$alert(
          "选中开始检测权限，需要全选客户清单和项目清单权限！",
          "保存失败",
          {
            confirmButtonText: "确定",
            callback: (action) => {},
          }
        );
        return false;
      }
      //  选中项目，没选中客户
      if (finditemListId > -1 && findcustomListId < 0) {
        this.$alert(
          "选中项目清单权限，需要全选客户清单和项目清单权限！",
          "保存失败",
          {
            confirmButtonText: "确定",
            callback: (action) => {},
          }
        );
        return false;
      }
      // 选中开始检测，检查项目是否存在子项未选中
      if (findCheckId > -1 && finditemListId > -1) {
        let checkfinditemListId = arrList.filter((item) => {
          return item.id == this.itemListId;
        });
        for (let i = 0; i < checkfinditemListId[0].childs.length; i++) {
          if (!checkfinditemListId[0].childs[i].isSelect) {
            this.$alert(
              "选中开始检测权限，需要全选客户清单和项目清单权限！",
              "保存失败",
              {
                confirmButtonText: "确定",
                callback: (action) => {},
              }
            );
            return false;
          }
        }
      }
      // 选中开始检测，检查客户是否存在子项未选中
      if (findCheckId > -1 && findcustomListId > -1) {
        let checkfinditemListId = arrList.filter((item) => {
          return item.id == this.customListId;
        });
        for (let i = 0; i < checkfinditemListId[0].childs.length; i++) {
          if (!checkfinditemListId[0].childs[i].isSelect) {
            this.$alert(
              "选中开始检测权限，需要全选客户清单和项目清单权限！",
              "保存失败",
              {
                confirmButtonText: "确定",
                callback: (action) => {},
              }
            );
            return false;
          }
        }
      }
      //  选中项目，没选中客户
      if (finditemListId > -1 && findcustomListId > -1) {
        let checkfinditemListId = arrList.filter((item) => {
          return item.id == this.itemListId;
        });
        for (let i = 0; i < checkfinditemListId[0].childs.length; i++) {
          if (!checkfinditemListId[0].childs[i].isSelect) {
            this.$alert(
              "选中项目清单权限，需要全选客户清单和项目清单权限！",
              "保存失败",
              {
                confirmButtonText: "确定",
                callback: (action) => {},
              }
            );
            return false;
          }
        }

        let checkfindcustomListId = arrList.filter((item) => {
          return item.id == this.customListId;
        });
        for (let i = 0; i < checkfindcustomListId[0].childs.length; i++) {
          if (!checkfindcustomListId[0].childs[i].isSelect) {
            this.$alert(
              "选中项目清单权限，需要全选客户清单和项目清单权限！",
              "保存失败",
              {
                confirmButtonText: "确定",
                callback: (action) => {},
              }
            );
            return false;
          }
        }
      }
      return true;
    },

    // 修改角色权限
    roleTotalListchange(list) {
      let params = { id: this.act, roleString: list };
      roleTotalListChange(params).then((res) => {
        if (res.msgCode == 1) {
          this.notify("修改角色权限成功！");
          this.selectAdd(this.act);
        } else {
          this.errorTip(res.msgInfo + ":" + res.data.toString());
          this.selectAdd(this.act);
        }
      });
    },
    // 成功提示
    notify(text) {
      this.$notify({
        title: "保存成功！",
        message: text,
        type: "success",
      });
    },
    // 错误提示
    errorTip(text) {
      this.$message({
        message: text,
        type: "warning",
        duration: 4000,
      });
    },
  },
  // 滚动条自动置底
  updated() {
    if (!this.addRoleBoolean) return;
    let ele = document.getElementById("chatRecord");
    ele.scrollTop = ele.scrollHeight;
  },
};
</script>

<style lang="stylus" scoped>
@import './common.css';

.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.addItem {
  width: 215px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 30px;
  cursor: pointer;
  margin-top: 24px;
}

.pages {
  overflow: hidden;
  padding-bottom: 60px;
}

.checkGround {
  border-radius: 8px;
  box-shadow: -4px 0px 16px 0px rgba(218, 213, 232, 0.6);
}

.checkGroundS {
  display: flex;
  flex-wrap: wrap;
  height: 52px;
  line-height: 52px;
  box-sizing: border-box;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(255, 255, 255, 1);
}

.checkGroundS:nth-child(odd) {
  background: rgba(251, 251, 251, 1);
}

.checkGroundSB {
  border-bottom: none;
  height: 45px;
  line-height: 45px;
  box-sizing: border-box;
}

.checkGroundSBno {
  box-sizing: border-box;
  border-bottom: none;
}

>>>.el-checkbox {
  margin-right: 0;
}

.checkItem {
  width: 98px;
  margin-left: 24px;
  box-sizing: border-box;
  word-wrap: break-word;
  word-break: normal;
  color: rgba(0, 0, 0, 0.6);
}

.checkGroundS-left {
  width: 154px;
  border-right: 1px solid rgba(217, 217, 217, 1);
}

.el-icon-circle-plus-outline {
  margin-right: 8px;
}

// 新的账户列表框
.content-container {
  // display: flex;
  margin-top: 8px;
  padding-right: 30px;
}

.content-left {
  width: 214px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  margin: 0 0 0 30px;
  box-sizing: border-box;
  font-size: 16px;
  margin-right: 16px;
  float: left;
  box-shadow: -4px 0px 16px 0px rgba(218, 213, 232, 0.6);
}

.content-right {
  flex: 1;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  float: left;
  box-shadow: -4px 0px 16px 0px rgba(218, 213, 232, 0.6);
  overflow: hidden;
  // height: 572px;
  width: calc(100% - 265px);
}

.content-left-default {
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  padding-left: 16px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content-left-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  line-height: 52px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.el-icon-container {
  display: flex;
  align-items: center;
}

.el-icon-delete {
  color: #D9D9D9;
  margin-left: 16px;
}

.el-icon-edit-outline {
  color: #2F54EB;
}

.addAct {
  background: rgba(240, 245, 255, 1);
  color: #2F54EB;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
}

.save {
  width: 76px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 3px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  margin-top: 24px;
  margin-right: 30px;
  cursor: pointer;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.roleListOver {
  max-height: 375px;
  overflow-x: hidden;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

>>>.el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: rgba(0, 0, 0, 0.6);
}

>>>.el-checkbox__input.is-checked+.el-checkbox__label {
  color: rgba(0, 0, 0, 0.6);
}

.toLongName {
  width: 122px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-btn:hover {
  background: rgba(47, 34, 235, 1);
}
</style>