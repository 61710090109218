<template>
  <div :id="'node' + node.id" v-if="node.isDisplay">
    <!-- <div :class="`section-wrapper-${level}`" :id="'node' + node.id"> -->
    <div :class="{ hide: isCensorDone && node.isDisplay == 0, [`section-wrapper-${level}`]: true }">
      <!--1级标题-->
      <div v-if="level == 0">
        <HeadLevel0 :indexes="indexes"></HeadLevel0>
      </div>
      <!--/1级标题-->
      <div
        v-else
        :class="{ hide: isCensorDone && node.isDisplay == 0, [`head-level-${level}`]: true }"
      >
        <!--标题的编辑弹出框-->
        <el-popover
          placement="top-start"
          :width="level == 2 ? 560 : 450"
          trigger="manual"
          v-model="toolTipVisible"
          @show="handleTooltipShow"
        >
          <div class="form-control-wrapper">
            <div class="input-wrapper">
              <el-input
                v-model="titleInput"
                style="width: 274px; margin-right: 5px"
                placeholder="请输入内容"
                :maxlength="level > 1 ? 20 : 15"
              ></el-input>
              <el-select
                v-model="riskLevel"
                style="width: 120px"
                placeholder="风险等级"
                v-if="level == 2"
              >
                <el-option
                  v-for="item in riskOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div class="btn-wrapper">
              <button class="btn btn-default" @click="handleBtnSaveClick">确定</button>
              <button class="btn btn-default" @click="handleBtnCancelClick">取消</button>
            </div>
          </div>
          <span v-if="level == 0" class="titleIconEnd"></span>

          <template slot="reference">
            <!--标题-->
            <div class="title-wrapper" @click.stop="handleTitleClick">
              <div style="display: flex; align-items: center">
                <span
                  v-if="node.children && node.children.length > 0 && level != 0"
                  class="titleIcon"
                ></span>

                <span
                  v-if="level != 0 && isEditable"
                  :class="{
                    bold: node.children && node.children.length > 0,
                    removed: !node.isDisplay,
                  }"
                  >{{ node.orderNumber }} {{ title }}</span
                >
                <span
                  v-else-if="level != 0 && !isEditable"
                  :class="{
                    bold: node.children && node.children.length > 0,
                    removed: !node.isDisplay,
                  }"
                  >{{ node.orderNumber }} {{ title }}</span
                >
                <span
                  v-else
                  :class="{
                    bold: node.children && node.children.length > 0,
                    removed: !node.isDisplay,
                  }"
                  style="
                    background: #2663f6;
                    height: 48px;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    border-radius: 4px 4px 0px 0px;
                    font-size: 18px;
                    font-weight: bold;
                  "
                >
                  {{ chineseNumber(node.orderNumber) }}、 {{ title }}</span
                >

                <!--                <i class="icon iconfont iconbianji" v-if="isEditable && !isSystemDiscountPolicy"></i>-->
                <template v-if="node.userData == 0">
                  <div class="badge yellow" v-if="isModifiedDiscountPolicy">已修改</div>
                  <div class="badge yellow" v-else-if="isInputModified">已修改</div>

                  <div class="badge red" v-if="isModifiedDiscountPolicyByMyself">已修改</div>
                  <div class="badge red" v-else-if="isInputModifiedByMyself">已修改</div>
                </template>

                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="node.notes"
                  placement="top"
                  v-if="node.notes"
                >
                  <el-button class="tooltip">
                    <i class="icon iconfont iconbangzhu2"></i>
                  </el-button>
                </el-tooltip>
                <!-- <WarningBadge :id="node.riskLevel"></WarningBadge> -->

                <span class="label-box" v-if="node.label">{{ node.label }}</span>
              </div>
            </div>

            <!--标题-->
          </template>
        </el-popover>
        <!--/标题的编辑弹出框-->

        <!--          <div class="btns-wrapper" v-if="isEditable && level != 0">-->
        <!--            <button-->
        <!--                class="btn btn-default"-->
        <!--                @click="handleBtnResetClick"-->
        <!--                v-if="node.id && ((node.children && node.children.length == 0) || this.isOverallNode)"-->
        <!--            >-->
        <!--              重置-->
        <!--            </button>-->
        <!--            <button class="btn btn-default" @click="handleBtnAddChild" v-if="!this.isOverallNode">-->
        <!--              添加子节点-->
        <!--            </button>-->
        <!--            &lt;!&ndash;          <button&ndash;&gt;-->
        <!--            &lt;!&ndash;            class="btn btn-default"&ndash;&gt;-->
        <!--            &lt;!&ndash;            @click="handleBtnDeleteClick"&ndash;&gt;-->
        <!--            &lt;!&ndash;            v-if="isUserDiscountPolicy"&ndash;&gt;-->
        <!--            &lt;!&ndash;          >删除</button>&ndash;&gt;-->
        <!--            <button class="btn btn-default" @click="handleBtnDisplayToggleClick">-->
        <!--              {{ toggleBtnLabel }}-->
        <!--            </button>-->
        <!--          </div>-->

        <div class="btnDirection">
          <el-button
            type="text"
            v-if="level == 2 && node.isDisplay && !node.editMode && isEditable"
            class="editBtn"
            @click="handleBtnEditClick"
          >
            <i class="iconfont iconicon_edit"></i> &nbsp; 编辑
          </el-button>
        </div>
      </div>
      <div :class="{ hide: isCensorDone && node.isDisplay == 0 }">
        <transition name="slide">
          <div class="section-body" v-if="isDisplay">
            <slot></slot>
          </div>
        </transition>
      </div>
    </div>
    <ConfirmDialog
      title="温馨提示"
      id="ConfirmDialog"
      ref="editConfirmDialog"
      @confirm="handleEditConfirmDialog"
    >
      <div class="editConfirmDialog">
        <div class="text1">
          <i class="icon el-icon-warning"></i> 有未保存的内容，确定退出编辑吗？
        </div>
        <div class="text2">退出编辑后，更新的内容不会自动保存！</div>
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { cloneDeep } from "lodash";
import WarningBadge from "@/pages/ResultEditor/components/WarningBadge.vue";
import { getRevokePdfCatalogData } from "@/api/shiwusuo";
import mixin from "@/pages/ResultEditor/mixin.js";
import { getEmptyNode } from "../../util/treeDataTemplate";
import HeadLevel0 from "./HeadLevel0";
import LazyLoader from "@/pages/ResultEditor/components/LazyLoader.vue";
import ConfirmDialog from "@/pages/ResultEditor/components/ConfirmDialog.vue";
import { findNode } from "@/utils/treeData";

const nzhcn = require("nzh/cn");
export default {
  mixins: [mixin],
  props: {
    title: String,
    level: Number, //当前节点在treeData中第几层
    indexes: {
      //当前组件所在treeData中的索引位置,如果是综述节点值就是overall
      type: Array,
      required: false,
    },
    target: {
      default: "treeData",
      type: String,
    },
    dataText: String,
    node2data: {
      type: Object,
      // 对象或数组默认值必须从一个工厂函数获取
      default: function () {
        return { pdfData: "" };
      },
    },
  },
  computed: {
    editMode: {
      get() {
        return !!this.node.editMode;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        nodeData.editMode = value;
        // pdfData.policyEdit = pdfData.policy;
        nodeData.pdfData = JSON.stringify(pdfData);
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });
      },
    },
    ruleRiskStatement: {
      get() {
        return this.pdfData.ruleRiskStatement;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.ruleRiskStatement = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
    ruleRiskStatementEdit: {
      get() {
        return this.pdfData.ruleRiskStatementEdit;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.ruleRiskStatementEdit = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
    dataTextEdit: {
      get() {
        return this.pdfData.dataTextEdit;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.dataTextEdit = value;
        // pdfData.policyEdit = pdfData.policy;
        nodeData.pdfData = JSON.stringify(pdfData);
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
    pdfData() {
      return JSON.parse(this.node.pdfData);
    },
    ...mapGetters("ResultEditor", ["isCensorDone"]),
    //是否是的优惠政策
    isDiscountPolicy() {
      return this.node.type == 5;
    },
    //是否是用户定义的优惠政策
    isUserDiscountPolicy() {
      return this.node.type == 5 && this.node.userData == 1;
    },
    //是否是系统自带的优惠政策
    isSystemDiscountPolicy() {
      return this.node.type == 5 && this.node.userData != 1;
    },
    //显示已经修改
    isModifiedDiscountPolicy() {
      let ret = false;
      if (this.isCensorDone) {
        return false;
      }
      const action = this.$route.params.action;
      if (action == "edit") {
        ret = this.node.lastCensorSnapshot ? this.node.lastCensorSnapshot.length > 0 : false;
      } else if (action == "censor") {
        ret = this.node.lastEditSnapshot ? this.node.lastEditSnapshot.length > 0 : false;
      }
      return ret;
    },
    isModifiedDiscountPolicyByMyself() {
      if (this.isCensorDone) {
        return false;
      }

      let ret = false;
      const action = this.$route.params.action;
      if (action == "edit") {
        ret = this.node.editSnapshot ? this.node.editSnapshot.length > 0 : false;
      } else if (action == "censor") {
        ret = this.node.censorSnapshot ? this.node.censorSnapshot.length > 0 : false;
      }
      return ret;
    },
    isInputModified() {
      let ret = false;
      if (this.isCensorDone) {
        return false;
      }
      if (this.$route.params.action == "edit") {
        ret = this.node.lastCensorSnapshot
          ? this.node.lastCensorSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.node.lastEditSnapshot
          ? this.node.lastEditSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;
      if (this.isCensorDone) {
        return false;
      }
      if (this.$route.params.action == "edit") {
        ret = this.node.editSnapshot ? this.node.editSnapshot.includes("TextChange") : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.node.censorSnapshot ? this.node.censorSnapshot.includes("TextChange") : false;
      }
      return ret;
    },
    //是否是综述节点
    isOverallNode() {
      return this.indexes == null;
    },
    ...mapState("ResultEditor", ["treeData", "overall", "discountPolicyTree"]),
    //是否隐藏
    isDisplay() {
      let ret = false;
      ret = !!this.node.isDisplay;
      return ret;
    },
    //按钮上的文字
    toggleBtnLabel() {
      return this.isDisplay ? "隐藏" : "显示";
    },
  },
  data: function () {
    return {
      titleInput: "",
      riskLevel: "",
      toolTipVisible: false,
      riskOptions: [
        {
          value: 1,
          label: "高风险",
        },
        {
          value: 2,
          label: "中风险",
        },
        {
          value: 3,
          label: "低风险",
        },
        {
          value: 4,
          label: "无风险",
        },
      ],
      parsePdfData: null,
      noSave: true,
      noSaveTip: false,
    };
  },
  components: {
    ConfirmDialog,
    LazyLoader,
    WarningBadge,
    HeadLevel0,
  },
  async mounted() {
    this.$eventBus.$on("ResultEditorBgClick", () => {
      this.toolTipVisible = false;
    });

    await this.load();
    // setTimeout(() => {
    //   debugger;
    // }, 5000);
  },
  updated() {},
  methods: {
    ...mapMutations("ResultEditor", [
      "setTreeDataByIndexes",
      "refineOrder",
      "setTree",
      "setTreeDataRecursively",
    ]),
    load() {
      try {
        this.parsePdfData = JSON.parse(this.node2data.pdfData);
      } catch {}
    },
    handleEditConfirmDialog() {
      this.setTreeDataRecursively({
        data: {
          editMode: false,
        },
        silent: true,
      });
      this.editMode = true;
    },
    handleBtnEditClick() {
      const theNode = findNode(this.treeData, "editMode", true);
      if (theNode) {
        this.$refs["editConfirmDialog"].open();
      } else {
        this.editMode = true;
      }
    },
    //阿拉伯数字转中文
    chineseNumber(number) {
      return nzhcn.encodeS(number);
    },
    ...mapMutations("ResultEditor", [
      "setTreeDataByIndexes",
      "refineOrder",
      "setDiscountPolicyTree",
    ]),
    ...mapActions("ResultEditor", ["refresh"]),
    handleTitleClick() {
      return false;
      if (!this.isEditable) {
        return;
      }

      if (this.isSystemDiscountPolicy) {
        return;
      }
      this.$eventBus.$emit("ResultEditorBgClick");
      this.toolTipVisible = true;
      //&&isEditable&&!isSystemDiscountPolicy
    },
    handleBtnAddChild() {
      const level1Node = cloneDeep(this.node);
      const level2Node = getEmptyNode(this.$route.params.nsrsbh, this.$route.params.uuid, 5, true);
      if (!level1Node.children) {
        level1Node.children = [];
      }
      level1Node.children.unshift(level2Node);
      this.setTreeDataByIndexes({
        data: level1Node,
        indexes: this.indexes,
      });
      this.refineOrder({ target: this.target });
    },
    //重置
    async handleBtnResetClick() {
      const pdfId = this.node.id;
      const resData = await getRevokePdfCatalogData({ pdfId });
      const nodeRemoteData = resData.data;

      if (resData.msgCode == "1") {
        if (this.isOverallNode) {
          //如果当前节点是综述节点
          const nativeTreeData = cloneDeep(this.node);
          // nativeTreeData.titleName = nodeRemoteData.titleName;
          nativeTreeData.pdfData = nodeRemoteData.pdfData;
          this.$store.commit("ResultEditor/setOverall", {
            data: nativeTreeData,
          });
        } else {
          //如果当前节点是树状目录中的节点
          const nodeData = cloneDeep(this.node);
          //nodeData.titleName = this.titleInput; //给节点设置新的titleName
          nodeData.pdfData = nodeRemoteData.pdfData;
          nodeData.dataText = nodeRemoteData.dataText;

          this.setTreeDataByIndexes({
            data: nodeData,
            indexes: this.indexes,
            target: this.target,
          });
        }
        this.$message({
          message: `${this.title}的数据已经被重置`,
          type: "info",
        });
      } else {
        this.$message.error(resData.msgInfo);
      }
    },
    //显示隐藏
    handleBtnDisplayToggleClick() {
      //如果当前节点是综述节点
      if (this.isOverallNode) {
        const node = cloneDeep(this.node);
        node.isDisplay = this.node.isDisplay == "1" ? 0 : 1;
        this.$store.commit("ResultEditor/setOverall", { data: node });
        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      } else {
        const nodeData = cloneDeep(this.node);
        //如果当前节点是树状目录中的节点
        nodeData.isDisplay = this.node.isDisplay == "1" ? 0 : 1;

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });

        this.refineOrder({ target: this.target });

        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (this.node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      }
    },
    async handleBtnDeleteClick() {
      const nodeId = this.node.id;
      const discountPolicyTree = cloneDeep(this.discountPolicyTree);

      this.$confirm("此操作将删除该优惠政策, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        discountPolicyTree[0].children = discountPolicyTree[0].children.filter((v) => {
          return v.id !== nodeId;
        });

        this.setDiscountPolicyTree({ data: discountPolicyTree });
      });
    },
    handleBtnCancelClick() {
      this.toolTipVisible = false;
    },
    handleBtnSaveClick() {
      this.toolTipVisible = false;

      //如果是综述则修改综述里的titleName
      if (this.isOverallNode) {
        const node = cloneDeep(this.node);
        node.titleName = this.titleInput;
        //node.riskLevel = this.riskLevel
        this.$store.commit("ResultEditor/setOverall", { data: node });
      } else {
        //设置vuex中的titleName
        const nodeData = cloneDeep(this.node);
        //如果当前节点是树状目录中的节点
        nodeData.titleName = this.titleInput;
        nodeData.riskLevel = this.riskLevel;
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });
      }
    },
    handleTooltipShow() {
      this.riskLevel = this.node.riskLevel;
      this.titleInput = this.node.titleName;
    },

    handleBtnSaveClickTwo() {
      this.editMode = false;
      const nodeData = cloneDeep(this.node);
      const pdfData = cloneDeep(this.pdfData);
      pdfData.policy = pdfData.policyEdit;
      pdfData.caseText = pdfData.caseTextEdit;
      pdfData.ruleAdvise = pdfData.ruleAdviseEdit;
      pdfData.dataText = pdfData.dataTextEdit;
      pdfData.ruleRiskStatement = pdfData.ruleRiskStatementEdit;

      nodeData.pdfData = JSON.stringify(pdfData);
      this.setTreeDataByIndexes({
        data: nodeData,
        indexes: this.indexes,
        target: this.target,
      });
      // if (!this.noSave) {
      //   this.$confirm("有未保存的内容，确定退出编辑吗?", "温馨提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   })
      //     .then(() => {
      //       this.noSaveTip = true;
      //       this.$message({
      //         type: "success",
      //         message: "保存成功!",
      //       });
      //     })
      //     .catch(() => {
      //       this.$message({
      //         type: "info",
      //         message: "已取消保存",
      //       });
      //     });
      // }
    },
  },
  watch: {
    title: {
      handler() {
        this.titleInput = this.title;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.label-box {
  margin-left: 10px;
  font-weight: normal;
  display: inline-block;
  padding: 1px 3px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  border: 1px solid rgba(47, 84, 235, 1);
  font-size: 12px;
  color: rgba(47, 84, 235, 1);
}

.btn-default {
  flex-grow: 0;
  flex-shrink: 0;
  background: #fff;
  border: 1px solid #2663F6
  color: #2663F6;
}

.btn-default:hover {
  background: #2663F6 !important;
  color: #fff !important;
  border: 1px solid #2663F6
  color: #2663F6;
}

.form-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .input-wrapper {
    flex-grow: 1;
  }

  .btn-wrapper {
    margin-left: 10px;
    display: flex;

    .btn {
      margin: 0 5px;
    }
  }
}

.title-wrapper {
  display: flex;
  align-items: center;
}

.icon {
  color: rgba(47, 84, 235, 1);
  margin-left: 8px;
  cursor: pointer;
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.bold {
  font-weight: bold;
}

.removed {
  text-decoration: line-through;
  color: #999;
}

.displayNone {
  display: none;

}

.head-level-0 {
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  display: none
}

.head-level-1 {
  //padding: 0 16px 0;
  font-size: 16px;
  font-weight: bold;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  margin: 0 20px;
}

.head-level-2, .head-level-3 {
  width 100%
  font-size: 16px;
  font-weight: bold;
  //padding: 0 16px 0;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-bottom: 1px solid #e6e6e6;
}

>>> .head-level-2 {
  //margin-top: 20px
}

.head-level-2:first-child {
  margin-top: 0px
}

.section-wrapper-2, .section-wrapper-3 {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px
}

.section-wrapper-2:hover {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
}
.section-wrapper-2:hover .editBtn{
  display:block;
}
.section-wrapper-2 {
  margin-bottom: 20px;
}


.section-wrapper-0 {
  background: rgba(255, 255, 255, 1);
  //box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  box-sizing: border-box;
  //margin: 24px 16px;
  padding: 20px 0;
  margin-top: 20px;
}

.btn {
  border-radius: 4px;
  //padding: 5.5px 16px;
  padding: 8px 16px
  font-size: 14px;
  line-height: 1.5;
  margin: 0 8px;
}

.btn.gray {
  background: rgba(217, 217, 217, 1);
  color: #fff;
}

.btn.disabled {
  background: rgba(217, 217, 217, 1);
  cursor: not-allowed;
}

.tooltip {
  padding: 0;
  border: 0 none;
  margin: 0 0 0 8px;

  .icon {
    color: #2F54EB;
    font-size: 18px;
  }
}

.tooltip:hover {
  background: none;
}

.section-body {
}

.badge {
  font-weight: normal;
  padding: 3px 8px;
  color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  margin-left: 16px;
}

.badge.red {
  background: rgba(245, 34, 45, 1);
  color: #fff;
  border: 1px solid rgba(245, 34, 45, 1);
}

.badge.yellow {
  color: rgba(250, 173, 20, 1);
  border: 1px solid rgba(250, 173, 20, 1);
  background: rgba(250, 173, 20, 0.08);
}

.hide {
  display none !important
}

.titleIcon {
  width: 4px;
  height: 16px;
  background: #2663F6;
  border-radius: 2px 2px 2px 2px;
  margin-right: 8px;
  display inline-block
}
.btnDirection{
  width: 66px;
  height: 28px;
}
.editBtn {
  color: #2663F6;
  font-size: 14px;
  padding: 6px 6px;
  display:none;
}

.editBtn:hover {
  background: #F1F6FE;
}


.handleClickEdit {
  padding: 10px 25px;
  background: #2663F6;
  border: 1px solid #2663F6;
  color: #fff;
  margin-left: 16px;
}

.handleClickEdit:hover {
  background: #2259DD
}

.item {
  .title {
    margin: 10px 0;
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #1D2129;
    line-height: 22px;
  }
}

>>> .el-textarea__inner {
  min-height: 100px
  padding: 10px 17px;
}

.risk-problem-wrapper {
  //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  //background: rgba(251, 251, 251, 1);

  .chart-wrapper {
    display: flex;
  }

  .desc {
    background: rgba(240, 245, 255, 1);
    padding: 16px;
    margin-top: 16px;

    .title {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);

    }

    .textarea-wrapper {
      margin-top: 10px;
    }
  }
}

.editConfirmDialog {
  padding-left 40px


  .text1 {
    position relative;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #1D2129;
    line-height: 24px;

    .icon {
      font-size: 32px;
      color: rgba(255, 177, 0, 1);
      margin-right: 10px;
      position absolute
      left -50px;
      //top 5px
    }
  }

  .text2 {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666
    line-height: 22px;
    margin-top 12px
  }
}
#ConfirmDialog >>>.el-dialog__title {
  font-size: 16px;
  font-weight: bold;
}
#ConfirmDialog>>>.el-dialog__headerbtn{
  font-size: 20px;
}
#ConfirmDialog>>>.el-button{
  padding: 11px 24px
}
#ConfirmDialog>>>.el-button+.el-button{
  margin-left: 16px;
}
#ConfirmDialog>>>.el-dialog__header {
  padding: 20px 24px;
}
#ConfirmDialog>>>.el-dialog__body {
  padding: 10px 24px;
}
#ConfirmDialog>>>.el-dialog__footer{
  padding: 24px 20px 20px;
}
.el-icon-warning{
  top: -4px;
}
</style>
