<template>
  <div class="fixed-item" v-loading="loading">
    <div class="fixed-item-top">
      <img
        @click="showHiddenDetail"
        class="fixed-item-icon cursion"
        src="@/assets/test/arrow-right.png"
        alt
      />
      <div>项目详情</div>
    </div>
    <div
      class="fixed-content overflow-xy"
      :class="{ fixedContentH: isEdit }"
      v-if="projectDetail"
    >
      <div class="content-title" :class="{ editActive: isEdit }">项目名称：</div>
      <div class="content-text" v-if="!isEdit">
        {{ projectDetail.projectName }}
      </div>
      <div class="content-text content-isEdit" id="content-isEdit" v-if="isEdit">
        <el-input v-model.trim="currentEdit.projectName" placeholder="请输入"></el-input>
      </div>
      <div class="content-title" :class="{ editActive: isEdit }">项目开始时间</div>
      <div class="content-text">{{ projectDetailTime }}</div>
      <div class="content-title" :class="{ editActive: isEdit }" v-if="!isEdit">
        项目结束时间
      </div>
      <div class="content-text" v-if="!isEdit">
        <span v-if="projectDetail.time[1]">{{ projectDetail.time[1] }}</span>
        <span v-else>暂无结束时间</span>
      </div>
      <div class="content-title" :class="{ editActive: isEdit }">项目经理</div>
      <div class="content-text" v-if="!isEdit">
        {{ projectDetail.projectManager }}
      </div>
      <div class="content-text content-isEdit" id="content-isEdit" v-if="isEdit">
        <el-input
          v-model="currentEdit.projectManager"
          :disabled="true"
          placeholder="请输入"
        ></el-input>
      </div>
      <div v-if="projectDetail.status == 3">
        <div class="content-title" :class="{ editActive: isEdit }">项目废除备注</div>
        <div class="content-text" v-if="!isEdit">
          <span v-if="projectDetail.remark">{{ projectDetail.remark }}</span>
          <span v-else>无备注</span>
        </div>
      </div>
    </div>
    <div class="fixed-bottom overflow-xy" v-if="projectDetail">
      <div class="item-flex1">
        <div class="content-title">企业名称：</div>
        <div class="content-text content-text-bottom">
          {{ projectDetail.consumerEntity.companyName }}
        </div>
        <div class="content-title">纳税人识别号：</div>
        <div class="content-text content-text-bottom">
          {{ projectDetail.consumerEntity.nsrsbh }}
        </div>
        <div class="content-title">所属地区：</div>
        <div class="content-text content-text-bottom">
          <span v-if="projectDetail.consumerEntity.cityCode">{{ conversionArea }}</span>
          <span v-else>暂无地区信息</span>
        </div>
        <div class="content-title">所属行业：</div>
        <div class="content-text content-text-bottom" v-if="dataList">
          {{ dataList[projectDetail.consumerEntity.industry] }}
        </div>
        <div class="content-title">联系人：</div>
        <div class="content-text content-text-bottom">
          {{ projectDetail.consumerEntity.contact }}
        </div>
      </div>
      <div class="item-flex1">
        <div class="content-title content-tran">客户所属行业：</div>
        <div class="content-text content-tran content-text-bottom">建筑业</div>
        <div class="content-title">企业规模：</div>
        <div class="content-text content-text-bottom">
          {{ projectDetail.consumerEntity.scale }}
        </div>
        <div class="content-title">业务员：</div>
        <div class="content-text content-text-bottom">
          {{ projectDetail.consumerEntity.salesman }}
        </div>

        <div class="content-title">联系电话：</div>
        <div class="content-text content-text-bottom">
          {{ projectDetail.consumerEntity.contactNumber }}
        </div>
      </div>
    </div>
    <div
      v-if="
        projectDetail &&
        projectDetail.status != 3 &&
        projectDetail &&
        projectDetail.status != 1
      "
    >
      <div class="content-btn ease" @click="detailEdit" v-show="!isEdit">编辑</div>
      <div class="content-btn ease detailContent" v-show="isEdit">
        <div class="detailcancel" @click="removeEdit">取消</div>
        <div class="detailSaves" @click="hold">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { editProjects } from "@/api/api";
export default {
  props: {
    projectDetail: [Object, Array],
    projectDetailTime: [String, Number],
    conversionArea: [String, Number],
    dataList: [Object, Array, String],
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      currentEdit: null,
      kjzdList: {
        qykjzd: "企业会计制度",
        qykjzz: "企业会计准则",
        xqykjzz: "小企业会计准则",
      },
    };
  },
  methods: {
    showHiddenDetail() {
      this.$emit("hiddenDetail");
    },
    detailEdit() {
      this.currentEdit = JSON.parse(JSON.stringify(this.projectDetail));
      this.isEdit = true;
    },
    removeEdit() {
      this.isEdit = false;
    },
    hold() {
      this.editprojects();
    },
    editprojects() {
      let params = {
        id: this.currentEdit.id, //项目id
        consumerId: this.currentEdit.consumerEntity.consumerId, //客户id
        consumer: this.currentEdit.consumerEntity.companyName, //客户名称
        projectName: this.currentEdit.projectName, //项目名称
        projectManager: this.currentEdit.projectManager, //项目经理
        projectManagerId: this.currentEdit.projectManagerId, //项目经理id
        startTime: this.projectDetailTime, //项目周期起
        endTime: this.currentEdit.time[1], //项目周期至
      };
      if (!this.currentEdit.projectName || !this.currentEdit.projectName.trim()) {
        this.$message({
          message: "项目名称不能为空!",
          type: "warning",
        });
        return;
      }
      editProjects(params).then((res) => {
        if (res.msgCode == 1) {
          this.$notify({
            title: "温馨提示",
            message: "修改项目成功",
            type: "success",
          });
          this.$emit("editProjectSuccess", this.currentEdit);
          this.isEdit = false;
        } else {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.fixed-item {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1008;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.fixed-item-top {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(218, 213, 232, 0.6);
}

.fixed-content {
  width: 464px;
  background: rgba(250, 173, 20, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(250, 173, 20, 0.24);
  margin: 16px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

@media (max-height: 800px) {
  .fixed-content {
    max-height: 220px;
    overflow-x: hidden;
  }

  .fixed-bottom {
    max-height: 200px;
    overflow-x: hidden;
  }
}

.overflow-xy::-webkit-scrollbar { /* 滚动条整体样式 */
  width: 10px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 1px;
  position: relative;
  right: 4px;
}

.overflow-xy::-webkit-scrollbar-thumb { /* 滚动条里面小方块 */
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.overflow-xy::-webkit-scrollbar-track { /* 滚动条里面轨道 */
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #EDEDED;
}

.content-title {
  margin: 18px 0 0 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content-text {
  margin: 8px 0 30px 16px;
  color: rgba(0, 0, 0, 0.6);
}

.fixed-item-icon {
  width: 10px;
  height: 16px;
  padding: 20px 16px 20px 32px;
}

.cursion {
  cursor: pointer;
}

.content-isEdit {
  margin-bottom: 16px;
}

#content-isEdit .el-input {
  width: 416px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-cascader {
  width: 416px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input__icon {
  line-height: 28px;
}

>>> #content-isEdit .el-input__inner {
  width: 416px;
  height: 36px;
  line-height: 36px;
}

.item-flex1 {
  flex: 1;
}

.content-text-bottom {
  margin-bottom: 25px;
}

.fixed-bottom {
  display: flex;
  width: 464px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 8px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.content-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  background: rgba(47, 84, 235, 1);
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  cursor: pointer;
}

.content-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.detailcancel {
  width: 50%;
}

.detailcancel:after {
  content: '|';
  position: relative;
  left: 108px;
  top: 1px;
}

.detailSaves {
  width: 50%;
}

.detailContent {
  display: flex;
  align-item: center;
}

.editActive:before {
  content: '*';
  color: red;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.fixedContentH {
  height: 252px;
}

.content-tran {
  color: transparent;
}
</style>
