<template>
  <div class="ldj">
    <div class="risk-problem-wrapper">
      <div class="descShow" v-if="isEditable && node.editMode">
        <div>
          <RiskLevelSelector v-model="form.riskLevel" class="form-item"></RiskLevelSelector>
          <!--          <el-input-->
          <!--              type="textarea"-->
          <!--              :disabled="!isEditable"-->
          <!--              :placeholder="isEditable ? '请输入内容' : '不显示'"-->
          <!--              v-model="form.dataText"-->
          <!--              maxlength="3000"-->
          <!--              show-word-limit-->
          <!--              class="textarea"-->
          <!--          ></el-input>-->
        </div>
        <div class="item form-item">
          <div class="title">分析结果</div>
          <div class="textarea-wrapper-two">
            <el-input
              type="textarea"
              placeholder="请输入分析结果"
              v-model="form.ruleRiskStatement"
              maxlength="3000"
              class="textarea"
              show-word-limit
              :autosize="{ minRows: 4 }"
            ></el-input>
          </div>
        </div>
        <div class="item form-item">
          <div class="title">解决方案</div>
          <div class="textarea-wrapper-two">
            <el-input
              type="textarea"
              placeholder="请输入解决方案"
              v-model="form.ruleAdvise"
              maxlength="3000"
              class="textarea"
              show-word-limit
              :autosize="{ minRows: 4 }"
            ></el-input>
          </div>
        </div>
        <div class="item form-item">
          <div class="title">法规</div>
          <div class="textarea-wrapper-two">
            <el-input
              type="textarea"
              placeholder="请输入法规"
              v-model="form.policy"
              maxlength="3000"
              class="textarea"
              :autosize="{ minRows: 4 }"
              show-word-limit
            ></el-input>
          </div>
        </div>
        <div class="item form-item">
          <div class="title">案例</div>
          <div class="textarea-wrapper-two">
            <el-input
              type="textarea"
              placeholder="请输入案例"
              v-model="form.caseText"
              maxlength="3000"
              class="textarea"
              show-word-limit
              :autosize="{ minRows: 4 }"
            ></el-input>
          </div>
          <!-- <div class="form-save-toolbar" v-if="node.indexes.length == 3 && node.editMode == true">
            <button @click="handleBtnCancelClick" class="btn">取消2</button>
            <button @click="handleBtnSave" class="btn btn-primary">确定2</button>
          </div> -->
          <div
            slot="footer"
            class="dialog-footer"
            id="dialog-footer"
            v-if="node.indexes.length == 3 && node.editMode == true"
          >
            <el-button @click="handleBtnCancelClick">取消</el-button>
            <el-button type="primary" @click="handleBtnSave">确定</el-button>
          </div>
        </div>
        <!-- <div class="item" style="display: none">
          <div class="title">风险说明</div>
          <div class="textarea-wrapper">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleRiskStatement"
              maxlength="3000"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 1, maxRows: 10 }"
            ></el-input>
          </div>
        </div>
        <div class="item">
          <div class="title blue">解决方案</div>
          <div class="textarea-wrapper">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleAdvise"
              maxlength="3000"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 1, maxRows: 10 }"
              :disabled="!editBtnShow"
            ></el-input>
          </div>
        </div>
        <div class="item">
          <div class="title blue">法规</div>
          <div class="textarea-wrapper">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="policy"
              maxlength="3000"
              show-word-limit
              class="textarea"
              autosize
              :disabled="!editBtnShow"
            ></el-input>
          </div>
        </div>
        <div class="item">
          <div class="title blue">案例</div>
          <div class="textarea-wrapper">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="caseText"
              maxlength="3000"
              class="textarea"
              show-word-limit
              autosize
              :disabled="!editBtnShow"
            ></el-input>
          </div>
        </div> -->
      </div>

      <div class="descTwo" v-else>
        <AnalyzeResult :riskLevel="node.riskLevel" :text="pdfData.ruleRiskStatement">
        </AnalyzeResult>
        <div class="desc-inner" v-if="pdfData.ruleAdvise || pdfData.policy || pdfData.caseText">
          <div class="item" v-if="pdfData.ruleAdvise">
            <div class="title blue">解决方案</div>
            <div class="textarea-wrapper">
              {{ pdfData.ruleAdvise }}
            </div>
          </div>
          <div class="item" v-if="pdfData.policy">
            <div class="title blue">法规</div>
            <div class="textarea-wrapper">
              {{ pdfData.policy }}
            </div>
          </div>
          <div class="item" v-if="pdfData.caseText">
            <div class="title blue">案例</div>
            <div class="textarea-wrapper">
              {{ pdfData.caseText }}
            </div>
          </div>
        </div>
      </div>

      <div class="chart-wrapper">
        <RiskProblemEchart
          :list="pdfData.ruleParamsTemplete"
          ref="child"
          v-if="pdfData.ruleParamsTemplete"
          :bq="node.zbBq"
          :sq="node.zbSq"
        ></RiskProblemEchart>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import RiskProblemEchart from "./RiskProblemEchart";
import mixin from "@/pages/ResultEditor/mixin.js";
import AnalyzeResult from "@/pages/ResultEditor/components/AnalyzeResult.vue";
import RiskLevelSelector from "@/pages/ResultEditor/components/RiskLevelSelector.vue";
import { stringify } from "@/utils/json";

export default {
  mixins: [mixin],
  components: {
    RiskLevelSelector,
    AnalyzeResult,
    RiskProblemEchart,
  },
  props: {
    indexes: Array,
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    editBtnShow: {
      get() {
        return !!this.node.editMode;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        nodeData.editMode = value;
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });
      },
    },
    pdfData() {
      return JSON.parse(this.node.pdfData);
    },
    ruleAdviseEdit: {
      get() {
        return this.pdfData.ruleAdviseEdit;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.ruleAdviseEdit = value;
        nodeData.pdfData = stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });

        // const pdfData = cloneDeep(this.pdfData);
        // pdfData.ruleAdvise = value;

        // //直接调用父组件里的方法设置属性
        // this.$parent.setTreeNodeAttribute({
        //   pdfData: stringify(pdfData)
        // });
      },
    },
    caseTextEdit: {
      get() {
        return this.pdfData.caseTextEdit;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.caseTextEdit = value;
        nodeData.pdfData = stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });

        // const pdfData = cloneDeep(this.pdfData);
        // pdfData.ruleAdvise = value;

        // //直接调用父组件里的方法设置属性
        // this.$parent.setTreeNodeAttribute({
        //   pdfData: stringify(pdfData)
        // });
      },
    },

    policyEdit: {
      get() {
        return this.pdfData.policyEdit;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.policyEdit = value;
        nodeData.pdfData = stringify(pdfData);
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
  },
  mounted() {},
  data() {
    return {
      form: {
        riskLevel: 1,
        ruleRiskStatement: "",
        dataText: "",
        ruleAdvise: "",
        policy: "",
        caseText: "",
      },
    };
  },
  watch: {
    node: {
      handler(val) {
        this.form.riskLevel = val.riskLevel;
        this.form.dataText = val.dataText;

        this.form.ruleAdvise = this.pdfData.ruleAdvise;
        this.form.policy = this.pdfData.policy;
        this.form.caseText = this.pdfData.caseText;
        this.form.ruleRiskStatement = this.pdfData.ruleRiskStatement;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
    handleBtnSave() {
      const nodeData = cloneDeep(this.node);
      const pdfData = cloneDeep(this.pdfData);
      nodeData.editMode = false;
      nodeData.dataText = this.form.dataText;
      nodeData.riskLevel = this.form.riskLevel;
      pdfData.ruleAdvise = this.form.ruleAdvise;
      pdfData.policy = this.form.policy;
      pdfData.caseText = this.form.caseText;

      pdfData.ruleRiskStatement = this.form.ruleRiskStatement;

      nodeData.pdfData = stringify(pdfData);
      this.setTreeDataByIndexes({
        data: nodeData,
        indexes: this.indexes,
        target: this.target,
      });
    },
    handleBtnCancelClick() {
      const nodeData = cloneDeep(this.node);
      nodeData.editMode = false;
      this.setTreeDataByIndexes({
        data: nodeData,
        indexes: this.indexes,
        target: this.target,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.risk-problem-wrapper {
  //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  background: #fff;

  .chart-wrapper {
    display: flex;
  }

  .desc {
    background: rgba(240, 245, 255, 1);
    padding: 16px;
    margin-top: 16px;

    .title {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);

    }

    .textarea-wrapper {
      margin-top: 10px;
    }
  }

  .descShow {
    margin-top: 16px;

    .item {
      margin-top: 16px;
      padding-bottom: 16px;
    }

    .item:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);

      margin-bottom 10px

    }

    .textarea-wrapper {
      margin-top: 10px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1D2129;
      line-height: 24px;
    }
  }

  .descTwo {
    .desc-inner {
      background: #F1F6FE;
      border-radius: 4px 4px 4px 4px;
      padding 16px
      margin-top: 20px;

      .item {
        .title {
          margin: 10px 0;
          font-size: 14px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #1D2129;
          line-height: 22px;
        }
        padding-bottom 16px
        border-bottom 2px dashed #dedede
      }

      .item:last-child {
        border-bottom 0 none;
      }
    }


  }
}

>>> .el-textarea.is-disabled {
  padding: 0
}


.item:first-child {
  margin-top: 0px;
}
>>>.dialog-footer{
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

}
#dialog-footer>>>.el-button{
  padding: 10px 26px;
}
>>>.el-button+.el-button {
  margin-left: 16px;
}
</style>
