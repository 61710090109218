<template>
  <div class="page-wrapper">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          <el-breadcrumb-item>开始检测</el-breadcrumb-item>
          <el-breadcrumb-item>数据读取</el-breadcrumb-item>
          <el-breadcrumb-item>报告生成</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">报告生成</template>
      </main-header>
    </div>

    <!-- <ProgressDiagram class="progressdis" :userInfo="userInfo" :progress="5" :version="version"></ProgressDiagram> -->


    <div class="invoice-content">
      <div class="progress-wrapper">
        <DaliProgress :startFrom="startFrom"></DaliProgress>
        <div class="text">报告生成中，预计2小时内完成，请耐心等待。前往
          <router-link :to='{ name: "test-log" }'>检测记录</router-link>
        </div>
      </div>
      <!--      <img class="invoice-img" src="@/assets/result/no-invoice.png" alt />-->
      <!--      <div class="invoice-text">检测报告预计2个小时内生成，请耐心等待</div>-->
      <!--      <div class="invoice-upload">报告生成后可在“检测记录”内查看下载</div>-->
      <!--      <div class="invoice-btn ease" @click="toTestLog">前往检测记录</div>-->
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import {mapState} from "vuex";
import {zq2name} from "@/utils/helper";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
// import InvoiceExtractGuide from "./components/InvoiceExtractGuide";
import Footer from "../ChooseTable/components/Footer";
import TestDataUploader from "../Components/TestDataUploader";

import {localize} from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";

localize("zh_CN", zh_CN);

//在加载的样式
import "vue-loading-overlay/dist/vue-loading.css";
import DaliProgress from "../../ResultEditor/components/DaliProgress";
import {getProgressBar} from "../../../api/api";

export default {
  components: {
    DaliProgress,
    Loading,
    BreadCrumb,
    MainHeader,
    ProgressDiagram,
    // InvoiceExtractGuide,
    saFooter: Footer,
    TestDataUploader,
  },
  props: {
    bankId: String,
    productID: [String, Number],
    productName: String,
    zqLocation: [String, Number],
    uuid: String,
    nsrsbh: String,
    zq: String,
    version: [Number, String],
  },
  data: function () {
    return {
      isLoading: false,
      fullPage: true,
      companyName: "",
      email: "",
      startFrom: 0,
      taxPayerID: "",
      mobile: "",
      userInfo: sessionStorage.getItem("method") || 1,
      haveRecord: true,
      timer:null
    };
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID"]),
    zqName: function () {
      return zq2name(this.zq);
    },
  },
  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
    }, 500);

    this.loadProgress();
    this.timer = setInterval(() => {
      this.loadProgress();
    }, 1000 * 60)

    this.checkRecord();
  },
  destroyed() {
    clearInterval(this.timer)
  },
  // 查看是否有检测记录权限

  methods: {

    async loadProgress() {
      const res = await getProgressBar(this.uuid)
      this.startFrom = res.data.exeDate
      if(res.data.accomplish){
        clearInterval(this.timer)
        this.$router.push({
          name: 'result-detect-always',
          params: {
            uuid: this.uuid,
            nsrsbh: this.nsrsbh,
            action: 'edit',
          },
        })
      }
    },
    checkRecord() {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).authorities;
      if (!getUserAuth || getUserAuth.length < 1) {
        return;
      }
      for (let i = 0; i < getUserAuth[0].menuList.length; i++) {
        if (
          getUserAuth[0].menuList[i].id == 16 &&
          getUserAuth[0].menuList[i].isSelect == false
        ) {
          this.haveRecord = false;
        }
      }
    },

    toTestLog() {
      if (!this.haveRecord) {
        this.$message({
          message: "您未获得检测记录权限，无法前往检测记录！",
          type: "warning",
        });
        return;
      }
      this.$router.push({name: "test-log"});
    },
    handleBtnNext2() {
      this.$refs["validationObserver"].validate().then((success) => {
        if (!success) {
          return;
        } else {
          this.$refs["testDataUploader"].handleBtnNext2Click();
        }
      });
    },
    // genTestReport({
    //       bankId: this.bankId,
    //       goodsId: this.productID,
    //       kjzd: this.companyInfo.kjzd,
    //       token: this.authorization,
    //       nsrsbh: this.companyInfo.nsrsbm,
    //       tag: this.version,
    //       id: this.$store.state.UploadTable.permissionID,
    //       uuid: this.uuid,
    //       zq,
    //       invoice_nsrsbh: this.inVoiceTaxPayerID,
    //       invoice_email: this.inVoiceEmail,
    //       invoice_company_name: this.inVoiceCompanyName,
    //       invoice_phone: this.inVoiceMobile
    //     });
  },
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
  height calc(100vh - 60px)
}

.form-wrapper {
  display: flex;
  margin-top: 24px;

  .left-wrapper {
    width: 50%;
  }

  .item {
    color: #666;
    font-size: 14px;
    margin-top: 16px;

    .required-icon {
      color: #F5222D;
      margin-right: 4px;
    }

    .label {
      width: 150px;
      display: inline-block;
      text-align: right;
      margin-right: 8px;
      vertical-align: top;
      line-height: 36px;
    }

    .input {
      border: 1px solid #D9D9D9;
      width: 400px;
      height: 36px;
      line-height: 36px;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      outline: none;
    }

    .input-wrapper {
      display: inline-block;
    }

    .big {
      font-size: 24px;
      margin: 16px auto;
      color: #333;
    }
  }

  .info {
    margin-left: 50px;
    margin-top: 25px;
  }
}

.progressdis {
  width: 90%;
  margin: 24px auto;
}

.invoice-content {
  text-align: center;
  height: calc(100% - 60px - 96px);
  min-height 768px;
  box-sizing border-box
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 32px 30px;
  display flex
  align-items center
  justify-content center

  .progress-wrapper {
    display flex
    align-items center
    justify-content center
    flex-direction column
  }

  .text {
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
    margin-top 50px
  }
}

.invoice-img {
  margin-top: 80px;
}

.invoice-text {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.invoice-upload {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.invoice-btn {
  width: 170px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  margin-top: 24px;
  cursor: pointer;
}

.invoice-btn:hover {
  background: rgba(47, 64, 235, 1);
}

.ease {
  transition: all 0.3s;
}
</style>
