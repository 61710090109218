<template>
  <div class="diqu" @click="diquDisplayClick">
    <div>检测地区概览表</div>
    <i v-show="diquDisplay == 1" class="el-icon el-icon-caret-right"></i>
    <i v-show="diquDisplay == 2" class="el-icon el-icon-caret-bottom"></i>
    <img
      v-if="diquDisplay == 2"
      class="diquImg"
      src="//shuian-file.oss-cn-hangzhou.aliyuncs.com/user-image/testImage/area.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      diquDisplay: 1,
    };
  },
  methods: {
    diquDisplayClick() {
      this.diquDisplay == 1 ? (this.diquDisplay = 2) : (this.diquDisplay = 1);
    },
  },
};
</script>

<style lang="stylus" scoped>
.diqu {
  position: absolute;
  left: 30px;
  top: 78px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  user-select: none;
}

.diquImg {
  position: absolute;
  top: 20px;
  left: 100px;
  width: 600px;
  z-index: 2002;
}

.el-icon {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  margin-left: 6px;
}
</style>
