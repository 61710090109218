<template>
  <div :class="popperClass">
    <div class="text" v-if="!disabled&&(isInputModifiedByMyself||isInputModified)">此内容已修改</div>
    <div class="content-wrapper">
      <slot></slot>
    </div>

  </div>

</template>
<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  props: {
    isInputModifiedByMyself: {
      type: Boolean,
      default: false
    },
    isInputModified: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  name: "ModifiedTip",
  computed: {
    popperClass() {
      let ret = "";
      if (this.disabled) {
        return ret;
      }
      if (this.isInputModifiedByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },
  }
})
</script>


<style scoped lang="stylus">
.modified-tooltip-red {
  .content-wrapper {
    border: 1px solid #F5222D;
    box-sizing border-box
  }

  .text {
    background #F5222D;
    color #fff;
    padding 3px 5px
    display inline-block
    font-size 12px
    border-radius 5px 5px 0 0
  }

}

.modified-tooltip-yellow {
  .content-wrapper {
    border: 1px solid #faad14;
    box-sizing border-box
  }

  .text {
    background #faad14;
    color #fff;
    padding 3px 5px
    display inline-block
    border-radius 5px 5px 0 0
  }
}
</style>
