// 菜单配置
// headerMenuConfig：头部导航配置
// asideMenuConfig：侧边导航配置

// 事务所可以看到房地产的ID  50,22,1,71
let isFdcFront = [1, 22, 50, 71, 49];
let firmId = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).firmId;
// 事务所可以看到房地产的ID  50,22,1,71


const headerMenuConfig = [];

const asideMenuConfig = [{
    path: "/",
    name: "首页",
    icon: "iconfont iconshouye",
    id: 1,
    routeNames: ["homePage"]
  },
  {
    path: "/",
    name: "客户管理",
    id: 4,
    icon: "el-icon-data-line",
    children: [{
        path: "/product/custom",
        name: "客户清单",
        id: 5,
        icon: "iconfont iconkehu",
        routeNames: ["custom"]
      },
      {
        path: "/custom/distribution",
        name: "客户分配记录",
        id: 55, 
        icon: "iconfont iconxiangmu",
        routeNames: ["customDistribution"]
      }
    ]
  },
  {
    path: "/",
    name: "项目管理",
    id: 9,
    icon: "el-icon-data-line",
    children: [{
      path: "/product/project",
      name: "项目清单",
      id: 10,
      icon: "iconfont iconxiangmu",
      routeNames: ["project"]
    }]
  },
  {
    path: "/",
    name: "风险检测",
    id: 14,
    children: [{
        path: "/test/products",
        name: "开始检测",
        id: 15,
        routeNames: [
          "test-hub",
          "choose-table",
          "upload-table",
          "result",
          "test-invoice"
        ],
        icon: "iconfont iconjiancezhongxin"
      },
      {
        path: "/test/test-log",
        name: "检测记录",
        id: 16,
        routeNames: ["test-log", "reportBill"],
        icon: "iconfont iconlishijiance"
      }
    ]
  },

  // 房地产项目
  {
    path: "/",
    name: "房地产测算",
    id: isFdcFront.indexOf(firmId) > -1 ? 9 : '',
    icon: "el-icon-data-line",
    children: [{
        path: "/product-fdc/product",
        name: "房地产项目",
        id: isFdcFront.indexOf(firmId) > -1 ? 10 : '',
        icon: "iconfont icongongshangchaxun",
        routeNames: ["fdcProject"]
      },
      {
        path: "/product-fdc/account",
        name: "房企账号",
        id: isFdcFront.indexOf(firmId) > -1 ? 10 : '',
        icon: "iconfont iconfapiaogongju",
        routeNames: ["fdcAccount"]
      },
      {
        path: "/test/test-log-fdc",
        name: "风险检测",
        id: isFdcFront.indexOf(firmId) > -1 ? 10 : '',
        // icon: "iconfont iconxiangmu",
        icon: "iconfont iconshenjigongju",
        routeNames: ["test-log-fdc", "choose-table-fdc", "upload-table-fdc"]
      }
    ]
  },
  // 房地产项目

  {
    path: "/",
    name: "审核管理",
    id: 22,
    icon: "el-icon-data-line",
    children: [{
      path: "/examine/examine",
      name: "审核列表",
      id: 23,
      icon: "iconfont iconshenhe",
      routeNames: ["examine"]
    }]
  },

  {
    path: "/",
    name: "数据统计",
    id: 26,
    icon: "el-icon-data-line",
    children: [{
      path: "/product/statistics",
      name: "数据统计",
      id: 27,
      icon: "iconfont iconshuju",
      routeNames: ["statistics"]
    },{
      path: "/product/employeeStatistics",
      name: "职工人数统计",
      id: 27,
      icon: "iconfont iconshuju",
      routeNames: ["employeeStatistics"]
    }]
  },
  {
    path: "/",
    name: "小工具",
    id: 50,
    icon: "el-icon-data-line",
    children: [{
        path: "/tools/billList",
        name: "发票查询",
        id: 53, //ID未确定
        icon: "iconfont iconfapiaogongju",
        routeNames: ["billList", "billDetail", "billGetData"]
      },
      {
        path: "/tools/businessCirclesQuery",
        name: "工商查询",
        icon: "iconfont icongongshangchaxun",
        id: 56,
        routeNames: ["businessCirclesQuery", ]
      },
      {
        path: "/tools/toolsAudit",
        name: "审计小工具",
        id: 51,
        icon: "iconfont iconshenjigongju",
        routeNames: ["toolsAudit", "authorization", "analysisResult", "analysisTable",
          "toolsTables"
        ]
      }, {
        path: "/tools/stampTax/yinHuaShuiList",
        name: "印花税测算",
        id: 52,
        icon: "iconfont iconyinhuashuicesuan",
        routeNames: ["stampTax-basicInfo", "stampTax-heTongTaiZhang", "stampTax-yinHuashui",
          "stampTax-done", "stampTax-list", "yinHuaShuiResult", "stampTax-result"
        ]
      }
    ]
  },


  {
    path: "/",
    name: "账户管理",
    id: 30,
    icon: "el-icon-data-line",
    children: [{
        path: "/account/account",
        name: "账号列表",
        id: 31,
        icon: "iconfont iconzhanghu",
        routeNames: ["account"]
      },
      {
        path: "/account/role",
        name: "角色管理",
        id: 36,
        icon: "iconfont iconjiaose",
        routeNames: ["role"]
      }
    ]
  },
  {
    path: "/",
    name: "品牌配置",
    id: 41,
    children: [{
      path: "/config/logo",
      name: "品牌配置",
      id: 42,
      icon: "iconfont iconshezhi",
      routeNames: ["configLogo"]
    }]
  },

  {
    path: "/",
    name: "日志管理",
    id: 44,
    children: [{
      path: "/config/record",
      name: "日志记录",
      id: 45,
      icon: "iconfont iconrizhi",
      routeNames: ["configRecord"]
    }]
  }


  // {
  //     path: "/product/statistics",
  //     name: "数据统计",
  //     icon: "iconfont icondingdan",
  //     routeNames: ["statistics"]
  // },

  // {
  //     path: "/",
  //     name: "用户设置",
  //     children: [{
  //         path: "/setUser",
  //         name: "用户设置",
  //         routeNames: ["setUser"],
  //         icon: "iconfont iconshezhi"
  //     }]
  // }
];

export {
  headerMenuConfig,
  asideMenuConfig
};
