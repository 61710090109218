var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{staticClass:"main section"},[(_vm.msgTitle == false)?_c('div',{staticClass:"section-title"},[_c('div',{staticClass:"titleIcon"}),_c('div',{staticClass:"title"},[_vm._v("财税风险")])]):_c('div',{staticClass:"head-level-title"},[_c('div',{staticClass:"head-level-0"},[_vm._v("企业财税风险概述")]),_c('div',{staticClass:"bottomDiv"})]),_c('div',{staticClass:"stats-wrapper"},[_c('div',{staticClass:"stats"},[_c('div',{staticClass:"stats-item FirstItem",staticStyle:{"width":"21%"}},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.data.total))]),_c('div',{staticClass:"text"},[_vm._v("检测总项")])]),_c('div',{staticClass:"stats-item"},[_c('div',{staticClass:"Items circularRed"},[_vm._m(0),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.data.highRisk))]),_c('div',{staticClass:"text"},[_vm._v("高风险")])])]),_c('div',{staticClass:"stats-item"},[_c('div',{staticClass:"Items circularYellow"},[_vm._m(1),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.data.middleRisk))]),_c('div',{staticClass:"text"},[_vm._v("中风险")])])]),_c('div',{staticClass:"stats-item"},[_c('div',{staticClass:"Items circularBlue"},[_vm._m(2),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.data.lowRisk))]),_c('div',{staticClass:"text"},[_vm._v("低风险")])])]),_c('div',{staticClass:"stats-item"},[_c('div',{staticClass:"Items circularGreen"},[_vm._m(3),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.data.noRisk))]),_c('div',{staticClass:"text"},[_vm._v("无风险")])])])])]),_c('div',{staticClass:"ciyun-wrapper",staticStyle:{"display":"none"}},[_c('div',{staticClass:"toolbar"},[_c('BigPowerLegends',{attrs:{"name":"风险词云","legends":[
          { color: '#EA6C5B', text: '高风险' },
          { color: '#F7C122', text: '中风险' },
          { color: '#63DAAB', text: '低风险' },
        ]}})],1),_c('div',{staticClass:"section-body"},[(_vm.data.riskWordCloudList)?_c('Ciyun',{ref:"danmu",staticClass:"danmu",attrs:{"legendsName":"风险词云","data":_vm.data.riskWordCloudList},on:{"click":_vm.handleCiyunClick}}):_c('div',{staticClass:"danmu"})],1)]),_c('div',{staticClass:"graph"},[_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"label"},[_vm._v("风险等级 :")]),_c('el-select',{attrs:{"placeholder":"请选择风险等级","filterable":""},on:{"change":_vm.loadFinancialRisk},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}},_vm._l((_vm.grade),function(item){return _c('el-option',{key:item.status,attrs:{"label":item.name,"value":item.status}})}),1)],1)]),_c('div',{staticClass:"table-wrapper"},[(_vm.dataTable)?_c('BigPowerTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"table",attrs:{"data":_vm.dataTable,"element-loading-text":"拼命加载中"}}):_vm._e()],1)]):_c('div',{staticClass:"main section",staticStyle:{"padding-bottom":"0"}},[_c('BigPowerLoading',{attrs:{"height":"790px"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backImg"},[_c('img',{attrs:{"src":require("../../../../assets/util/路径 9.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backImg"},[_c('img',{attrs:{"src":require("../../../../assets/util/路径 9.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backImg"},[_c('img',{attrs:{"src":require("../../../../assets/util/路径 9.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backImg"},[_c('img',{attrs:{"src":require("../../../../assets/util/路径 9.png"),"alt":""}})])
}]

export { render, staticRenderFns }