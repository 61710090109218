<template>
  <div class="container">
    <div class="header">
      <div class="title">数据走势</div>
      <div class="select">
        <div>
          <span class="year" :class="{choice:act==0}" @click="checkMonth">月度</span>
          <span class="month" :class="{choice:act==1}" @click="checkYear">年度</span>
        </div>
        <div class="time" v-if="act==0">
          <el-date-picker
            v-model="formItem.startDate"
            type="date"
            placeholder="选择开始日期"
            :picker-options="pickerOptionstart"
            :clearable="false"
            @change="startChange"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <span class="rangeClass">至</span>
          <el-date-picker
            v-model="formItem.endDate"
            type="date"
            placeholder="选择结束日期"
            :picker-options="pickerOptions"
            :clearable="false"
            @change="endChange"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <div class="time" v-if="act==1">
          <el-date-picker
            v-model="formItemYear.startDate"
            type="month"
            placeholder="选择月"
            :clearable="false"
            @change="startChangeY"
            value-format="yyyy-MM"
          ></el-date-picker>
          <span class="rangeClass">至</span>
          <el-date-picker
            v-model="formItemYear.endDate"
            type="month"
            placeholder="选择月"
            :picker-options="pickerOptionsY"
            :clearable="false"
            @change="endChangeY"
            value-format="yyyy-MM"
          ></el-date-picker>
        </div>
      </div>
    </div>
    <div class="chartContainer">
      <div id="chartColumn" class="chart"></div>
    </div>
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import { getIndexData } from "@/api/api.js";
import echarts from "echarts";
export default {
  components: { "v-chart": ECharts },
  data() {
    return {
      formItem: {
        startDate: new Date(new Date().getTime() - 3600 * 1000 * 24 * 6),
        endDate: new Date(),
      },
      pickerOptionstart: {
        disabledDate: (time) => {
          // let endDateVal = this.formItem.endDate;
          // if (endDateVal) {
          //   return time.getTime() > new Date(endDateVal).getTime();
          // }
        },
      },
      pickerOptions: {
        disabledDate: (time) => {
          let beginDateVal = this.formItem.startDate;
          if (beginDateVal) {
            var date1 = new Date(this.formItem.startDate);
            var date2 = new Date(date1);
            date2.setDate(date1.getDate() + 30);
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > date2.getTime()
            );
          }
        },
      },
      pickerOptionsY: {
        disabledDate: (time) => {
          let beginDateVal = this.formItemYear.startDate;
          if (beginDateVal) {
            var date1 = new Date(this.formItemYear.startDate);
            var date2 = new Date(date1);
            date2.setDate(date1.getDate() + 365);
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > date2.getTime()
            );
          }
        },
      },
      formItemYear: {
        startDate: new Date(new Date().getTime() - 3600 * 1000 * 24 * 365),
        endDate: new Date(),
      },
      type: 1,
      xList: null,
      yList1: [],
      yList2: [],
      yList3: [],
      yearNum: 30,
      act: 0,
      value: null,
      polars: null,
      polar: {
        backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          seriesIndex: "11",
          padding: 10,
        },
        legend: {
          bottom: 24,
          data: ["客户数量", "项目数量", "检测数量"],
          icon: "rectangle",
          itemGap: 48,
          itemWidth: 12,
          itemHeight: 8,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "16%",
          top: "8%",
          containLabel: true,
        },
        toolbox: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          nameGap: 10,
          axisLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLabel: {
            margin: 18,
            textStyle: {
              color: "rgba(0,0,0,0.43)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          axisLabel: {
            formatter: "{value}个",
            textStyle: {
              color: "rgba(0,0,0,0.43)",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: ["#DAD5E8"],
              opacity: 0.6,
            },
          },
        },
        series: [
          {
            name: "客户数量",
            type: "line",
            color: "#2F54EB",
            symbol: "circle",
            symbolSize: 10,
            data: [],
            itemStyle: {
              borderWidth: 2,
              borderColor: "#fff",
              normal: {
                areaStyle: {
                  type: "default",
                  color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(47,84,235,0.48)" },
                    { offset: 1, color: "#fff" },
                  ]),
                },
                color: "#2F54EB",
                lineStyle: {
                  color: "#2F54EB",
                },
                itemStyle: {
                  color: "#2F54EB",
                },
                borderColor: "#fff",
                borderWidth: 2,
              },
            },
          },
          {
            name: "项目数量",
            type: "line",
            color: "#FAAD14",
            symbol: "circle",
            symbolSize: 10,
            data: [],
            itemStyle: {
              borderWidth: 2,
              borderColor: "#fff",
              normal: {
                areaStyle: {
                  type: "default",
                  color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(250,173,20,0.48)" },
                    { offset: 1, color: "#fff" },
                  ]),
                },
                color: "#FAAD14",
                lineStyle: {
                  color: "#FAAD14",
                },
                itemStyle: {
                  color: "#FAAD14",
                },
                borderWidth: 2,
                borderColor: "#fff",
              },
            },
          },
          {
            name: "检测数量",
            type: "line",
            color: "#2FC25B",
            symbol: "circle",
            symbolSize: 10,
            data: this.yList3,
            itemStyle: {
              borderWidth: 2,
              borderColor: "#fff",
              normal: {
                areaStyle: {
                  type: "default",
                  color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(82,196,26,0.48)" },
                    { offset: 1, color: "#fff" },
                  ]),
                },
                color: "#2FC25B",
                lineStyle: {
                  color: "#2FC25B",
                },
                itemStyle: {
                  color: "#2FC25B",
                },
                borderColor: "#fff",
                borderWidth: 2,
              },
            },
          },
        ],

        dataZoom: [
          {
            type: "slider",
            show: false,
            yAxisIndex: [0],
            left: "96%",
            bottom: "5%",
            start: 0,
            end: 100,
          },
        ],
      },
    };
  },
  created() {
    this.value = [this.formItem.startDate, this.formItem.endDate];
    this.type = 1;
    this.getindexData(
      this.type,
      this.formItem.startDate,
      this.formItem.endDate
    );
  },
  methods: {
    startChange() {
      this.formItem.endDate = "";
    },

    endChange() {
      if (this.formItem.endDate) {
        this.getindexData(
          this.type,
          this.formItem.startDate,
          this.formItem.endDate
        );
      }
    },

    startChangeY() {
      this.formItemYear.endDate = "";
    },

    endChangeY() {
      if (this.formItemYear.endDate) {
        this.getindexData(
          this.type,
          this.formItemYear.startDate,
          this.formItemYear.endDate
        );
      }
    },

    checkYear() {
      this.act = 1;
      this.yearNum = 365;
      this.formItemYear.endDate = this.formatDateTes(new Date(), 1);
      this.formItemYear.startDate = this.formatDateTes(
        this.fun_date(this.yearNum),
        1
      );
      this.value = [this.formItemYear.startDate, this.formItemYear.endDate];
      this.type = 2;
      this.getindexData(
        this.type,
        this.formItemYear.startDate,
        this.formItemYear.endDate
      );
    },

    checkMonth() {
      this.act = 0;
      this.yearNum = 30;
      this.formItem.endDate = this.formatDateTes(new Date());
      this.formItem.startDate = this.formatDateTes(this.fun_date(this.yearNum));
      this.value = [this.formItem.startDate, this.formItem.endDate];
      this.type = 1;
      this.getindexData(
        this.type,
        this.formItem.startDate,
        this.formItem.endDate
      );
    },

    showChart() {
      this.chartColumn = echarts.init(document.getElementById("chartColumn"));
      this.chartColumn.setOption(this.polar);
    },
    // 获取折线图
    getindexData(type, date2, date) {
      let params = {
        type: type,
        fromDate: date2,
        toDate: date,
      };
      getIndexData(params, localStorage.getItem("authorization")).then(
        (res) => {
          this.xList = res.data.consumerData.map((item) => {
            return item.date;
          });
          this.yList1 = res.data.consumerData.map((item) => {
            return item.total;
          });
          this.yList2 = res.data.projectData.map((item) => {
            return item.total;
          });
          this.yList3 = res.data.recordData.map((item) => {
            return item.total;
          });
          this.polar.xAxis.data = this.xList;
          this.polar.series[0].data = this.yList1;
          this.polar.series[1].data = this.yList2;
          this.polar.series[2].data = this.yList3;
          this.showChart();
        }
      );
    },

    fun_date(aa) {
      var date1 = new Date(),
        time1 =
          date1.getFullYear() +
          "-" +
          (date1.getMonth() + 1) +
          "-" +
          date1.getDate(); //time1表示当前时间baidu

      var date2 = new Date(date1);

      date2.setDate(date1.getDate() - aa);
      var time2 =
        date2.getFullYear() +
        "-" +
        (date2.getMonth() + 1) +
        "-" +
        date2.getDate();
      return time2;
    },

    formatDateTes(timestamp, index) {
      if (!index) index = 0;
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      if (index == 0) {
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
      } else {
        var M =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
      }
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      if (index == 0) {
        return Y + M + D;
      } else {
        return Y + M;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartContainer {
  width: 100%;
  height: 468px;
}

.echarts {
  width: 100%;
  height: 100%;
}

.chart {
  width: 100%;
  height: 468px;
}

.container {
  height: 522px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(218, 213, 232, 0.6);
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  margin-left: 24px;
}

.select {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.time {
  margin: 0 16px;
}

.year, .month {
  display: inline-block;
  width: 60px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
}

.month {
  border-radius: 0px 4px 4px 0px;
  border-left: none;
}

.choice {
  color: #2F54EB;
}

.el-range-editor.el-input__inner {
  height: 36px;
}

>>> .time .el-date-editor .el-range__icon {
  line-height: 28px;
}

>>> .el-date-editor .el-range-separator {
  line-height: 28px;
}

>>> .el-date-editor .el-range__close-icon {
  line-height: 28px;
}

>>> .el-date-editor {
  width: 150px;
  height: 36px;
  line-height: 36px;
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

.rangeClass {
  padding: 0 10px;
}
</style>