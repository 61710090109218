<template>
    <el-dialog title="温馨提示" :visible.sync="dialogVisible" :close-on-click-modal="false" width="480px">
        <div class="info">
                <i class="iconfont icon-icon_warn infoIcon"></i>
                <div>
                    <div>该地区系统正在维护中，目前暂不可用。如需体验，请通过企业微信或者客服电话联系我们，我们将为您选择其他合适的版本或提供进一步的帮助。感谢您的理解与耐心等待！</div>
                    <div style="font-weight: 400;font-size: 14px;margin-top: 16px;line-height: 22px;">客服电话：0571-85055930</div>

                </div>
            
                
            </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible=false">知道了</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  import { exchange } from "@/api/api";
  export default {
    props: {},
    data() {
      return {
        dialogVisible: false,
      };
    },
    methods: {
     
    }
  };
  </script>
  
  <style lang="stylus" scoped>
  .info{
    padding :12px
    display: flex;
    >i{
        margin-right :12px;
    }
    .infoIcon{
        color:#FDB320;
        font-size:22px
      }
      div{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #1D2129;
        line-height: 24px;
    }
}
  .error-tip {
    margin-top: 10px;
    color: #F5222D;
  }
  
  >>> .el-dialog {
    border-radius: 8px;
    overflow: hidden;
  }
  
  >>> .el-dialog__header {
    padding: 16px 24px;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid #EEEEEE;
  }
  
  >>> .el-dialog__title {
    color: #333;
    font-size: 16px;
    font-weight: 600;
  }
  
  >>> .el-dialog__body {
    padding: 0;
  }
  
  >>> .el-dialog__footer {
    border-top: 1px solid #EEEEEE;
    padding: 12px 24px;
  
    .el-button {
      background: hsla(228, 82%, 55%, 1);
      width: 76px;
      height: 32px;
      background: rgba(47, 84, 235, 1);
      border-radius: 4px;
      border: 0 none;
      padding: 0;
      line-height: 32px;
      font-size: 14px;
    }
  
    .el-button:hover {
      background: hsla(228, 82%, 45%, 1);
    }
  }
  
  .row {
    width: 80%;
    padding: 48px 0;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    color: #666;
  
    .label {
      position: relative;
      top: 10px;
      vertical-align: top;
      margin-right: 8px;
    }
  
    .control {
      margin-left: 8px;
      vertical-align: top;
      width: 320px;
    }
  }
  </style>