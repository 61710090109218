<template>
  <el-dialog
    title="添加目录"
    :visible.sync="dialogVisible"
    width="460px"
    :before-close="close"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :destroy-on-close="true"
  >
    <div class="wrapper">
      <el-form :rules="rules" :model="form" class="form" ref="ruleForm">
        <el-form-item label="上级目录">
          <!--          <div class="label">上级目录</div>-->
          <MenuTreePathSelector v-model="form.indexes"></MenuTreePathSelector>
        </el-form-item>
        <el-form-item label="目录名称" prop="name">
          <!--          <div class="label"><span class="required">*</span>目录名称</div>-->
          <el-input v-model="form.name" size="small" placeholder="请输入目录名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="label"></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleBtnConfirmClick">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { defineComponent } from "vue";
import MenuTreePathSelector from "@/pages/ResultEditor/components/MenuTreePathSelector.vue";

export default defineComponent({
  name: "MenuTreePathSelectorDialog",
  components: { MenuTreePathSelector },
  data() {
    return {
      dialogVisible: false,
      form: {
        indexes: "",
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入目录名称", trigger: "blur" }],
      },
    };
  },
  methods: {
    async open() {
      const selectedNode = this.$store.getters["ResultEditor/getSelectedNode"]();
      let indexes = [];
      if (selectedNode) {
        indexes = selectedNode.indexes;
      }
      this.form.indexes = indexes;
      this.form.name = "";
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    async handleBtnConfirmClick() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.dialogVisible = false;

          this.$emit("confirm", {
            indexes: this.form.indexes,
            name: this.form.name,
          });
        }
      });
    },
  },
});
</script>

<style scoped lang="stylus">
>>> .el-dialog__body {
  padding 0 24px
}

.wrapper {
  padding-top 20px

  .form {
    >>>.el-form-item__label{
      font-size 14px
      color #1D2129
      line-height 1.5
    }
  }
}
>>>.el-dialog__title {
  font-weight: 600;
}
>>>.el-input--small .el-input__inner {
  height: 36px;
  line-height: 36px;
}
>>>.el-button{
  padding: 11px 24px;
}
>>>.el-button+.el-button{
  margin-left: 16px;
}
>>>.el-dialog__footer {
  padding: 10px 24px 24px;
}
</style>
