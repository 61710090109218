<template>
  <div>
    <div>
      <div class="title-content">
        <bread-crumb>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>日志管理</el-breadcrumb-item>
            <el-breadcrumb-item>日志记录</el-breadcrumb-item>
          </el-breadcrumb>
        </bread-crumb>
        <main-header>
          <template slot="title">日志记录</template>
        </main-header>
      </div>
      <div class="filter-body">
        <div class="inputs-wrapper">
          <div class="inputs-wraper-row">
            <div class="form-item" id="form-items">
              <div class="label">操作人：</div>
              <el-input
                v-model="filter.operator"
                placeholder="请输入"
                clearable
                @keyup.enter.native="search"
                @input="changeName"
              ></el-input>
            </div>
            <div class="form-item" id="form-items">
              <div class="label">时间：</div>
              <el-date-picker
                v-model="filter.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="changeTime"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <div class="btn-look-up ease" data-index="100" @click="search">查询</div>
        <!-- <button class="btn btn-primary btn-look-up" @click="reset">重置</button> -->
      </div>
      <div class="content-body">
        <template>
          <el-table
            :data="tableDataList"
            :default-sort="{ prop: 'date', order: 'descending' }"
            style="width: 100%"
          >
            <el-table-column width="12"></el-table-column>
            <el-table-column prop="operator" label="操作人"></el-table-column>
            <el-table-column prop="operation" label="行为"></el-table-column>
            <el-table-column prop="operatTime" label="时间"></el-table-column>
            <el-table-column prop="ip" label="ip" width="130"></el-table-column>
            <el-table-column width="12"></el-table-column>
          </el-table>
        </template>
      </div>
      <el-pagination
        class="pagination-low"
        @current-change="handleCurrentChange"
        :current-page="pageno"
        layout="total, prev, pager, next, jumper"
        :total="totalNumber"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getAPIBase, showLog } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import pagination from "@/pages/product/components/pagination.vue";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    pagination,
  },

  async mounted() {
    this.showlog();
  },
  data() {
    return {
      dragging: null,
      totalNumber: 10, //总数
      selectArr: ["10", "20", "50", "100"], //传入可切换的每页条数
      checkCustom: false,
      tableDataList: [],
      firstTime: "2018-11-13",
      lastTime: "2099-12-30",
      tag: "",
      filter: {
        operator: "",
        status: "",
        time: [],
        source: "",
      },
      APIBase: getAPIBase(),
      tableData: [],
      pageno: 1,
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pageno = val;
      this.showlog();
    },
    changeTime() {
      this.search();
    },

    search() {
      this.pageno = 1;
      this.showlog();
    },

    changeName() {
      if (!this.filter.operator) {
        this.search();
      }
    },

    reset() {
      this.pageno = 1;
      this.filter = {
        operator: "",
        status: "",
        startTime: "",
        endTime: "",
      };

      this.showlog();
    },

    showlog() {
      let params = {
        operator: this.filter.operator.trim(),
        startTime: this.filter.time ? this.filter.time[0] : "",
        endTime: this.filter.time ? this.filter.time[1] : "",
        pageNo: this.pageno,
        pageSize: 10,
      };
      showLog(params).then((res) => {
        this.tableDataList = res.data.content;
        for (let i = 0; i < this.tableDataList.length; i++) {
          this.tableDataList[i].operatTime = this.formatDateTes(this.tableDataList[i].operatTime);
        }
        this.totalNumber = res.data.totalSize;
      });
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 15vh;
  width: 480px;
  height: 684px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

>>> #form-items .project-input .el-input {
  height: 36px;
}

>>> #form-items .el-input__inner {
  height: 36px;
}

>>> #projectBoxs .project-input .el-input {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 32px;
}

>>> #projectBoxs .project-input .el-input .el-input__inner {
  height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 0;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #F5222D;
}

.pagination {
  padding-top: 20px;
  text-align: right;
  margin-top: 20px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // min-height: 80vh;
  padding: 0 10px;
}

.btn-view {
  line-height: 28px;
  height: 28px;
  color: #2f54eb;
  margin-right: 10px;
  display: inline-block;
}

.btn-view:hover {
  color: rgb(8, 47, 202);
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // padding: 20px;
  // padding-top: 20px;
  // padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 69px;
          text-align: right;
          margin-right: 8px;
        }

        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}

.delete {
  background: rgba(245, 34, 45, 1);
}

.perfect {
  background: rgba(250, 173, 20, 1);
}

.btn-yellow {
  color: yellow;
}

.paginations {
  float: right;
  margin-right: 20px;
  margin-bottom: 30px;
}

.fixed-item {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 9999;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.fixed-content {
  width: 464px;
  height: 224px;
  background: rgba(250, 173, 20, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(250, 173, 20, 0.24);
  margin: 16px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.fixed-bottom {
  display: flex;
  width: 464px;
  height: 280px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 8px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.project-info {
  width: 416px;
  height: 220px;
  padding: 0;
  margin-top: -16px;
  margin-left: 32px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.fixed-item-top {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(218, 213, 232, 0.6);
}

.fixed-item-icon {
  width: 10px;
  height: 16px;
  padding: 20px 16px 20px 32px;
}

.content-title {
  margin: 18px 0 0 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content-text {
  margin: 10px 0 30px 16px;
  color: rgba(0, 0, 0, 0.6);
}

.content-tran {
  color: transparent;
}

.content-text-bottom {
  margin-bottom: 25px;
}

.content-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  background: rgba(47, 84, 235, 1);
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  cursor: pointer;
}

.item-flex1 {
  flex: 1;
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.cursion {
  cursor: pointer;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

.customFade-enter-active, .customFade-leave-active {
  transition: opacity 0.5s;
}

.customFade-enter, .customFade-leave-to { /* .customFade-leave-active below version 2.1.8 */
  opacity: 0;
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> .project-input .el-input__icon {
  line-height: 36px;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-select {
  width: 240px;
}

>>>.el-input {
  width: 240px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

>>>.el-pagination__editor.el-input {
  width: 50px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

>>>.form-item .el-input__icon {
  line-height: 30px;
}

.containerMM {
  width: 600px;
  height: 300px;
  border: 1px solid green;
  margin-bottom: 20px;
  word-wrap: break-word;
  word-break: normal;
}

.item {
  width: 50px;
  height: 30px;
  margin: 10px;
  display: inline-block;
  transition: all linear 0.2s;
}

.item10 {
  width: 60px;
  height: 40px;
  background: gray;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
}
</style>
