var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data.anchorPointType == 'invoice-model')?_c('div',{staticClass:"btn-toolbar",class:{ 'left-align': _vm.data.floor == 2 }},[_c('button',{staticClass:"btn btn-show-dialog",on:{"click":_vm.handleShowInvoiceModelDialog}},[_c('i',{staticClass:"iconfont iconicon_fp"}),_vm._v(" 发票 ")])]):_vm._e(),_c('div',{staticClass:"table-wrapper",class:{ default: !_vm.width }},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',_vm._l((_vm.labels),function(label,index){return _c('th',{key:index,style:(_vm.getThStyle(label))},[_vm._v(" "+_vm._s(label)+" ")])}),0)]),_c('tbody',_vm._l((_vm.tableData),function(row,index){return _c('tr',{key:index},[_vm._l((row),function(col,index2){return (!row.includes('合计'))?_c('td',{key:index2,style:({ 'text-align': _vm.getAlign(_vm.labels[index2]) })},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":_vm.tipText(index, index2),"placement":"top-start","disabled":!_vm.tipText(index, index2)}},[(col == 'button{detail}')?_c('span',{staticClass:"clickable",class:{ 'text-has-tip': _vm.tipText(index, index2) },on:{"click":function($event){return _vm.handleCellClick(index)}}},[_c('a',{staticClass:"link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleToggleDetailCLick.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.toggleLabel))])]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":col,"placement":"right","disabled":col.length < 15,"open-delay":1000}},[_c('span',{class:{
                    'text-has-tip': _vm.tipText(index, index2),
                    'text-has-table': !!_vm.subTable(index, index2),
                  },domProps:{"innerHTML":_vm._s(col)},on:{"click":function($event){_vm.handleSubTableLinkClick(_vm.subTable(index, index2), index, index2)}}})])],1)],1):_vm._e()}),_vm._l((row),function(col,index2){return (row.includes('合计'))?_c('td',{staticStyle:{"position":"sticky","bottom":"-1px","background":"#fff","border":"1px solid #dedede"},style:({ 'text-align': _vm.getAlign(_vm.labels[index2]) })},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":_vm.tipText(index, index2),"placement":"top-start","disabled":!_vm.tipText(index, index2)}},[(col == 'button{detail}')?_c('span',{staticClass:"clickable",class:{ 'text-has-tip': _vm.tipText(index, index2) },on:{"click":function($event){return _vm.handleCellClick(index)}}},[_c('a',{staticClass:"link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleToggleDetailCLick.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.toggleLabel))])]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":col,"placement":"right","disabled":col.length < 15,"open-delay":1000}},[_c('span',{class:{
                    'text-has-tip': _vm.tipText(index, index2),
                    'text-has-table': !!_vm.subTable(index, index2),
                  },domProps:{"innerHTML":_vm._s(col)},on:{"click":function($event){_vm.handleSubTableLinkClick(_vm.subTable(index, index2), index, index2)}}})])],1)],1):_vm._e()})],2)}),0)]),_c('el-drawer',{attrs:{"title":_vm.subTableInDrawer ? _vm.subTableInDrawer.name : '',"visible":_vm.drawer,"direction":"rtl","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"drawer-inner"},[(_vm.generalTableData)?_c('GenericTable',{attrs:{"data":_vm.generalTableData}}):_vm._e()],1)]),_c('el-dialog',{attrs:{"title":"","visible":_vm.dialogVisible,"width":"1240px","z-index":2010,"before-close":_vm.handleDialogClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('ReportBill',{attrs:{"tax-no":_vm.$route.query.taxNo,"uuid":_vm.$route.params.uuid,"model-id":_vm.data.modelId}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }