<template>
  <div>
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- {{grade[pdfData.grade]}} -->
        <template v-if="data.userData == 1">
          <el-input
            v-model="policyDigest"
            placeholder="请输入内容"
            :disabled="!isEditable"
          ></el-input>
        </template>
        <template v-else>
          <div class="item-content" :title="pdfData.policyDigest">{{ pdfData.policyDigest }}</div>
        </template>
      </div>
      <div class="item-right">
        <div class="item-right-title">
          <span class="item-flex-l item-color-l">标题：</span>
          <span class="item-flex-1 item-color-r">
            <template v-if="data.userData == 1">
              <el-input
                v-model="policyTitle"
                placeholder="请输入内容"
                :disabled="!isEditable"
              ></el-input>
            </template>
            <template v-else>{{ pdfData.policyTitle }}</template>
          </span>
        </div>
        <div class="item-right-title">
          <span class="item-flex-l item-color-l">文号：</span>
          <span class="item-flex-1 item-color-r">
            <template v-if="data.userData == 1">
              <el-input
                v-model="policySymbol"
                placeholder="请输入内容"
                :disabled="!isEditable"
              ></el-input>
            </template>
            <template v-else>{{ pdfData.policySymbol }}</template>
          </span>
        </div>
        <div class="item-right-title">
          <span class="item-flex-l item-color-l">出台单位：</span>
          <span class="item-flex-1 item-color-r">
            <template v-if="data.userData == 1">
              <el-input
                v-model="promulgatedCompany"
                placeholder="请输入内容"
                :disabled="!isEditable"
              ></el-input>
            </template>
            <template v-else>{{ pdfData.promulgatedCompany }}</template>
          </span>
        </div>
        <div class="item-right-title">
          <span class="item-flex-l item-color-l">官方公告：</span>
          <span class="item-flex-1 item-color-r">
            <template v-if="data.userData == 1">
              <el-input
                v-model="relatedLinks"
                placeholder="请输入内容"
                :disabled="!isEditable"
              ></el-input>
            </template>
            <template v-else>{{ pdfData.relatedLinks }}</template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import mixin from "@/pages/ResultEditor/mixin.js";

export default {
  mixins: [mixin],
  props: {
    indexes: Array,
  },
  data: function () {
    return {
      gradeLabel: ["", "国家级", "省级", "市级", "区级"],
    };
  },
  watch: {
    grade: {
      handler(newVal, oldVal) {
        const data = this.data;
        data.label = this.gradeLabel[this.pdfData.grade];
        const indexes = this.indexes;
        this.setTreeDataByIndexes({
          data,
          indexes,
          target: "discountPolicyTree",
          silent: true,
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    pdfData() {
      return JSON.parse(this.data.pdfData);
    },
    grade() {
      return this.pdfData.grade;
    },
    data() {
      return this.findTheNodeByIndexes(this.indexes, "discountPolicyTree");
    },
    policyTitle: {
      get: function () {
        return this.pdfData.policyTitle;
      },
      set: function (val) {
        let pdfData = this.pdfData;
        pdfData.policyTitle = val;

        const data = {
          pdfData: JSON.stringify(pdfData),
        };
        this.setTreeDataByIndexes({
          data,
          indexes: this.indexes,
          target: "discountPolicyTree",
        });
      },
    },
    policyDigest: {
      get: function () {
        return this.pdfData.policyDigest;
      },
      set: function (val) {
        let pdfData = this.pdfData;
        pdfData.policyDigest = val;

        const data = {
          pdfData: JSON.stringify(pdfData),
        };
        this.setTreeDataByIndexes({
          data,
          indexes: this.indexes,
          target: "discountPolicyTree",
        });
      },
    },
    policySymbol: {
      get: function () {
        return this.pdfData.policySymbol;
      },
      set: function (val) {
        let pdfData = this.pdfData;
        pdfData.policySymbol = val;

        const data = {
          pdfData: JSON.stringify(pdfData),
        };
        this.setTreeDataByIndexes({
          data,
          indexes: this.indexes,
          target: "discountPolicyTree",
        });
      },
    },
    promulgatedCompany: {
      get: function () {
        return this.pdfData.promulgatedCompany;
      },
      set: function (val) {
        let pdfData = this.pdfData;
        pdfData.promulgatedCompany = val;

        const data = {
          pdfData: JSON.stringify(pdfData),
        };
        this.setTreeDataByIndexes({
          data,
          indexes: this.indexes,
          target: "discountPolicyTree",
        });
      },
    },
    relatedLinks: {
      get: function () {
        return this.pdfData.relatedLinks;
      },
      set: function (val) {
        let pdfData = this.pdfData;
        pdfData.relatedLinks = val;

        const data = {
          pdfData: JSON.stringify(pdfData),
        };
        this.setTreeDataByIndexes({
          data,
          indexes: this.indexes,
          target: "discountPolicyTree",
        });
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px 24px;
}

.content-wrapper {
  box-sizing: border-box;
  padding: 0 0 16px;
}

.item-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(245, 34, 45, 0.03);
  font-size: 14px;
  padding: 24px 28px 34px 18px;
}

.item-content {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.5;
}

.item-bottom {
  margin-top: 28px;
}

.item-bottom-btn {
  padding: 8px 16px;
  background: rgba(251, 251, 251, 1);
  border-radius: 4px;
  margin-right: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.item-i-margin {
  margin-right: 8px;
}

.item-right-title {
  line-height: 1.5;
  margin: 4px 0;
  display: flex;
  align-items: center;
}

.item-flex-l {
  display: inline-block;
  width: 85px;
  text-align: right;
}

.item-flex-1 {
  flex: 1;
  line-height: 1.5;
}

.item-color-l {
  color: rgba(0, 0, 0, 0.4);
}

.item-color-r {
  color: rgba(0, 0, 0, 0.8);
  flex-shrink: 0;
}

.item-color-b {
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
}
</style>
