<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>数据统计</el-breadcrumb-item>
          <el-breadcrumb-item>职工人数统计</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="submit-title">职工人数统计</div>
    </div>

    <div class="filter-body">
      <div>
        <div class="title">导入员工花名册，系统自动统计每月员工人数，学历情况，研发人员情况。</div>
        <div class="downLoad">
          <div>
            <el-upload
              class="upload-demo"
              ref="upload"
              action
              :on-change="uploadChange"
              :limit="1"
              :auto-upload="false"
              :show-file-list="false"
            >
              <div class="addItem"><i class="el-icon-plus"></i>上传花名册</div>
            </el-upload>
          </div>
          <div style="margin: 0 0 0 20px">
            <el-button type="text" style="font-size: 15px">
              <a
                href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E8%81%8C%E5%B7%A5%E4%BA%BA%E6%95%B0%E7%BB%9F%E8%AE%A1%E6%A8%A1%E6%9D%BF.xlsx "
                class="btn-download fix-flexs1"
                download
                >下载模板</a
              >
            </el-button>
          </div>
        </div>
        <div class="downLoadFileName" v-if="fileName">
          <el-button type="text" style="font-size: 15px">{{ fileName ? fileName : "" }}</el-button>
          <el-link type="danger" style="margin-left: 12px" @click="deleteFile">删除</el-link>
        </div>
        <div class="downLoadStatus" v-if="generateStatus != 3">
          {{
            generateStatus == 1
              ? "数据生成中，请稍候~"
              : generateStatus == 2
              ? "数据统计出错，请查看是否按模板要求提供数据"
              : generateStatus == 3
              ? "已生成"
              : ""
          }}
        </div>
        <div v-else>
          <el-button type="primary" icon="el-icon-download" @click="loadUploadInit"
            >下载统计数据</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { getInfoApi, getDelApi, postUploadExcelApi } from "../../api/api";
export default {
  components: {
    BreadCrumb,
  },
  data() {
    return {
      id: "",
      loading: false,
      fileList: [],
      fileName: "",
      errorData: "",
      successData: false,
      generateStatus: null,
    };
  },
  methods: {
    // 上传花名册
    uploadChange(file, fileList) {
      this.fileList = fileList;
      this.loading = this.$loading({
        lock: true,
        text: "正在上传花名册...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.submitUpload();
    },
    submitUpload() {
      let userId = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).firmId;
      let formFile = new FormData();
      let file = this.fileList[0].raw;
      formFile.append("files", file);
      formFile.append("userId", userId);
      formFile.append("source", 1);
      this.getToExcel(formFile);
    },
    getToExcel(formFile) {
      postUploadExcelApi(formFile)
        .then((res) => {
          this.loading.close();
          this.$refs.upload.clearFiles();
          if (res.code == 200) {
            this.loadUpload();
            this.$alert("成功导入花名册", "温馨提示", {
              confirmButtonText: "确定",
              type: "success",
            });
            this.successData = true;
          } else {
            this.errorData = res.message;
            this.this.$alert(res.message, "温馨提示", {
              confirmButtonText: "确定",
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.loading.close();
          this.$message.error("服务器错误");
        });
    },

    //删除生成的文件名
    deleteFile() {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getDelApi(this.id).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.loadUpload();
              this.$message({
                message: res.message,
                type: "success",
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 下载统计数据
    async loadUploadInit() {
      //获取职工人数统计信息详情
      let firmId = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).firmId;
      getInfoApi(firmId).then((res) => {
        if (res) {
          console.log(res);
          this.id = res.data.id;
          this.fileName = res.data.fileName;
          window.open(res.data.ossLink);
        }
      });
    },
    //默认展示
    async loadUpload() {
      //获取职工人数统计信息详情
      let firmId = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).firmId;
      getInfoApi(firmId).then((res) => {
        if (res) {
          this.generateStatus = res.data.generateStatus;
          this.id = res.data.id;
          this.fileName = res.data.fileName;
        }
      });
    },
  },
  async mounted() {
    await this.loadUpload();
  },
};
</script>
<style scoped lang="stylus">
.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}
.submit-title {
  padding: 13px 0 0 30px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
.submit-title {
  padding: 0;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  padding-left: 30px;
}
>>>.bread-crumb-wrapper {
  margin-top: 0;
}
.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // padding: 20px;
  // padding-top: 20px;
  // padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 16px
}
.title{
  margin-bottom 36px
}
.downLoad{
  display: flex
  margin-bottom 18px
}
.downLoadFileName{
  margin 18px 0 0 0
}

>>>.el-icon-plus{
  font-size: 20px
}
.upload-demo{
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
  color:rgba(0, 0, 0, 0.26)
}
.upload-demo:hover{
  color:#66b1ff
  border-color:#66b1ff
}
>>> .el-upload{
width:100%
}
</style>
