<template>
  <div class="wrapper" :style="{ height: height }">
    <div class="chart-wrapper" :style="{ height: height }">
      <PieChart :colors="colors" :data="chartData" :key="JSON.stringify(chartData)"></PieChart>
    </div>

    <div class="legends" :style="{ height: height }">
      <div class="legend" v-for="(item, index) in chartData" :key="index">
        <el-tooltip
          popper-class="box-items"
          class="item"
          effect="dark"
          :content="item.name"
          placement="top-start"
          :open-delay="1000"
        >
          <div class="title">
            <div class="box" :style="{ background: colors[index % colors.length] }"></div>
            {{ item.name }}
          </div>
        </el-tooltip>
        <div class="value">{{ item.value | percentage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "./PieChart";
export default {
  name: "pie",
  props: {
    data: Object,
    height: {
      type: String,
      default: "362px",
    },
  },
  components: {
    PieChart,
  },
  computed: {
    chartData() {
      const ret = [];
      for (const i in this.data.xLabels) {
        const label = this.data.xLabels[i];
        const value = this.data.series[0].data[i];
        ret.push({ name: label, value });
      }
      return ret;
    },
  },
  data() {
    return {
      colors: [
        "#6492FF",
        "#FDBF33",
        "#71DDA4",
        "#FF8F64",
        "#9D6CED",
        "#E264E2",
        "#A4D02B",
        "#1799D3",
        "#7E91FF",
        "#EAB57C",
        "#6569C5",
        "#30CADE",
        "#C99696",
        "#8DD87F",
        "#3AA5B2",
        "#B78122",
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  display: flex;
  //width: 828px;
  height: 362px;

  .chart-wrapper {
    height: 362px;
    display: flex;
    align-items: center;
    margin: 0 120px;
  }

  .legends {
    height: 362px;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    margin-right: 193px;

    .legend {
      width: 100%;
      color: #666;
      display: flex;
      justify-content: space-between;
      vertical-align: top;
      margin: 11px 0;

      .box {
        width: 24px;
        height: 16px;
        background: #6492FF;
        border-radius: 4px 4px 4px 4px;
        display: inline-block;
        background: red;
        position: relative;
        top: 3px;
        margin-right: 6px;
      }

      .title {
        vertical-align: top;
        width: 197px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #1D2129;
      }

      .value {
        vertical-align: top;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #1D2129;
        margin-left: 14px;
      }
    }
  }
}
</style>
<style lang="scss">
.box-items {
  width: auto !important;
  padding: 0.8rem 0.5rem;
}
</style>
