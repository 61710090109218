<template>
  <div>
    <div class="wrapper ldj">
      <div class="textarea-wrapper">

        <div v-if="isEditable&&node.editMode">
          <RiskLevelSelector v-model="form.riskLevel" class="form-item"></RiskLevelSelector>
          <el-input
              :class="{
              isModified: isInputModified,
              isModifiedByMyself: isInputModifiedByMyself,
            }"
              type="textarea"
              placeholder="请输入内容"
              v-model="form.dataText"
              maxlength="3000"
              show-word-limit
              class="textarea"
              :disabled="!isEditable"
          ></el-input>
        </div>
        <div v-else>
          <ModifiedTip :is-input-modified="isInputModified" :is-input-modified-by-myself="isInputModifiedByMyself">
            <AnalyzeResult :riskLevel="node.riskLevel" :text="node.dataText">

            </AnalyzeResult>
          </ModifiedTip>
        </div>


        <div class="form-save-toolbar" v-if="node.indexes.length == 3 && node.editMode == true">
          <button @click="handleBtnCancelClick" class="btn "> 取消</button>
          <button @click="handleBtnSave" class="btn btn-primary"> 确定</button>
        </div>
      </div>

      <div class="chart-wrapper" v-if="showChart">
        <div class="chart">
          <div class="chartColumn" id="chartColumn" ref="chart"></div>
        </div>
        <div class="chart" v-if="yeb">
          <GenericTable :data="yeb"></GenericTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GenericTable from "../InvoiceIndex/components/GenericTable";
import {mapGetters, mapMutations} from "vuex";
import {cloneDeep} from "lodash";
import mixin from "@/pages/ResultEditor/mixin.js";

import echarts from "echarts";
import AnalyzeResult from "@/pages/ResultEditor/components/AnalyzeResult.vue";
import RiskLevelSelector from "@/pages/ResultEditor/components/RiskLevelSelector.vue";
import ModifiedTip from "@/pages/ResultEditor/components/ModifiedTip.vue";
import {stringify} from "@/utils/json";

export default {
  mixins: [mixin],
  components: {
    ModifiedTip,
    RiskLevelSelector,
    AnalyzeResult,
    GenericTable,
  },
  props: {
    indexes: Array,
  },
  data: function () {
    return {
      title: "",
      desc: [],
      x: [],
      rects: [],
      lines: new Array(10),
      showChart: true,
      warningId: null,
      yeb: null,
      form: {
        riskLevel: 1,
        dataText: ''
      }
    };
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    //行业参考值的名字
    industryReferenceValueLabel() {
      return this.pdfData.header[2];
    },
    //主要的指标名字
    mainBarLabel() {
      return this.pdfData.header[1];
    },
    data() {
      return this.findTheNodeByIndexes(this.indexes);
    },
    dataText: {
      get() {
        return this.data.dataText;
      },
      set(value) {
        const nodeData = cloneDeep(this.data);
        nodeData.dataText = value; //给节点设置新的dataText
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });

        // this.findTheNodeByIndexes({
        //   dataText: value
        // });
        // this.$parent.setTreeNodeAttribute({
        //   dataText: value
        // });
      },
    },
    pdfData: function () {
      return JSON.parse(this.data.pdfData);
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.lastCensorSnapshot
            ? this.data.lastCensorSnapshot.includes("TextChange")
            : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.lastEditSnapshot
            ? this.data.lastEditSnapshot.includes("TextChange")
            : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.editSnapshot
            ? this.data.editSnapshot.includes("TextChange")
            : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.censorSnapshot
            ? this.data.censorSnapshot.includes("TextChange")
            : false;
      }
      return ret;
    },
  },
  mounted() {
    this.getData(this.data.titleName);
    //debugger

    if (this.showChart) {
      this.showMap();
    }
  },
  watch: {
    node: {
      handler(val) {
        this.form.riskLevel = val.riskLevel
        this.form.dataText = val.dataText
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
    handleBtnSave() {
      const nodeData = cloneDeep(this.node);
      const pdfData = cloneDeep(this.pdfData);
      nodeData.editMode = false;
      nodeData.dataText = this.form.dataText;
      nodeData.riskLevel = this.form.riskLevel;
      // pdfData.policyEdit = pdfData.policy;
      nodeData.pdfData = stringify(pdfData);
      this.setTreeDataByIndexes({
        data: nodeData,
        indexes: this.indexes,
        target: this.target,
      });
    },
    handleBtnCancelClick() {
      const nodeData = cloneDeep(this.node);
      nodeData.editMode = false;
      this.setTreeDataByIndexes({
        data: nodeData,
        indexes: this.indexes,
        target: this.target,
      });
    },
    getData(name) {
      this.title = name;

      this.x = this.pdfData.body.map((v) => v[""]);

      this.rects = this.pdfData.body.map((v) => v[this.mainBarLabel]);
      this.lines[0] = this.pdfData.body[0][this.industryReferenceValueLabel];
      this.lines.fill(
          this.pdfData.body[1][this.industryReferenceValueLabel],
          1
      );

      this.showChart = true;
    },
    //echart
    showMap() {
      this.chartColumn = echarts.init(this.$refs["chart"]);
      const option = {
        color: ["#3398DB"],
        tooltip: {
          formatter: (params) => {
            const text = params[0].seriesName.includes("行业")
                ? ""
                : `${params[0].seriesName}:${params[0].value}%`;

            return text;
          },
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          x: "center",
          bottom: "5%",
          data: [this.mainBarLabel, this.industryReferenceValueLabel],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "15%",
          top: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              this.x[0],
              this.x[1],
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
            ],
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEEEE",
                width: 1,
                type: "solid",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#666",
              },
            },
            axisLabel: {
              // rotate: 35,
              interval: 0,
              textStyle: {
                fontSize: 11,
                fontFamily: "PingFangSC-Regular,PingFang SC",
                color: "rgba(0,0,0,0.6)",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            max: () => {
              let max = Math.max(...this.lines, ...this.rects);
              if (max > 0) {
                max = Math.ceil((max * 100) / 100);
              } else {
                max = Math.floor((max * 100) / 100);
              }

              return max < 0 ? 0 : max;
            },
            min: () => {
              let min = Math.min(...this.lines, ...this.rects);

              if (min > 0) {
                min = Math.ceil((min * 100) / 100);
              } else {
                min = Math.floor((min * 100) / 100);
              }

              min = Math.ceil((min * 100) / 100);
              return min > 0 ? 0 : min;
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#666",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEEEE",
                width: 1,
                type: "dashed",
              },
            },
            axisLabel: {show: true, formatter: "{value} %"},
          },
          {
            type: "value",
            name: "",
            max: () => {
              let max = Math.max(...this.lines, ...this.rects);
              if (max > 0) {
                max = Math.ceil((max * 100) / 100);
              } else {
                max = Math.floor((max * 100) / 100);
              }
              return max < 0 ? 0 : max;
            },
            min: () => {
              let min = Math.min(...this.lines, ...this.rects);
              if (min > 0) {
                min = Math.ceil((min * 100) / 100);
              } else {
                min = Math.floor((min * 100) / 100);
              }
              return min > 0 ? 0 : min;
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#666",
              },
            },
            axisTick: {
              // 坐标轴 刻度
              show: false, // 是否显示
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#EEEEEE",
                width: 1,
                type: "dashed",
              },
            },
            axisLabel: {show: false},
          },
        ],
        series: [
          {
            name: this.mainBarLabel,
            type: "bar",

            center: ["50%", "1%"],
            color: ["#597EF7"],
            barWidth: "30",
            data: this.rects,
          },
          {
            name: this.industryReferenceValueLabel,
            type: "line",
            yAxisIndex: 1,
            color: ["#D6B35B"],
            data: this.lines,
          },
        ],
      };

      this.chartColumn.setOption(option);
    },
  },
};
</script>


<style lang="stylus" scoped>
.wrapper {

  .textarea-wrapper {

    .isModified.textarea {
      >>> .el-textarea__inner {
        border: 1px solid #faad14 !important;
        border-color: #faad14 !important;
      }

      border-radius: 4px;
    }

    .isModifiedByMyself.textarea {
      >>> .el-textarea__inner {
        border: 1px solid rgba(245, 34, 45, 1) !important;
        border-color: rgba(245, 34, 45, 1) !important;
      }

      border-radius: 4px;
    }
  }
}

.TaxIndex {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 16px;
}

.title {
  border-bottom: 1px solid #eee;
  padding: 16px 24px;
  font-weight: 600;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
  color: #333;
  line-height: 25px;
  display: flex;
}

.body-wrapper {
  display: flex;
  background: rgba(47, 84, 235, 0.04);

  .desc {
    padding: 16px 24px;
    width: 380px;
    box-sizing: border-box;
    flex-grow: 0;
    font-size: 14px;
    color: #666;

    p {
      font-size: 14px;
      color: #666;
      line-height: 20px;
    }
  }

  .chart-wrapper {
    background: #fff;
    flex-grow: 1;

    .chart {
      width: 764px;
      height: 261px;
      background: #fff;
    }
  }
}

.chart-wrapper {
  background: #fff;

  .chart {
    width: 764px;

    .chartColumn {
      height: 261px;
      width: 100%;

      >>> div:first-child {
        width: 100% !important;
      }
    }
  }
}
</style>
