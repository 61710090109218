<template>
    <div>
        <el-dialog title="通知公告" :visible.sync="dialogVisible" width="50%" @opened="GetList(null, true)">
            <div class="selectButtons">
                <el-select v-model="readType" placeholder="请选择" class="mr-12" @change="typechange">
                    <el-option label="未读" :value="0">
                    </el-option>
                    <el-option label="已读" :value="1">
                    </el-option>
                </el-select>
                <el-dropdown class="mr-12" @command="handleCommand" :disabled="selecedtValue.length == 0">
                    <span class="el-dropdown-link">
                        标记为<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="0">未读</el-dropdown-item>
                        <el-dropdown-item :command="1">已读</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button @click="markAsReads(null, 1, null)">全部标记为已读</el-button>
            </div>
            <div style="margin-top:12px">
                <span>
                    (
                    共{{ allCount }}条，其中未读通知<span style="color:red">{{ length }}</span>
                    )
                </span>
            </div>
            <el-table :data="tableData" style="width: 100%" @sort-change="sortChange" @selection-change="selectedChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="content" label="通知内容" width="">
                    <template slot-scope="scope">
                        <div>
                            <el-popover placement="top-start" title="" width="300" trigger="click"
                                @show="popovershow(scope.row)" :content="scope.row.content">
                                <div slot="reference">
                                    <img src="@/assets/unread.png" alt="" v-if="scope.row.readtype === 0" class="imgs">
                                    <img src="@/assets/read.png" alt="" v-if="scope.row.readtype === 1"  class="imgs">
                                    <span :class="scope.row.readtype === 1 ? 'read' : 'unread'">{{ scope.row.content
                                    }}</span>
                                </div>
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="startDate" label="通知时间" width="100" sortable>
                </el-table-column>

            </el-table>
            <div style="width:100%;text-align:right;margin-top:16px">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pages.pageNum" :page-sizes="[5, 10]" :page-size="pages.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="pages.total">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getNoticePage, markAsRead, getUnreadNum } from "@/api/api";
export default {

    data() {
        return {
            dialogVisible: false,
            orderType: '',
            readType: '',
            pages: {
                pageNum: 1,
                pageSize: 5,
                total: 0
            },
            tableData: [],
            selecedtValue: [],
            allCount: 0,
            length: 0
        }
    },
    async mounted() {
        // await this.GetList()
        await this.getNoticeNum()

    },
    methods: {
        async getNoticeNum() {
            const res = await getUnreadNum()
            if (res.code == 200) {
                this.length = res.data
                if (res.code == 200) {
                    this.$emit('lengthchange', res.data)
                }
            }
        },
        popovershow(e) {
            this.markAsReads(null, null, e.noticeId)
        },
        async markAsReads(readType, selectAll, id) {
            let params = {
                selectAll: null,
                noticeIdList: this.selecedtValue ? this.selecedtValue.map(item => item.noticeId) : [],
                readType: 1
            }
            if (selectAll) {
                params.selectAll = 1
            } else {
                params.selectAll = 0
            }
            if (id) {
                let list = []
                list.push(id)
                params.noticeIdList = list
            }
            if (readType !== null) {
                params.readType = readType
            }
            const res = await markAsRead(params)
            console.log(res);
            if (res.code==200) {
                await this.GetList(null, true)
                await this.getNoticeNum()
            }
        },
        selectedChange(e) {
            this.selecedtValue = e
        },
        handleCommand(command) {
            this.markAsReads(command, null, null)
        },
        typechange() {
            this.pages.pageNum = 1
            this.GetList()
        },
        sortChange(column, prop, order) {
            this.pages.pageNum = 1
            if (column.order == "ascending") {
                this.GetList('asc')
            } else if (column.order == "descending") {
                this.GetList('desc')
            } else {
                this.GetList()
            }
        },
        async GetList(e, showall) {
            const params = {
                orderType: this.orderType,
                pageNum: this.pages.pageNum,
                pageSize: this.pages.pageSize,
                readType: this.readType
            }
            if (e) {
                params.orderType = e
            }
            const res = await getNoticePage(params)
            if (res.list) {
                this.tableData = res.list;
                this.pages.total = res.pageTotal * 1
                if (showall) {
                    this.allCount = res.pageTotal * 1
                }
            }
        },
        handleSizeChange(v) {
            this.pages.pageSize = v
            this.pages.pageNum = 1
            this.GetList()
        },
        handleCurrentChange(v) {
            this.pages.pageNum = v
            this.GetList()
        },
        showdia() {
            this.dialogVisible = true
        },
    }
}
</script>
<style lang="stylus" scoped>
.imgs{
    vertical-align: middle;
    margin-right: 12px
}
.read{
    color: #ccc
}   
.unread{
    color: #000
    font-weight: bold

}
.selectButtons>>>.el-button{
    padding: 0
}
.mr-12{
    margin-right: 12px 
}
.selectButtons>>>.el-input__icon{
    line-height: 30px
}
.selectButtons>>>.el-input__inner,.selectButtons>>>.el-button{
    width: 120px;
    height: 30px;
    line-height: 30px
}
</style>