<template>
  <div>
    <div class="desc" :class="descClass" v-if="!(riskLevel==4&&text=='')">
      <div class="waringGrade" v-if="riskLevel!=4">
        <span>{{ label }}</span>
      </div>
      <div class="textarea-wrapper">
        <div class="title" v-if="text">分析结果</div>
        <div class="waringGradeContent">
          {{ text }}
        </div>
      </div>
      <img class="imgWaring" src="@/assets/util/img_gfx.png" v-if="riskLevel==1"/>
      <img class="imgWaring" src="@/assets/util/img_zfx.png" v-else-if="riskLevel==2"/>
      <img class="imgWaring" src="@/assets/util/img_dfx.png" v-else-if="riskLevel==3"/>
      <img class="imgWaring" src="@/assets/util/img_wfx.png" v-else-if="riskLevel==4"/>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AnalyzeResult",
  props: {
    riskLevel: Number,
    text: String,
  },
  methods: {},
  computed: {
    descClass() {
      const data = ['', 'red', 'yellow', 'blue', 'green']
      return data[this.riskLevel]
    },
    label() {
      const data = ['', '高', '中', '低', '无']
      return data[this.riskLevel]
    }
  }
})
</script>
<style scoped lang="stylus">

.desc {
  width: 100%;
  min-height: 112px;
  font-size: 14px;
  line-height: 22px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px 4px 4px 4px
}

.desc.red {
  background: #FFF0F0;
  color: #F53F3F
  position: relative;

  .waringGrade {
    width: 80px;
    height: 80px;
    background: #F53F3F;
    border-radius: 50%
    font-weight: bold;
    border: 9px solid #fac9c9;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    span {
      color: #FFFFFF;
      font-size: 20px;
    }
  }

  .title {
    color: #F53F3F;
  }
}

.desc.yellow {
  background: #fff7e5;
  color: #FFB100
  position: relative;

  .waringGrade {
    width: 80px;
    height: 80px;
    background: #FFB100;
    border-radius: 50%
    font-weight: bold;
    border: 9px solid #fff7e5b3;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    span {
      color: #FFFFFF;
      font-size: 20px;
    }
  }

  .title {
    color: #FFB100;
  }

}

.desc.blue {
  position: relative;
  background: #ebf7fd;
  color: #0096e6

  .waringGrade {
    width: 80px;
    height: 80px;
    background: #0096E6;
    border-radius: 50%
    font-weight: bold;
    border: 9px solid #ebf7fdbf;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    span {
      color: #FFFFFF;
      font-size: 20px;
    }
  }

  .title {
    color: #0096E6;
  }
}

.desc.green {
  position: relative;
  background: #ebfdef;
  color: #2eb060

  .waringGrade {
    width: 80px;
    height: 80px;
    background: #2EB060;
    border-radius: 50%
    font-weight: bold;
    border: 9px solid #ebfdefc7;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    span {
      color: #FFFFFF;
      font-size: 20px;
    }
  }

  .title {
    color: #2EB060;
  }
}

.imgWaring {
  position: absolute;
  right: 0;
  bottom: 0
}

.textarea-wrapper {
  margin-left: 20px

  .title {
    font-size: 14px;
    margin: 4px 0;
    font-weight bold
  }

  .waringGradeContent {
    line-height: 24px;
    width: 900px;
    min-height: 42px;
  }
}
</style>
