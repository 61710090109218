import axios, {get, post} from "@/axios";
// const base = process.env.VUE_APP_API_ROOT_FK;
let base = process.env.VUE_APP_API_ROOT_INDEX;

/****
 * 手工填表
 *
 */
//上传表格中的数据
export const uploadTableData = async (nsrsbh, zq, templateId, uuid, data) => {
  const url = `${base}/taxfirm/common/webupload/${nsrsbh}/${zq}/${templateId}?uuid=${uuid}`;
  const res = await axios.post(url, {...data});
  return res.data;
};

//手工报表上传oss接口
export const uploadWebTableExcel = async ({tableLabel, nsrsbh, templeteId, zq, uuid}) => {
  const url = `${base}/taxfirm/common/uploadWebTableExcel`;
  const res = await axios.post(url, {tableLabel, nsrsbh, templeteId, zq, uuid});
  return res.data;
};

//读取上传的表格的列表
// export const loadTablesStatusList = async (nsrsbh, zq, bankId, qykjzd) => {
//   const url = `${base}/taxfirm/common/getHistoryTable`;
//   const res = await axios.get(url, { params: { nsrsbh, zq, bankId, qykjzd } });
//   return res.data;
// };
//删除单个文件
export const deleteTable = async (nsrsbh, zq, templateId, token, uuid) => {
  const url = `${base}/taxfirm/model/cancel/upload/${token}`;
  const res = await axios.post(url, {nsrsbh, zq, template: templateId, uuid});
  return res.data;
};

export const uploadForTestUrl = (nsrsbh, zq, uuid, qykjzd) => {
  return `${base}/taxfirm/common/uploadExcelBatch?nsrsbh=${nsrsbh}&zq=${zq}&uuid=${uuid}&qykjzd=${qykjzd}`;
};

//清空所有上传的表格
export const clearUploadedTableforTest = async (nsrsbh, zq, token) => {
  const url = `${base}/taxfirm/model/clean/${token}`;
  const res = await axios.get(url, {params: {nsrsbh, zq}});
  return res.data;
};

//获取所有表格
export const getUserTables = async (
  bankId,
  tag,
  nsrsbh,
  zq,
  uuid,
  onUploaded = false
) => {
  const url = `${base}/taxfirm/common/getListV2`;

  //todo uuid暂时先写死
  // uuid = "fb4d26c248874dc8ab1917369dadcca5";
  // tag = 1036;

  const data = {zq, programId: uuid, tag};
  if (onUploaded) {
    data.type = "1";
  }
  const res = await get(url, data);
  return res.data;
};
//读取table数据
export const loadTableData = async (zq, templateId, token, uuid) => {
  const url = `${base}/taxfirm/model/user/excel/null/${zq}/${templateId}/${token}?uuid=${uuid}`;
  const res = await axios.get(url);

  if (res.data.code == 200 && res.data.data.dataJson) {
    res.data.data.dataJson = JSON.parse(res.data.data.dataJson);
  }
  return res.data;
};

//本期上期数据读取
export const getPreviousTableFill = async (nsrsbh, zq, templeteId) => {
  const url = `${base}/taxfirm/fill/getFillInData`;
  const res = await axios.post(url, {nsrsbh, zq, templeteId});
  return res.data;
};

//读取table数据AI
export const loadTableDataAi = async (zq, templateId, token, uuid) => {
  const url = `${base}/taxfirm/model/user/excel/null/${zq}/${templateId}/${token}?uuid=${uuid}`;
  const res = await axios.get(url);

  if (res.data.code == 200 && res.data.data.dataJson) {
    res.data.data.dataJson = JSON.parse(res.data.data.dataJson);
  }
  return res.data;
};

/**
 * 读取表格总体结构
 * @param {{templateId:string,zq:string}}data
 * @returns {Promise<*>}
 */
export const loadTableStructure = async data => {
  const {templateId, zq} = data;
  const url = `${base}/admin/reportData/loadByTempIdAndZqAndVersion`;
  const res = await axios.get(url, {params: {templateId: templateId, zq}});
  return res.data;
};

export const uploadForTestUrlTag = (nsrsbh, zq, uuid, qykjzd, balance, tag) => {
  return `${baseTaxfirm}/common/uploadExcelBatch?nsrsbh=${nsrsbh}&zq=${zq}&uuid=${uuid}&qykjzd=${qykjzd}&balance=${balance}&tag=${tag}`;
};
