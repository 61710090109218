<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="460px"
      :before-close="close"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false"
  >
    <slot></slot>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="handleBtnConfirmClick">确 定</el-button>
  </span>
  </el-dialog>
</template>
<script>
import {defineComponent} from 'vue'
import MenuTreePathSelector from "@/pages/ResultEditor/components/MenuTreePathSelector.vue";

export default defineComponent({
  name: "ConfirmDialog",
  components: {MenuTreePathSelector},
  props: {
    title: String
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        indexes: '',
        name: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入目录名称', trigger: 'blur'},
        ]
      }
    };
  },
  methods: {
    async open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    async handleBtnConfirmClick() {
      this.$emit("confirm")
      this.dialogVisible = false;
    }
  }
})
</script>


<style scoped lang="stylus">
>>> .el-dialog__title {
  font-size 14px
  font-weight bold
}

>>> .el-dialog__body {
  padding 10px 20px
}
</style>
