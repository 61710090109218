<template>
  <div>
    <div class="wrapper ldj">
      <div class="content-wrapper">
        <div class="textarea-wrapper" v-if="isEditable&&editMode">
          <div>
            <RiskLevelSelector v-model="form.riskLevel" class="form-item"></RiskLevelSelector>
            <el-input
                :class="{
                isModified: isInputModified,
                isModifiedByMyself: isInputModifiedByMyself,
              }"
                type="textarea"
                :disabled="!isEditable"
                :placeholder="isEditable ? '请输入内容' : '不显示'"
                v-model="form.dataText"
                maxlength="3000"
                show-word-limit
                class="textarea"
            ></el-input>
          </div>


          <div class="form-save-toolbar" v-if="level == 3 && node.editMode == true">
            <button @click="handleBtnCancelClick" class="btn "> 取消</button>
            <button @click="handleBtnSave" class="btn btn-primary"> 确定</button>
          </div>
        </div>
        <div v-else>
          <ModifiedTip :is-input-modified="isInputModified" :is-input-modified-by-myself="isInputModifiedByMyself" :disabled="node.userData==1">
            <AnalyzeResult :riskLevel="node.riskLevel" :text="node.dataText">

            </AnalyzeResult>
          </ModifiedTip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import mixin from "@/pages/ResultEditor/mixin.js";
import AnalyzeResult from "@/pages/ResultEditor/components/AnalyzeResult.vue";
import RiskLevelSelector from "@/pages/ResultEditor/components/RiskLevelSelector.vue";
import {cloneDeep} from "lodash";
import ModifiedTip from "@/pages/ResultEditor/components/ModifiedTip.vue";
import {stringify} from "@/utils/json";

export default {
  name: "SimpleTextContent",
  components: {ModifiedTip, RiskLevelSelector, AnalyzeResult},
  mixins: [mixin],
  props: {
    indexes: Array,
  },
  data: function () {
    return {
      form: {
        riskLevel: 1,
        dataText: ''
      }
    };
  },

  watch: {
    node: {
      handler(val) {
        this.form.riskLevel = val.riskLevel
        this.form.dataText = val.dataText
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
    handleBtnSave() {
      const nodeData = cloneDeep(this.node);
      const pdfData = cloneDeep(this.pdfData);
      nodeData.editMode = false;
      nodeData.dataText = this.form.dataText;
      nodeData.riskLevel = this.form.riskLevel;
      // pdfData.policyEdit = pdfData.policy;
      nodeData.pdfData = stringify(pdfData);
      this.setTreeDataByIndexes({
        data: nodeData,
        indexes: this.indexes,
        target: this.target,
      });
    },
    handleBtnCancelClick() {
      const nodeData = cloneDeep(this.node);
      nodeData.editMode = false;
      this.setTreeDataByIndexes({
        data: nodeData,
        indexes: this.indexes,
        target: this.target,
      });
    }
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes", "isCensorDone"]),
    level() {
      return this.indexes.length
    },
    pdfData() {
      return JSON.parse(this.node.pdfData);
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.node.lastCensorSnapshot
            ? this.node.lastCensorSnapshot.includes("TextChange") ||
            this.node.lastCensorSnapshot.includes("addChange")
            : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.node.lastEditSnapshot
            ? this.node.lastEditSnapshot.includes("TextChange") ||
            this.node.lastEditSnapshot.includes("addChange")
            : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      if (this.isCensorDone) {
        return false;
      }
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.node.editSnapshot
            ? this.node.editSnapshot.includes("TextChange") ||
            this.node.editSnapshot.includes("addChange")
            : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.node.censorSnapshot
            ? this.node.censorSnapshot.includes("TextChange") ||
            this.node.censorSnapshot.includes("addChange")
            : false;
      }
      return ret;
    },
    popperClass() {
      let ret = "";

      if (this.isCensorDone) {
        return ret;
      }

      if (this.isInputModifiedByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },
    editMode() {
      return this.node.editMode
    }
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
}

.content-wrapper {
  box-sizing: border-box;
  padding: 0 0 16px;

  .text {
    font-size 14px
  }
}

.item-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(245, 34, 45, 0.03);
  font-size: 14px;
  padding: 24px 28px 34px 18px;
}

.item-content {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.5;
}

.item-bottom {
  margin-top: 28px;
}

.item-bottom-btn {
  padding: 8px 16px;
  background: rgba(251, 251, 251, 1);
  border-radius: 4px;
  margin-right: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.item-i-margin {
  margin-right: 8px;
}

.item-right-title {
  line-height: 1.5;
  margin: 4px 0;
  display: flex;
  align-items: center;
}

.item-flex-l {
  display: inline-block;
  width: 85px;
  text-align: right;
}

.item-flex-1 {
  flex: 1;
  line-height: 1.5;
}

.item-color-l {
  color: rgba(0, 0, 0, 0.4);
}

.item-color-r {
  color: rgba(0, 0, 0, 0.8);
  flex-shrink: 0;
}

.item-color-b {
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
}

.textarea-wrapper {
  margin: 16px;

  >>> .el-textarea__inner {
    height: 100px;
  }

  .isModified.textarea {
    >>> .el-textarea__inner {
      border: 1px solid #f5ad14 !important;
      border-color: #faad14 !important;
    }

    border-radius: 4px;
  }

  .isModifiedByMyself.textarea {
    >>> .el-textarea__inner {
      border: 1px solid rgba(245, 34, 45, 1) !important;
      border-color: rgba(245, 34, 45, 1) !important;
    }

    border-radius: 4px;
  }
}
</style>
