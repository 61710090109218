//import 'core-js';


//new Map(Array.from(src.entries()).map(([k, v]) => [k, clone(v)]))

//import regeneratorRuntime from "regenerator-runtime";

// require("core-js/modules/es6.set");

// require("core-js/modules/web.dom.iterable");

// require("core-js/modules/es6.array.iterator");

// require("core-js/modules/es6.object.to-string");

// require("core-js/modules/es6.string.iterator");

// require("core-js/modules/es6.map");

import Vue from "vue";
import "@/prototype";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css/normalize.css";
import "./styles/global.scss";
import {ValidationProvider, ValidationObserver} from "@/Validator";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VideoPlayer from 'vue-video-player'
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue"
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import '@/directives/scroll.js'

Vue.use(VXETable)
Vue.use(VideoPlayer);
Vue.component('BigPowerTable', BigPowerTable)

import "@/pages/editorTarget/editorCommon.css";

// 重写message消息
import {message} from "./resetMessage.js"

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('BigPowerLoading', BigPowerLoading)
Vue.component('BigPowerInput', BigPowerInput)
//滚动条样式美化

Vue.prototype.$eventBus = new Vue()

//滚动跳滚到.
var VueScrollTo = require('vue-scrollto');
// You can also pass in the default options
Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: -100,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

import {dateFormat} from "@/filters/date";
//引入iconfont symbol图标
import "./assets/fonts/iconfont";
// 引入drogzone.js样式
import "../node_modules/dropzone/dist/dropzone.css";

import "@/directives/maskers.js";
import {percentage, toFixed} from "./utils/format";
import BigPowerLoading from "./pages/Result/ThreeYearResult/components/BigPowerLoading";
import BigPowerInput from "./pages/Result/ThreeYearResult/components/BigPowerInput";

Vue.use(ElementUI);

Vue.prototype.$message = message;

Vue.config.productionTip = false;
const envName = process.env.NODE_ENV_NAME;

//路由守卫鉴权
const whiteList = [
    "login",
    "register",
    "companyApply",
    "forgetPassword",
    "wxlogin",
    "thirdPartAuth",
    "thirdPartRegister",
    "resultFromAdmin",
    "AdminUploadedTables",
    "admin-manual-fill-table",
    "exHomePage",
    "admin-result-head",
    "admin-result-head-middle-page",
    "admin-ResultEditor",
    "ThreeYearResult",
    "result-detect-always",
    "ThreeYearResult-always",
    "manual-fill-table-bill",
    "yanshi"
]; //不需要验证的路由name

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // if(envName!=="正式环境"){
    //   document.title += `[${envName}]`
    // }

    let token = localStorage.getItem("authorization");
    if (token) {
        next();
    } else {
        if (whiteList.indexOf(to.name) !== -1) {
            // 在免登录白名单，直接进入
            next();
        } else {
            //next('/passport/login');
            // console.log("login")
            next({name: "login", query: {redirect: to.path}}); // 否则全部重定向到登录页
        }
    }
});
Vue.filter("timeFormat", function (timeStamp) {
    if (!timeStamp) {
        return "";
    }
    let date = new Date(timeStamp);
    return dateFormat.formatDate(date, "yyyy年MM月d日 hh:mm:ss");
});

Vue.filter("dateFormat", function (timeStamp) {
    if (!timeStamp) {
        return "";
    }
    let date = new Date(timeStamp);
    return dateFormat.formatDate(date, "yyyy年MM月d日");
});

Vue.filter("toFixed", toFixed);
Vue.filter("percentage", percentage);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
