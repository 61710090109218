<template>
  <div>
    <Summary style="margin: 20px auto 0; display: none"></Summary>
    <financialRisk
        style="margin: 20px auto 0; display: none"
        :msgTitle="titleStyle"
    ></financialRisk>
    <!-- <SectionNode
      :title="overall.titleName"
      :level="1"
      :overall="overall"
    >
      <div class="textarea-wrapper">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="overallPDFData"
          maxlength="1500"
          :autosize="{ minRows: 8, maxRows: 20 }"
          show-word-limit
          class="textarea"
          :disabled="!isEditable"
        ></el-input> -->

    <!-- <textarea name id rows="10" maxlength="1500" class="textarea" v-model="overallPDFData"></textarea> -->
    <!-- </div>
    </SectionNode> -->
    <div
        class="section-wrapper-0"
        v-for="(node0, index0) in treeData"
        :key="node0.id + '_' + node0.tmpId"
        :id="'node' + node0.id"
    >
      <HeadLevel0 :indexes="[index0]" style="display: none"></HeadLevel0>
      <transition name="slide" :key="node0.id + '_' + node0.tmpId">
        <div class="section-body">
          <div slot="body" v-if="node0.children && node0.children.length > 0">
            <!--一级节点-->
            <SectionNode
                :key="node0.indexes.join('-')"
                :title="`${node0.titleName}`"
                :indexes="[index0]"
                :level="0"
            >
              <!--二级节点-->
              <SectionNode
                  v-for="(node1, index1) in node0.children"
                  :key="node1.id + '_' + node1.tmpId"
                  :title="`${node1.titleName}`"
                  :indexes="[index0, index1]"
                  :level="1"
              >
                <div v-if="node1.children && node1.children.length > 0">
                  <!--三级节点-->
                  <SectionNode
                      v-for="(node2, index2) in node1.children"
                      :key="node2.id + '_' + node2.tmpId"
                      :indexes="[index0, index1, index2]"
                      :title="`${node2.titleName}`"
                      :level="2"
                      :dataText="node2.dataText"
                      :node2data="node2"

                  >
                    <div v-if="node2.children && node2.children.length > 0">
                      <!--四级节点-->
                      <SectionNode
                          v-for="(node3, index3) in node2.children"
                          :key="node3.id + '_' + node3.tmpId"
                          :indexes="[index0, index1, index2, index3]"
                          :title="`${node3.titleName}`"
                          :level="3"
                      >
                      </SectionNode>
                      <!--四级节点-->
                    </div>
                    <div v-else>

                      <LazyLoader v-if="node2.type == 1">
                        <InvoiceIndex
                            :indexes="[index0, index1, index2]"
                        ></InvoiceIndex>
                      </LazyLoader>

                      <LazyLoader v-if="node2.type == 2">
                        <RiskProblem
                            :indexes="[index0, index1, index2]"
                        ></RiskProblem>
                      </LazyLoader>
                      <LazyLoader v-if="node2.type == 4">
                        <TaxIndex

                            :indexes="[index0, index1, index2]"
                        ></TaxIndex>
                      </LazyLoader>
                      <!-- <Riskfenlei
                  :indexes="[index0, index1, index2]"
                  v-if="node2.type == 3"
                ></Riskfenlei> -->

                      <!--简单文本框组件-->
                      <LazyLoader v-if="node2.type == 5">
                        <SimpleTextContent  :indexes="[index0, index1, index2]">
                        </SimpleTextContent>
                      </LazyLoader>
                      <!--/简单文本框组件-->

                      <!--优惠政策-->
                      <!-- <DiscountPolicy
                  v-if="node2.type == 5"
                  :indexes="[index0, index1, index2]"
                ></DiscountPolicy> -->
                      <!--/优惠政策-->
                    </div>
                  </SectionNode>
                  <!--/三级节点-->
                </div>
                <div v-else>
                  <TaxIndex v-if="node1.type == 2" :indexes="[index0, index1]"></TaxIndex>
                  <Riskfenlei :indexes="[index0, index1]" v-if="node1.type == 3"></Riskfenlei>
                  <!--优惠政策-->
                  <!--            <DiscountPolicy-->
                  <!--              v-if="node1.type == 5"-->
                  <!--              :indexes="[index0, index1]"-->
                  <!--            ></DiscountPolicy>-->
                  <!--/优惠政策-->

                  <!--如果node节点的type是5(优惠政策),并且当前节点是最后一个节点，就增加一个添加框-->
                </div>
              </SectionNode>
            </SectionNode>
            <!--简单文本框组件-->
            <!--            <SimpleTextContent  v-if="node0.children[node0.children.length - 1].type == 5"-->
            <!--                                :indexes="[index0]">-->
            <!--          </SimpleTextContent>-->
            <!--/简单文本框组件-->
            <!--优惠添加组件-->
            <!--        <DiscountPolicyAppend-->
            <!--          v-if="node0.children[node0.children.length - 1].type == 5"-->
            <!--          :indexes="[index0]"-->
            <!--        ></DiscountPolicyAppend>-->
            <!--/优惠添加组件-->
            <!--/二级节点-->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import "@/pages/ResultEditor/global.styl";

import {cloneDeep} from "lodash";
import SectionNode from "./SectionNode";
import InvoiceIndex from "./InvoiceIndex/";
import TaxIndex from "./TaxIndex/";
import Riskfenlei from "./Riskfenlei";
import DiscountPolicy from "./DiscountPolicy/DiscountPolicy";
import DiscountPolicyAppend from "./DiscountPolicy/DiscountPolicyAppend";
import RiskProblem from "./RiskProblem/";
import HeadLevel0 from "./HeadLevel0";

import {mapState, mapGetters} from "vuex";
import SimpleTextContent from "./SimpleTextContent";
import Summary from "@/pages/Result/ThreeYearResult/financialAnalysis/Summary";
import financialRisk from "@/pages/Result/ThreeYearResult/financialAnalysis/FinancialRisk";
import LazyLoader from "@/pages/ResultEditor/components/LazyLoader.vue";

export default {
  filters: {
    textarea: function (value) {
      return value.replace(/\n/g, "<br/>");
    },
  },
  components: {
    LazyLoader,
    SimpleTextContent,
    SectionNode,
    InvoiceIndex,
    TaxIndex,
    Riskfenlei,
    DiscountPolicy,
    DiscountPolicyAppend,
    RiskProblem,
    HeadLevel0,
    Summary,
    financialRisk,
  },
  computed: {
    textarea: function () {
      return value.replace(/\n/g, "<br/>");
    },
    ...mapState("ResultEditor", ["treeData", "overall"]),
    overallPDFData: {
      get() {
        return this.$store.state.ResultEditor.overall.pdfData;
      },
      set(value) {
        const overall = cloneDeep(this.$store.state.ResultEditor.overall);
        overall.pdfData = value;
        this.$store.commit("ResultEditor/setOverall", {data: overall});
      },
    },
  },
  data: function () {
    return {titleStyle: true};
  },
  mounted() {
  },
};
</script>

<style lang="stylus" scoped>
.textarea-wrapper {
  display: flex;
}

.section-wrapper-0 {
  width: 1100px;
  margin: 20px auto 0;
}

.textarea {
  >>> textarea {
    padding: 16px;
  }

  display: block;
  margin: 16px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  flex-grow: 1;
}


.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
