import {
  getEditPdfCatalogData,
  getSencorPdfCatalogData,
  updateReviewStatus,
  updateUnReviewStatus,
  getCheckLogList
} from "@/api/shiwusuo";
import {cloneDeep} from "lodash";
import {getAdminPdfCatalogData} from "../../api/shiwusuo";
import {getDesensitizationFromRouter} from "../../utils/helper";
import Vue from "vue";

function initialState() {
  return {
    openMode: 'detailed',//detailed:详情报告,threeYears:三年期
    downloadUrl: "",//下载报告
    isGlobalDisableEdit: false,//是否全局禁用编辑
    isLoading: true,
    records: [],
    treeData: [],
    overall: {},
    ReviewStatus: -1, //审核状态
    discountPolicyTree: {},
    initDataSnapShot: "", //服务器数据的快照,
    isDataModified: false, //数据是否更新过
    resultHeadLegends: {},//可视化报告的图表的legend
  };
}

export const state = {
  ...initialState()
};

//根据indexes找到data里对应的子节点
function findTheNode(data, indexes) {
  indexes = [...indexes];
  let node;

  function findNode(data, indexes) {
    let index = indexes.shift();
    if (indexes.length >= 0 && index !== null) {
      node = data[index];
      if (!node) {
        return null;
      } else if (node.children && indexes.length > 0) {
        findNode(node.children, indexes);
      }
    }
  }

  findNode(data, indexes);
  return node;
}


function findActiveNode(treeData) {
  let resultNode

  function walk(theNode) {
    for (const theChildNode of theNode.children) {
      if (theChildNode.selected) {
        resultNode = theChildNode
        break
      } else {
        walk(theChildNode)
      }
    }
  }

  for (const theNode of treeData) {
    if (theNode.selected) {
      resultNode = theNode
      break
    } else {
      walk(theNode)
    }
  }
  return resultNode
}

const getters = {
  // 根据indexes查找节点
  findTheNodeByIndexes: state => (indexes, target = "treeData") => {
    return cloneDeep(findTheNode(state[target], indexes));
  },
  getSelectedNode: state => (target = 'treeData') => {
    return cloneDeep(findActiveNode(state[target]))
  },
  isEditableForAction: state => action => {
    const isProjectManangerEditableReviewStatus = [1019, 1020, 1022, 1027];
    const isCensorEditableReviewStatus = [1021, 1025, 1023];
    let ret = false;

    if (action == "edit") {
      ret = isProjectManangerEditableReviewStatus.includes(parseInt(state.ReviewStatus));
    } else if (action == "censor") {
      ret = isCensorEditableReviewStatus.includes(parseInt(state.ReviewStatus));
    }
    return ret;
  },
  //审核完成
  isCensorDone() {
    const censorDoneStatus = [1024, 1026];
    return censorDoneStatus.includes(parseInt(state.ReviewStatus));
  }
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  /**
   * 设置报告详情是三年期报告还是普通报告详情
   * @param state
   * @param mode
   */
  setOpenMode(state, {mode}) {
    state.openMode = mode
  },
  initResultHeadLegends(state, {groupName, size}) {
    const resultHeadLegends = cloneDeep(state.resultHeadLegends)
    if (!resultHeadLegends[groupName]) {
      resultHeadLegends[groupName] = []

      for (let i = 0; i < size; i++) {
        resultHeadLegends[groupName][i] = true
      }

    }
    state.resultHeadLegends = resultHeadLegends;
  },
  toggleResultHeadLegends(state, {groupName, index}) {
    const resultHeadLegends = cloneDeep(state.resultHeadLegends)
    resultHeadLegends[groupName][index] = !resultHeadLegends[groupName][index];
    state.resultHeadLegends = resultHeadLegends;
  },
  setResultHeadLegends(state, {groupName, index, value}) {
    const resultHeadLegends = cloneDeep(state.resultHeadLegends)
    resultHeadLegends[groupName][index] = value;
    state.resultHeadLegends = resultHeadLegends;
  },
  showOnlyResultHeadLegends(state, {groupName, index}) {
    const resultHeadLegends = cloneDeep(state.resultHeadLegends)
    for (const i in resultHeadLegends[groupName]) {
      resultHeadLegends[groupName][i] = false
    }
    resultHeadLegends[groupName][index] = true;
    state.resultHeadLegends = resultHeadLegends;
  },
  /*更新indexes修改vuex里节点数的属性的值
   * data 要更新的节点的数据
   * indexes 在整个结构里的层次索引
   * target 综合风险还是优惠政策
   * silent 是否触发修改检查（如果触发，保存按钮就会变得可用）
   *
   */
  setTreeDataByIndexes(state, {data, indexes, target = "treeData", silent = false}) {
    //深拷贝一下vuex里的treeData
    const nativeTreeData = cloneDeep(state[target]);

    //根据索引找到vuex里的treeData里的节点
    const theNode = findTheNode(nativeTreeData, indexes);
    //
    Object.entries(data).forEach(([key, val]) => {
      theNode[key] = val;
    });

    state[target] = nativeTreeData;

    if (!silent) {
      this.commit("ResultEditor/compareSnapshot");
    }
  },
  setDownloadUrl(state, downloadUrl) {
    state.downloadUrl = downloadUrl;
  },
  /*
   *整理一下sort和orderNumber
   * sort是排序用的序号
   * orderNumber是显示用的序号 之所以有两个是因为  ,节点隐藏后节点显示的序号不被计算,
   * 但是节点的实际的序号是不会改变
   * orderStartFrom 标签第一层的起始数字
   * sortStartFrom 顺序的起始数字
   */
  refineOrder(state, {target = "treeData"}) {
    const data = state[target];
    let sort = 0;

    let ret = [];

    function refine(data, floor, orderLabel = [], indexes = []) {
      let order = 0;
      if (target == "discountPolicyTree" && floor == 0) {
        order = state.treeData.length; //优惠政策的order的第一层 从标记综合风险评估的第一层的最大order开始
      }
      return data.map((aData, index) => {
        const currentOrderLabel = orderLabel.concat(order + 1);
        aData.floor = currentOrderLabel.length;
        const str = "ABCDEF"
        aData.gradeMark = str[aData.floor - 1];

        //如果节点显示
        if (aData.isDisplay) {
          order++;
          aData.orderNumber = currentOrderLabel.join(".");
        } else {
          //如果节点隐藏
          aData.orderNumber = "";
        }
        aData.sort = sort;
        aData.indexes = indexes.concat(index);
        aData.index = index;
        aData.selected = false

        //
        sort++;
        if (aData.children) {
          if (aData.children.length > 0) {
            data[index].children = refine(aData.children, ++floor, currentOrderLabel, aData.indexes);
          } else {
            //delete aData.children //如果没有子节点就删除children属性
          }
        }

        return aData;
      });
    }

    ret = refine(data, 0, [], []);

    //提交新的数据
    state[target] = cloneDeep(ret);

    // if (state.status == 0) {
    //   state.status = 1;
    // }
  },
  setTreeDataRecursively(state, {data, target = 'treeData', silent = false}) {
    const nativeTreeData = state[target]

    function walk(theNode) {
      Object.entries(data).forEach(([key, val]) => {
        Vue.set(theNode, key, val)
      })
      if(theNode.children && theNode.children.length > 0){
        for (const child of theNode.children) {
          walk(child)
        }
      }
    }

    for (const theNativeTreeData of nativeTreeData) {
      walk(theNativeTreeData)
    }
  },
  //综合风险评估
  setTreeData(state, {data}) {
    state.treeData = data;
    this.commit("ResultEditor/compareSnapshot");
  },
  setRecords(state, {data}) {
    state.records = data;
  },
  setIsLoading(state, isLoading) {

    state.isLoading = isLoading
  },
  //优惠政策
  setDiscountPolicyTree(state, {data}) {
    state.discountPolicyTree = data;
    this.commit("ResultEditor/compareSnapshot");
  },
  setTree(state, {data, target}) {
    if (target == "treeData") {
      this.commit("ResultEditor/setTreeData", {data, target});
    } else if (target == "discountPolicyTree") {
      this.commit("ResultEditor/compareSnapshot", {data, target});
    }
  },
  //企业财税风险概述
  setOverall(state, {data}) {
    state.overall = data;
    this.commit("ResultEditor/compareSnapshot");
  },

  setReviewStatus(state, status) {
    state.ReviewStatus = status;
  },
  snapshotInitData(state) {
    const data = {
      treeData: state.treeData,
      overall: state.overall,
      discountPolicyTree: state.discountPolicyTree
    };
    state.initDataSnapShot = JSON.stringify(data);
  },
  compareSnapshot(state) {
    if (!state.isDataModified && state.initDataSnapShot) {
      const data = {
        treeData: state.treeData,
        overall: state.overall,
        discountPolicyTree: state.discountPolicyTree
      };
      const currentSnapshot = JSON.stringify(data);
      if (currentSnapshot !== state.initDataSnapShot) {
        state.isDataModified = true;
      }
    }
  },
};

const actions = {
  //载入测评报告信息
  async loadPDFEditorData({commit, rootState}, {
    uuid,
    nsrsbh,
    action,
    isSimple = 0,
    isFromAdmin = false
  }) {
    let resData;
    try {
      if (isFromAdmin) {
        resData = await getAdminPdfCatalogData({
          nsrsbh,
          uuid,
          isSimple,
          desensitizationStatus: getDesensitizationFromRouter()
        });
      } else if (action == "edit") {
        resData = await getEditPdfCatalogData({
          nsrsbh,
          uuid,
          isSimple,
          desensitizationStatus: getDesensitizationFromRouter()
        });
      } else {
        resData = await getSencorPdfCatalogData({
          nsrsbh,
          uuid,
          isSimple,
          desensitizationStatus: getDesensitizationFromRouter()
        });
      }
    } catch (error) {
      throw error;
    }
    commit("setOverall", {data: resData.data.ZS});
    commit("setTreeData", {data: resData.data.pdfData});
    commit("refineOrder", {target: "treeData"});

    commit("setDiscountPolicyTree", {data: resData.data.YHZC});
    //commit("refineOrder", { target: "discountPolicyTree" });
    commit("setDownloadUrl", resData.data.downloadUrl);
    commit("setReviewStatus", resData.data.ReviewStatus);
    commit("snapshotInitData");
  },
  async refresh({dispatch, commit, state, rootState}, {uuid, nsrsbh, isSimple = 0, action}) {

    await dispatch("loadPDFEditorData", {uuid, nsrsbh, action, isSimple});
    await dispatch("loadCheckLogList", {uuid, nsrsbh});

  },
  async updateReviewStatus(
    {dispatch, commit, state, rootState},
    {uuid, nsrsbh, status, action, isSimple = 0}
  ) {
    const resData = await updateReviewStatus({nsrsbh, uuid, status, action, isSimple});

    if (resData.msgCode == 1) {
      commit("setReviewStatus", status);
    }
  },
  //无需审核更改状态
  async updateUnReviewStatus(
    {dispatch, commit, state, rootState},
    {uuid, nsrsbh, status, action, isSimple = 0}
  ) {
    const resData = await updateUnReviewStatus({nsrsbh, uuid, status, action, isSimple});

    if (resData.msgCode == 1) {
      commit("setReviewStatus", status);
    }
    return resData;
  },
  async loadCheckLogList({dispatch, commit, state, rootState}, {uuid, nsrsbh}) {
    const resData = await getCheckLogList({
      nsrsbh: nsrsbh,
      uuid: uuid
    });

    if (resData.msgCode == 1) {
      commit("setRecords", {data: resData.data});
    }
  },
  async submit() {
    // setTimeout(()=>{
    //找到顶部的导航栏里隐藏的保存按钮点击一下
    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true
    });

    document.querySelector("#btn-save").dispatchEvent(event);
    // },1000)

  },
  async reload() {
    // setTimeout(()=>{
    state.isLoading = true;
    //找到顶部的导航栏里隐藏的保存按钮点击一下
    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true
    });

    document.querySelector("#btn-reload").dispatchEvent(event);
    state.isLoading = false;
    //},1000)

  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
