<template>
  <div>
    <div class="wrapper">
      <draggable v-model="currentNode" @update="handleDragMoved" :disabled="!isEditable">
        <transition-group>
          <template v-for="(item, index) in data">
            <div
              :key="item.id || item.tmpId"
              class="item"
              v-if="!(isCensorDone && !item.isDisplay)"
            >
              <div
                class="title-wrapper"
                :class="item.titleName != '' && item.titleName == classTitleName ? 'orderNum' : ''"
              >
                <el-tooltip
                  effect="dark"
                  :content="item.titleName"
                  placement="top"
                  :disabled="item.titleName.length < 14"
                  popper-class="titleItem"
                  :open-delay="300"
                >
                  <div
                    :class="{
                      title1: item.children && item.children.length > 0,
                      removed: !item.isDisplay,
                      isModified: isMenuItemChanged(index),
                      dragable: isEditable,
                      selected: item.selected,
                    }"
                    class="title"
                    :id="`section-${level + 1}`"
                    v-if="!(isCensorDone && !item.isDisplay)"
                    @click="handleDraggableChoose(item)"
                  >
                    <div style="display: flex; padding: 7px 0">
                      <div
                        v-if="item.children && item.children.length > 0 && item.isDisplay"
                        @click="handleBtnArrowCLick($event, orderNumber(index))"
                      >
                        <div class="el-icon-caret-bottom icon" v-if="!item.hidden"></div>
                        <div class="el-icon-caret-right icon" v-else></div>
                      </div>
                      <div class="blank-icon" v-else></div>
                      <span
                        style="
                          width: 216px;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.orderNumber }} {{ item.titleName }}</span
                      >
                    </div>
                  </div>
                </el-tooltip>
                <div
                  style="position: absolute; right: 0px; top: 7px"
                  v-if="ReviewStatus != 1024 && ReviewStatus != 1026"
                >
                  <el-dropdown placement="right" size="medium">
                    <i
                      class="iconfont iconicon_more"
                      :class="
                        item.titleName != '' && item.titleName == classTitleName ? 'moreClass' : ''
                      "
                    ></i>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span
                          @click="handleBtnRenameClick(item)"
                          @mouseenter="hoverBtnRenameClick(item)"
                          @mouseleave="leaveBtnRenameClick(item)"
                          >&nbsp;<i
                            class="iconfont iconicon_edit"
                            id="iconfont"
                            style="margin-right: 8px"
                          ></i
                          >重命名
                        </span>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <span
                          @click="handleBtnDisplayToggleClick(item)"
                          @mouseenter="hoverBtnRenameClick(item)"
                          @mouseleave="leaveBtnRenameClick(item)"
                        >
                          <template v-if="item.isDisplay == '1'">
                            <i
                              class="iconfont iconicon_yincang"
                              id="iconfont"
                              style="margin-right: 8px"
                            ></i>
                            隐藏
                          </template>
                          <template v-else>
                            <i
                              class="iconfont iconicon_xianshi"
                              id="iconfont"
                              style="margin-right: 8px"
                            ></i>
                            显示
                          </template>

                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="隐藏后，PDF中将不再显示此目录的内容"
                            placement="bottom"
                            popper-class="box-bottom"
                          >
                            <i class="iconfont iconicon_tips" id="iconfont"></i> </el-tooltip
                        ></span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <MenuTree
                :target="target"
                v-if="item.children && !item.hidden && item.isDisplay"
                :data="item.children"
                :indexes="[...indexes, index]"
                :previousOrderNumber="orderNumber(index)"
                :level="level + 1"
              ></MenuTree>
            </div>
          </template>
        </transition-group>
      </draggable>
    </div>
    <RenameDialog ref="RenameDialogRef" @confirm="handleRenameDialogClick"></RenameDialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapGetters, mapMutations, mapState } from "vuex";
import draggable from "vuedraggable";
import mixin from "@/pages/ResultEditor/mixin.js";
import RenameDialog from "@/pages/ResultEditor/components/RenameDialog.vue";

export default {
  mixins: [mixin],
  name: "MenuTree",
  components: {
    RenameDialog,
    draggable,
  },
  props: {
    indexes: Array,
    target: String, //判断菜单是显示优惠政策，还是显示综合风险评估
    data: [Object, Array],
    previousOrderNumber: {
      type: String,
      default: "",
    },
    level: {
      //当前是第几层
      type: Number,
      default: 0,
    },
  },
  watch: {},
  methods: {
    hoverBtnRenameClick(v) {
      this.classTitleName = v.titleName;
    },
    leaveBtnRenameClick(v) {
      if (v.titleName == this.classTitleName) {
        this.classTitleName = "";
      }
    },
    ...mapMutations("ResultEditor", [
      "setTreeDataByIndexes",
      "refineOrder",
      "setTree",
      "setTreeDataRecursively",
    ]),
    handleRenameDialogClick({ name, indexes }) {
      //如果是综述则修改综述里的titleName
      if (this.isOverallNode) {
        const node = cloneDeep(this.node);
        node.titleName = name;
        //node.riskLevel = this.riskLevel
        this.$store.commit("ResultEditor/setOverall", { data: node });
      } else {
        //设置vuex中的titleName
        const nodeData = this.$store.getters["ResultEditor/findTheNodeByIndexes"](indexes);

        //如果当前节点是树状目录中的节点
        nodeData.titleName = name;
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: indexes,
        });
      }
    },
    handleDraggableChoose(item) {
      this.setTreeDataRecursively({
        data: {
          selected: false,
        },
        silent: true,
      });
      this.setTreeDataByIndexes({
        indexes: item.indexes,
        data: {
          selected: true,
        },
        silent: true,
      });
      setTimeout(() => {
        this.handleScrollTo(item.id);
      });
    },
    //这个菜单对应的节点是否被修改过(标黄)
    isMenuItemChanged(index) {
      let ret = false;
      if (this.isCensorDone) {
        return ret;
      }
      let indexes;
      if (this.previousOrderNumber) {
        indexes = this.previousOrderNumber
          .split(".")
          .map((v) => parseInt(v - 1))
          .concat(index);
      } else {
        indexes = [index];
      }

      const node = this.findTheNodeByIndexes(indexes, this.target);

      return this.isInputModified(node) || this.isInputModifiedByMyself(node);
    },
    isInputModified(node) {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = node.lastCensorSnapshot ? node.lastCensorSnapshot.length > 0 : false;
      } else if (this.$route.params.action == "censor") {
        ret = node.lastEditSnapshot ? node.lastEditSnapshot.length > 0 : false;
      }
      return ret;
    },
    isInputModifiedByMyself(node) {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = node.editSnapshot ? node.editSnapshot.length > 0 : false;
      } else if (this.$route.params.action == "censor") {
        ret = node.censorSnapshot ? node.censorSnapshot.length > 0 : false;
      }
      return ret;
    },
    handleScrollTo(id) {
      id = "#node" + id;
      this.$scrollTo(id, 1000);
    },
    //计算前面的序号
    orderNumber(index) {
      return this.previousOrderNumber
        ? this.previousOrderNumber + "." + (index + 1)
        : (index + 1).toString();
    },
    //左边的小箭头点击折叠
    handleBtnArrowCLick(e, indexes) {
      //获得索引
      indexes = indexes.split(".").map((v) => v - 1);

      //获得当前节点
      this.draggingNode = this.findTheNodeByIndexes(indexes, this.target);

      //根据索引更新数据
      this.setTreeDataByIndexes({
        data: {
          hidden: !this.draggingNode.hidden,
        },
        indexes,
        target: this.target,
        silent: true,
      });
    },

    handleDragMoved(e) {
      const index = e.newIndex;

      const indexes = this.previousOrderNumber
        ? this.previousOrderNumber
            .split(".")
            .map((v) => parseInt(v - 1))
            .concat(index)
        : [index];
      // debugger;
      //标识这个节点位置变过
      this.setTreeDataByIndexes({
        data: {
          updateSort: 1,
        },
        indexes,
        target: this.target,
      });

      // this.$message({
      //   message: `${this.draggingNode.titleName} 与 ${this.targetNode.titleName} 位置已经交换`,
      //   type: "info"
      // });
    },
    //重命名
    handleBtnRenameClick(item) {
      this.$refs["RenameDialogRef"].open(item.titleName, item.indexes);
    },
    //保存
    handleSaveName() {
      this.dialogFormVisibleName = false;
      const node = cloneDeep(this.node);
      node.titleName = this.titleInput;
      this.$store.commit("ResultEditor/setOverall", { data: node });
      this.setTreeDataByIndexes({
        data: nodeData,
        indexes,
        target: this.target,
      });
    },
    //隐藏显示
    handleBtnDisplayToggleClick(item) {
      const indexes = item.indexes;
      const nodeData = cloneDeep(item);
      nodeData.isDisplay = nodeData.isDisplay == "1" ? 0 : 1;
      this.setTreeDataByIndexes({
        data: nodeData,
        indexes,
        target: this.target,
      });
      this.refineOrder({ target: this.target });
    },
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes", "isCensorDone"]),
    //是否是综述节点
    isOverallNode() {
      return this.indexes == null;
    },
    getTitleClass() {
      return "title" + this.level;
    },
    ...mapState("ResultEditor", ["treeData", "discountPolicyTree", "ReviewStatus"]),

    currentNode: {
      get() {
        return this.data;
      },
      set(value) {
        let nativeTreeData = cloneDeep(this[this.target]);

        if (this.previousOrderNumber) {
          //如果不是父节点
          let indexes = this.previousOrderNumber.split(".");
          //获得索引
          indexes = indexes.map((v) => v - 1);

          //获得当前节点
          this.targetNode = this.findTheNodeByIndexes(indexes, this.target);

          const children = cloneDeep(value).map((item, index) => {
            item.sort = index;
            return item;
          });

          this.setTreeDataByIndexes({
            data: {
              children,
            },
            indexes,
            target: this.target,
          });
        } else {
          //如果是父节点
          const data = cloneDeep(value).map((item, index) => {
            item.sort = index;
            return item;
          });

          this.setTree({ data, target: this.target });

          // nativeTreeData = cloneDeep(value).map((item, index) => {
          //   item.sort = index;
          //   return item;
          // });
        }

        this.refineOrder({ target: this.target });

        // nativeTreeData = this.refineOrder(nativeTreeData);

        // if (this.target == "discountPolicyTree") {
        //   this.$store.commit("ResultEditor/setDiscountPolicyTree", {
        //     data: nativeTreeData
        //   });
        // } else {
        //   this.$store.commit("ResultEditor/setTreeData", {
        //     data: nativeTreeData
        //   });
        // }
      },
    },
  },
  data: function () {
    return {
      nativeTreeData: [],
      draggingNode: null,
      targetNode: null,
      dialogFormVisibleName: false,
      // dialogFormVisible: false,
      titleInput: "",
      dialogTitle: "",
      classTitleName: "",
    };
  },
  async mounted() {
    // setTimeout(() => {
    //   debugger;
    // }, 5000);
    // console.log(this.data);
  },
};
</script>

<style lang="stylus" scoped>
.sortable-ghost {
  opacity: 0 !important;
}

.wrapper {
  //color: #666;
  line-height: 1.5;
  font-size: 14px;
  display: flex;
  flex-direction: column;

  .item {
    //padding: 8px;
    //padding-left: 8px;


    .title-wrapper {
      display: flex;
      align-items: center;
      position relative

      .blank-icon {
        padding-right: 0px;
        width: 17px;
      }

      .icon {
        cursor: pointer;
        padding-right: 4px;
        width: 17px;
        box-sizing: border-box;
      }

      .title {
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 260px;
      }

      .title.dragable {
        cursor: grab;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .title:hover {

      }

      .title0 {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 600;
      }

      .title1 {
        color: rgba(0, 0, 0, 0.8);
        font-weight: 600;
      }

      .removed {
        text-decoration: line-through;
        color: #999;
      }

      .isModified {
        color: rgba(250, 173, 20, 1) !important;
      }
    }
  }

  .selected {
    background-color: #e6f7ff;
    color: #2663F6;
  }
}

>>> item {
  overflow hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  //width:214px;
}

>>> .wrapper .item .title-wrapper:hover {
  background: #F6F8FA;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
}

>>> .wrapper .item .title-wrapper:hover .iconicon_more {
  color: #1D2129
}
.orderNum{
  background: #F6F8FA;
}
:deep(.el-tooltip__popper) {
  padding: 0.7rem;
  width: auto
}

#iconfont{
  font-size:14px;
  color:#1D2129;
}
.iconfont.iconicon_more {
  padding: 5px 5px;
  border-radius: 4px 4px 4px 4px;
  color: #F6F8FA;
  margin-top: 2px
  font-size:14px;
}

.iconfont.iconicon_more:hover {
  background: #dededec7;
  color: #1D2129;
}

#section-1 {
  padding-left: 7px
}

#section-2 {
  padding-left: 20px
}

#section-3 {
  padding-left: 30px
}

>>> .el-dropdown-menu--medium {
  padding: 4px 8px;
}

>>> .el-dropdown-menu__item:not(.is-disabled):hover {
  color: #1D2129;
  background: #F6F8FA;
  border-radius: 4px 4px 4px 4px
}
>>> .el-dropdown-menu--medium .el-dropdown-menu__item {
  padding: 0 9px;
  line-height: 36px;
}
>>> .el-dropdown-menu--medium .el-dropdown-menu__item {
  //padding: 8px 9px;
}
>>>.el-dropdown-menu__item {
  color:#1D2129;
  margin: 0 8px;
}
.moreClass{
  background: #dededec7;
  color: #1D2129 !important;
}
</style>
<style lang="scss">
.titleItem {
  width: auto !important;
  padding: 0.8rem 0.5rem;
}

.box-bottom {
  width: auto !important;
  padding: 0.8rem 0.5rem;
}
</style>
