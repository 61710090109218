<template>
  <div class="table-wrapper">
    <div style="overflow-x: hidden; width: 100%; position: relative">
      <div
        class="fake"
        :style="{
          'padding-right': `${scrollBarWidth}px`,
          height: `${tableHeaderHeight}px`,
          left: -realTableWrapperscrollLeft + 'px',
        }"
        v-show="isOverFlowed"
      >
        <table class="table" :style="{ width: tableWidth + 'px' }">
          <thead>
            <tr :key="rowIndex" v-for="(row, rowIndex) in table.header">
              <th
                v-for="(col, colIndex) in row"
                :style="getThStyle(col)"
                :class="{ removed: col._removed }"
                :rowspan="col.rowspan"
                :colspan="col.colspan"
                v-if="!col._removed"
              >
                <div :title="col.content" class="content">
                  {{ col.content }}
                  <template v-if="col.tip">
                    <el-tooltip class="item" effect="dark" placement="top">
                      <div slot="content">{{ col.tip }}</div>
                      <!-- <i class="tip-icon el-icon-warning"></i> -->
                      <i class="iconfont iconicon_tips"></i>
                    </el-tooltip>
                  </template>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="table.body && table.body.length > 0">
              <template v-for="(row, rowIndex) in table.body">
                <tr :key="rowIndex">
                  <td
                    v-for="(col, colIndex) in row"
                    :style="getTdStyle(table, col, colIndex)"
                    :rowspan="col.rowspan"
                    :colspan="col.colspan"
                    v-if="!col._removed"
                    :key="`${colIndex}`"
                  >
                    <div :title="col.content" class="content" :style="{ color: col.color }">
                      {{ col.content }}
                    </div>
                  </td>
                </tr>
                <template v-for="(col, colIndex) in row">
                  <template v-if="col.children && col.showChildren">
                    <tr v-for="(row2, rowIndex2) in col.children" :key="`${rowIndex2}_children`">
                      <td
                        v-for="(col2, colIndex2) in row2"
                        :style="getTdStyle(table, col2, colIndex2)"
                        :rowspan="col2.rowspan"
                        :colspan="col2.colspan"
                        v-if="!col2._removed"
                        :key="`${colIndex2}_children`"
                      >
                        <div :title="col2.content" class="content" :style="{ color: col2.color }">
                          {{ col2.content }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
            <template v-else>
              <tr>
                <td :colspan="columnCount" style="text-align: center" class="no-data">暂无数据</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div ref="realTable-wrapper" class="realTable-wrapper bigPowerTable">
        <table ref="realTable" class="table">
          <thead>
            <tr v-for="row in table.header">
              <th
                v-for="(col, colIndex) in row"
                :style="getThStyle(col)"
                :class="{ removed: col._removed }"
                :rowspan="col.rowspan"
                :colspan="col.colspan"
                v-if="!col._removed"
              >
                <div :title="col.content" class="content" @click="handleCellClick(col)">
                  {{ col.content }}
                  <template v-if="col.tip">
                    <el-tooltip class="item" effect="dark" placement="top">
                      <div slot="content">{{ col.tip }}</div>
                      <i class="iconfont iconicon_tips"></i>
                    </el-tooltip>
                  </template>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="table.body && table.body.length > 0">
              <template v-for="(row, rowIndex) in table.body">
                <tr :key="`${rowIndex}`">
                  <td
                    v-for="(col, colIndex) in row"
                    :style="getTdStyle(table, col, colIndex)"
                    :rowspan="col.rowspan"
                    :colspan="col.colspan"
                    v-if="!col._removed"
                    :key="`${colIndex}`"
                  >
                    <div
                      :title="col.content"
                      class="content"
                      :class="{ clickable: col.click || (col.children && col.children.length > 0) }"
                      @click="handleCellClick(col)"
                    >
                      {{ col.content }}
                      <template v-if="col.tip">
                        <el-tooltip class="item" effect="dark" placement="top">
                          <div slot="content">{{ col.tip }}</div>
                          <i class="iconfont iconicon_tips"></i>
                        </el-tooltip>
                      </template>
                    </div>
                  </td>
                </tr>
                <template v-for="(col, colIndex) in row">
                  <template v-if="col.children && col.showChildren">
                    <tr v-for="(row2, rowIndex2) in col.children" :key="`${rowIndex2}_children`">
                      <td
                        v-for="(col2, colIndex2) in row2"
                        :style="getTdStyle(table, col2, colIndex2)"
                        :rowspan="col2.rowspan"
                        :colspan="col2.colspan"
                        v-if="!col2._removed"
                        :key="`${colIndex2}_children`"
                      >
                        <div
                          :title="col2.content"
                          class="content"
                          :class="{ clickable: col.click }"
                          @click="handleCellClick(col2)"
                        >
                          {{ col2.content }}
                          <template v-if="col2.tip">
                            <el-tooltip class="item" effect="dark" placement="top">
                              <div slot="content">{{ col2.tip }}</div>
                              <i class="iconfont iconicon_tips"></i>
                            </el-tooltip>
                          </template>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
            <template v-else>
              <tr>
                <td :colspan="columnCount" style="text-align: center" class="no-data">暂无数据</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  drawRemovedRect,
  getThStyle,
  getTdStyle,
  getIsOverflowed,
} from "@/components/bigPowerTable/BigPowerTableEffects";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    data: Object,
  },
  mounted() {
    this.render();
  },
  data() {
    return {
      scrollBarWidth: 0,
      scrollBarHeight: 0,
      tableHeaderHeight: 0,
      isOverFlowed: false,
      tableWidth: 0,
      tableHeight: 0,
      realTableWrapperscrollLeft: 0,
      table: null,
    };
  },
  watch: {
    data: {
      handler() {
        const data = cloneDeep(this.data);
        if (data && data.header) {
          data.header.forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
              const colspan = cell.colspan || 1;
              const rowspan = cell.rowspan || 1;
              if (cell.showChildren == null) {
                cell.showChildren = false;
              }

              if (colspan > 1 || rowspan > 1) {
                drawRemovedRect(
                  data.header,
                  rowIndex,
                  colIndex,
                  rowIndex + rowspan - 1,
                  colIndex + colspan - 1
                );
              }
            });
          });
        }
        if (data && data.body) {
          data.body.forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
              const colspan = cell.colspan || 1;
              const rowspan = cell.rowspan || 1;
              if (cell.showChildren == null) {
                cell.showChildren = false;
              }
              if (colspan > 1 || rowspan > 1) {
                drawRemovedRect(
                  data.body,
                  rowIndex,
                  colIndex,
                  rowIndex + rowspan - 1,
                  colIndex + colspan - 1
                );
              }
            });
          });
        }
        this.table = data;
        this.render();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    columnCount() {
      if (this.data && this.data.header && this.data.header.length > 0) {
        return this.data.header[0].reduce((acc, cur) => {
          let colspan = 1;
          if (cur.colspan) {
            colspan = cur.colspan;
          }
          return acc + colspan;
        }, 0);
      } else {
        return 1;
      }
    },
  },
  methods: {
    handleRealTableScroll(e) {
      if (e.target) {
        this.realTableWrapperscrollLeft = e.target.scrollLeft;
      }
    },
    /**
     * 渲染表格
     */
    render() {
      setTimeout(() => {
        const realTable = this.$refs["realTable"];
        if (realTable != null) {
          //滚动条宽度
          this.scrollBarWidth = realTable.parentNode.offsetWidth - realTable.offsetWidth;

          //表格头高度
          this.tableHeaderHeight = realTable.querySelector("thead").clientHeight;

          this.tableWidth = realTable.offsetWidth;
          //表格内容是否溢出
          this.isOverFlowed = getIsOverflowed(realTable);
        }

        const realTableWrapper = this.$refs["realTable-wrapper"];
        if (realTableWrapper) {
          realTableWrapper.removeEventListener("scroll", this.handleRealTableScroll);
          if (realTableWrapper != null) {
            this.scrollBarHeight = 15;
            realTableWrapper.addEventListener("scroll", this.handleRealTableScroll);

            this.tableHeight = realTableWrapper.offsetHeight - this.scrollBarHeight;
          }
        }
      });
    },
    /**
     * 获得th的style
     */
    getThStyle(cell) {
      return getThStyle(cell);
    },
    getTdStyle(table, cell, colIndex) {
      return getTdStyle(table, cell, colIndex);
    },
    handleCellClick(cell) {
      if (cell.children) {
        cell.showChildren = !cell.showChildren;
        this.render();
      }

      if (cell.click) {
        cell.click(cell);
      }
    },
  },
});
</script>

<style lang="stylus" scoped>

@import '~element-ui/lib/theme-chalk/index.css';

.table-wrapper {
  box-sizing border-box
  padding 0
  position relative

  .fake {
    padding-right 16px
    box-sizing border-box
    position absolute
    z-index 1
    overflow hidden
    height 50px
  }

  .fake-left {
    overflow hidden
  }

  .realTable-wrapper {
    overflow-y auto
    overflow-x visible
  }
}

table {
  border-collapse: collapse
  margin-bottom 0
  width 100%

}

th {
  background #ececec
}

th, td {
  border 1px solid #ccc;
  text-align justify
  padding 5px
  min-width 80px
  box-sizing border-box

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.removed {
  background red
}

td {
  background #fff
  border 1px solid #eee;
  line-height 1.2
  text-align justify
}

td:first-child {
  text-align center
}

.text-has-tip {
  color rgba(245, 34, 45, 1)
}

td.left {
  text-align left
}

td.center {
  text-align center;
}

td.left {
  text-align right
}

td.justify {
  text-align justify
}

.clickable {
  color #0000ff
  cursor pointer
}

.no-data {
  color #aaa
}
.iconicon_tips{
  font-size:14px
}
</style>
