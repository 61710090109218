import axios from "axios";
import router from "@/router";
import {
  Message
} from 'element-ui'
import {
  message
} from './resetMessage';

const base = process.env.VUE_APP_API_ROOT;
const baseTaxfirm = base + '/taxfirm';
const baseFk = process.env.VUE_APP_API_ROOT_FK;


axios.defaults.timeout = 1200000;

const matchUrl = ["common/findNeedExcel2", "breeze/getCompanyMsgByTaxNo", "breeze/getProvince",
  "breeze/getIdentityInfo", "breeze/breezeAuthorizationByCode", "breeze/getLoginMethod"
]

// 响应拦截器
axios.interceptors.response.use(response => {
  if (response.status === 200) {
    if (response.data && response.data.msgCode == "401") {
      router.push({
        name: "login",
        query: {
          redirect: window.location.href
        }
      });
      localStorage.clear();
      sessionStorage.clear();
      return Promise.reject(response);
    } else if (response.data.msgCode == "403") {
      // 无权限提示
      if (window.location.href.indexOf('login') > -1) return;
      Message.warning({
        message: response.data.msgInfo
      });
    } else if (response.data.msgCode == "409") {
      // 无权限提示
      message.warning({
        message: "您账户权限已被管理员变更，请重新登录使用!"
      });
      router.push({
        name: "login",
        query: {
          redirect: window.location.href
        }
      });
      localStorage.clear();
      sessionStorage.clear();
      return Promise.reject(response);
    } else if (response.data.msgCode != "1") {
      if (response.data.msgInfo) {
        // if (response.config.url.indexOf("common/findNeedExcel2") == -1) {
        //   Message.warning({
        //     message: response.data.msgInfo
        //   });
        // }
        let matchArr = [];
        let res = [];

        for (let i = 0; i < matchUrl.length; i++) {
          matchArr.push(response.config.url.indexOf(matchUrl[i]));
        }
        res = matchArr.filter((num) => {
          return num > -1;
        });
        if (res.length == 0) {
          Message.warning({
            message: response.data.msgInfo
          });
        }
      }
      return Promise.resolve(response);
    } else {
      return Promise.resolve(response);
    }

    //return Promise.resolve(response);
  } else if (response.status === 401) {
    //token失效
    router.push({
      name: "login"
    });
    return Promise.reject(response);
  } else {
    return Promise.reject(response);
  }
});

//不需要登录权限的URL的前缀
const publicURLs = [
  `${baseTaxfirm}/auth/login`,
  `${baseTaxfirm}/auth/pwd/reset`,
  `${baseFk}/bCompanyUser/login`,
  `${baseFk}/bCompanyUser/login/code`,
  `${baseFk}/bCompanyUser/code/REGISTER/`,
  `${baseFk}/bCompanyUser/code/LOGINNOTICE/`,
  `${baseFk}/bCompanyUser/register`,
  `${baseFk}/bCompanyUser/info/pwd/reset`,
  `${baseFk}/bCompanyUser/code/RESTPWD/`
];

//全局配置请求头+token
axios.interceptors.request.use(
  config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token  有问题。。。
    let token = localStorage.getItem("authorization");
    // 来至后台 取另外一个token  localStorage.getItem("authorizationFromHouTai");
    if(window.location.href.indexOf("fromHouTai") > -1) {
      token = localStorage.getItem("authorizationFromHouTai");
    }
    // 来至后台 取另外一个token  localStorage.getItem("authorizationFromHouTai");
    if (token && config.url.indexOf(baseTaxfirm) > -1) {
      let isPublicURLs = publicURLs.some(url => {
        if (config.url == `${baseTaxfirm}/auth/login/bindingMobile`) {
          return false
        } else {
          return config.url.startsWith(url)
        }

      });
      if (!isPublicURLs) {
        config.headers.Authorization = `${token}`;
      }
    }

    if(token && config.url.indexOf("real-rstate") > -1){
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, isAutoWaring = true) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        if (isAutoWaring && res.data.msgCode != "1" && res.data.msgInfo) {
          Message.warning({
            message: res.data.msgInfo
          });
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, isAutoWaring = true, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then(res => {
        if (isAutoWaring && res.data.msgCode != "1" && res.data.msgInfo) {
          Message.warning({
            message: res.data.msgInfo
          });
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default axios;
