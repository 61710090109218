<template>
  <el-dialog
    title="重命名"
    :visible.sync="dialogVisible"
    width="460px"
    :before-close="close"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :lock-scroll="false"
  >
    <div class="wrapper">
      <el-form :rules="rules" :model="form" class="form" ref="ruleForm">
        <el-form-item label="目录名称" prop="name">
          <el-input v-model="form.name" size="small"></el-input>
        </el-form-item>
      </el-form>
      <div class="label"></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleBtnConfirmClick">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { defineComponent } from "vue";
import MenuTreePathSelector from "@/pages/ResultEditor/components/MenuTreePathSelector.vue";

export default defineComponent({
  name: "RenameDialog",
  components: { MenuTreePathSelector },
  data() {
    return {
      dialogVisible: false,
      form: {
        indexes: "",
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入目录名称", trigger: "blur" }],
      },
    };
  },
  methods: {
    async open(name, indexes) {
      this.form.indexes = indexes;
      this.form.name = name;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    async handleBtnConfirmClick() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.dialogVisible = false;

          this.$emit("confirm", {
            indexes: this.form.indexes,
            name: this.form.name,
          });
        }
      });
    },
  },
});
</script>

<style scoped lang="stylus">
>>> .el-dialog__body {
  padding 0 24px
}

.wrapper {
  padding-top 20px

  .form {
    >>>.el-form-item__label{
      font-size 14px
      color #1D2129
      line-height 1.5
    }
  }
}
</style>
