import {mapGetters, mapState} from "vuex";
import {findTheNode, isSame} from "@/utils/treeData";

let mixin = {
    computed: {
        ...mapState("ResultEditor", ["treeData", "isGlobalDisableEdit"]),
        ...mapGetters("ResultEditor", ["isEditableForAction", "findTheNodeByIndexes"]),
        isEditable() {
            return !this.isGlobalDisableEdit && this.isEditableForAction(this.$route.params.action);
        }
    },
    data() {
        return {
            node: null,
        }
    },
    watch: {
        treeData: {
            handler(newValue) {
                if (newValue == null) {
                    return
                }
                const newNode = findTheNode(newValue, this.indexes)
                const oldNode = this.node
                // 如果节点不相等，说明节点已经被替换了，需要重新加载数据
                if (!isSame(newNode, oldNode)) {
                    //如果没有提供indexes则是overall
                    if (this.isOverallNode) {
                        this.node = this.overall;
                    } else {
                        //如果有则是可以排序的节点
                        this.node = this.findTheNodeByIndexes(this.indexes, this.target);
                    }
                }

                if (!this.indexes) {
                    if (this.isOverallNode) {
                        this.node = this.overall;
                    } else {
                        //如果有则是可以排序的节点
                        this.node = this.findTheNodeByIndexes(this.indexes, this.target);
                    }
                } else {
                    const newNode = findTheNode(newValue, this.indexes)
                    const oldNode = this.node
                    // 如果节点不相等，说明节点已经被替换了，需要重新加载数据
                    if (!isSame(newNode, oldNode)) {
                        //如果没有提供indexes则是overall
                        if (this.isOverallNode) {
                            this.node = this.overall;
                        } else {
                            //如果有则是可以排序的节点
                            this.node = this.findTheNodeByIndexes(this.indexes, this.target);
                        }
                    }
                }

            },
            deep: true,
            immediate: true
        }
    }
};

export default mixin;
