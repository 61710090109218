<template>
  <div class="nav">
    <div class="navmain">
      <div class="navtitle">
        <!-- <img class="navtitle-img" src="@/assets/home/aitax-icon.png" alt /> -->
        <div class="navtitle-text">
          <a target="_blank" href="http://www.ai-tax.cn/">进入官网</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {};
  }
};
</script>
<style scoped lang="scss">
.nav {
  position: relative;
  height: 80px;
  line-height: 80px;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  .navmain {
    width: 1200px;
    margin: 0 auto;

    .navtitle {
      width: 100%;
      div {
        display: inline-block;
        vertical-align: middle;
        color: #2f54eb;
        position: absolute;
        right: 48px;
        a {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2f54eb;
        }
      }
      img {
        position: absolute;
        left: 48px;
        top: 24px;
      }
    }
  }
}
</style>