<template>
  <div>
    <div
      class="btn-toolbar"
      v-if="data.anchorPointType == 'invoice-model'"
      :class="{ 'left-align': data.floor == 2 }"
    >
      <button class="btn btn-show-dialog" @click="handleShowInvoiceModelDialog">
        <i class="iconfont iconicon_fp"></i> 发票
      </button>
      <!-- <el-button type="primary" plain> <i class="iconfont iconicon_fp"></i>发票</el-button> -->
    </div>
    <div class="table-wrapper" :class="{ default: !width }">
      <table class="table">
        <thead>
          <tr>
            <th v-for="(label, index) in labels" :key="index" :style="getThStyle(label)">
              {{ label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in tableData" :key="index">
            <td
              v-if="!row.includes('合计')"
              v-for="(col, index2) in row"
              :key="index2"
              :style="{ 'text-align': getAlign(labels[index2]) }"
            >
              <el-tooltip
                class="item"
                effect="light"
                :content="tipText(index, index2)"
                placement="top-start"
                :disabled="!tipText(index, index2)"
              >
                <span
                  v-if="col == 'button{detail}'"
                  class="clickable"
                  @click="handleCellClick(index)"
                  :class="{ 'text-has-tip': tipText(index, index2) }"
                >
                  <a href="#" @click.prevent="handleToggleDetailCLick" class="link">{{
                    toggleLabel
                  }}</a>
                </span>

                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="col"
                  placement="right"
                  :disabled="col.length < 15"
                  :open-delay="1000"
                  v-else
                >
                  <span
                    v-html="col"
                    :class="{
                      'text-has-tip': tipText(index, index2),
                      'text-has-table': !!subTable(index, index2),
                    }"
                    @click="handleSubTableLinkClick(subTable(index, index2), index, index2)"
                  ></span>
                </el-tooltip>
              </el-tooltip>
            </td>
            <td
              style="position: sticky; bottom: -1px; background: #fff; border: 1px solid #dedede"
              v-if="row.includes('合计')"
              v-for="(col, index2) in row"
              :style="{ 'text-align': getAlign(labels[index2]) }"
            >
              <el-tooltip
                class="item"
                effect="light"
                :content="tipText(index, index2)"
                placement="top-start"
                :disabled="!tipText(index, index2)"
              >
                <span
                  v-if="col == 'button{detail}'"
                  class="clickable"
                  @click="handleCellClick(index)"
                  :class="{ 'text-has-tip': tipText(index, index2) }"
                >
                  <a href="#" @click.prevent="handleToggleDetailCLick" class="link">{{
                    toggleLabel
                  }}</a>
                </span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="col"
                  placement="right"
                  :disabled="col.length < 15"
                  :open-delay="1000"
                  v-else
                >
                  <span
                    v-html="col"
                    :class="{
                      'text-has-tip': tipText(index, index2),
                      'text-has-table': !!subTable(index, index2),
                    }"
                    @click="handleSubTableLinkClick(subTable(index, index2), index, index2)"
                  ></span>
                </el-tooltip>
              </el-tooltip>
            </td>
          </tr>
          <!--  -->
        </tbody>
      </table>

      <el-drawer
        :title="subTableInDrawer ? subTableInDrawer.name : ''"
        :visible.sync="drawer"
        direction="rtl"
        :before-close="handleClose"
      >
        <div class="drawer-inner">
          <GenericTable v-if="generalTableData" :data="generalTableData"></GenericTable>
        </div>
      </el-drawer>

      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="1240px"
        :z-index="2010"
        :before-close="handleDialogClose"
      >
        <ReportBill
          :tax-no="$route.query.taxNo"
          :uuid="$route.params.uuid"
          :model-id="data.modelId"
        ></ReportBill>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ReportBill from "@/pages/tools/bill/report-bill.vue";

const alignRightKeywords = [
  "本期期末余额借方",
  "本期期末余额贷方",
  "本期行业平均上期",
  "发票数量",
  "价税合计",
  "占比税额",
  "金额",
  "占比",
  "税额",
  "开票数量",
  "被执行标的金额合计",
  "涉案案件次数",
  "采购价税合计",
  "销售价税合计",
  "均值",
  "2031年",
  "2030年",
  "2029年",
  "2028年",
  "2027年",
  "2026年",
  "2025年",
  "2024年",
  "2023年",
  "2022年",
  "2021年",
  "2020年",
  "本期",
  "上期",
  "数量",
  "计税合计金额",
];

export default {
  name: "GenericTable",
  components: {
    ReportBill,
  },
  props: {
    width: {
      type: [String, Number],
      default: " 1060px",
    },
    data: Object,
    drawerTriggerIndex: Number,
    showDetails: Boolean,
    tips: Array,
    subTables: Array,
  },
  computed: {
    tableData() {
      return this.data.data;
    },
    labels() {
      return this.data.labels;
    },
    toggleLabel() {
      return this.showDetails ? "收起" : "展开";
    },
    //通用表格
    generalTableData() {
      if (!this.subTableInDrawer) {
        return null;
      }
      const ret = [];
      for (let i = 0; i < this.subTableInDrawer.detailedTable.body.length; i++) {
        ret[i] = [];

        this.subTableInDrawer.detailedTable.header.forEach((key) => {
          ret[i].push(this.subTableInDrawer.detailedTable.body[i][key]);
        });
      }

      return {
        data: ret,
        labels: this.subTableInDrawer.detailedTable.header,
        anchorPointType: this.subTableInDrawer.detailedTable.anchorPointType,
        modelId: this.data.modelId,
        floor: 2,
      };
    },
  },
  data() {
    return {
      drawer: false,
      dialogVisible: false,
      subTableInDrawer: null,
    };
  },
  mounted() {},
  methods: {
    getThStyle(label) {
      const tableWidth = parseInt(this.width.replace("px", ""));
      const ret = {
        "text-align": this.getAlign(label),
      };
      if (this.labels.length * 100 <= tableWidth) {
        ret["min-width"] = "100px";
      } else {
        ret["width"] = Math.min(tableWidth / this.labels.length) + "px";
      }
      return ret;
    },
    handleDialogClose() {
      this.dialogVisible = false;
    },
    handleShowInvoiceModelDialog() {
      this.dialogVisible = true;
    },
    guessThStyle(label, index) {
      let ret = {
        "max-width": "300px",
        "min-width": "100px",
      };
      if (label.includes("序号") || label.includes("行次")) {
        ret = { width: "30px", "min-width": "0px", "text-align": "center" };
      } else if (label.includes("日期")) {
        ret = { width: "100px" };
      } else if (label.includes("时间")) {
        ret = { width: "100px" };
      } else if (label.includes("地区")) {
        ret = { width: "100px" };
      } else if (label.includes("信息类型")) {
        ret = { width: "100px" };
      } else if (label.includes("变更类型")) {
        ret = { width: "250px" };
      } else if (label.includes("资产（单位：万元）") || label.includes("负债（单位：万元）")) {
        ret = { width: "450px" };
      } else if (label.includes("最近2年连续供应年限")) {
        ret = { width: "100px", "white-space": "wrap" };
      }
      return ret;
    },
    getAlign(label, index) {
      if (label == null) {
        return "justify";
      }
      if (alignRightKeywords.find((item) => label.trim().startsWith(item))) {
        return "right";
      } else {
        return "justify";
      }
    },
    guessTdStyle(value, index, label) {
      if (value == null) {
        return {};
      }
      if (typeof value == "string") {
        value = value.replace(/%$/, "");
      }

      let ret = {};
      value = value.toString().replace(/%$/, "");
      // value = value.replace(/万/,'')
      // value = value.replace(/人民币/,'')
      // value = value.replace(/元/,'')
      // value = value.replace(/升/,'')
      // value = value.replace(/月/,'')
      // value = value.replace(/年/,'')
      if (index === 0) {
        ret = {
          "text-align": "center",
        };
      }
      if (label == "状态") {
        ret = { "text-align": "justify" };
      } else if (label.includes("日期") || label.includes("月份")) {
        ret = { "text-align": "justify" };
      } else if (label.includes("时间")) {
        ret = { "text-align": "justify" };
      } else if (value == "-") {
        ret = {
          "text-align": "center",
        };
      } else if (!isNaN(value)) {
        ret = {
          "text-align": "right",
        };
      } else {
        ret = {
          "text-align": "justify",
        };
      }
      return ret;
    },
    handleSubTableLinkClick(tableData, index, index2) {
      if (!tableData) {
        return;
      }
      this.subTableInDrawer = tableData;
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
    //点击打开表格
    subTable(row, col) {
      let ret = null;
      if (!this.subTables) {
        return null;
      }
      const table = this.subTables.find((v) => {
        let isShowTipInRow = false;
        if (v.pos[0] == -1 || v.pos[0] == row) {
          isShowTipInRow = true;
        }

        let isShowTipInCol = false;
        if (v.pos[1] == -1 || v.pos[1] == col) {
          isShowTipInCol = true;
        }
        if (isShowTipInRow && isShowTipInCol) {
          // debugger
        }
        return isShowTipInRow && isShowTipInCol;
      });
      if (table) {
        ret = {
          detailedTable: table.detailedTable,
          name: table.name,
        };
      }

      return ret;
    },
    //需要提示的文案
    tipText(row, col) {
      // console.log(row, col);
      let ret = null;
      if (!this.tips) {
        return null;
      }
      const tip = this.tips.find((v) => {
        let isShowTipInRow = false;
        if (v.pos[0] == -1 || v.pos[0] == row) {
          isShowTipInRow = true;
        }

        let isShowTipInCol = false;
        if (v.pos[1] == -1 || v.pos[1] == col) {
          isShowTipInCol = true;
        }

        return isShowTipInRow && isShowTipInCol;
      });
      if (tip) {
        ret = tip.text;
      }
      return ret;
    },
    handleCellClick(row) {
      this.$emit("cellClick", row);
    },
    handleToggleDetailCLick() {
      this.$emit("update:showDetails", !this.showDetails);
    },
  },
};
</script>

<style lang="stylus" scoped>
.table-wrapper {
  max-height: 550px;
  overflow: auto;
  border-top: 1px solid rgba(29,33,41,0.06);
  border-bottom: 1px solid rgba(29,33,41,0.06);
  //border-top: 1px solid #e5e6eb;
  //border-bottom: 1px solid #e5e6eb;

}
.btn-toolbar {
  display flex
  justify-content flex-end

  .btn-show-dialog {
    margin-left 0px
    margin-bottom 20px
  }
}

.btn-toolbar.left-align {
  justify-content flex-start
}
.drawer-inner {
  overflow-y auto;
  padding: 25px;
  height calc(100vh - 70px);
  padding-top 0;
  box-sizing: border-box
}


.text-has-tip {
  text-decoration underline;
  cursor pointer
}

.text-has-table {
  color blue
  cursor pointer
  text-decoration underline;
}

.default {
  min-width: 819px;
  max-width: 1200px;
}

.table {
  width 100%
  border-collapse: collapse;
  border-spacing: 0;
  //margin-top:20px


  td, th {
    text-align: justify;
    font-size: 14px;
    padding: 8px 6px;
    border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;
    min-width: auto;

    .clickable {
      color: #2F54EB;
      cursor: pointer;
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      //background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }
}

>>> :focus {
  outline: none;
}
.table th{
  padding:13px 12px;
  font-size: 14px;
  font-weight: bold;
  color: #1D2129;
}
.table td{
  padding:13px 12px;
  font-size: 14px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
}

/* 滚动条thumb的背景色 */
::-webkit-scrollbar-thumb {
  width: 8px;
  //height: 204px;
  background: #8D8F93;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

table td span{
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;  /* 内容自动换行 */
  -webkit-box-orient: vertical; /* 垂直排列 */
  display: -webkit-box;
  -webkit-line-clamp:2;
}

:deep(.el-table__empty-block) {
  display: none !important; //把暂无数据的显示给去掉
}
.btn{
  padding: 0 18px
  border: 1px solid #2663f6;
  color: #2663f6
  .iconfont {
    font-size: 14px;
}
}
thead{
  position: sticky;
  top: -1px;
  tr{
    position relative;
    //top:-1px
  }
}
</style>
