<template>
  <scroll-bar>
    <router-link :to="{ name: 'homePage' }" class="logo">
      <img v-if="icon" :src="icon" class="logo-pic" />
    </router-link>

    <div class="sider-bar-menu-wrapper">
      <div class="scrollbar-inner">
        <template v-for="(item, index) in asideMenuConfig">
          <div
            class="item"
            :class="{
              actived: !!item.routeNames && item.routeNames.includes($route.name),
            }"
            v-if="!item.children"
            :key="index"
          >
            <router-link :to="item.path">
              <i class="icon" :class="item.icon"></i>
              {{ item.name }}
            </router-link>
          </div>

          <div class="group" :key="index" v-else>
            <div class="title" v-if="menuLists[item.id]">{{ item.name }}</div>
            <template v-for="(item2, index2) in item.children">
              <div
                class="item"
                :class="{
                  actived: !!item2.routeNames && item2.routeNames.includes($route.name),
                }"
                :key="index2"
                :data-index="item2.id"
                v-if="menuLists[item2.id]"
              >
                <router-link :to="item2.path" v-if="item2.path != '/test/products'">
                  <i class="icon" :class="item2.icon"></i>
                  {{ item2.name }}
                </router-link>
                <span class="item-btn" v-else @click="add">
                  <i class="icon" :class="item2.icon"></i>
                  {{ item2.name }}
                </span>
              </div>
            </template>
            <!-- <div class="item">
            <router-link to="/">
              <i class="icon el-icon-house"></i> 风险检测
            </router-link>
              </div>-->
          </div>
        </template>
      </div>
    </div>
  </scroll-bar>
</template>

<script>
import { asideMenuConfig } from "@/config/menu";
import ScrollBar from "./ScrollBar";
import { checkProductPermission, getUUID, mateGoods, getUUIDAi } from "@/api/api";

export default {
  name: "SideBar",
  components: { ScrollBar },
  props: ["icon"],
  data() {
    return {
      asideMenuConfig,
      uuid: "",
      menuLists: {},
    };
  },
  mounted() {
    this.$route.name;
    var menuList = {};
    if (JSON.parse(localStorage.getItem("user")).authorities.length < 1) return;
    var meList = JSON.parse(localStorage.getItem("user")).authorities[0].menuList;
    for (let i = 0; i < meList.length; i++) {
      menuList[meList[i].id] = meList[i].isSelect;
    }
    this.menuLists = menuList;
    for (let i = 0; i < this.asideMenuConfig.length; i++) {
      this.asideMenuConfig[i].isSelect = menuList[this.asideMenuConfig[i].id];
      if (!this.asideMenuConfig[i].children) continue;
      for (let j = 0; j < this.asideMenuConfig[i].children.length; j++) {
        this.asideMenuConfig[i].children[j].isSelect =
          menuList[this.asideMenuConfig[i].id];
      }
    }
    this.asideMenuConfig.reverse().reverse();
  },
  methods: {
    async add() {
      sessionStorage.setItem("method", 1);
      // if (name == "test-hub") {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //const specId = this.specs[0].id;
      const uuidData = await getUUIDAi();
      if (uuidData.msgCode == 1) {
        this.loading.close();
        this.uuid = uuidData.data.uuid;
        this.$router.push({
          name: "choose-table",
          params: {
            productID: this.$store.state.goods.id,
            bankId: this.$store.state.goods.bankId,
            productName: "风险检测",
            uuid: this.uuid,
          },
        });
      } else {
        alert(uuidData.msgInfo);
        this.loading.close();
      }

      // 匹配风险库
      // let params = {
      //   token: this.$store.state.authorization
      // };
      // await mateGoods(params).then(res => {
      //   if (res.msgCode == 1) {
      //     this.$store.commit("setGoods", res.data);
      //   } else {
      //     this.$message({
      //       message: res.msgInfo,
      //       type: "error"
      //     });
      //   }
      // });
      // await this.mategoods();

      // const permission = await checkProductPermission(
      //   this.$store.state.authorization,
      //   this.$store.state.goods.id,
      //   // buyStatus == "Y" ? null : "onTrain" //如果已经购买 则不传onTrain
      //   null //如果已经购买 则不传onTrain
      // );
      // loading.close();

      // if (permission && permission.msgCode == 1) {
      //   //设置permissionID
      //   this.$store.commit("UploadTable/setPermissionID", {
      //     permissionID: permission.data.id
      //   });
      //   // await this.getUUID();
      //   this.$router.push({
      //     name: "choose-table",
      //     params: {
      //       productID: this.$store.state.goods.id,
      //       bankId: this.$store.state.goods.bankId,
      //       productName: "风险检测",
      //       uuid: this.uuid
      //     }
      //   });
      // } else if (permission.msgCode == "1002") {
      //   this.$store.commit("UploadTable/setPermissionID", {
      //     permissionID: ""
      //   });
      //   this.$confirm("用户未进行企业认证！是否前去认证", "提示", {
      //     confirmButtonText: "前往完善",
      //     cancelButtonText: "取消",
      //     type: "warning"
      //   }).then(() => {
      //     this.$router.push({ name: "companyApply" });
      //   });
      // } else if (permission.msgCode == "1007") {
      //   this.$confirm(permission.msgInfo, {
      //     confirmButtonText: "前往完善",
      //     cancelButtonText: "取消",
      //     type: "warning"
      //   })
      //     .then(() => {
      //       this.$router.push({
      //         path: "/setUser"
      //       });
      //     })
      //     .catch(() => {});
      // } else {
      //   this.$store.commit("UploadTable/setPermissionID", {
      //     permissionID: ""
      //   });
      //   this.$alert(permission.msgInfo, "错误", {
      //     type: "error"
      //   });
      // }
      // }
    },
    mategoods() {
      let params = {
        token: this.$store.state.authorization,
      };
      mateGoods(params).then((res) => {
        if (res.msgCode == 1) {
          this.$store.commit("setGoods", res.data);
        } else {
          this.$message({
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      this.loading && this.loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.ps {
  height: calc(100vh - 61px);
}
.logo {
  display: flex;
  width: 100%;
  position: relative;
  left: 0px;
  justify-content: center;
  align-items: center;
  line-height: 64px;
  background: #fff;
  color: #333;
  text-align: center;
  font-size: 20px;
  font-weight: 600;

  .logo-pic {
    width: 110px;
  }
}

.sider-bar-menu-wrapper {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  padding-top: 72px;
  flex-grow: 1;
  color: #555;
  font-size: 14px;
  line-height: 1.5;
  .item {
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    a {
      color: rgba(0, 0, 0, 0.8);
      text-decoration: none;
    }
    .icon {
      padding-right: 5px;
    }
  }
  .item.actived {
    background: #2f54eb;
    a {
      color: #fff;
    }
    .item-btn {
      color: #fff;
    }
  }
  .group {
    .title {
      padding: 16px 0 8px 16px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.item-btn {
  display: inline-block;
  cursor: pointer;
  width: 100%;
}

.scrollbar-inner {
  padding-bottom: 30px;
  overflow-y: auto;
  max-height: 87vh;
}
</style>
