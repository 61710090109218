<template>
  <div>
    <el-cascader
      popper-class="cascaderStyle"
      :value="value"
      :options="options"
      @input="handleChange"
      size="small"
      style="width: 100%"
      clearable
      :props="{ label: 'titleName', value: 'index', children: 'children', checkStrictly: true }"
      placeholder="请选择目录名称"
    ></el-cascader>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "MenuTreePathSelector",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState("ResultEditor", ["treeData"]),
    options() {
      const ret = cloneDeep(this.treeData);

      function walk(node) {
        if (node.children) {
          if (node.children.length > 0) {
            for (const childNode of node.children) {
              walk(childNode);
            }
          } else {
            delete node.children;
          }
        }
      }

      for (const node of ret) {
        walk(node);
      }

      return ret;
    },
  },
  data() {
    return {
      //value: [0,0,0],
    };
  },
  methods: {
    handleChange(value) {
      this.$emit("input", value);
    },
  },
});
</script>

<style scoped lang="stylus"></style>
<style lang="scss">
.cascaderStyle {
  .el-cascader-node {
    padding: 0 30px 0 7px !important;
  }
}
</style>
