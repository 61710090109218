import axios from "@/axios";

const baseAI = process.env.VUE_APP_API_ROOT_AI;
const test_baseAI = "http://192.168.10.67:9009";
//管理后台获取PDF编辑器数据
export const getAdminPdfCatalogData = async ({ nsrsbh, uuid,isSimple=0 ,desensitizationStatus=0}) => {
  const url = `${baseAI}/BackgroundPdfData/getPdfCatalogDataRecord`;
  const res = await axios.get(url, { params: { nsrsbh, uuid,isSimple,desensitizationStatus } });
  return res.data;
};
//获取项目经理PDF编辑器数据
export const getEditPdfCatalogData = async ({ nsrsbh, uuid,isSimple=0 ,desensitizationStatus=0}) => {
  const url = `${baseAI}/pdfData/getPdfCatalogDataRecord`;
  const res = await axios.get(url, { params: { nsrsbh, uuid,isSimple,desensitizationStatus } });
  return res.data;
};

//获取审核老师PDF编辑器数据
export const getSencorPdfCatalogData = async ({ nsrsbh, uuid,isSimple=0 ,desensitizationStatus=0}) => {
  const url = `${baseAI}/pdfData/getPdfCatalogDataReview`;
  const res = await axios.get(url, { params: { nsrsbh, uuid,isSimple,desensitizationStatus } });
  return res.data;
};

//获取重置数据
export const getRevokePdfCatalogData = async ({ pdfId }) => {
  const url = `${baseAI}/pdfData/revokePdfCatalogData?PdfId=${pdfId}`;
  const res = await axios.get(url);
  return res.data;
};

//保存
export const updatePdfCatalogData = async (params, action) => {
  const url = `${baseAI}/pdfData/updatePdfCatalogData?action=${action}`;
  const res = await axios.post(url, params);
  return res.data;
};

//生成
export const createPdf = async ({ nsrsbh, uuid,isSimple=0 }) => {
  const url = `${baseAI}/pdfData/createPdf`;
  const res = await axios.get(url, { params: { nsrsbh, uuid,isSimple } });
  return res.data;
};

//删除
export const delectPdfCatalogData = async ({ pdfCatalogDataId, nsrsbh, uuid,isSimple=0 }) => {
  const url = `${baseAI}/pdfData/delectPdfCatalogData`;
  const res = await axios.get(url, {
    params: {
      nsrsbh,
      uuid,
      pdfCatalogDataId,
      isSimple
    }
  });
  return res.data;
};

//新增节点
export const addNode = async params => {
  const url = `${baseAI}/pdfData/addPdfCatalogData`;
  const res = await axios.post(url, params);
  return res.data;
};

//修改PDF状态
export const updateReviewStatus = async ({ nsrsbh, uuid, status, action,isSimple=0 }) => {
  const url = `${baseAI}/pdfData/updateReviewStatus?action=${action}&nsrsbh=${nsrsbh}&uuid=${uuid}&reviewStatus=${status}&isSimple=${isSimple}`;
  const res = await axios.get(url);
  return res.data;
};


//无需审核修改PDF状态
export const updateUnReviewStatus = async ({ nsrsbh, uuid, status, action,isSimple=0 }) => {
  const url = `${baseAI}/pdfData/updateUnReviewStatus?action=${action}&nsrsbh=${nsrsbh}&uuid=${uuid}&reviewStatus=${status}&isSimple=${isSimple}`;
  const res = await axios.get(url);
  return res.data;
};


//修改PDF状态
export const getCheckLogList = async ({ nsrsbh, uuid }) => {
  const url = `${baseAI}/pdfData/getCheckLogList2?nsrsbh=${nsrsbh}&uuid=${uuid}`;
  const res = await axios.get(url);
  return res.data;
};


