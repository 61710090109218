<template>
  <div :class="{ hide: isCensorDone && node.isDisplay == 0 }">
    <!--标题-->
    <div class="head-wrapper">
      <!--标题的编辑弹出框-->
      <el-popover
          placement="top-start"
          width="400"
          trigger="manual"
          v-model="toolTipVisible"
          @show="handleTooltipShow"
      >
        <div class="form-control-wrapper">
          <div class="input-wrapper">
            <el-input v-model="titleInput" placeholder="请输入内容"></el-input>
          </div>

          <div class="btn-wrapper">
            <button class="btn btn-default" @click="handleBtnSaveClick">确定</button>
            <button class="btn btn-default" @click="handleBtnCancelClick">取消</button>
          </div>
        </div>
        <template slot="reference">
          <div class="wrapper">
            <div
                class="head-level-0"
                @click.stop="handleHeadClick"
            >
              <span v-if="node.orderNumber != ''">{{ chineseNumber(node.orderNumber) }}、</span
              >{{ node.titleName }}
              <!--              <i class="icon iconfont iconbianji" v-if="isEditable"></i>-->
            </div>
            <!--            <div class="btn-grp" style="width: 191px" v-if="!isCensorDone">-->
            <!--              <el-dropdown @command="handleBtnAddClick">-->
            <!--                <el-button type="primary" class="btn btn-default btn-add">-->
            <!--                  添加<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            <!--                </el-button>-->
            <!--                <el-dropdown-menu slot="dropdown">-->
            <!--                  <el-dropdown-item command="sibling">添加同级目录</el-dropdown-item>-->
            <!--                  <el-dropdown-item command="child">添加子目录</el-dropdown-item>-->
            <!--                </el-dropdown-menu>-->
            <!--              </el-dropdown>-->

            <!--              <button-->
            <!--                class="btn btn-default btns-wrapper-showable"-->
            <!--                @click="handleBtnDisplayToggleClick"-->
            <!--                v-if="isDisplay"-->
            <!--              >-->
            <!--                {{ toggleBtnLabel }}-->
            <!--                &lt;!&ndash; {{ isDisplay ? "隐藏" : "显示" }} &ndash;&gt;-->
            <!--              </button>-->

            <!--              <button-->
            <!--                class="btn btn-default btn-toggle-show"-->
            <!--                v-else-->
            <!--                @click="handleBtnDisplayToggleClick"-->
            <!--              >-->
            <!--                &lt;!&ndash; <i class="iconfont iconxianshi"></i> &ndash;&gt;-->
            <!--                {{ toggleBtnLabel }}-->
            <!--              </button>-->

            <!--              &lt;!&ndash;              <button class="btn btn-default" @click="handleBtnDisplayToggleClick">{{toggleBtnLabel}}</button>&ndash;&gt;-->
            <!--            </div>-->
          </div>
        </template>
      </el-popover>
    </div>

    <!--/标题的编辑弹出框-->

    <!--标题-->
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import mixin from "@/pages/ResultEditor/mixin.js";
import {cloneDeep} from "lodash";
import {getEmptyNode} from "../../util/treeDataTemplate";

const nzhcn = require("nzh/cn");
export default {
  mixins: [mixin],
  props: {
    indexes: Array,
    target: {
      default: "treeData",
      type: String,
    },
  },
  computed: {
    ...mapState("ResultEditor", ["treeData"]),
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes", "isCensorDone"]),
    pos() {
      return this.indexes.join(".");
    },
    //是否隐藏
    isDisplay() {
      let ret = false;
      ret = !!this.node.isDisplay;
      return ret;
    },
    //按钮上的文字
    toggleBtnLabel() {
      return this.isDisplay ? "隐藏" : "显示";
    },
  },
  mounted() {
    this.$eventBus.$on("ResultEditorBgClick", () => {
      this.toolTipVisible = false;
    });
  },
  methods: {
    ...mapMutations("ResultEditor", [
      "setTreeDataByIndexes",
      "refineOrder",
      "setTreeDataByIndexes",
      "setTreeData",
    ]),
    ...mapActions("ResultEditor", ["refresh", "submit", "reload"]),
    handleBtnAddClick(command) {
      if (command === "sibling") {
        this.handleBtnAddSibling();
      } else if (command === "child") {
        this.handleBtnAddChild();
      }
    },
    handleBtnAddSibling() {
      const treeData = cloneDeep(this.treeData);

      const level0Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid
      );
      const level1Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid
      );
      level0Node.children.push(level1Node);
      const level2Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid,
          5,
          true
      );
      level1Node.children.push(level2Node);

      treeData.unshift(level0Node);
      this.setTreeData({data: treeData});
      this.refineOrder({target: this.target});
    },
    handleBtnAddChild() {
      const level0Node = cloneDeep(this.node);
      const level1Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid
      );
      level0Node.children.unshift(level1Node);
      const level2Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid,
          5,
          true
      );
      level1Node.children.unshift(level2Node);
      this.setTreeDataByIndexes({
        data: level0Node,
        indexes: this.indexes,
      });
      this.refineOrder({target: this.target});
    },
    //显示隐藏
    handleBtnDisplayToggleClick() {
      //如果当前节点是综述节点
      if (this.isOverallNode) {
        const node = cloneDeep(this.node);
        node.isDisplay = this.node.isDisplay == "1" ? 0 : 1;
        this.$store.commit("ResultEditor/setOverall", {data: node});
        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      } else {
        const nodeData = cloneDeep(this.node);
        //如果当前节点是树状目录中的节点
        nodeData.isDisplay = this.node.isDisplay == "1" ? 0 : 1;

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });

        this.refineOrder({target: this.target});
        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (this.node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      }
      //this.submit();
    },
    //阿拉伯数字转中文
    chineseNumber(number) {
      return nzhcn.encodeS(number);
    },
    handleHeadClick() {
      return
      if (!this.isEditable) {
        return;
      }
      this.$eventBus.$emit("ResultEditorBgClick");
      this.toolTipVisible = true;
    },
    handleBtnCancelClick() {
      this.toolTipVisible = false;
    },
    handleBtnSaveClick() {
      this.toolTipVisible = false;

      //如果是综述则修改综述里的titleName
      if (this.pos == "overall") {
        const node = cloneDeep(this.node);
        node.titleName = this.titleInput;
        this.$store.commit("ResultEditor/setOverall", {data: node});
      } else {
        const nodeData = cloneDeep(this.node);
        nodeData.titleName = this.titleInput; //给节点设置新的titleName
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      }
    },
    handleTooltipShow() {
      this.titleInput = this.node.titleName;
    },
  },
  data: function () {
    return {
      node: null,
      titleInput: "",
      toolTipVisible: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
.head-wrapper {
  border-bottom 2px solid #2663f6;
}

.hide {
  display none !important
}

.head-wrapper {
  display: flex;
  justify-content: space-between;
  position relative;
  margin: 0 20px;

  .head-level-0 {
    //line-height: 48px;
    text-align: center;
    color: #fff;
    line-height 21.15px;
    font-size: 18px;
    padding: 12px 16px;
    //height: 48px;
    background: #2663F6;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
    display: inline-block;
  }

  .head-level-0.editable {
    cursor pointer
  }

  .btn-grp {
    position absolute;
    //right 32px
    top 10px
    right: -14px;
    display: flex;
    width: 177px;
    margin: -6px 0;
    box-sizing: border-box;

    .btn-default {
      background-color: #fff;
      border: 1px solid #2f54eb;
    }

    .btn-add:active, .btn-add:focus {
      color #2f54eb;
    }
  }
}


.form-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .input-wrapper {
    flex-grow: 1;
  }

  .btn-wrapper {
    margin-left: 10px;
    display: flex;

    .btn {
      margin: 0 5px;
    }
  }


}

>>> .btn {
  padding: 8px 16px;
}
</style>
