<template>
  <div class="container">
    <div class="title">项目信息</div>
    <div class="item itemRadio">
      <span class="item-title">选择项目：</span>
      <span>
        <el-radio-group v-model="radio" @change="changeRadio">
          <el-radio label="1">选择已有项目</el-radio>
          <el-radio label="2">创建新项目</el-radio>
        </el-radio-group>
      </span>
    </div>
    <div class="item prjectSet" v-if="radio == 1">
      <span class="item-titles item-right">项目名称：</span>
      <el-select v-model="value" placeholder="请选择" @change="projectChange" filterable>
        <el-option
          v-for="item in projectData"
          :key="item.id"
          :label="item.projectName"
          :value="item.id"
        ></el-option>
      </el-select>
    </div>
    <div v-if="haveProject && radio == 1">
      <div class="detail detail-Top">
        <div class="detail-item">
          <div class="top">项目名称</div>
          <div v-if="info.consumerEntity">{{ info.projectName }}</div>
        </div>
        <div class="detail-item">
          <div class="top">项目开始时间</div>
          <div v-if="info.consumerEntity">{{ formatDateTes(info.createTime) }}</div>
        </div>
        <div class="detail-item">
          <div class="top">项目结束时间</div>
          <div v-if="info.endTime">{{ formatDateTes(info.endTime) }}</div>
          <div v-else>暂无结束时间</div>
        </div>
        <div class="detail-item">
          <div class="top">项目经理</div>
          <div v-if="info">{{ info.projectManager }}</div>
        </div>
      </div>
      <div class="detail detailBottom">
        <div class="detail-item">
          <div class="top">企业名称</div>
          <div class="bottom" v-if="info.consumerEntity">
            {{ info.consumerEntity.companyName }}
          </div>
          <div class="top">企业行业</div>
          <div class="bottom" v-if="info.consumerEntity && info.consumerEntity.industry">
            {{ dataList[info.consumerEntity.industry] }}
          </div>
          <div class="bottom" v-else>暂无行业信息</div>
        </div>
        <div class="detail-item">
          <div class="top">纳税人识别号</div>
          <div class="bottom" v-if="info.consumerEntity">
            {{ info.consumerEntity.nsrsbh }}
          </div>
          <div class="top">业务员</div>
          <div class="bottom" v-if="info.consumerEntity">
            {{ info.consumerEntity.salesman }}
          </div>
        </div>
        <div class="detail-item">
          <div class="top">企业地区</div>
          <div class="bottom" v-if="info.consumerEntity">
            <span v-if="info.consumerEntity.cityCode">{{ conversionArea }}</span>
            <span v-else>暂无地区信息</span>
          </div>
          <div class="top">联系人</div>
          <div v-if="info.consumerEntity">
            {{ info.consumerEntity.contact }}
          </div>
        </div>
        <div class="detail-item">
          <div class="top">联系电话</div>
          <div class="bottom" v-if="info.consumerEntity">
            {{ info.consumerEntity.contactNumber }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="haveNewInfo && radio == 2">
      <div class="detail detail-Top">
        <div class="detail-item">
          <div class="top">项目名称</div>
          <div v-if="newInfo.consumerEntity">{{ newInfo.projectName }}</div>
        </div>
        <div class="detail-item">
          <div class="top">项目开始时间</div>
          <div v-if="newInfo.consumerEntity">{{ formatDateTes(newInfo.createTime) }}</div>
        </div>
        <div class="detail-item">
          <div class="top">项目结束时间</div>
          <div v-if="newInfo.endTime">{{ formatDateTes(newInfo.endTime) }}</div>
          <div v-else>暂无结束时间</div>
        </div>
        <div class="detail-item">
          <div class="top">项目经理</div>
          <div v-if="newInfo">{{ newInfo.projectManager }}</div>
        </div>
      </div>
      <div class="detail detailBottom">
        <div class="detail-item">
          <div class="top">企业名称</div>
          <div class="bottom" v-if="newInfo.consumerEntity">
            {{ newInfo.consumerEntity.companyName }}
          </div>
          <div class="top">企业行业</div>
          <div
            class="bottom"
            v-if="newInfo.consumerEntity && newInfo.consumerEntity.industry"
          >
            {{ dataList[newInfo.consumerEntity.industry] }}
          </div>
          <div class="bottom" v-else>暂无行业信息</div>
        </div>
        <div class="detail-item">
          <div class="top">纳税人识别号</div>
          <div class="bottom" v-if="newInfo.consumerEntity">
            {{ newInfo.consumerEntity.nsrsbh }}
          </div>
          <div class="top">业务员</div>
          <div class="bottom" v-if="newInfo.consumerEntity">
            {{ newInfo.consumerEntity.salesman }}
          </div>
        </div>
        <div class="detail-item">
          <div class="top">企业地区</div>
          <div class="bottom" v-if="newInfo.consumerEntity">
            <span v-if="newInfo.consumerEntity.cityCode">{{ conversionArea }}</span>
            <span v-else>暂无地区信息</span>
          </div>
          <div class="top">联系人</div>
          <div v-if="newInfo.consumerEntity">
            {{ newInfo.consumerEntity.contact }}
          </div>
        </div>
        <div class="detail-item">
          <div class="top">联系电话</div>
          <div class="bottom" v-if="newInfo.consumerEntity">
            {{ newInfo.consumerEntity.contactNumber }}
          </div>
        </div>
      </div>
    </div>
    <autoMethod
      @supplement="supplement"
      ref="auto"
      @changeHaveProject="changeHaveProject"
      :uuid="uuid"
    ></autoMethod>
  </div>
</template>

<script>
import { getTradeList } from "@/api/api";
import { area } from "@/pages/product/utils/limit.js";

import autoMethod from "@/pages/Test/UploadTable/components/autoMethod";

export default {
  components: {
    autoMethod,
  },
  props: {
    projectData: [Array],
    projectId: [Array],
    city: [Object, Array],
    uuid: [String, Number],
  },
  mounted() {
    this.gettradeList();
  },
  data() {
    return {
      conversionArea: null,
      dataList: null,
      hyList: null,
      radio: "1",
      haveProject: false,
      value: "",
      info: {},
      newInfo: {},
      haveNewInfo: false,
    };
  },
  methods: {
    supplement(val) {
      this.newInfo = val.newInfo;
      this.$refs.dialogTableVisible = false;
      this.haveNewInfo = true;

      this.changeEmit();
      this.$emit("getNewList");
    },

    projectChange() {
      // 通过选中的value值，去拿到这个值的整组数据
      let projectIndex = this.projectId.findIndex((item) => item == this.value);
      this.info = this.projectData[projectIndex];
      localStorage.setItem("projectCustomInfo", JSON.stringify(this.info));
      this.conversionArea = area(
        [
          this.info.consumerEntity.provinceCode,
          this.info.consumerEntity.cityCode,
          this.info.consumerEntity.districtCode,
        ],
        this.city
      );

      this.haveProject = true; //已经选中了项目

      this.changeEmit();

      // 存税号
      // 客户里面的税号
      sessionStorage.setItem("manualNsrsbh", this.info.consumerEntity.nsrsbh);
    },
    changeEmit() {
      let info = "";
      if (this.radio == 1) {
        info = this.info;
      } else {
        info = this.newInfo;
      }
      this.$emit("selectPro", {
        info: info,
        projectIndex: this.radio,
        // nsrsbh: this.info.nsrsbh,
      });
      this.conversionArea = area(
        [
          info.consumerEntity.provinceCode,
          info.consumerEntity.cityCode,
          info.consumerEntity.districtCode,
        ],
        this.city
      );
    },
    changeRadio() {
      if (this.radio == 2) {
        this.$refs.auto.openDialog();
      } else {
        this.haveNewInfo = false;
      }
    },
    changeHaveProject() {
      this.radio = "1";
      this.changeEmit();
    },
    gettradeList() {
      getTradeList().then((res) => {
        let data = JSON.parse(res.data);
        this.hyList = data;
        let dataList = {};
        for (let i = 0; i < this.hyList.length; i++) {
          this.hyList[i].hymc = this.hyList[i].hy_dm + this.hyList[i].hymc;
          dataList[this.hyList[i].hy_dm] = this.hyList[i].hymc;
        }
        this.dataList = dataList;
      });
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
        "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D;
    },
  },
};
</script>

<style scoped>
.container {
  margin: 16px 30px;
  padding-bottom: 24px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 25px;
  padding: 24px 0 24px 24px;
}

.item {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 68px;
}

.item-titles:before {
  content: "*";
  color: red;
  position: relative;
  right: 2px;
  top: 0;
}

.item-title {
  margin-right: 8px;
  margin-left: 6px;
}

.info {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 138px;
  margin-top: 16px;
}

.time {
  margin-right: 48px;
}

.detail {
  display: flex;
  min-width: 950px;
  max-width: 1300px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  margin: 8px 20px 0 151px;
  padding: 16px;
  box-sizing: border-box;
}

.detail-item {
  width: 33.3%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.top {
  margin-bottom: 4px;
}

.bottom {
  margin-bottom: 24px;
}

.info-right {
  font-weight: 400;
}

.itemRadio {
  margin-bottom: 24px;
}

.noProject {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 138px;
}

.prjectSet >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
  width: 325px;
}

.prjectSet >>> .el-input__icon {
  line-height: 36px;
}

.detail-Top {
  /* height: 76px; */
  background: rgba(250, 173, 20, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(250, 173, 20, 0.24);
  /* padding-bottom: 24px; */
}

.detailBottom {
  padding-bottom: 0;
}

.item-right {
  margin-right: 6px;
}
</style>
