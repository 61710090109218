<template>
  <el-select :value="value" placeholder="请选择" @change="handleChange"
             size="sa">
    <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "RiskLevelSelector",
  props: {
    value: Number,
  },
  data() {
    return {
      options: [{
        value: 1,
        label: '高风险'
      }, {
        value: 2,
        label: '中风险'
      }, {
        value: 3,
        label: '低风险'
      }, {
        value: 4,
        label: '无风险'
      }]
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('input',value)
    }
  }
})
</script>


<style scoped lang="stylus">


</style>
