<template>
  <div
    class="box-container"
    id="projectBoxs"
    v-loading="addLoading"
  >
    <div class="addTitle">创建客户</div>
    <div class="project-close" @click="closeDialogBox">×</div>
    <div class="custom-overflow customOverflowX">
      <div class="project-name"><span class="project-named">*</span>企业名称</div>
      <div class="project-input">
        <el-select
          ref="optionRef"
          v-model="companyName"
          filterable
          remote
          reserve-keyword
          placeholder="请输入企业名称，至少4个字"
          @change="optionRefChange"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="(item, index) in cityOptions"
            :key="item.creditCode"
            :label="item.name"
            :value="index"
          >
          </el-option>
        </el-select>
      </div>
      <div class="project-name"><span class="project-named">*</span>纳税人识别号</div>
      <div class="project-input">
        <el-input
          placeholder="请输入"
          v-model.trim="custom.nsrsbh"
          maxlength="20"
          show-word-limit
        ></el-input>
      </div>
      <div class="project-name"><span class="project-named"></span>所属地区</div>
      <div class="project-input">
        <el-cascader filterable :options="cityList" v-model="custom.area"></el-cascader>
      </div>
      <div class="project-name"><span class="project-named"></span>所属行业</div>
      <div class="project-input">
        <el-select v-model="custom.industry" placeholder="请选择" filterable>
          <el-option
            v-for="item in hyData"
            :key="item.hy_dm"
            :label="item.hymc"
            :value="item.hy_dm"
          ></el-option>
        </el-select>
      </div>
      <div class="project-name"><span class="project-named"></span>企业规模</div>
      <div class="project-input">
        <el-select v-model="custom.scale" placeholder="请选择">
          <el-option
            v-for="item in qyStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <div class="project-name"><span class="project-named"></span>业务员</div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model="custom.salesman"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
        <div class="project-name"><span class="project-named"></span>联系人</div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model="custom.contact"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
        <div class="project-name"><span class="project-named"></span>联系电话</div>
        <div class="project-input">
          <el-input placeholder="请输入" v-model="custom.contactNumber"></el-input>
        </div>
      </div>
    </div>
    <div class="project-btn ease">
      <div class="project-btnL ease" @click="addProjectBoth" v-if="haveTotal">
        创建客户并创建项目
      </div>
      <div class="project-btnR ease" @click="addProject">创建客户</div>
    </div>
  </div>
</template>

<script>
import { addCustom, getCompanyMsgByTaxNo, getQuerySectionListV2 } from "@/api/api";
import { qyStatusList } from "../help.js";

export default {
  props: {
    haveTotal: [Boolean],
    hyData: [Object, Array],
    cityList: [Object, Array],
  },
  data() {
    return {
      addLoading: false,
      custom: {
        companyName: "",
        nsrsbh: "",
      },
      qyStatusList: qyStatusList,
      companyName: "",
      cityOptions: {},
    };
  },
  methods: {
    optionRefChange() {
      this.custom.nsrsbh = this.cityOptions[this.companyName].creditCode;
      this.custom.companyName = this.cityOptions[this.companyName].name;
    },
    remoteMethod(query) {
      if (query.length < 4) return;
      getQuerySectionListV2(query).then((res) => {
        this.cityOptions = res.list;
      });
    },
    resetFormCheck() {
      if (!this.custom.nsrsbh || !this.custom.companyName) {
        this.$message({
          message: "名称或纳税人识别号不能为空！",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    closeDialogBox() {
      this.$emit("closeCreatedCustom");
    },
    addProjectBoth() {
      if (!this.haveTotal) {
        this.$message({
          message: "您未获得项目权限，无法创建项目",
          type: "warning",
        });
        return;
      }
      if (!this.resetFormCheck()) return;
      this.addLoading = true;
      if (this.custom.area && this.custom.area.length > 0) {
        [this.custom.provinceCode, this.custom.cityCode, this.custom.districtCode] = [
          this.custom.area[0],
          this.custom.area[1],
          this.custom.area[2],
        ];
      }
      addCustom(this.custom).then((res) => {
        this.addLoading = false;
        if (res.msgCode == 1) {
          sessionStorage.setItem("both", 1);
          this.$notify({
            title: "温馨提示",
            message: "添加客户成功",
            type: "success",
          });
          this.custom = {};
          this.$router.push({
            name: "project",
            query: {
              bothCreate: 1,
              consumerId: res.data.consumerId,
              id: res.data.id,
              name: res.data.companyName,
            },
          });
        }
      });
    },
    addProject() {
      if (!this.resetFormCheck()) return;
      this.addLoading = true;
      if (this.custom.area && this.custom.area.length > 0) {
        [this.custom.provinceCode, this.custom.cityCode, this.custom.districtCode] = [
          this.custom.area[0],
          this.custom.area[1],
          this.custom.area[2],
        ];
      }
      addCustom(this.custom).then((res) => {
        this.addLoading = false;
        if (res.msgCode == 1) {
          this.$notify({
            title: "温馨提示",
            message: "添加客户成功",
            type: "success",
          });
          this.custom = {};
          this.$emit("addCustomSuccess");
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../common.css';

.box-container {
  position: fixed;
  left: 50%;
  margin-left: -240px;
  top: 10vh;
  width: 480px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

.box-container >>>.el-select {
  width: 416px;
}

.box-container >>>.el-input {
  width: 416px;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.customOverflowX {
  max-height: 375px;
  overflow-x: hidden;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #f5222d;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-btn {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.project-btnL:after {
  content: '|';
  position: relative;
  left: 57px;
}

.project-btnL:hover, .project-btnR:hover {
  background: rgba(47, 44, 235, 1);
}

.project-btn div {
  flex: 1;
}

>>> #projectBoxs .el-input {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs >>>.el-input >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> #projectBoxs .el-cascader {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.custom-overflow {
  margin-bottom: 90px;
}
</style>
