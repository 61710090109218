<template>
  <div v-scroll="f">
    <div v-if="show" ref="lazy-content">
      <slot></slot>
    </div>
    <div v-else :style="`height:${maskHeight}px`"></div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import {debounce} from "lodash";


export default defineComponent({
  name: "LazyLoader",
  mounted() {
    const el = this.$refs['lazy-content']
    setTimeout(()=>{
      this.maskHeight = el.getBoundingClientRect().height//元素高度
    },100)
    this.debounce_fun= debounce((e, el) => {
      this.offsetTop = el.offsetTop
      this.height = el.getBoundingClientRect().height
      this.show= false
      //如果el的scrollTop在屏幕内
      if ((el.offsetTop + el.getBoundingClientRect().height + 500 <= window.scrollY)
          || (el.offsetTop >= window.scrollY + screen.height + 500)
      ) {
        if (this.maskHeight) {
          this.show = false
        }
      } else {
        this.show = true
      }

    }, 10);
  },
  data() {
    return {
      maskHeight: 0,
      show: true,
      height:'',
      scrollY:'',
      debounce_fun:null
    }
  },
  methods: {
    f(e, el) {
      this.debounce_fun(e, el)
    },
  }
})
</script>
<style scoped lang="stylus">

</style>
