<template>
  <div @click="handleBgCLick">
    <div class="updownItem">
      <el-tooltip effect="dark" content="收起目录" placement="right-start" popper-class="box-item">
        <div class="upDown" v-show="leftShow" @click="leftShowClick">
          <i class="iconfont iconicon_arrow_line_left"></i>
        </div>
      </el-tooltip>
      <el-tooltip effect="dark" content="展开目录" placement="right-start" popper-class="box-item">
        <div class="upDownRight" v-show="rightShow" @click="rightShowClick">
          <i class="iconfont iconicon_arrow_xx_right"></i>
        </div>
      </el-tooltip>
    </div>

    <div
      class="wrapper"
      v-loading="!dataLoaded"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :class="{ 'reportTypeClass-wrapper': reportType == 3 }"
    >
      <ToolBar
        @btnSaveClick="handleBtnSaveClick"
        @switchTab="handleSwitchTab"
        v-if="dataLoaded"
        :reportType="reportType"
      ></ToolBar>
      <template v-if="tabId == 0 && dataLoaded">
        <div class="nestedTree-wrapper" v-if="isShowMenu">
          <div class="nestedTree" v-if="dataLoaded">
            <div class="treeTitle-wrapper">
              <div class="treeTitle">
                <div class="text">目录</div>
                <button
                  class="btn-default btn-add"
                  @click="handleBtnAddClick"
                  v-if="ReviewStatus != 1024 && ReviewStatus != 1026"
                >
                  <i class="iconfont iconicon_add"></i> 添加
                </button>
              </div>
              <MenuTree :data="treeData" target="treeData" :key="0" :indexes="[]"></MenuTree>

              <MenuTreePathSelectorDialog
                ref="MenuTreePathSelectorDialogRef"
                @confirm="handleMenuTreePathSelectorDialogClick"
              ></MenuTreePathSelectorDialog>
            </div>
          </div>
        </div>

        <ResultContent :id="rightShow ? 'openContent' : ''" class="resultContent"></ResultContent>
      </template>
      <!--      <template v-if="tabId == 1 && dataLoaded">-->
      <!--        <div class="nestedTree-wrapper" v-if="isShowMenu">-->
      <!--          <div class="nestedTree" v-if="dataLoaded">-->
      <!--            <div class="treeTitle-wrapper">-->
      <!--              <div class="treeTitle">优惠政策提醒</div>-->
      <!--              <MenuTree :data="discountPolicyTree" target="discountPolicyTree" :key="1"></MenuTree>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <DiscountPolicyTab class="resultContent"></DiscountPolicyTab>-->
      <!--      </template>-->

      <!--      <HistoryLog v-if="dataLoaded" style="display: none"></HistoryLog>-->
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapMutations, mapState } from "vuex";
import MenuTree from "./components/MenuTree";
import ToolBar from "./components/ToolBar";
import ResultContent from "./components/ResultContent";
import HistoryLog from "./components/HistoryLog/HistoryLog";
import DiscountPolicyTab from "./components/DiscountPolicyTab";
import MenuTreePathSelectorDialog from "@/pages/ResultEditor/components/MenuTreePathSelectorDialog.vue";
import { getEmptyNode } from "@/pages/util/treeDataTemplate";

export default {
  props: {
    reportType: [Number, String],
    anchor: [String, Number],
  },
  data: function () {
    return {
      data: [],
      tabId: 0,
      leftShow: true,
      rightShow: false,
      isShowMenu: true,
    };
  },
  computed: {
    ...mapState("ResultEditor", ["treeData", "discountPolicyTree", "ReviewStatus"]),

    dataLoaded() {
      //加载完成
      return Object.keys(this.treeData).length > 0;
    },
  },
  components: {
    MenuTreePathSelectorDialog,
    MenuTree,
    ToolBar,
    ResultContent,
    HistoryLog,
    DiscountPolicyTab,
  },
  async mounted() {
    await this.loadData();
    this.$emit("load");
    setTimeout(() => {
      const anchor = this.$route.query.anchor || this.anchor;
      this.$scrollTo(document.querySelector("#node" + anchor), 1000);
      // debugger;
    }, 1000);
  },
  activated() {
    const anchor = this.$route.query.anchor || this.anchor;
    this.$scrollTo(document.querySelector("#node" + anchor), 1000);
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes", "refineOrder", "setTreeData"]),
    handleMenuTreePathSelectorDialogClick({ name, indexes }) {
      const parent = this.$store.getters["ResultEditor/findTheNodeByIndexes"](indexes);
      if (parent == null) {
        //如果没有选择
        const treeData = cloneDeep(this.treeData);

        const level0Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid
        );
        level0Node.titleName = name;
        const level1Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid
        );
        level1Node.titleName = name;
        level0Node.children.unshift(level1Node);
        const level2Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid,
          5,
          true
        );
        level2Node.titleName = name;
        level1Node.children.unshift(level2Node);

        treeData.unshift(level0Node);
        this.setTreeData({ data: treeData });
        this.refineOrder({ target: this.target });
      } else if (parent.indexes.length == 1) {
        //如果是一级节点
        const level0Node = cloneDeep(parent);
        const level1Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid
        );
        level1Node.titleName = name;
        level0Node.children.unshift(level1Node);
        const level2Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid,
          5,
          true
        );
        level2Node.titleName = name;
        level1Node.children.unshift(level2Node);
        this.setTreeDataByIndexes({
          data: level0Node,
          indexes: indexes,
        });
      } else if (parent.indexes.length == 2) {
        //如果是二级节点
        const level1Node = cloneDeep(parent);
        const level2Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid,
          5,
          true
        );
        level2Node.titleName = name;
        level1Node.children.unshift(level2Node);
        this.setTreeDataByIndexes({
          data: level1Node,
          indexes: indexes,
        });
      } else if (parent.indexes.length == 3) {
        //如果是三级节点
        const level3Node = cloneDeep(parent);
        const level2NodeIndexes = level3Node.indexes.slice(0, -1);
        const level2Node =
          this.$store.getters["ResultEditor/findTheNodeByIndexes"](level2NodeIndexes);

        const anotherLevel3Node = getEmptyNode(
          this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          this.$route.params.uuid,
          5,
          true
        );
        anotherLevel3Node.titleName = name;
        level2Node.children.unshift(anotherLevel3Node);
        this.setTreeDataByIndexes({
          data: level2Node,
          indexes: level2NodeIndexes,
        });
      }

      this.refineOrder({ target: this.target });
    },
    handleBtnAddClick() {
      const selectedNode = this.$store.getters["ResultEditor/getSelectedNode"]();
      this.$refs.MenuTreePathSelectorDialogRef.open();
    },
    handleBgCLick() {
      this.$eventBus.$emit("ResultEditorBgClick");
    },
    async loadData() {
      await this.$store.dispatch("ResultEditor/loadPDFEditorData", {
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        action: this.$route.params.action,
        isFromAdmin: !!this.$route.query.fromAdmin,
      });
    },
    handleBtnSaveClick() {},
    handleSwitchTab(tabId) {
      this.tabId = tabId;
    },
    // sortedData(data) {
    //   const ret = [];

    //   const sort = data => {
    //     data.forEach((aData, index) => {
    //       if (aData.children) {
    //         data[index].children = sort(aData.children);
    //       }
    //     });

    //     return data.sort((a, b) => a.sort - b.sort);
    //   };

    //   return sort(data);
    // }
    leftShowClick() {
      this.leftShow = false;
      this.rightShow = true;
      this.isShowMenu = false;
    },
    rightShowClick() {
      this.leftShow = true;
      this.rightShow = false;
      this.isShowMenu = true;
    },
  },
  destroyed() {
    this.$store.commit("reset");
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding-top: 61px;
  display: flex;
  min-height: 100vh;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);

  .nestedTree-wrapper {
    position: fixed;
    width: 300px;
    z-index: 10;
    height: calc(100vh - 61px);
    overflow: auto;
    /* position: absolute;
    width: 300px;
    z-index: 10; */
    .ps {
      height: calc(100vh - 61px);
    }

    .nestedTree {
      z-index: 2;

      flex: 0 0 auto;

      .treeTitle-wrapper {
        padding: 8px;
        //background: rgba(240, 245, 255, 1);
        background: #ffffff;
        box-sizing: border-box;
        min-height: calc(100vh - 61px);

        padding-right: 5px;
        padding-bottom: 30px;

        .treeTitle {
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #1d2129;
          line-height: 22px;
          border-bottom: 1px solid #dedede;
          padding: 8px 0 15px;
          display: flex;
          margin: 0 8px 5px;
          align-items: center;
          justify-content: space-between;

          .btn-add {
            border-radius: 2px 2px 2px 2px;
            font-size: 14px;
            color: #2663f6;
            background: #fff;
            border: none;
            padding: 1px 9px;
            line-height: 25px !important;
            .iconfont {
              font-size: 14px;
            }
          }

          .btn-add:hover {
            background: #f6f8fa !important;
            color: #2663f6 !important;
            cursor: pointer;
          }
        }
      }
    }
  }

  .resultContent {
    flex: 1 1 auto;
    margin-left: 320px;
  }
}

.reportTypeClass-wrapper {
  padding-top: 0px;
}

.upDown {
  width: 16px;
  height: 48px;
  background: #fff;
  //z-index: 1;
  position: fixed;
  left: 300px;
  border-radius: 0px 16px 16px 0px;
  top: 341px;
  cursor: pointer;
}

.upDownRight {
  width: 16px;
  height: 48px;
  background: #fff;
  //z-index: 1;
  position: fixed;
  left: 2px;
  border-radius: 0px 16px 16px 0px;
  top: 341px;
  cursor: pointer;
}

#openContent {
  margin-left: 0 !important;
}

#el-tooltip__popper {
  width: auto;
  padding: 0.6rem;
  background: pink;
}
</style>
<style scoped lang="stylus">
.upDown >>> .iconfont {
  position absolute;
  top: 14px;
}

.upDown:hover >>> .iconfont {
  color: #2663F6
}

.upDownRight >>> .iconfont {
  position absolute;
  top: 14px;
}

.upDownRight:hover >>> .iconfont {
  color: #2663F6
}

/* 滚动条thumb的背景色 */
::-webkit-scrollbar-thumb {
  width: 6px;
  height: 54px;
  background: #8d8f93;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

>>> .iconfont {
  font-size: 12px
}
</style>
<style lang="scss">
.box-item {
  width: auto !important;
  padding: 0.8rem 0.5rem;
}
</style>
