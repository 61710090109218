<template>
  <form autocomplete="off" class="container">
    <div class="title">申报数据上传</div>
    <div class="user field">
      <div class="label required">纳税人识别号：</div>
      <el-input class="input" disabled v-model="companyNameInfo.nsrsbh"></el-input>
    </div>
    <div class="user field">
      <div class="label required">企业名称：</div>
      <el-input class="input" disabled v-model="companyNameInfoCompanyName"></el-input>
    </div>
    <!--  -->
    <div class="user field">
      <el-popover
        placement="top-start"
        title="电子税务局账号"
        width="200"
        trigger="hover"
        content="企业登录电子税务局网站的账号，一般为纳税人识别号、法人身份证、用户名等。"
        :open-delay="300"
      >
        <i slot="reference" class="el-icon-warning icon-zq"></i>
      </el-popover>

      <div class="label required">电子税务局账号：</div>
      <el-input
        class="input"
        v-model.trim="name"
        @input="changeName"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field" v-if="this.province != '黑龙江'">
      <el-popover
        placement="top-start"
        title="电子税务局密码"
        width="200"
        trigger="hover"
        content="企业登录电子税务局网站的密码或个人密码，如果忘记密码，请先在电子税务局页面找回密码后登录。"
        :open-delay="300"
      >
        <i slot="reference" class="el-icon-warning icon-zq"></i>
      </el-popover>
      <div class="label required">{{this.areaIndex == 0 && this.$route.query.thirdDataSource == "DB_KUANGU"  ? '个人用户密码：' : '电子税务局密码：'}}</div>
      <el-input
        @focus="toSearchEare"
        class="input"
        type="password"
        v-model.trim="password"
        @input="changeName"
        placeholder="请输入"
        :show-password="true"
        auto-complete="new-password"
      ></el-input>
    </div>
    <div class="user field" v-if="showIndex1">
      <div class="label required">手机号码/用户名/身份证号：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="mobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field code-flex" id="code" v-if="showIndex1">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="mobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div class="code-btn ease" :class="{ actives: canClick }" @click="getCode">
        {{ codeContent }}
      </div>
    </div>
    <div class="clickQr ease" v-if="clickQr" @click="clickQrs">点击验证</div>

    <!-- 安徽 -->
    <div class="user field" id="selectInfo" v-if="anHuiJSelect">
      <div class="label required">身份选择：</div>
      <el-select
        v-model="anHuiJidentityValue"
        placeholder="请选择"
        @change="anHuiJgetCode"
      >
        <el-option
          v-for="item in anHuikeyArray"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <!--  -->
    <div class="user field selectInfoQr" id="selectInfo" v-if="anHuiJSelect">
      <div class="label required">选择身份后扫码：</div>
      <div class="labelQcode">
        <img :src="'data:image/Jpeg;base64,' + anHuilabelQcode" alt="" />
      </div>
    </div>
    <!-- 安徽 -->

    <!-- 广东 -->
    <div class="user field" v-if="guangDongJPhone">
      <div class="label required">账号/身份证号/手机号码：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="guangDongMobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <!-- 广东 -->

    <!-- 青海 -->
    <div class="user field" v-if="qingHaiJPhone">
      <div class="label required">手机号码：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="qingHaiJMobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field code-flex" id="code" v-if="qingHaiJPhone">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="qingHaiJPhoneCode"
        placeholder="请输入"
        @input="changeName"
        :disabled="!qingHaiJMobile"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: qingHaiJcanClick }"
        @click="qingHaiJgetCode"
      >
        {{ qingHaiJcodeContent }}
      </div>
    </div>
    <!--  -->
    <div class="clickQr ease" v-if="qinghaiClickQr" @click="qinghaiClickQrs">
      获取身份
    </div>
    <!--  -->
    <div class="user field" id="selectInfo" v-if="qinghaiSelect">
      <div class="label required">身份选择：</div>
      <el-select
        v-model="qinghaiJidentityValue"
        placeholder="请选择"
        @change="changeName"
      >
        <el-option
          v-for="item in qinghaiKeyArray"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <!-- 青海 -->

    <!-- 吉林 -->
    <div class="user field" id="selectInfo" v-if="jiLinJPhone">
      <div class="label required">身份选择：</div>
      <el-select v-model="jiLinJidentityValue" placeholder="请选择" @change="changeName">
        <el-option
          v-for="item in jiLinValArray"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>

    <div class="user field" v-if="jiLinJPhone">
      <div class="label required">手机号码：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="jiLinJMobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field code-flex" id="code" v-if="jiLinJPhone">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="jiLinJPhoneCode"
        placeholder="请输入"
        @input="changeName"
        :disabled="!jiLinJMobile"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: jiLinJcanClick }"
        @click="jiLinJgetCode"
      >
        {{ jiLinJcodeContent }}
      </div>
    </div>
    <!-- 吉林 -->

    <!-- 云南 -->
    <div class="user field" id="selectInfo" v-if="yunNanJSelect">
      <div class="label required">身份选择：</div>
      <el-select v-model="yunNanJidentityValue" placeholder="请选择" @change="changeName">
        <el-option
          v-for="(item, index) in yunnanValArray"
          :key="index"
          :label="item.xm + '/' + item.sfzjhm + '/' + item.sjhm + '/' + item.type"
          :value="index"
        ></el-option>
      </el-select>
    </div>
    <!--  -->
    <div v-if="yunnanValArray[yunNanJidentityValue]">
      <!-- 选择方式 -->
      <div class="user field" id="selectInfo" v-if="yunNanJSelect">
        <div class="label required">选择方式：</div>
        <el-radio-group v-model="yunnanRadio">
          <el-radio label="PWD">密码方式</el-radio>
          <el-radio label="DX">短信方式</el-radio>
        </el-radio-group>
      </div>
      <!-- 选择方式 -->
      <!-- 密码 -->
      <div
        class="user field"
        id="selectInfo"
        v-if="yunNanJSelect && yunnanRadio == 'PWD'"
      >
        <div class="label required">密码：</div>
        <el-input
          class="input"
          v-model.trim="yunNanJPwd"
          @input="changeName"
          placeholder="请输入密码"
          type="password"
          :show-password="true"
          auto-complete="new-password"
        ></el-input>
      </div>
      <!-- 验证码 -->
      <div
        class="user field code-flex"
        id="code"
        v-if="yunNanJSelect && yunnanRadio == 'DX'"
      >
        <span class="label required">验证码：</span>
        <el-input
          prefix-icon="el-icon-document-checked"
          class="input"
          v-model.trim="yunNanJPhoneCode"
          placeholder="请输入"
          @input="changeName"
        ></el-input>
        <div
          class="code-btn ease"
          :class="{ actives: yunNanJcanClick }"
          @click="yunNanJgetCode"
        >
          {{ yunNanJcodeContent }}
        </div>
      </div>
    </div>
    <!-- 云南 -->

    <!-- 黑龙江 -->
    <div class="user field" v-if="heiLongJPhone">
      <div class="label required">手机号码：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="heiLongJMobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field code-flex" id="code" v-if="heiLongJPhone">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="heiLongJPhoneCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: heiLongJcanClick }"
        @click="heiLongJgetCode"
      >
        {{ heiLongJcodeContent }}
      </div>
    </div>
    <div class="clickQr ease" v-if="heiLongJGet" @click="heiLongJGetInfo">点击验证</div>
    <!-- 写到这里 -->
    <div class="user field" id="selectInfo" v-if="heiLongJSelect">
      <div class="label required">身份选择：</div>
      <el-select
        v-model="heiLongJidentityValue"
        placeholder="请选择"
        @change="changeName"
      >
        <el-option
          v-for="(item, index) in keyArray"
          :key="index"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <!--  -->
    <div class="user field" id="selectInfo" v-if="heiLongJSelect">
      <div class="label required">密码：</div>
      <el-input
        class="input"
        v-model.trim="heiLongJPwd"
        @input="changeName"
        placeholder="请输入密码"
        type="password"
        :show-password="true"
        auto-complete="new-password"
      ></el-input>
    </div>

    <!-- 黑龙江 -->

    <!-- 天津 -->
    <div class="user field" v-if="tianJinPhone">
      <div class="label required">身份证号/税号/手机号码：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="tianJinMobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <!-- 天津 -->

    <!-- 四川 -->
    <div class="user field" v-if="sichuanPhone">
      <div class="label required">手机号码：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="sichuanMobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field code-flex" id="code" v-if="sichuanPhone">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="sichuanPhoneCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: sichuancanClick }"
        @click="sichuangetCode"
      >
        {{ sichuancodeContent }}
      </div>
    </div>
    <!-- 四川 -->

    <!-- 福建授权方式 -->
    <div class="user field" id="selectInfo" v-if="fujianInfoListSelect">
      <div class="label required">身份选择：</div>
      <el-select
        v-model="fujianInfoListSelectValue"
        placeholder="请选择"
        @change="fujianChangeName"
      >
        <el-option
          v-for="item in fujianvalueListKey"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <div class="user field code-flex" id="code" v-if="fujianPhone">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="fujianPhoneCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: fujiancanClick }"
        @click="fujiangetCode"
      >
        {{ fujiancodeContent }}
      </div>
    </div>
    <!-- 福建授权方式 -->

    <!-- 山西授权方式 -->
    <div class="user field" v-if="showShanXiPhone">
      <div class="label required">手机号码：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="showShanXiPhoneData"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field code-flex" id="code" v-if="showShanXiPhone">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="showShanXiPhoneCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: sShanxicanClick }"
        @click="sShanxigetCode"
      >
        {{ sShanxicodeContent }}
      </div>
    </div>
    <div class="clickQr ease" v-if="sShanxiGetInfoQr" @click="sShanxiGetInfo">获取</div>
    <!-- 山西授权方式 -->

    <!-- 江西授权方式 -->
    <div class="user field" id="selectInfo" v-if="jiangxiInfoListSelect">
      <div class="label required">身份选择：</div>
      <el-select
        v-model="jiangxiInfoListSelectValue"
        placeholder="请选择"
        @change="jiangxiChangeName"
      >
        <el-option
          v-for="item in jiangxivalueListKey"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <div class="user field code-flex" id="code" v-if="jiangxiInfoListSelectCode">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="jiangxidqmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: jiangxidqcanClick }"
        @click="jiangxidqgetCode"
      >
        {{ jiangxidqcodeContent }}
      </div>
    </div>
    <!-- 江西授权方式 -->

    <!-- 上海授权方式 -->
    <!-- <div class="user field" id="selectInfo" v-if="shGetTaxPerson">
      <div class="label required">办税人员选择：</div>
      <el-select v-model="shNameListValue" placeholder="请选择" @change="shChangeQcode">
        <el-option
          v-for="(item, index) in shNameList"
          :key="item.bdgxId"
          :label="item.xm + '  ' + item.sfzjhm + '  ' + item.mobile"
          :value="index"
        ></el-option>
      </el-select>
    </div> -->
    <!--  -->
    <!-- <div class="user field" id="selectInfo" v-if="shGetTaxPersonMethod">
      <div class="label required">授权方式选择：</div>
      <div>
        <el-radio-group v-model="shRadio" @change="changeName">
          <el-radio :label="1">密码</el-radio>
          <el-radio :label="2">验证码</el-radio>
        </el-radio-group>
      </div>
    </div> -->
    <!-- <div class="user field" id="selectInfo" v-if="shGetTaxPersonMethod && shRadio == 1">
      <div class="label required">密码：</div>
      <el-input
        class="input"
        v-model.trim="shpasswordInput"
        @input="changeName"
        placeholder="请输入密码"
        type="password"
        :show-password="true"
        auto-complete="new-password"
      ></el-input>
    </div> -->
    <div class="user field" v-if="shGetTaxPerson">
      <div class="label required">身份证/用户名/手机号：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="shNameListValue"
        placeholder="身份证/用户名/手机号"
      ></el-input>
    </div>
    <div
      class="user field code-flex"
      id="code"
      v-if="shGetTaxPerson"
    >
      <div class="label required">验证码：</div>
      <el-input
        class="input"
        prefix-icon="el-icon-document-checked"
        v-model.trim="shmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div class="code-btn ease" :class="{ actives: shcanClick }" @click="shgetCode">
        {{ shcodeContent }}
      </div>
    </div>
    <!-- 上海授权方式 -->

    <!-- 湖南授权方式 -->
    <!-- hunanInfoListSelectRole -->
    <div class="user field" id="selectInfo" v-if="hunanInfoListSelect">
      <div class="label required">办税人员选择：</div>
      <el-select v-model="hunanListValue" placeholder="请选择">
        <el-option
          v-for="item in hunanNameList"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <div class="user field code-flex" id="code" v-if="hunanInfoListSelect">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="hunandqmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: hunandqcanClick }"
        @click="hunandqgetCode"
      >
        {{ hunandqcodeContent }}
      </div>
    </div>
    <!-- 湖南授权方式 -->

    <!-- 河北授权方式 -->
    <div class="user field" id="selectInfo" v-if="hebeiInfoListSelect">
      <div class="label required">身份选择：</div>
      <el-select
        v-model="hebeiInfoListSelectValue"
        placeholder="请选择"
        @change="hebeidqChangeName"
      >
        <el-option
          v-for="(item, index) in hebeiIdentyList.bsrs"
          :key="item.bdgxId"
          :label="item.xm + '  ' + item.mobile + '  ' + item.sfzjhm"
          :value="index"
        ></el-option>
      </el-select>
    </div>
    <!--  -->
    <div class="user field" v-if="hebeiName">
      <div class="label required">用户名：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="hebeiNameValue"
        placeholder="请输入电子税务局账号或税号"
      ></el-input>
    </div>
    <!-- 验证码 -->
    <div class="user field code-flex" id="code" v-if="hebeiInfoListSelectCode">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="hebeidqmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: hebeidqcanClick }"
        @click="hebeidqgetCode"
      >
        {{ hebeidqcodeContent }}
      </div>
    </div>
    <!-- 河北授权方式 -->

    <!-- 陕西授权方式 -->
    <div class="user field" id="selectInfo" v-if="shanxiInfoListSelect">
      <div class="label required">身份选择：</div>
      <el-select
        v-model="shanxiInfoListSelectValue"
        placeholder="请选择"
        @change="shanxidqChangeName"
      >
        <el-option
          v-for="item in shanxiInfoListKey"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>

    <div class="user field" id="selectInfo" v-if="shanxiInfoListSelectMsg">
      <div class="label required">办税人选择：</div>
      <template v-if="shanxiMsg.cwfzr">
        <el-select
          v-model="shanxiInfoListSelectValueMsg"
          placeholder="请选择cwfzr"
          @change="shanxidqChangeNameMsg"
        >
          <el-option
            v-for="(item, index) in shanxiMsg.cwfzr"
            :key="item.reqid"
            :label="item.xm + '  ' + item.dh"
            :value="index"
          ></el-option>
        </el-select>
      </template>
      <template v-if="shanxiMsg.fddbr">
        <el-select
          v-model="shanxiInfoListSelectValueMsg"
          placeholder="请选择fddbr"
          @change="shanxidqChangeNameMsg"
        >
          <el-option
            v-for="(item, index) in shanxiMsg.fddbr"
            :key="item.reqid"
            :label="item.xm + '  ' + item.dh"
            :value="index"
          ></el-option>
        </el-select>
      </template>
      <template v-if="shanxiMsg.bsr">
        <el-select
          v-model="shanxiInfoListSelectValueMsg"
          placeholder="请选择bsr"
          @change="shanxidqChangeNameMsg"
        >
          <el-option
            v-for="(item, index) in shanxiMsg.bsr"
            :key="item.reqid"
            :label="item.xm + '  ' + item.dh"
            :value="index"
          ></el-option>
        </el-select>
      </template>
    </div>

    <!-- 陕西宽古选择身份 -->
    <div class="user field" id="selectInfo" v-if="shanxiMsgBsrSelect">
      <div class="label required">办税人选择：</div>
      <el-select
        v-model="newShanxiInfoListSelectValueMsg"
        placeholder="请选择bsr"
        @change="newShanxidqChangeNameMsg"
      >
        <el-option
          v-for="(item, index) in shanxiMsgBsr"
          :key="item.reqid"
          :label="item.xm + '  ' + item.dh"
          :value="index"
        ></el-option>
      </el-select>
    </div>
    <!-- 陕西宽古选择身份 -->

    <!--  -->
    <div class="user field code-flex" id="code" v-if="shanxiInfoListSelectCode">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="shanxidqmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div
        class="code-btn ease"
        :class="{ actives: shanxidqcanClick }"
        @click="shanxidqgetCode"
      >
        {{ shanxidqcodeContent }}
      </div>
    </div>
    <!-- 陕西授权方式 -->

    <!-- 河南授权方式 -->
    <div class="user field" id="selectInfo" v-if="henanInfoListSelect">
      <div class="label required">身份证/用户名/手机号：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="hnMobile"
        placeholder="请输入"
      ></el-input>
    </div>

    <div class="user field code-flex" id="code" v-if="henanInfoListSelect">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="hndqmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div class="code-btn ease" :class="{ actives: hndqcanClick }" @click="hndqgetCode">
        {{ hndqcodeContent }}
      </div>
    </div>
    <!-- <div class="clickQr ease" v-if="henanInfoListSelectNext" @click="clickQrshnCode">
      下一步
    </div> -->
    <!-- 河南授权方式 -->

    <!-- 广西授权方式 -->
    <div class="user field" id="selectInfo" v-if="gcVerifiType">
      <div class="label required">身份选择：</div>
      <el-select v-model="gcVerifiTypeValue" placeholder="请选择" @change="changeName">
        <el-option
          v-for="item in gcnameList"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <div class="user field code-flex" id="code" v-if="gcVerifiType">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="gcmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div class="code-btn ease" :class="{ actives: gccanClick }" @click="gcgetCode">
        {{ gccodeContent }}
      </div>
    </div>
    <!-- 广西授权方式 -->

    <!-- 辽宁授权方式 -->
    <div class="user field" id="selectInfo" v-if="lnVerifiType">
      <div class="label required">身份选择：</div>
      <el-select v-model="lnVerifiTypeValue" placeholder="请选择" @change="changeName">
        <el-option
          v-for="item in lnnameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="user field" v-if="lnVerifiType">
      <div class="label required">手机号码：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="lnmobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field code-flex" id="code" v-if="lnVerifiType">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="lnmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div class="code-btn ease" :class="{ actives: lncanClick }" @click="lngetCode">
        {{ lncodeContent }}
      </div>
    </div>
    <!-- 辽宁授权方式 -->

    <!-- 北京授权方式 -->
    <div class="user field" id="selectInfo" v-if="bjgetTaxPerson">
      <div class="label required">办税人员选择：</div>
      <el-select
        v-model="bjvalue"
        placeholder="请选择"
        @change="bjchangeQcode"
        v-if="
          this.$route.query.thirdDataSource == 'DB_WF' || this.thirdDataSource2 == 'DB_WF'
        "
      >
        <el-option
          v-for="item in bjnameListArr"
          :key="item.key"
          :label="item.xm + '  ' + item.zjhm + '  ' + item.sjhm + '  ' + item.sfmc"
          :value="item.key"
        ></el-option>
      </el-select>
      <el-select v-model="bjvalue" placeholder="请选择" @change="bjchangeQcode" v-else>
        <el-option
          v-for="item in newBjList"
          :key="item.key"
          :label="item.key"
          :value="item.val"
        ></el-option>
      </el-select>
    </div>
    <div
      class="user field"
      v-if="bjverificationMethod && this.$route.query.thirdDataSource != 'DB_WF'"
    >
      <span class="label required">办税人姓名： </span>

      <el-input
        v-model.trim="bjNewName"
        @input="changeName"
        placeholder="请输入办税人姓名"
        class="input"
      ></el-input>
    </div>
    <div
      class="user field"
      v-if="bjverificationMethod && this.$route.query.thirdDataSource != 'DB_WF'"
    >
      <span class="label required">身份类型： </span>
      <el-radio-group v-model="bjNewIndenty" @change="changeName">
        <el-radio :label="1">财务负责人</el-radio>
        <el-radio :label="2">法定代表人</el-radio>
        <el-radio :label="3">办税人</el-radio>
        <el-radio :label="4">购票员</el-radio>
      </el-radio-group>
    </div>
    <div class="user field" v-if="bjverificationMethod">
      <span class="label required">手机号： </span>

      <el-input
        v-model.trim="bjNewPassword"
        @input="changeName"
        placeholder="请输入手机号"
        class="input"
      ></el-input>
    </div>
    <div class="user field" v-if="bjverificationMethod">
      <span class="label required">身份证号： </span>

      <el-input
        v-model.trim="bjnewIdentCode"
        @input="changeName"
        placeholder="请输入身份证号"
        class="input"
      ></el-input>
    </div>
    <div class="user field" v-if="bjverificationMethod">
      <span class="label required">验证方式(密码)： </span>

      <el-input
        v-model.trim="bjpasswordInput"
        @input="changeName"
        placeholder="请输入密码"
        type="password"
        :show-password="true"
        auto-complete="new-password"
        class="input"
      ></el-input>
    </div>
    <!-- 北京授权方式 -->

    <!-- 新疆授权方式 -->
    <div class="user field" id="selectInfo" v-if="xjgetTaxPerson">
      <div class="label required">办税人员选择：</div>
      <el-select v-model="xjvalue" placeholder="请选择" @change="xjchangeQcode">
        <el-option
          v-for="item in xjnameListArr"
          :key="item.bdgxId"
          :label="
            item.xm +
            '  ' +
            item.sfzjhm +
            '  ' +
            item.mobile +
            ' ' +
            xjPersonType[item.bsrType]
          "
          :value="item.bdgxId"
        ></el-option>
      </el-select>
    </div>
    <!-- <div class="label required" v-if="xjshowInfo">用户名：{{ xjshowInfo }}</div> -->
    <div class="user field" v-if="xjshowInfo">
      <span class="label required">用户名：</span>
      <span>{{ xjshowInfo }}</span>
    </div>
    <!-- <div class="user xjuser"></div> -->
    <div class="user field code-flex" id="code" v-if="xjshowInfo">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="xjmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div class="code-btn ease" :class="{ actives: xjcanClick }" @click="xjgetCode">
        {{ xjcodeContent }}
      </div>
    </div>
    <!-- 新疆授权方式 -->

    <!-- 内蒙授权方式 -->
    <!-- <div class="selectInfo identity" id="selectInfo" v-if="nmIdentity">
      身份选择：
      <el-select
        v-model="nmIdentityValue"
        placeholder="请选择"
        @change="nmChangeIdentity"
      >
        <el-option
          v-for="item in nmIdentityList"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div> -->
    <div class="user field" v-if="nmVerifiType">
      <div class="label required">身份证/用户名/手机号：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="nmMobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field code-flex" id="code" v-if="nmVerifiType">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="nmMobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div class="code-btn ease" :class="{ actives: nmcanClick }" @click="nmGetCode">
        {{ nmCodeContent }}
      </div>
    </div>
    <!-- 内蒙授权方式 -->

    <!-- 江苏 -->
    <!-- 法定代表人|财务负责人|办税员|领票人员 -->
    <div class="user field" id="selectInfo" v-if="identity">
      <div class="label required">身份选择：</div>
      <el-select v-model="identityValue" placeholder="请选择" @change="changeIdentity">
        <el-option
          v-for="item in identityList"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <!-- <div class="label required" v-if="selection">人员选择：{{ identityselect }}</div> -->
    <div class="user field" id="selectInfo" v-if="selection">
      <span class="label required">人员选择：</span>
      <!-- <span>{{ identityselect }}</span> -->
      <el-select
        v-model="jsSelectRoleValue"
        placeholder="请选择"
        @change="changeIdentityRole"
      >
        <el-option
          v-for="(item, index) in jsSelectRoleList"
          :key="index"
          :label="item"
          :value="index"
        ></el-option>
      </el-select>
    </div>
    <!-- <div class="selection"> -->
    <div class="user field code-flex" id="code" v-if="showCode">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="jsmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div class="code-btn ease" :class="{ actives: jscanClick }" @click="jsgetCode">
        {{ jscodeContent }}
      </div>
    </div>
    <!-- 江苏 -->

    <!-- 重庆授权方式 -->
    <!-- 步骤1，通过税号匹配到了重庆地区，要求输入用户名 cqVerifiType 和 cqVerifiTypeNext 变 true-->
    <!-- 步骤2，输入完用户名，点击下一步，这个时候获取登录方式，会随机返回密码方式或者验证码方式 -->
    <!-- 如果是密码方式，掉接口获取登录方式 -->
    <!-- 如果是验证码方式，手机号框和验证码框输入参数，获取验证码 cqVerifiTypeCode变成true -->
    <!-- 步骤3 不管是密码方式，还是验证码方式，下一步就得获取用户信息 -->
    <!-- 如果是sfChoose 需要再次获取验证码, 输入验证码之后，走授权 -->
    <!-- 如果是loginSuccess 直接走授权 -->
    <div class="user field" v-if="cqVerifiType">
      <div class="label required">用户名：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="cqmobile"
        placeholder="请输入"
      ></el-input>
    </div>
    <!--  -->
    <div class="clickQr ease" v-if="cqVerifiTypeNext" @click="clickQrsCq">下一步</div>
    <div class="user field" v-if="cqVerifiTypeCode">
      <div class="label required">手机号：</div>
      <el-input
        class="input"
        @input="changeName"
        v-model.trim="cqmobileOne"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="user field code-flex" id="code" v-if="cqVerifiTypeCode">
      <span class="label required">验证码：</span>
      <el-input
        prefix-icon="el-icon-document-checked"
        class="input"
        v-model.trim="cqmobileCode"
        placeholder="请输入"
        @input="changeName"
      ></el-input>
      <div
        v-if="this.cqVerifiTypeNextCode"
        class="code-btn ease"
        :class="{ actives: cqcanClick }"
        @click="cqgetCode"
      >
        {{ cqcodeContent }}
      </div>
      <div
        v-else
        class="code-btn ease"
        :class="{ actives: cqcanClick }"
        @click="cqgetCode"
      >
        再次获取验证码
      </div>
    </div>
    <div class="clickQr ease" v-if="cqVerifiTypeNextCode" @click="clickQrsCqCode">
      下一步
    </div>
    <!-- 重庆授权方式 -->

    <!-- 山东授权方式 输入委托人手机号/身份证号码/操作员代码 -->
    <div class="user field" v-if="SDMethod">
      <div class="label required">委托人手机号/身份证号码/操作员代码：</div>
      <el-input
        class="input"
        v-model.trim="czydm"
        @input="changeName"
        placeholder="请输入"
      ></el-input>
    </div>
    <!-- 山东授权方式 -->
    <!-- 浙江 -->
    <div class="user field" id="selectInfo" v-if="step1">
      <div class="label required">办税人员选择：</div>
      <el-select
        v-model="value"
        placeholder="请选择"
        @change="changeQcode"
        v-if="
          this.$route.query.thirdDataSource == 'DB_WF' || this.thirdDataSource2 == 'DB_WF'
        "
      >
        <el-option
          v-for="item in nameListArr"
          :key="item.uuid"
          :label="item.xm + '  ' + item.sfzjhm + '  ' + item.sjhm"
          :value="item.uuid"
        ></el-option>
      </el-select>
      <!-- <el-select v-model="value" placeholder="请选择" @change="changeQcode" v-else>
        <el-option
          v-for="item in newZjList"
          :key="item.val"
          :label="item.key"
          :value="item.val"
        ></el-option>
      </el-select> -->
      <!-- <el-tooltip
        class="item"
        effect="dark"
        content="如果办税人员下拉列表选不到指定人员，请前往电子税务局实名认证，如果该办税人员已经办理实名认证，请尝试点击刷新按钮刷新办税人员列表。"
        placement="top-start"
      >
        <div
          class="get-selectInfo"
          v-if="
            this.$route.query.thirdDataSource == 'DB_KUANGU' ||
            this.thirdDataSource2 == 'DB_KUANGU'
          "
          @click="getZjKgUserInfo"
        >
          <i class="el-icon-warning-outline"></i>
          刷新
        </div>
      </el-tooltip> -->
    </div>
    <div class="user field" v-if="kuanGuZjMethod">
      <div class="label required">委托人手机号/身份证号码/操作员代码：</div>
      <el-input
        class="input"
        v-model.trim="zjBsryxm"
        @input="changeName"
        placeholder="请输入"
      ></el-input>
    </div>
    <!--  -->
    <div class="user field" id="selectInfo" v-if="zJselection">
      <span class="label required">登录身份：</span>
      <el-select v-model="zJSelectRoleValue" placeholder="请选择" @change="changeName">
        <el-option
          v-for="item in zJSelectRoleList"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <!--  -->
    <div class="user field" v-if="step2">
      <span class="label required">验证方式：</span>
      <span>密码</span>
    </div>
    <div v-if="step2">
      <div v-show="radio == 1" class="baseImage-c">
        <div class="baseImage">
          <img :src="baseImage" alt />
        </div>
        <div class="baseImage-icon-c">
          打开支付宝
          <span class="baseImage-icon"> <img src="@/assets/test/aipay.png" alt /> </span
          >进行认证
        </div>
      </div>
      <div id="step2" v-show="radio == 2">
        <el-input
          v-model.trim="passwordInput"
          @input="inputTwoPassword"
          placeholder="请输入密码"
          type="password"
          auto-complete="new-password"
          :show-password="true"
        ></el-input>
      </div>
    </div>
    <div class="read">
      <el-checkbox v-model="active" @change="changeName">我已阅读并同意</el-checkbox
      ><span class="active" @click="openLicense">《授权协议》</span>
    </div>
  </form>
</template>

<script>
import {
  getProvinceAi,
  getIdentityInfoAi,
  getQrCodeAi,
  breezeCodeAi,
  getProvinceBath,
  getLoginMethod,
  sendAuthSmsOne,
  getCompanyMsgByTaxNo,
  zjBreezeLogin,
  //
  newShanxiGetCode,
} from "@/api/api";

export default {
  props: {
    thirdDataSource2: String,
    zq: [String, Number],
    uuid: String,
    nsrsbhIndex: String,
  },
  data() {
    return {
      companyNameInfo: JSON.parse(this.$route.query.info).consumerEntity,
      companyNameInfoCompanyName: JSON.parse(this.$route.query.info).consumerEntity
        .companyName,
      nameInfo: [],
      nameInfoList: "",
      // 江苏
      identityList: ["法定代表人", "财务负责人", "办税员", "领票人员"],
      identity: false,
      identityValue: "",
      identityselect: "",
      selection: false,
      showCode: false,
      jsmobileCode: "",
      jsinfo: "",
      jscookies: "",
      jscanClick: true,
      jscodeContent: "获取验证码",
      //改成可以选择
      jsSelectRoleList: "",
      jsSelectRoleValue: "",
      jsInfoData: "",
      jsSelectRoleValueBoolean: false,
      // 江苏
      input: "",
      active: false,
      name: sessionStorage.getItem("manualNsrsbh") || "",
      password: "",
      province: "",
      options: [],
      value: "",
      nameListArr: [],
      value: "",
      radio: "2",
      step1: false,
      step2: false,
      dtlSessionId: "",
      baseImage: "",
      areaList: [
        "浙江",
        "宁波",
        "江苏",
        "山东",
        "内蒙古",
        "新疆",
        "海南",
        "重庆",
        "北京",
        "辽宁",
        "广西",
        "河南",
        "湖南",
        "上海",
        "陕西",
        "河北",
        "江西",
        "山西",
        "福建",
        "四川",
        "天津",
        "黑龙江",
        "云南",
        "吉林",
        "青海",
        "广东",
        "安徽",
      ],
      areaIndex: 1,
      step2Msg: null,
      loading: "",
      loading2: "",
      bizNo: "",
      passwordInput: "",
      clickQr: true,
      mobile: "",
      codeContent: "获取验证码",
      mobileCode: "",
      totalTime: 60,
      canClick: true,
      showIndex1: false,
      index1Data: null,
      indexJylsh: null,
      batchNo: "",
      // 山东参数
      SDMethod: false, // 山东授权方式
      czydm: "", // 操作员代码
      // 山东参数

      // 内蒙古参数
      nmIdentityList: ["法定代表人", "财务负责人", "办税员"],
      nmIdentity: false,
      nmIdentityValue: "",
      nmVerifiType: false,
      nmMobile: "",
      nmMobileCode: "",
      nmCodeContent: "获取验证码",
      nmCookies: "",
      nmcanClick: true,
      //
      //
      nminfoData: "",
      nmotherData: "",
      // 内蒙古参数

      // 新疆参数
      xjtaxSiteUsername: "",
      xjcanClick: true,
      xjmobileCode: "",
      xjcodeContent: "获取验证码",
      xjshowInfo: "",
      xjSm2k: "",
      xjDzswj_tgc: "",
      xjCookies: "",
      xjnameInfoList: "",
      xjnameList: "",
      xjnameListArr: [],
      xjnameInfo: [],
      xjgetTaxPerson: false,
      xjvalue: "",
      xjPersonType: [
        "(未知)",
        "(办税员)",
        "(法定代表人)",
        "(财务负责人)",
        "(代理人)",
        "(自然人)",
        "(购票员)",
        "(未知)",
        "(报验经办人)",
      ],
      // 新疆参数

      // 重庆参数
      cqloginStatusOne: "",
      cqVerifiTypename: false,
      cqVerifiType: false,
      cqVerifiTypeNext: false,
      cqVerifiTypeCode: false,
      cqVerifiTypeNextCode: false,
      cqmobile: "",
      cqmobileCode: "",
      cqcanClick: true,
      cqcodeContent: "获取验证码",
      cqcookies: "",
      cqUsernameAccount: "",
      cqmobileOne: "",
      // 重庆参数

      // 北京参数
      bjnameList: [],
      bjcookies: "",
      bjnameListArr: [],
      bjnameInfo: [],
      bjgetTaxPerson: false,
      bjverificationMethod: false,
      bjpasswordInput: "",
      bjvalue: "",
      bjnameInfoList: [],
      // 20220810新增
      bjNewPassword: "",
      bjnewIdentCode: "",
      // 20220928新增
      newBjList: "",
      newBjToken: "",
      bjNewValue: "",
      bjNewName: "",
      bjNewIndenty: 1,
      // 北京参数

      // 辽宁参数
      lnVerifiType: false,
      lnmobile: "",
      lnmobileCode: "",
      lnVerifiTypeValue: "",
      lncodeContent: "获取验证码",
      lncanClick: true,
      lnnameList: [
        {
          value: "01",
          label: "法人",
        },
        {
          value: "02",
          label: "财务",
        },
        {
          value: "03",
          label: "办税员",
        },
      ],
      lndtlSessionId: "",
      lndzswjTgc: "",
      // 辽宁参数

      // 广西参数
      gcVerifiType: false,
      gcmobileCode: "",
      gccanClick: true,
      gccodeContent: "获取验证码",
      gcVerifiTypeValue: "",
      gcnameList: ["法定代表人", "财务负责人", "办税员"],
      gccookies: "",
      gcdjxh: "",
      gcczydm: "",
      gcsfmc: "",
      gcsfzjhm: "",
      // 广西参数

      // 河南参数
      henanInfoList: null,
      henanInfoListKey: null,
      henanCookies: "",
      hndqCookies: "",

      henanInfoListSelect: false,
      henanInfoListSelectValue: "",
      hndqmobileCode: "",
      hndqmobileCodeData: {},

      hndqcanClick: true,
      hndqcodeContent: "获取验证码",
      //
      henanInfoListSelectNext: false,
      henanCodeData: "",
      // 新增参数
      loginStatusHN: "",
      nsrmcHN: "",
      useridHN: "",
      // 河南参数

      // 湖南参数
      hunanLoginStatus: "",
      hunanCookies: "",
      hunanInfoListSelect: false,
      hunandqmobileCode: "",
      hunandqcodeContent: "获取验证码",
      hunandqcanClick: true,
      //
      hunanListValue: "",
      hunanNameList: [],
      // 湖南参数

      // 新版参数
      newZjToken: "",
      newZjList: [],
      zjNewValue: "",
      newZjBsry: "",
      newZjBsryList: "",

      // 上海参数
      shNameList: "",
      shCookies: "",
      shJylsh: "",
      shDzswj_tgc: "",
      shGetTaxPerson: false,
      shGetTaxPersonMethod: false,
      shNameListValue: "",
      shRadio: 1,
      shpasswordInput: "",
      shmobileCode: "",
      shcanClick: true,
      shcodeContent: "获取验证码",
      shBsrs: "",
      // 上海参数

      // 陕西参数
      shanxiInfoListSelect: false,
      shanxiInfoListSelectValue: "",
      shanxiInfoListKey: [
        {
          name: "法人",
          value: 1,
        },
        {
          name: "财务负责人",
          value: 2,
        },
        {
          name: "办税人",
          value: 3,
        },
      ],
      shanxiCookies: "",
      shanxiFlag: "",
      shanxiMsg: "",
      shanxiInfoListSelectMsg: false,
      shanxiInfoListSelectValueMsg: "",
      shanxiInfoListSelect: false,
      shanxidqmobileCode: "",
      shanxidqcanClick: true,
      shanxidqcodeContent: "获取验证码",
      shanxiInfoListSelectCode: false,
      shanxiLoginData: "",
      shanxisjid: "",
      // 陕西新参数
      shanxiMsgBsr: null,
      shanxiMsgBsrSelect: false,
      newShanxiInfoListSelectValueMsg: "",
      newShanxiCode: "",
      // 河北
      hebeiInfoListSelect: false,
      hebeiInfoListSelectValue: "",
      hebeiInfoListKey: "",
      hebeiIdentyList: "",
      hebeiCookies: "",
      hebeiName: false,
      hebeiPwd: false,
      hebeiNameValue: "",
      hebeiPwdValue: "",
      hebeiInfoListSelectCode: false,
      hebeidqmobileCode: "",
      hebeidqcanClick: true,
      hebeidqcodeContent: "获取验证码",
      hebeiGetInfoBoolean: false,
      // 江西参数
      jiangxicookies: "",
      jiangxivalueList: "",
      jiangxivalueListKey: "",
      jiangxiInfoListSelect: false,
      jiangxiInfoListSelectValue: "",
      jiangxiInfoListSelectCode: false,
      jiangxidqmobileCode: "",
      jiangxidqcanClick: true,
      jiangxidqcodeContent: "获取验证码",
      jiangxinsrbh: "",
      jiangxinsrmc: "",
      jiangxiData: "",
      // 山西参数
      showShanXiPhone: "",
      showShanXiPhoneData: "",
      showShanXiPhoneCode: "",
      sShanxicanClick: true,
      sShanxicodeContent: "获取验证码",
      sShanxiSendType: 1,
      sShanxiCookies: null,
      sShanxiloginStatus: "",
      sShanxiGetInfoQr: false,
      // 福建参数
      fujianInfoListSelect: false,
      fujianInfoListSelectValue: "",
      fujianvalueListKey: [],
      fujianCookies: {},
      fujianPhone: false,
      fujianPhoneCode: "",
      fujiancanClick: true,
      fujiancodeContent: "获取验证码",
      // 四川参数
      sichuanPhone: false,
      sichuanPhoneCode: "",
      sichuanMobile: "",
      sichuancanClick: true,
      sichuancodeContent: "获取验证码",
      sichuanInfoData: "",
      sichuanOtherData: "",
      sichuanCookies: "",
      // 天津参数
      tianJinPhone: "",
      tianJinMobile: "",
      // 黑龙江
      heiLongJPhone: false,
      heiLongJMobile: "",
      heiLongJPhoneCode: "",
      heiLongJcanClick: true,
      heiLongJcodeContent: "获取验证码",
      //
      heiLongJInfodata: "",
      heiLongJCookies: "",
      heiLongJOtherData: "",
      heiLongJValue: "",
      heiLongJGet: false,
      heiLongJSelect: false,
      heiLongJidentityValue: "",
      heiLongJPwd: "",
      keyArray: [],
      heiLongJinfo_data: {},
      // 云南
      yunNanJSelect: false,
      yunNanJidentityValue: "",
      yunNanJPwd: "",
      yunnanArray: "",
      yunnanKeyArray: "",
      yunnanValArray: "",
      yunNanCookie: "",
      yunnanRadio: "PWD",
      yunNanJPhoneCode: "",
      yunNanJcanClick: true,
      yunNanJcodeContent: "获取验证码",
      // 吉林
      jiLinJPhone: false,
      jiLinJMobile: "",
      jiLinJPhoneCode: "",
      jiLinJcanClick: true,
      jiLinJcodeContent: "获取验证码",
      jiLinJidentityValue: "",
      jiLinValArray: ["法人", "财务负责人", "办税员"],
      jiLininfo_data: "",
      jiLincookies: "",
      // 青海
      qingHaiJPhone: false,
      qingHaiJMobile: "",
      qingHaiJPhoneCode: "",
      qingHaiJcanClick: true,
      qingHaiJcodeContent: "获取验证码",
      qinghaiGetCodeKey: 1,
      qinghaiInfo_data: "",
      qinghaiCookies: null,
      qinghaiClickQr: false,
      qinghaiLogin_status: "",
      qinghaiOther_data: null,
      qinghaiValue: "",
      qinghaiKeyArray: "",
      qinghaiValArray: "",
      qinghaiJidentityValue: "",
      qinghaiSelect: false,
      // 浙江新参数
      zjBsryxm: "",
      kuanGuZjMethod: false,
      // 广东参数
      guangDongJPhone: false,
      guangDongMobile: "",
      // 浙江新参数20230508
      zJselection: false,
      zJSelectRoleValue: 3,
      zJSelectRoleList: [
        {
          name: "财务负责人",
          value: 1,
        },
        {
          name: "法定代表人",
          value: 2,
        },
        {
          name: "办税人",
          value: 3,
        },
        {
          name: "开票员",
          value: 4,
        },
      ],
      // 安徽参数
      anHuiJGet: false,
      anHuiJinfo_data: {},
      anHuikeyArray: null,
      anHuiJOtherData: null,
      anHuiJCookies: "",
      anHuiJSelect: false,
      anHuiJidentityValue: "",
      anHuiJPhoneCode: "",
      anHuiJcanClick: true,
      anHuiJcodeContent: "获取验证码",
      anHuisms_data: "",
      anHuilabelQcode: "",
      // 河南概半
      hnMobile: "",
      // 上海新版
      shanghaiother_data: "",
      shanghaiinfo_data: "",
      shanghaicookies: ""
    };
  },
  methods: {
    // 安徽
    anhuiGeiInfo() {
      let params = {
        additionalPassword: this.password.trim(),
        additionalUsername: this.name.trim(),
        taxpayerId: this.name.trim(),
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
      };
      getIdentityInfoAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        // 获取授权人员名单
        this.anHuiJinfo_data = res.data.data.data.value.info_data;
        //
        let keyArray = [];
        for (let key in this.anHuiJinfo_data) {
          // console.log(key);
          keyArray.push(key);
        }
        // console.log(keyArray);
        this.anHuikeyArray = keyArray;
        //
        this.clickQr = false;
        this.anHuiJOtherData = res.data.data.data.other_data;
        this.anHuiJCookies = res.data.data.data.cookies;
        this.anHuiJSelect = true;

        this.changeName();
      });
    },
    anHuiJgetCode() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        area: this.province,
        batchNo: this.batchNo,
        taxpayerId: this.name.trim(),
        cookies: this.anHuiJCookies,
        otherData: this.anHuiJOtherData,
        infoData: this.anHuiJinfo_data[this.anHuiJidentityValue],
      };
      getQrCodeAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        // console.log(res);
        this.anHuilabelQcode = res.data.data.data.qr_data.qr_image;
        this.anHuisms_data = {
          qr_token: res.data.data.data.qr_data.qr_token,
          qr_uuid: res.data.data.data.qr_data.qr_uuid,
        };
        //
        this.changeName();
      });
    },
    // 安徽
    // 青海
    qinghaiClickQrs() {
      if (!this.qingHaiJPhoneCode) {
        this.$message({
          message: "请输入验证码,再点击获取身份！",
          type: "warn",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        taxpayerId: this.name.trim(),
        additionalPassword: this.password.trim(),
        phone: this.qingHaiJMobile.trim(),
        smsCode: this.qingHaiJPhoneCode.trim(),
        cookies: this.qinghaiCookies,
        infoData: this.qinghaiInfo_data,
      };
      getIdentityInfoAi(params).then((res) => {
        this.loading.close();
        //
        if (res.data.login_status) {
          this.qinghaiLogin_status = res.data.login_status;
          this.qinghaiOther_data = res.data.other_data;
          this.qinghaiValue = res.data.value.info_data;
          this.qinghaiCookies = res.data.cookies;
        } else if (res.data.data.login_status) {
          this.qinghaiLogin_status = res.data.data.login_status;
          this.qinghaiOther_data = res.data.data.other_data;
          this.qinghaiValue = res.data.data.value.info_data;
          this.qinghaiCookies = res.data.data.cookies;
        } else {
          this.qinghaiLogin_status = res.data.data.data.login_status;
          this.qinghaiOther_data = res.data.data.data.other_data;
          this.qinghaiValue = res.data.data.data.value.info_data;
          this.qinghaiCookies = res.data.data.data.cookies;
        }
        //
        this.qinghaiKeyArray = [];
        this.qinghaiValArray = [];
        for (let key in this.qinghaiValue) {
          this.qinghaiKeyArray.push(key);
          this.qinghaiValArray.push(this.qinghaiValue[key]);
        }
        //
        this.qinghaiClickQr = false;
        this.qinghaiSelect = true;
        // 判断loginstatus
        this.checkQingHaiStatus();
        this.changeName();
      });
    },
    checkQingHaiStatus() {
      if (this.qinghaiLogin_status == "sfChoose") {
        // 不用再次发短信需要选身份
        this.$message({
          message: "选择人员之后点击下一步授权！",
          type: "success",
        });
      }
      if (this.qinghaiLogin_status == "loginSuccess") {
        // 直接授权
        this.$message({
          message: "选择人员之后点击下一步授权！",
          type: "success",
        });
      }
      if (this.qinghaiLogin_status == "bsryChoose") {
        // 需要发第二次验证码
        this.$message({
          message: "选择人员之后再次获取验证码,输入验证码之后点击下一步授权！",
          type: "success",
        });
        this.qingHaiJPhoneCode = "";
        this.qinghaiGetCodeKey = 2;
      }
      this.changeName();
    },
    //
    qingHaiJgetCode() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        taxpayerId: this.name.trim(),
        additionalPassword: this.password.trim(),
        phone: this.qingHaiJMobile.trim(),
        otherData: this.qinghaiOther_data,
        jsLoginType: this.qinghaiGetCodeKey,
        data: this.qinghaiJidentityValue
          ? this.qinghaiValue[this.qinghaiJidentityValue]
          : null,
        cookies: this.qinghaiCookies,
      };
      breezeCodeAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        //

        if ((this.qinghaiGetCodeKey = 1)) {
          if (res.data.info_data) {
            this.qinghaiInfo_data = res.data.info_data;
            this.qinghaiCookies = res.data.cookies;
          } else if (res.data.data.info_data) {
            this.qinghaiInfo_data = res.data.data.info_data;
            this.qinghaiCookies = res.data.data.cookies;
          } else {
            this.qinghaiInfo_data = res.data.data.data.info_data;
            this.qinghaiCookies = res.data.data.data.cookies;
          }
          this.qinghaiClickQr = true;
        } else {
          if (res.data.other_data) {
            this.qinghaiOther_data = res.data.other_data;
            this.qinghaiCookies = res.data.cookies;
          } else if (res.data.data.other_data) {
            this.qinghaiOther_data = res.data.data.other_data;
            this.qinghaiCookies = res.data.data.cookies;
          } else {
            this.qinghaiOther_data = res.data.data.data.other_data;
            this.qinghaiCookies = res.data.data.data.cookies;
          }
        }
        this.changeName();
      });
    },
    // 青海
    // 吉林
    jiLinJgetCode() {
      if (!this.jiLinJcanClick) {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        area: this.province,
        batchNo: this.batchNo,
        taxpayerId: this.name.trim(),
        additionalPassword: this.password.trim(),
        phone: this.jiLinJMobile.trim(),
        loginType: this.jiLinJidentityValue,
      };
      breezeCodeAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收，输入验证码之后，点击获取按钮！",
          type: "success",
        });

        this.jiLinJcanClick = false;
        //
        if (res.data.info_data) {
          this.jiLininfo_data = res.data.info_data;
          this.jiLincookies = res.data.cookies;
        } else if (res.data.data.info_data) {
          this.jiLininfo_data = res.data.data.info_data;
          this.jiLincookies = res.data.data.cookies;
        } else {
          this.jiLininfo_data = res.data.data.data.info_data;
          this.jiLincookies = res.data.data.data.cookies;
        }
        //
        this.changeName();
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.jiLinJcodeContent = this.totalTime + "s后重发";
          if (this.totalTime < 0) {
            window.clearInterval(clock);
            this.jiLinJcodeContent = "重发验证码 ";
            this.totalTime = 60;
            this.jiLinJcanClick = true;
          }
        }, 1000);
      });
    },
    // 吉林
    // 云南
    getYunNanInfo() {
      this.changeName();
      let params = {
        taxpayerId: this.name.trim(),
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        additionalPassword: this.password.trim(),
      };
      getIdentityInfoAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.clickQr = false;
        this.yunNanJSelect = true;
        this.yunnanArray = res.data.data.resultObj;
        this.yunNanCookie = res.data.data.cookies;
        this.yunnanKeyArray = [];
        this.yunnanValArray = [];
        for (let key in this.yunnanArray) {
          this.yunnanKeyArray.push(key);
          this.yunnanValArray.push(this.yunnanArray[key]);
        }
        this.changeName();
      });
    },
    yunNanJgetCode() {
      if (!this.yunNanJcanClick) {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        area: this.province,
        batchNo: this.batchNo,
        taxpayerId: this.name.trim(),
        zjUuid: this.yunnanKeyArray[this.yunNanJidentityValue],
        dtlSessionId: this.yunNanCookie.dtl_SESSION_ID,
        acwTc: this.yunNanCookie.acw_tc,
      };
      breezeCodeAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收，输入验证码之后，点击获取按钮！",
          type: "success",
        });
        this.changeName();
        this.yunNanJcanClick = false;
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.yunNanJcodeContent = this.totalTime + "s后重发";
          if (this.totalTime < 0) {
            window.clearInterval(clock);
            this.yunNanJcodeContent = "重发验证码 ";
            this.totalTime = 60;
            this.yunNanJcanClick = true;
          }
        }, 1000);
      });
    },
    // 云南
    // 天津
    getTianJinInfo() {
      this.loading.close();
      this.tianJinPhone = true;
      this.clickQr = false;
      this.changeName();
    },
    // 天津
    // 四川
    getSiChuanInfo() {
      this.loading.close();
      this.sichuanPhone = true;
      this.clickQr = false;
      this.changeName();
    },
    sichuangetCode() {
      if (!this.sichuanMobile) {
        this.$message("手机号码不能为空！");
        return false;
      }
      if (!this.sichuancanClick) {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        taxpayerId: this.name.trim(),
        taxSiteUsername: this.name.trim(),
        taxSitePwd: this.password.trim(),
        batchNo: this.batchNo,
        area: this.province,
        legalMobile: this.sichuanMobile,
      };
      breezeCodeAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        this.sichuancanClick = false;
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.sichuancodeContent = this.totalTime + "s后重发";
          if (this.totalTime < 0) {
            window.clearInterval(clock);
            this.sichuancodeContent = "重发验证码 ";
            this.totalTime = 60;
            this.sichuancanClick = true;
          }
        }, 1000);
        // 参数获取

        // 参数
        if (res.data.data.cookies) {
          this.sichuanInfoData = res.data.data.info_data;
          this.sichuanOtherData = res.data.data.other_data;
          this.sichuanCookies = res.data.data.cookies;
        } else {
          this.sichuanInfoData = res.data.data.data.info_data;
          this.sichuanOtherData = res.data.data.data.other_data;
          this.sichuanCookies = res.data.data.data.cookies;
        }
        this.changeName();
      });
    },
    // 四川
    // 福建
    fujiangetCode() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        taxpayerId: this.name.trim(),
        batchNo: this.batchNo,
        area: this.province,
        xm: this.fujianInfoListSelectValue,
        dzswjTgc: this.fujianCookies.dzswj_TGC,
        tgc: this.fujianCookies.tgc,
        jsessionid: this.fujianCookies.jsessionid,
        route: this.fujianCookies.route,
      };
      breezeCodeAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
      });
    },
    getFuJianInfo() {
      this.clickQr = false;
      this.changeName();
      let params = {
        batchNo: this.batchNo,
        area: this.province,
        taxpayerId: this.name.trim(),
        additionalPassword: this.password.trim(),
      };
      getIdentityInfoAi(params).then((res) => {
        this.loading.close();
        if (res.data.code == 200) {
          this.fujianInfoListSelect = true;
          this.fujianvalueListKey = res.data.data.bsryList;
          this.fujianCookies = res.data.data.cookies;
        }
      });
    },
    fujianChangeName() {
      this.fujianPhone = true;
    },
    // 山西
    sShanxigetCode() {
      if (!this.showShanXiPhoneData) {
        this.$message({
          message: "请输入手机号！",
          type: "warn",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        area: this.province,
        batchNo: this.batchNo,
        taxpayerId: this.name.trim(),
        taxSiteUsername: this.name.trim(),
        taxSitePwd: this.password.trim(),
        phone: this.showShanXiPhoneData,
        loginMethod: this.sShanxiSendType,
        cookies: this.sShanxiCookies,
      };
      breezeCodeAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        if (!this.sShanxiCookies) {
          this.$message({
            message: "获取验证码成功，请注意查收，输入验证码之后，点击获取按钮！",
            type: "success",
          });
          this.sShanxiGetInfoQr = true;
        } else {
          this.$message({
            message:
              "获取验证码成功，请注意查收，输入验证码之后，点击下一步按钮完成授权！",
            type: "success",
          });
          this.sShanxiGetInfoQr = false;
        }

        //
        this.sShanxiGetInfoQr = true;
        if (res.data.data.cookies) {
          this.sShanxiCookies = res.data.data.cookies;
        } else {
          this.sShanxiCookies = res.data.data.data.cookies;
        }
        this.changeName();
        //
        this.sShanxicanClick = false;
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.sShanxicodeContent = this.totalTime + "s后重发";
          if (this.totalTime < 0) {
            window.clearInterval(clock);
            this.sShanxicodeContent = "重发验证码 ";
            this.totalTime = 60;
            this.sShanxicanClick = true;
          }
        }, 1000);
      });
    },
    sShanxiGetInfo() {
      if (!this.showShanXiPhoneCode) {
        this.$message({
          message: "请输入验证码！",
          type: "warn",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        area: this.province,
        batchNo: this.batchNo,
        taxpayerId: this.name.trim(),
        taxSiteUsername: this.name.trim(),
        taxSitePwd: this.password.trim(),
        smsCode: this.showShanXiPhoneCode,
        phone: this.showShanXiPhoneData,
        cookies: this.sShanxiCookies,
      };
      getIdentityInfoAi(params, this.apiPrefix).then((res) => {
        // console.log(res);
        this.loading.close();
        if (res.msgCode == 1) {
          if (res.data.data.cookies) {
            this.sShanxiloginStatus = res.data.data.login_status;
            this.sShanxiCookies = res.data.data.cookies;
          } else {
            this.sShanxiloginStatus = res.data.login_status;
            this.sShanxiCookies = res.data.cookies;
          }
          // console.log(this.sShanxiCookies);
          // this.sShanxiloginStatus的值是sfChoose或者loginSuccess
          if (this.sShanxiloginStatus == "sfChoose") {
            this.$message({
              message: "请再次获取验证码",
              type: "success",
            });
            this.sShanxiSendType = 2;
            this.showShanXiPhoneCode = "";
            this.sShanxicodeContent = "获取验证码";
            this.totalTime = 2;
            this.sShanxicanClick = true;
          } else {
            this.$message({
              message: "直接点击最底部下一步按钮授权",
              type: "success",
            });
          }
          this.changeName();
        }
      });
    },
    // 山西
    // 江西
    jiangxiDqnandqGeiInfo() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        taxpayerId: this.name.trim(),
        taxSiteUsername: this.name.trim(),
        area: this.province,
        taxSitePwd: this.password.trim(),
        batchNo: this.batchNo,
      };
      getIdentityInfoAi(params, this.apiPrefix).then((res) => {
        this.loading.close();
        this.clickQr = false;
        if (res.msgCode == 1) {
          // console.log(res);
          this.jiangxicookies = res.data.data.data.cookies;
          this.jiangxivalueList = res.data.data.data.value.info_data;
          this.jiangxinsrbh = res.data.data.data.value.nsrbh;
          this.jiangxinsrmc = res.data.data.data.value.nsrmc;
          this.jiangxivalueListKey = Object.keys(this.jiangxivalueList);
          // console.log(this.jiangxivalueListKey);
          this.jiangxiInfoListSelect = true;
          this.changeName();
        } else {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.clickQr = true;
            },
          });
          return;
        }
      });
    },
    jiangxiChangeName() {
      this.jiangxiInfoListSelectCode = true;
      let jiangxiData = {};
      jiangxiData[this.jiangxiInfoListSelectValue] = this.jiangxivalueList[
        this.jiangxiInfoListSelectValue
      ];
      this.jiangxiData = jiangxiData;
      this.changeName();
    },
    jiangxidqgetCode() {
      if (!this.jiangxiInfoListSelectValue) {
        this.$message("请选择身份！");
        return false;
      }
      if (!this.jiangxidqcanClick) {
        return;
      }
      this.jiangxidqcanClick = false;
      this.jiangxiGetCodeMethod();
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.jiangxidqcodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.jiangxidqcodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.jiangxidqcanClick = true;
        }
      }, 1000);
      this.changeName();
    },
    jiangxiGetCodeMethod() {
      let jiangxiData = {};
      jiangxiData[this.jiangxiInfoListSelectValue] = this.jiangxivalueList[
        this.jiangxiInfoListSelectValue
      ];
      this.jiangxiData = jiangxiData;
      let params = {
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        taxpayerId: this.name.trim(),
        cookies: this.jiangxicookies,
        swjgDm: this.jiangxinsrbh,
        nsrmc: this.jiangxinsrmc,
        data: JSON.stringify(this.jiangxiData),
      };
      breezeCodeAi(params, this.apiPrefix).then((res) => {
        if (res.msgCode == -1) {
          this.clickQr = true;
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        // if (res.data.data.cookies) {
        //   this.jiangxicookies = res.data.data.cookies.cookies;
        //   this.jiangxinsrbh = res.data.data.value.nsrbh;
        //   this.jiangxinsrmc = res.data.data.value.nsrmc;
        // } else {
        //   this.jiangxicookies = res.data.cookies.cookies;
        //   this.jiangxinsrbh = res.data.data.value.nsrbh;
        //   this.jiangxinsrmc = res.data.data.value.nsrmc;
        // }
        this.changeName();
      });
    },
    // 江西
    // 上海
    shgetCode() {
      if (!this.shNameListValue) {
        this.$message({
          message: "请输入身份证/用户名/手机号！",
          type: "warning",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (!this.shcanClick) {
        return;
      }
      let params = {
        taxpayerId: this.name.trim(),
        taxSitePwd: this.password.trim(),
        taxSiteUsername: this.shNameListValue.trim(),
        area: this.province,
        batchNo: this.batchNo,
      };
      breezeCodeAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        // 
        if(res.data.other_data) {
          this.shanghaiother_data = res.data.other_data;
          this.shanghaiinfo_data = res.data.info_data;
          this.shanghaicookies = res.data.cookies;
        } else if(res.data.data.other_data) {
          this.shanghaiother_data = res.data.data.other_data;
          this.shanghaiinfo_data = res.data.data.info_data;
          this.shanghaicookies = res.data.data.cookies;
        } else {
          this.shanghaiother_data = res.data.data.data.other_data;
          this.shanghaiinfo_data = res.data.data.data.info_data;
          this.shanghaicookies = res.data.data.data.cookies;
        }
        this.changeName();
        // 
        this.shcanClick = false;
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.shcodeContent = this.totalTime + "s后重发";
          if (this.totalTime < 0) {
            window.clearInterval(clock);
            this.shcodeContent = "重发验证码 ";
            this.totalTime = 60;
            this.shcanClick = true;
          }
        }, 1000);
      });
    },
    shChangeQcode() {
      this.shBsrs = this.shNameList[this.shNameListValue];
      this.shGetTaxPersonMethod = true;
      this.changeName();
    },
    shGetInfo() {
      this.shGetTaxPerson = true;
      this.loading.close();
      this.clickQr = false;
      this.changeName();
    },
    // 上海
    // 河北
    hebeidqjGetCodeMethod() {
      let params = {
        taxpayerId: this.name.trim(),
        area: this.province,
        batchNo: this.batchNo,
        taxSitePwd: this.password.trim(),
        ssoLoginTgc: this.hebeiCookies.sso_LOGIN_TGC,
        jsessionid: this.hebeiCookies.jsessionid,
        topappCookie: this.hebeiCookies.topapp_COOKIE,
        bsrType: this.hebeiIdentyList.bsrs[this.hebeiInfoListSelectValue].bsrType,
        bdgxId: this.hebeiIdentyList.bsrs[this.hebeiInfoListSelectValue].bdgxId,
        showInfo: this.hebeiIdentyList.bsrs[this.hebeiInfoListSelectValue].showInfo,
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.clickQr = true;
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        if (res.data.data.cookies) {
          this.hebeiCookies = res.data.data.cookies;
        } else {
          this.hebeiCookies = res.data.data.data.cookies;
        }
        this.changeName();
      });
    },
    hebeidqgetCode() {
      if (!this.hebeiNameValue) {
        this.$alert("用户名不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (!this.hebeidqcanClick) {
        return;
      }
      this.hebeidqcanClick = false;
      this.hebeidqjGetCodeMethod();
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.hebeidqcodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.hebeidqcodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.hebeidqcanClick = true;
        }
      }, 1000);
      this.changeName();
    },
    hebeidqChangeName() {
      // 选择人员的时候，显示电子税务局账号和密码框框
      this.hebeiName = true;
      this.hebeiPwd = true;
      this.hebeiInfoListSelectCode = true;
      this.hebeiGetInfoBoolean = true;
      this.changeName();
    },
    hebeiDqnandqGeiInfo() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 获取身份
      let params = {
        taxpayerId: this.name.trim(),
        area: this.province,
        batchNo: this.batchNo,
      };
      getIdentityInfoAi(params).then((res) => {
        this.loading.close();
        this.hebeiInfoListSelect = true;
        // console.log(res);
        this.hebeiIdentyList = res.data.data.value;
        this.hebeiCookies = res.data.data.cookies;
        this.changeName();
      });
    },
    // 河北
    // 新版浙江取数
    // 新版浙江方法
    getZjKgUserInfo() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.newZjGetInfo();
    },
    newZjGetInfo() {
      this.clickQr = false;
      this.loading.close();
      this.kuanGuZjMethod = true;
      this.changeName();
      // let params = {
      //   companyNo: this.nsrsbhIndex,
      //   password: this.password,
      //   programId: this.uuid,
      //   userId: this.name,
      //   zq: this.zq,
      // };
      // zjBreezeLogin(params).then((res) => {
      //   this.loading.close();
      //   if (res.msgCode == 1) {
      //     this.newZjToken = res.data.token;
      //     this.newZjList = res.data.czrys;
      //     this.step1 = true;
      //     this.clickQr = false;
      //     // 新参数
      //     this.ZjGetVal(this.newZjList);
      //     //
      //     this.changeName();
      //   } else {
      //     this.$message({
      //       message: res.msgInfo,
      //       type: "error",
      //     });
      //   }
      // });
    },
    ZjGetVal(val) {
      let zjBsry = {};
      for (let i = 0; i < val.length; i++) {
        zjBsry[val[i].val] = val[i].key;
      }
      this.newZjBsryList = zjBsry;
      this.changeName();
    },
    // 新的浙江方式
    // 新版浙江取数
    getCompanyName() {
      getCompanyMsgByTaxNo(this.companyNameInfo.nsrsbh).then((res) => {
        this.companyNameInfoCompanyName = res.data.name;
      });
    },
    // 陕西
    shanxidqgetCode() {
      if (!this.shanxidqcanClick) {
        return;
      }
      let msg = "";
      if (this.shanxiMsg.cwfzr) {
        msg = {
          cwfzr: [this.shanxiMsg.cwfzr[this.shanxiInfoListSelectValueMsg]],
          sjid: this.shanxisjid,
        };
      }
      if (this.shanxiMsg.fddbr) {
        msg = {
          fddbr: [this.shanxiMsg.fddbr[this.shanxiInfoListSelectValueMsg]],
          sjid: this.shanxisjid,
        };
      }
      if (this.shanxiMsg.bsr) {
        msg = {
          bsr: [this.shanxiMsg.bsr[this.shanxiInfoListSelectValueMsg]],
          sjid: this.shanxisjid,
        };
      }
      // console.log(msg);
      // return;
      this.shanxiLoginData = msg;
      this.shanxidqcanClick = false;
      this.changeName();
      // return;
      // this.shanxiGetCodeMethod();
      if (
        this.$route.query.thirdDataSource == "DB_WF" ||
        this.thirdDataSource2 == "DB_WF"
      ) {
        this.shanxiGetCodeMethod();
      } else {
        this.newShanxiGetCodeMethod();
      }
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.shanxidqcodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.shanxidqcodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.shanxidqcanClick = true;
        }
      }, 1000);
    },
    shanxiGetCodeMethod() {
      let params = {
        taxpayerId: this.name.trim(),
        area: this.areaList[this.areaIndex],
        cookies: this.shanxiCookies,
        data: JSON.stringify(this.shanxiLoginData),
        smrzFlag: this.shanxiFlag,
        batchNo: this.batchNo,
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          // this.clickQr = true;
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        this.changeName();
      });
    },
    // 陕西
    // 河南
    clickQrshnCode() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        taxSiteUsername: this.name.trim(),
        taxSitePwd: this.password.trim(),
        taxpayerId: this.name.trim(),
        smsCode: this.hndqmobileCode,
        djxh: this.henanInfoListSelectValue,
        wsbsfwtsessionId: this.henanCookies,
        //
        data: this.hndqmobileCodeData || "01",
      };
      getLoginMethod(params).then((res) => {
        this.loading.close();
        // console.log(res);
        if (res.msgCode != -1) {
          this.useridHN = res.data.data.userid;
          this.nsrmcHN = res.data.data.nsrmc;
          this.loginStatusHN = res.data.data.loginStatus;
          this.henanInfoListSelectNext = false;
          this.loginStatusHNMeth();
          this.changeName();
        }
      });
    },
    loginStatusHNMeth() {
      if (this.loginStatusHN == "sfChoose") {
        this.hndqmobileCode = "";
      }
      if (this.loginStatusHN == "loginSuccess") {
        this.$message({
          message: "点击下方蓝色下一步，完成提交！",
          type: "success",
        });
      }
      this.changeName();
    },
    hndqgetCode() {
      if (!this.hndqcanClick) {
        return;
      }
      this.hndqcanClick = false;
      this.hndqjGetCodeMethod();
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.hndqcodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.hndqcodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.hndqcanClick = true;
        }
      }, 1000);
    },
    hndqjGetCodeMethod() {
      let params = {
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        taxSiteUsername: this.hnMobile,
        taxpayerId: this.name.trim(),
        taxSitePwd: this.password.trim(),
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.changeName();
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        if (res.data.data.cookies) {
          this.hndqCookies = res.data.data.cookies;
          this.hndqmobileCodeData = res.data.data.other_data;
          // hndqmobileCodeData
        } else if (res.data.cookies) {
          this.hndqCookies = res.data.cookies;
          this.hndqmobileCodeData = res.data.other_data;
        } else {
          this.hndqCookies = res.data.data.data.cookies;
          this.hndqmobileCodeData = res.data.data.data.other_data;
        }
        this.changeName();
      });
    },
    hndqChangeName() {
      this.changeName();
    },
    hndqGeiInfo() {
      this.henanInfoListSelect = true;
    },
    // 河南

    // 广西
    gcjGetCodeMethod() {
      let params = {
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        taxSiteUsername: this.name.trim(),
        taxpayerId: this.name.trim(),
        taxSitePwd: this.password.trim(),
        loginType: this.gcVerifiTypeValue,
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        if (res.data.data.cookies) {
          this.gccookies = res.data.data.cookies;
          this.gcdjxh = res.data.data.infoData.dxJyxh;
          this.gcczydm = res.data.data.infoData.curUserId;
          this.gcsfmc = res.data.data.infoData.sflxDm;
          this.gcsfzjhm = res.data.data.infoData.djhm;
        } else {
          this.gccookies = res.data.data.data.cookies;
          this.gcdjxh = res.data.data.data.infoData.dxJyxh;
          this.gcczydm = res.data.data.data.infoData.curUserId;
          this.gcsfmc = res.data.data.data.infoData.sflxDm;
          this.gcsfzjhm = res.data.data.data.infoData.djhm;
        }
        this.changeName();
      });
    },
    gcgetCode() {
      if (!this.gcVerifiTypeValue) {
        this.$message("请选择身份！");
        return false;
      }
      if (!this.gccanClick) {
        return;
      }
      this.gccanClick = false;
      this.gcjGetCodeMethod();
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.gccodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.gccodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.gccanClick = true;
        }
      }, 1000);
    },
    // 广西
    // 辽宁
    lngetCode() {
      if (!this.lnVerifiTypeValue) {
        this.$message("请选择身份！");
        return false;
      }
      if (!this.lnmobile) {
        this.$message("手机号码不能为空！");
        return false;
      }
      if (!this.lncanClick) {
        return;
      }
      this.lncanClick = false;
      this.lnjGetCodeMethod();
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.lncodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.lncodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.lncanClick = true;
        }
      }, 1000);
    },
    lnjGetCodeMethod() {
      let params = {
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        taxSiteUsername: this.name.trim(),
        taxpayerId: this.name.trim(),
        taxSitePwd: this.password.trim(),
        loginType: this.lnVerifiTypeValue,
        phone: this.lnmobile,
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        if (res.data.lesb_session) {
          this.lndtlSessionId = res.data.lesb_session;
          this.lndzswjTgc = res.data.sto_id_20480_dzswj_wssb;
        } else {
          this.lndtlSessionId = res.data.data.lesb_session;
          this.lndzswjTgc = res.data.data.sto_id_20480_dzswj_wssb;
        }
        this.changeName();
      });
    },
    // 辽宁

    cqgetcodesuccess() {
      // console.log("das");
      // console.log(this.cqVerifiTypeNextCod);
      // this.cqcookies = res.data.data.cookies;
      this.changeName();
      let params = {
        cookies: this.cqcookies,
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        taxSiteUsername: this.cqmobile.trim(), // 电子税务局账号，多一个框输账号
        taxSitePwd: this.password.trim(),
        phone: this.cqmobileOne,
        taxpayerId: this.name.trim(),
      };
      if (this.cqVerifiTypeNextCode) {
        sendAuthSmsOne(params).then((res) => {
          this.loading.close();
          if (res.msgCode == -1) {
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return;
          }
          this.cqcanClick = false;
          this.$message({
            message: "获取验证码成功，请注意查收！",
            type: "success",
          });
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.cqcodeContent = this.totalTime + "s后重发";
            if (this.totalTime < 0) {
              window.clearInterval(clock);
              this.cqcodeContent = "重发验证码 ";
              this.totalTime = 60;
              this.cqcanClick = true;
            }
          }, 1000);
        });
      } else {
        breezeCodeAi(params).then((res) => {
          this.loading.close();
          if (res.msgCode == -1) {
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return;
          }
          this.cqcanClick = false;
          this.$message({
            message: "获取验证码成功，请注意查收！",
            type: "success",
          });
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.cqcodeContent = this.totalTime + "s后重发";
            if (this.totalTime < 0) {
              window.clearInterval(clock);
              this.cqcodeContent = "重发验证码 ";
              this.totalTime = 60;
              this.cqcanClick = true;
            }
          }, 1000);
        });
      }
    },
    cqGetCodeMethod() {
      let params = {
        batchNo: this.batchNo,
        area: this.province,
        phone: this.cqmobileOne,
        taxSiteUsername: this.cqmobile,
        taxSitePwd: this.password.trim(),
        cookies: this.cqcookies,
        taxpayerId: this.name.trim(),
      };
      // getIdentityInfoAi(params).then((res) => {
      //   if (res.msgCode == -1) {
      //     this.loading.close();
      //     this.$alert(res.msgInfo, "温馨提示", {
      //       confirmButtonText: "确定",
      //       callback: (action) => {},
      //     });
      //     return;
      //   }
      //   if (res.data.data.resultCode == 100002) {
      //     this.loading.close();
      //     this.$alert(res.data.data.resultMsg, "温馨提示", {
      //       confirmButtonText: "确定",
      //     });
      //     return;
      //   }
      this.cqgetcodesuccess();
      // });
    },
    cqgetCode() {
      if (!this.cqmobileOne) {
        this.$message("手机号码不能为空！");
        return false;
      }
      if (!this.cqcanClick) {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.cqGetCodeMethod();
    },
    xjgetCode() {
      if (!this.xjcanClick) {
        return;
      }
      this.xjcanClick = false;
      this.xjGetCodeMethod();
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.xjcodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.xjcodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.xjcanClick = true;
        }
      }, 1000);
    },
    xjGetCodeMethod() {
      let params = {
        taxpayerId: this.name.trim(),
        taxSitePwd: this.password.trim(),
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        cookies: this.xjCookies,
        sm2k: this.xjSm2k,
        taxSiteUsername: this.xjnameInfoList.showInfo,
        smrzFlag: this.xjnameInfoList.smrzFlag,
        isPersonRegister: this.xjnameInfoList.isPersonRegister,
        bdgxId: this.xjnameInfoList.bdgxId,
        showInfo: this.xjnameInfoList.showInfo,
        bsrType: this.xjnameInfoList.bsrType,
        xm: this.xjnameInfoList.xm,
        legalMobile: this.xjnameInfoList.mobile,
        smrzType: this.xjnameInfoList.smrzType,
        sfzjhm: this.xjnameInfoList.sfzjhm,
        encryptSfzjhm: this.xjnameInfoList.encryptSfzjhm,
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
      });
    },
    // 内蒙授权方式获取验证码
    nmGetCode() {
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.nmMobile.trim())) {
        this.$message("请输入正确手机号");
        return false;
      }
      if (!this.nmcanClick) {
        return;
      }
      this.nmcanClick = false;
      this.nmGetCodeMethod();
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.nmCodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.nmCodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.nmcanClick = true;
        }
      }, 1000);
    },
    // 内蒙授权方式获取验证码
    nmGetCodeMethod() {
      let params = {
        taxpayerId: this.name.trim(),
        taxSitePwd: this.password.trim(),
        taxSiteUsername: this.nmMobile.trim(),
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
      };
      breezeCodeAi(params).then((res) => {
        if (res.code) {
          if (res.code != 200) {
            this.$alert(res.message, "温馨提示", {
              confirmButtonText: "确定",
            });
            return;
          }
          this.$message({
            message: "获取验证码成功，请注意查收！",
            type: "success",
          });
          this.nmCookies = res.data.cookies;
          this.nminfoData = res.data.info_data;
          this.nmotherData = res.data.other_data;
          this.changeName();
        } else {
          if (res.data.code != 200) {
            this.$alert(res.message, "温馨提示", {
              confirmButtonText: "确定",
            });
            return;
          }
          this.$message({
            message: "获取验证码成功，请注意查收！",
            type: "success",
          });
          this.nmCookies = res.data.data.cookies;
          this.nminfoData = res.data.data.info_data;
          this.nmotherData = res.data.data.other_data;
          this.changeName();
        }
      });
    },
    // 内蒙授权方式获取验证码
    // 内蒙授权方式选择身份
    nmChangeIdentity() {
      this.nmVerifiType = true;
      this.changeName();
    },
    // 内蒙授权方式选择身份
    getprovinceBath() {
      let params = {
        taxpayerId: this.name.trim(),
      };
      getProvinceBath(params).then((res) => {
        this.batchNo = res.data.data;
        this.changeName();
      });
    },
    //
    //
    changeIdentityRole() {
      let key = this.jsSelectRoleList[this.jsSelectRoleValue];
      this.jsinfo = this.jsInfoData[key];
      this.jsSelectRoleValueBoolean = true;
      this.changeName();
    },
    jsgetCode() {
      if (!this.jscanClick) {
        return;
      }
      let params = {
        taxpayerId: this.name.trim(),
        rybz: this.jsinfo.rybz,
        yxbz: this.jsinfo.yxbz,
        sjhm: this.jsinfo.sjhm,
        bz: this.jsinfo.bz,
        xm: this.jsinfo.xm,
        tel: this.jsinfo.tel,
        sfzh: this.jsinfo.sfzh,
        zjlx: this.jsinfo.zjlx,
        type: this.jsinfo.type,
        cookies: this.jscookies,
        batchNo: this.batchNo,
        area: this.province,
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.getprovinceBath();
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        this.jscanClick = false;
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.jscodeContent = this.totalTime + "s后重发";
          if (this.totalTime < 0) {
            window.clearInterval(clock);
            this.jscodeContent = "重发验证码 ";
            this.totalTime = 60;
            this.jscanClick = true;
          }
        }, 1000);
      });
    },
    changeIdentity() {
      this.loading = this.$loading({
        lock: true,
        text: "正在选择身份",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        taxpayerId: this.name.trim(),
        area: this.province,
        taxSitePwd: this.password.trim(),
        batchNo: this.batchNo,
        loginType: this.identityValue,
      };
      // step2 获取身份信息
      getIdentityInfoAi(params).then((res) => {
        this.loading.close();
        this.clickQr = false;
        if (res.msgCode == 1) {
          this.showCode = true;
          this.jscookies = res.data.data.cookies;
          let value = res.data.data.value.info_data;
          // let info = Object.keys(value)[0];
          // this.jsinfo = value[info];
          // this.identityselect = value[info].xm;
          this.jsInfoData = res.data.data.value.info_data;
          this.jsSelectRoleList = Object.keys(value);
          this.jsSelectRoleValue = "";
          this.jsSelectRoleValueBoolean = false;
          this.selection = true;
          this.changeName();
        } else {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          return;
        }
      });
    },
    nGetCode() {
      let params = {
        taxpayerId: this.name.trim(),
        taxSiteUsername: this.mobile.trim(),
        area: this.areaList[this.areaIndex],
        taxSitePwd: this.password.trim(),
        batchNo: this.batchNo,
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.getprovinceBath();
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        this.index1Data = res.data.data.cookies;
        this.indexJylsh = res.data.data.other_data;
      });
    },

    getCode() {
      if (!this.mobile) {
        this.$message("请输入手机号码/用户名/身份证号");
        return false;
      }
      if (!this.canClick) {
        return;
      }
      this.canClick = false;
      this.nGetCode();
      // this.codeContent = this.totalTime + "s后重发";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.codeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.codeContent = "重发验证码 ";
          this.totalTime = 60;
          this.canClick = true;
        }
      }, 1000);
    },

    radioChange() {
      this.changeName();
    },

    inputTwoPassword() {
      this.changeName();
    },

    changeName() {
      this.$emit("setName", {
        name: this.name.trim(),
        password: this.password.trim(),
        active: this.active,
        index: this.areaIndex,
        step2Msg: this.step2Msg,
        value: this.value,
        province: this.province,
        bizNo: this.bizNo,
        radio: this.radio,
        passwordInput: this.passwordInput,
        clickQr: this.clickQr,
        index1Data: this.index1Data,
        indexJylsh: this.indexJylsh,
        mobile: this.mobile,
        mobileCode: this.mobileCode,
        batchNo: this.batchNo,
        // 江苏
        jsmobileCode: this.jsmobileCode,
        jscookies: this.jscookies,
        jsinfo: this.jsinfo,
        identityValue: this.identityValue,
        nameListArr: this.nameListArr,
        nameInfoList: this.nameInfoList,
        //
        jsSelectRoleValue: this.jsSelectRoleValue,
        jsSelectRoleValueBoolean: this.jsSelectRoleValueBoolean,
        //
        // 江苏
        // 山东
        czydm: this.czydm,
        // 山东
        // 内蒙参数
        nmCookies: this.nmCookies,
        nmPhone: this.nmMobile,
        nmMobileCode: this.nmMobileCode,
        nmIdentityValue: this.nmIdentityValue,
        nminfoData: this.nminfoData,
        nmotherData: this.nmotherData,
        // 新疆
        xjmobileCode: this.xjmobileCode,
        xjbdgxId: this.xjnameInfoList.bdgxId,
        xjbsrType: this.xjnameInfoList.bsrType,
        xjcookies: this.xjCookies,
        xjtaxSiteUsername: this.xjtaxSiteUsername,
        xjvalue: this.xjvalue,
        // 重庆
        cqcookies: this.cqcookies,
        cqmobileCode: this.cqmobileCode,
        cqmobile: this.cqmobile,
        // cqLoginType: "",
        cqloginStatusOne: this.cqloginStatusOne,
        // 北京
        bjadditionalPassword: this.bjpasswordInput,
        bjcookies: this.bjcookies,
        bjnameInfoList: this.bjnameInfoList,
        bjvalue: this.bjvalue,
        // 20220810新增
        bjNewPassword: this.bjNewPassword,
        bjnewIdentCode: this.bjnewIdentCode,
        newBjToken: this.newBjToken,
        bjNewValue: this.bjNewValue,
        //
        bjNewName: this.bjNewName,
        bjNewIndenty: this.bjNewIndenty,
        // 辽宁
        lnmobile: this.lnmobile,
        lnVerifiTypeValue: this.lnVerifiTypeValue,
        lnmobileCode: this.lnmobileCode,
        lndtlSessionId: this.lndtlSessionId,
        lndzswjTgc: this.lndzswjTgc,
        // 广西
        gcmobileCode: this.gcmobileCode,
        gcVerifiTypeValue: this.gcVerifiTypeValue,
        gccookies: this.gccookies,
        gcdjxh: this.gcdjxh,
        gcczydm: this.gcczydm,
        gcsfmc: this.gcsfmc,
        gcsfzjhm: this.gcsfzjhm,
        // 河南
        hndqmobileCode: this.hndqmobileCode,
        henanInfoListSelectValue: this.henanInfoListSelectValue,
        hndqCookies: this.hndqCookies,
        hndqmobileCodeData: this.hndqmobileCodeData,
        //
        henanInfoListSelectNext: this.henanInfoListSelectNext,
        loginStatusHN: this.loginStatusHN,
        nsrmcHN: this.nsrmcHN,
        useridHN: this.useridHN,
        //重庆
        // 第一个下一步按钮
        cqVerifiTypeNext: this.cqVerifiTypeNext,
        cqLoginType: this.cqLoginType,
        cqmobileOne: this.cqmobileOne,
        cqVerifiTypeNextCode: this.cqVerifiTypeNextCode,
        //
        // 湖南
        hunanLoginStatus: this.hunanLoginStatus,
        hunanCookies: this.hunanCookies,
        hunandqmobileCode: this.hunandqmobileCode,
        // 浙江新参数
        zjNewValue: this.zjNewValue,
        newZjToken: this.newZjToken,
        newZjBsry: this.newZjBsry,
        // 上海
        shpasswordInput: this.shpasswordInput,
        shBsrs: this.shBsrs,
        shRadio: this.shRadio,
        shCookies: this.shCookies,
        shmobileCode: this.shmobileCode,
        // 陕西参数
        shanxiCookies: this.shanxiCookies,
        shanxidqmobileCode: this.shanxidqmobileCode,
        shanxiLoginData: this.shanxiLoginData,
        shanxiInfoListSelectValue: this.shanxiInfoListSelectValue,
        // 河北参数
        hebeiNameValue: this.hebeiNameValue,
        hebeidqmobileCode: this.hebeidqmobileCode,
        hebeiCookies: this.hebeiCookies,
        hebeiGetInfoBoolean: this.hebeiGetInfoBoolean,
        // 江西参数
        jiangxicookies: this.jiangxicookies,
        jiangxinsrbh: this.jiangxinsrbh,
        jiangxinsrmc: this.jiangxinsrmc,
        jiangxiData: this.jiangxiData,
        jiangxidqmobileCode: this.jiangxidqmobileCode,
        jiangxiInfoListSelectValue: this.jiangxiInfoListSelectValue,
        // 山西参数
        showShanXiPhoneData: this.showShanXiPhoneData,
        showShanXiPhoneCode: this.showShanXiPhoneCode,
        sShanxiCookies: this.sShanxiCookies,
        sShanxiloginStatus: this.sShanxiloginStatus,
        // 福建
        fujianCookies: this.fujianCookies,
        fujianPhoneCode: this.fujianPhoneCode,
        // 四川
        sichuanInfoData: this.sichuanInfoData,
        sichuanOtherData: this.sichuanOtherData,
        sichuanCookies: this.sichuanCookies,
        sichuanPhoneCode: this.sichuanPhoneCode,
        sichuanMobile: this.sichuanMobile,
        // 天津
        tianJinMobile: this.tianJinMobile,
        // 黑龙江
        heiLongJOtherData: this.heiLongJOtherData,
        heiLongJinfo_data: this.heiLongJinfo_data,
        heiLongJCookies: this.heiLongJCookies,
        heiLongJPwd: this.heiLongJPwd,
        heiLongJidentityValue: this.heiLongJinfo_data[this.heiLongJidentityValue],
        // 云南
        yunNanCookie: this.yunNanCookie,
        yunNanJPwd: this.yunNanJPwd,
        yunNanUUID: this.yunnanKeyArray[this.yunNanJidentityValue],
        yunnanRadio: this.yunnanRadio,
        yunNanJPhoneCode: this.yunNanJPhoneCode,
        // 吉林
        jiLinJMobile: this.jiLinJMobile,
        jiLinJPhoneCode: this.jiLinJPhoneCode,
        jiLininfo_data: this.jiLininfo_data,
        jiLincookies: this.jiLincookies,
        // 青海
        qingHaiJPhoneCode: this.qingHaiJPhoneCode,
        qinghaiInfo_data: this.qinghaiInfo_data,
        qinghaiCookies: this.qinghaiCookies,
        qinghaiOther_data: this.qinghaiOther_data,
        qinghaiLogin_status: this.qinghaiLogin_status,
        qinghaiValObject: this.qinghaiJidentityValue
          ? this.qinghaiValue[this.qinghaiJidentityValue]
          : null,
        // infodata错误 如果选择 需要的是人员的对象 如果没选
        // 就是之前返回的infodata
        //
        // 浙江新参数
        zjBsryxm: this.zjBsryxm,
        // 广东参数
        guangDongMobile: this.guangDongMobile,
        // 浙江新参数20230508
        zJSelectRoleValue: this.zJSelectRoleValue,
        // 安徽参数
        anHuiJidentityValue: this.anHuiJidentityValue,
        anhuiInfoData: this.anHuiJinfo_data[this.anHuiJidentityValue],
        anHuiJCookies: this.anHuiJCookies,
        anHuiJOtherData: this.anHuiJOtherData,
        anHuisms_data: this.anHuisms_data,
        anHuiJPhoneCode: this.anHuiJPhoneCode,
        // 河南
        hnMobile: this.hnMobile,
        // 上海概半
        shanghaiother_data: this.shanghaiother_data,
        shanghaiinfo_data: this.shanghaiinfo_data,
        shanghaicookies: this.shanghaicookies,
        shNameListValue: this.shNameListValue
      });
    },

    clickQrs() {
      if (!this.name.trim() || !this.password.trim()) {
        this.$message("账号密码不能为空！");
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取地区信息",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.getprovince();
    },

    addActive() {
      this.active = !this.active;
      this.changeName();
    },

    openLicense() {
      this.$emit("openlicense");
    },

    getprovince() {
      // step1 获取省份信息
      let params = {
        taxpayerId: this.name.trim(),
      };
      getProvinceAi(params).then((res) => {
        // this.loading.close();
        if (res.msgCode == 1) {
          this.province = res.data.province;
          this.batchNo = res.data.batchNo;
          // 判断地区
          let proIndex = this.areaList.findIndex((item) => {
            return item == this.province;
          });
          this.areaIndex = proIndex;
          this.changeName();
          // 如果匹配不到特殊的地区，就下一步，走通用
          if (this.areaIndex < 0) {
            this.$alert("该地区目前暂不支持！", "温馨提示", {
              confirmButtonText: "确定",
            });
            this.loading.close();
            this.changeName();
            return;
          }

          // 匹配到的是浙江，获取身份信息
          if (this.areaIndex == 0) {
            if (
              this.$route.query.thirdDataSource == "DB_WF" ||
              this.thirdDataSource2 == "DB_WF"
            ) {
              let params = {
                taxpayerId: this.name.trim(),
                area: this.province,
                taxSitePwd: this.password.trim(),
                batchNo: this.batchNo,
              };
              // step2 获取身份信息
              getIdentityInfoAi(params).then((res) => {
                if (res.msgCode == -1) {
                  this.loading.close();
                  this.$alert(res.msgInfo, "温馨提示", {
                    confirmButtonText: "确定",
                    callback: (action) => {},
                  });
                  return;
                }

                if (res.data.data.resultCode == 100002) {
                  this.$alert(res.data.data.resultMsg, "温馨提示", {
                    confirmButtonText: "确定",
                  });
                  this.active = false;
                  this.changeName();
                  this.loading.close();
                  return;
                }

                this.nameList = res.data.data.info_data;
                this.step2Msg = res.data.data.cookies;
                for (let i in this.nameList) {
                  this.nameListArr.push(this.nameList[i]);
                }
                this.dtlSessionId = res.data.data.cookies.dtl_SESSION_ID;
                this.step1 = true;

                this.loading.close();
                this.clickQr = false;
                this.changeName();
                this.nameInfo = this.nameListArr.map((item) => {
                  return item.uuid;
                });
              });
            } else {
              this.newZjGetInfo();
            }
          }

          // 匹配到的是宁波,则获取手机号和验证码
          if (this.areaIndex == 1) {
            this.showIndex1 = true;
            this.resetType();
          }

          // 匹配的到是江苏
          if (this.areaIndex == 2) {
            this.identity = true;
            this.resetType();
          }
          // 匹配到的是山东
          if (this.areaIndex == 3) {
            this.SDMethod = true;
            this.resetType();
          }
          // 匹配到的是内蒙古
          if (this.areaIndex == 4) {
            this.nmVerifiType = true;
            this.resetType();
          }
          // 匹配到的是新疆
          if (this.areaIndex == 5) {
            this.xjGetInfo();
          }
          // 匹配到的是重庆
          if (this.areaIndex == 7) {
            this.cqVerifiType = true;
            this.cqVerifiTypeNext = true;
            this.resetType();
            // 调获取取数方式接口
            // this.getLoginMethodCy();
          }
          // 匹配到的是北京
          if (this.areaIndex == 8) {
            // this.bjGetInfo();
            if (
              this.$route.query.thirdDataSource == "DB_WF" ||
              this.thirdDataSource2 == "DB_WF"
            ) {
              this.bjGetInfo();
            } else {
              this.newBjGetInfo();
            }
          }
          // 匹配到的是辽宁
          if (this.areaIndex == 9) {
            this.lnVerifiType = true;
            this.resetType();
          }
          // 匹配到的是辽宁
          if (this.areaIndex == 10) {
            this.gcVerifiType = true;
            this.resetType();
          }
          // 匹配到的是河南
          if (this.areaIndex == 11) {
            this.resetType();
            // 获取身份
            this.hndqGeiInfo();
          }
          // 匹配到的是湖南
          if (this.areaIndex == 12) {
            this.resetType();
            // 获取身份
            this.hunandqGeiInfo();
          }
          // 匹配到的是上海
          if (this.areaIndex == 13) {
            this.shGetInfo();
          }
          // 匹配到的是陕西
          if (this.areaIndex == 14) {
            // this.resetType();
            // // 获取身份
            // this.sxDqnandqGeiInfo();
            if (
              this.$route.query.thirdDataSource == "DB_WF" ||
              this.thirdDataSource2 == "DB_WF"
            ) {
              this.resetType();
              this.sxDqnandqGeiInfo();
            } else {
              this.newSxDqnandqGeiInfo();
            }
          }
          // 匹配到的是河北
          if (this.areaIndex == 15) {
            this.resetType();
            // 获取身份
            this.hebeiDqnandqGeiInfo();
          }
          // 匹配到的是江西
          if (this.areaIndex == 16) {
            this.resetType();
            // 获取身份
            this.jiangxiDqnandqGeiInfo();
          }
          // 匹配到的是山西
          if (this.areaIndex == 17) {
            this.resetType();
            // 获取身份
            this.showShanXiPhone = true;
          }
          // 匹配到的是福建
          if (this.areaIndex == 18) {
            // 获取身份
            this.getFuJianInfo();
          }
          // 匹配到的是四川
          if (this.areaIndex == 19) {
            // 获取身份
            this.getSiChuanInfo();
          }
          // 匹配到的是天津
          if (this.areaIndex == 20) {
            // 获取身份
            this.getTianJinInfo();
          }
          // 匹配到的是黑龙江
          if (this.areaIndex == 21) {
          }
          // 匹配到的是云南
          if (this.areaIndex == 22) {
            // 获取身份
            this.getYunNanInfo();
          }
          // 吉林
          if (this.areaIndex == 23) {
            this.loading.close();
            this.clickQr = false;
            this.jiLinJPhone = true;
            this.changeName();
          }
          // 青海
          if (this.areaIndex == 24) {
            this.loading.close();
            this.clickQr = false;
            this.qingHaiJPhone = true;
            this.changeName();
          }
          // 广东
          if (this.areaIndex == 25) {
            this.loading.close();
            this.clickQr = false;
            this.guangDongJPhone = true;
            this.changeName();
          }
          // 安徽
          if (this.areaIndex == 26) {
            this.anhuiGeiInfo();
          }
          //
        } else {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          this.loading.close();
          return;
        }
      });
    },
    // 陕西授权方式宽古
    newSxDqnandqGeiInfo() {
      let params = {
        companyNo: this.nsrsbhIndex,
        password: this.password,
        programId: this.uuid,
        userId: this.name,
        zq: this.zq,
      };
      zjBreezeLogin(params, this.apiPrefix).then((res) => {
        this.resetType();
        if (res.msgCode == 1) {
          this.shanxiMsgBsr = res.data;
          this.shanxiMsgBsrSelect = true;
        } else {
          this.$message({
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
    newShanxidqChangeNameMsg() {
      this.newShanxiCode = this.shanxiMsgBsr[this.newShanxiInfoListSelectValueMsg].reqid;
      this.shanxiInfoListSelectCode = true;
    },
    newShanxiGetCodeMethod() {
      let params = {
        // czydm: 123,
        czydm: this.newShanxiCode,
        programId: this.uuid,
      };
      newShanxiGetCode(params, this.apiPrefix).then((res) => {
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        this.changeName();
      });
    },
    // 陕西授权方式宽古
    // 陕西授权方式
    shanxidqChangeNameMsg() {
      this.shanxiInfoListSelectCode = true;
      this.changeName();
    },
    sxDqnandqGeiInfo() {
      this.shanxiInfoListSelect = true;
      this.changeName();
    },
    shanxidqChangeName() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.shanxiInfoListSelectValueMsg = "";
      this.shanxiInfoListSelectCode = false;
      this.shanxidqmobileCode = "";
      this.shanxidqcodeContent = "获取验证码";
      this.shanxidqcanClick = true;
      this.totalTime = 60;
      this.changeName();
      let params = {
        taxpayerId: this.name.trim(),
        taxSiteUsername: this.name.trim(),
        area: this.province,
        taxSitePwd: this.password.trim(),
        batchNo: this.batchNo,
        loginType: this.shanxiInfoListSelectValue,
      };
      getIdentityInfoAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == 1) {
          // console.log(res);
          //
          this.shanxiMsg = {};
          //
          this.shanxiCookies = res.data.data.cookies;
          this.shanxiFlag = res.data.data.flag;
          this.shanxiMsg = res.data.data.msg ? JSON.parse(res.data.data.msg) : [];
          this.shanxiInfoListSelectMsg = true;
          // console.log(JSON.parse(res.data.data.msg));
          this.shanxisjid = JSON.parse(res.data.data.msg).sjid;
          this.changeName();
        } else {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          return;
        }
      });
    },
    // 陕西授权方式

    // 湖南
    hunandqgetCode() {
      if (!this.hunandqcanClick) {
        return;
      }
      this.hunandqcanClick = false;
      this.hunanGetCodeMethod();
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.hunandqcodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.hunandqcodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.hunandqcanClick = true;
        }
      }, 1000);
    },
    hunanGetCodeMethod() {
      let params = {
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        cookies: this.hunanCookies,
        taxpayerId: this.name.trim(),
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        if (res.data.data.cookies) {
          this.hunanCookies = res.data.data.cookies;
        } else {
          this.hunanCookies = res.data.cookies;
        }
        this.changeName();
      });
    },
    hunandqGeiInfo() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        taxpayerId: this.name.trim(),
        area: this.province,
        taxSitePwd: this.password.trim(),
        batchNo: this.batchNo,
      };
      getIdentityInfoAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.loading.close();
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.clickQr = true;
            },
          });
          return;
        }
        if (res.data.data && res.data.data.resultCode == 100002) {
          this.$alert(res.data.data.resultMsg, "温馨提示", {
            confirmButtonText: "确定",
          });
          this.changeName();
          this.loading.close();
          return;
        }
        //
        this.hunanNameList = res.data.data.value.info_data;

        this.hunanLoginStatus = res.data.data.login_status;
        this.hunanCookies = res.data.data.cookies;
        this.loading.close();
        this.changeName();
        this.setHunanLoginType(this.hunanLoginStatus);
      });
    },
    setHunanLoginType() {
      // this.hunanLoginStatus = "sfChoose";
      if (this.hunanLoginStatus == "loginSuccess") {
        this.$message.success("验证成功，直接点击按钮授权！");
      }
      if (this.hunanLoginStatus == "sfChoose") {
        // 显示验证码框
        this.hunanInfoListSelect = true;
      }
      this.changeName();
    },
    // 湖南

    // 重庆
    // 输完用户名之后点击这个下一步
    clickQrsCq() {
      if (!this.cqmobile) {
        this.$message("请输入用户名，再点击下一步！");
        return;
      }
      this.getLoginMethodCy();
      this.changeName();
    },
    getLoginMethodCy() {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        taxSiteUsername: this.cqmobile.trim(),
        area: this.province,
        batchNo: this.batchNo,
      };
      getLoginMethod(params).then((res) => {
        // console.log(res);
        this.loading.close();
        this.cqcookies = res.data.data.cookies;
        this.cqLoginType = res.data.data.login_method;
        this.cqVerifiTypeNext = false;
        this.cqVerifiTypeNextCode = true;
        // 这里分两种情况
        // 第一种返回 pwdAuthentication, 走密码的流程
        // 第二种返回，smsAuthentication, 获取验证码
        this.checkCqLoginType();
        this.changeName();
      });
    },
    checkCqLoginType() {
      if (this.cqLoginType == "pwdAuthentication") {
        // 密码方式 获取身份
        this.cqgetIdentityInfoAiPwd();
      }
      if (this.cqLoginType == "smsAuthentication") {
        this.cqVerifiTypeCode = true;
      }
      this.changeName();
    },
    // 点击下一步获取身份信息
    // this.cqgetIdentityInfoAiPwd();
    cqgetIdentityInfoAiPwd(type) {
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        batchNo: this.batchNo,
        area: this.province,
        taxSiteUsername: this.cqmobile.trim(), // 电子税务局账号，多一个框输账号
        taxSitePwd: this.password.trim(),
        taxpayerId: this.name.trim(),
        cookies: this.cqcookies,
        smsCode: this.cqmobileCode, //如果是密码方式，就是空
        loginMethod: this.cqLoginType,
      };
      getIdentityInfoAi(params).then((res) => {
        // console.log(res);
        this.loading.close();
        if (res.msgCode == -1 && this.cqLoginType == "pwdAuthentication") {
          this.cqVerifiTypeNext = true;
          this.cqVerifiTypeNextCode = false;
          return;
        }
        if (res.msgCode == -1) return;

        // console.log(res);
        this.cqcookies = res.data.data.cookies;
        this.cqloginStatusOne = res.data.data.login_status;
        this.cqmobileCode = "";
        // 第二步，直接check 或者 再次获取验证码
        this.changeName();
        this.cqTwoCheck();
      });
    },
    cqTwoCheck() {
      this.cqVerifiTypeNextCode = false;
      if (this.cqloginStatusOne == "sfChoose") {
        this.cqVerifiTypeCode = true;
        // 再次获取验证码
      }
      if (this.cqloginStatusOne == "loginSuccess") {
        // 直接走check
      }
      this.changeName();
    },
    cqgetIdentityInfoAiSms() {},
    clickQrsCqCode() {
      if (!this.cqmobileOne || !this.cqmobileCode) {
        this.$message("请输入手机号和验证码，再点击下一步！");
        return;
      }
      this.cqgetIdentityInfoAiPwd();
    },
    // 重庆
    // 北京授权方式
    bjchangeQcode() {
      this.bjverificationMethod = true;
      let index = this.bjnameInfo.indexOf(this.bjvalue);
      this.bjnameInfoList = this.bjnameListArr[index];
      this.bjNewValue = this.bjvalue;
      this.changeName();
    },
    bjGetInfo() {
      let params = {
        taxpayerId: this.name.trim(),
        taxSiteUsername: this.name.trim(),
        area: this.province,
        taxSitePwd: this.password.trim(),
        batchNo: this.batchNo,
      };
      getIdentityInfoAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.loading.close();
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.clickQr = true;
            },
          });
          return;
        }
        if (res.data.data && res.data.data.resultCode == 100002) {
          this.$alert(res.data.data.resultMsg, "温馨提示", {
            confirmButtonText: "确定",
          });
          this.changeName();
          this.loading.close();
          return;
        }
        this.bjnameList = res.data.data.data.value;
        this.bjcookies = res.data.data.data.cookies;
        for (let i in this.bjnameList) {
          this.bjnameListArr.push(this.bjnameList[i]);
        }
        this.bjgetTaxPerson = true;

        this.loading.close();
        this.clickQr = false;
        this.changeName();
        this.bjnameInfo = this.bjnameListArr.map((item) => {
          return item.key;
        });
      });
    },
    newBjGetInfo() {
      let params = {
        companyNo: this.nsrsbhIndex,
        password: this.password,
        programId: this.uuid,
        userId: this.name,
        zq: this.zq,
      };
      zjBreezeLogin(params).then((res) => {
        // console.log(res);
        this.loading.close();
        if (res.msgCode == 1) {
          this.newBjToken = res.data.token;
          this.newBjList = res.data.czrys;
          this.bjgetTaxPerson = true;
          this.clickQr = false;
          this.changeName();
        } else {
          this.$message({
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
    // 北京授权方式
    xjchangeQcode() {
      let index = this.xjnameInfo.indexOf(this.xjvalue);
      this.xjnameInfoList = this.xjnameListArr[index];
      this.xjshowInfo = this.xjnameInfoList.showInfo;
      this.xjtaxSiteUsername = this.xjnameInfoList.showInfo;
      this.changeName();
    },
    // 新疆
    xjGetInfo() {
      let params = {
        taxpayerId: this.name.trim(),
        area: this.province,
        batchNo: this.batchNo,
      };
      getIdentityInfoAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.loading.close();
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          return;
        }
        this.xjnameList = res.data.data.value.bsrs;
        this.xjSm2k = res.data.data.sm2k;
        this.xjCookies = res.data.data.cookies;
        for (let i in this.xjnameList) {
          this.xjnameListArr.push(this.xjnameList[i]);
        }
        this.xjDzswj_tgc = res.data.data.value.dzswj_tgc;
        this.xjgetTaxPerson = true;

        this.loading.close();
        this.clickQr = false;
        this.changeName();
        this.xjnameInfo = this.xjnameListArr.map((item) => {
          return item.bdgxId;
        });
      });
    },
    resetType() {
      this.loading.close();
      this.clickQr = false;
      this.changeName();
    },
    changeQcode() {
      this.step2 = true;
      let index = this.nameInfo.indexOf(this.value);
      this.nameInfoList = this.nameListArr[index];
      this.zjNewValue = this.value;
      this.newZjBsry = this.newZjBsryList[this.zjNewValue];
      this.changeName();
    },
    toSearchCode() {
      // step3 获取二维码
      let params = {
        acwTc: this.name.trim(),
        area: this.province,
        aliyungfTc: this.password.trim(),
        dtlSessionId: this.dtlSessionId,
        zjUuid: this.value,
        batchNo: this.batchNo,
      };
      getQrCodeAi(params).then((res) => {
        this.step2 = true;
        this.baseImage = res.data.data.resultObj.ewm;
        this.bizNo = res.data.data.resultObj.bizNo;
        this.changeName();
        this.loading2.close();
      });
    },
    toSearchEare() {},
    heiLongJGetInfo() {
      if (!this.heiLongJPhoneCode) {
        this.$message("验证码不能为空！");
        return false;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        cookies: this.heiLongJCookies,
        smsCode: this.heiLongJPhoneCode,
        taxpayerId: this.name.trim(),
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        phone: this.heiLongJMobile,
      };
      getIdentityInfoAi(params).then((res) => {
        this.loading.close();
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo + "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.heiLongJGet = false;
        // 获取授权人员名单
        this.heiLongJinfo_data = res.data.data.data.value.info_data;
        //
        let keyArray = [];
        for (let key in this.heiLongJinfo_data) {
          // console.log(key);
          keyArray.push(key);
        }
        // console.log(keyArray);
        this.keyArray = keyArray;
        //
        this.heiLongJOtherData = res.data.data.data.other_data;
        this.heiLongJCookies = res.data.data.data.cookies;
        this.heiLongJSelect = true;
        this.changeName();
      });
    },
    heiLongJGetCodeMethod() {
      let params = {
        batchNo: this.batchNo,
        area: this.areaList[this.areaIndex],
        taxpayerId: this.name.trim(),
        phone: this.heiLongJMobile,
      };
      breezeCodeAi(params).then((res) => {
        if (res.msgCode == -1) {
          this.$alert(res.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.$message({
          message: "获取验证码成功，请注意查收！",
          type: "success",
        });
        // console.log(res);
        if (res.data.info_data) {
          this.heiLongJInfodata = res.data.info_data;
          this.heiLongJCookies = res.data.cookies;
        } else {
          this.heiLongJInfodata = res.data.data.data.info_data;
          this.heiLongJCookies = res.data.data.data.cookies;
        }
        // console.log(this.heiLongJCookies);
        // console.log(this.heiLongJInfodata);
        this.changeName();
        // 选择身份接口
        this.heiLongJGet = true;
      });
    },
    heiLongJgetCode() {
      if (!this.heiLongJMobile) {
        this.$message({
          message: "请输入手机号！",
          type: "warn",
        });
        return;
      }
      if (!this.heiLongJcanClick) {
        return;
      }
      this.heiLongJcanClick = false;
      this.heiLongJGetCodeMethod();
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.heiLongJcodeContent = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.heiLongJcodeContent = "重发验证码 ";
          this.totalTime = 60;
          this.heiLongJcanClick = true;
        }
      }, 1000);
      this.changeName();
    },
    //
    //
    // keyArrayChange() {
    //   console.log(this.heiLongJidentityValue);
    //   console.log(this.heiLongJinfo_data[this.heiLongJidentityValue])
    // },
  },
  mounted() {
    // Object.keys(this.heiLongJinfo_data);
    this.changeName();
    this.getCompanyName();
    //
    let params = {
      taxpayerId: this.companyNameInfo.nsrsbh,
    };
    getProvinceAi(params).then((res) => {
      // this.loading.close();
      if (res.msgCode == 1) {
        this.province = res.data.province;
        if (this.province == "黑龙江") {
          this.heiLongJPhone = true;
          this.clickQr = false;
          //
          this.batchNo = res.data.batchNo;
          // 判断地区
          let proIndex = this.areaList.findIndex((item) => {
            return item == this.province;
          });
          this.areaIndex = proIndex;
          // console.log(this.areaIndex);
          this.changeName();
        }
        //
        // 匹配到的是浙江，获取身份信息
        if (this.province == "浙江") {
          if (
            this.$route.query.thirdDataSource == "DB_KUANGU" ||
            this.thirdDataSource2 == "DB_KUANGU"
          ) {
            this.areaIndex = 0;
            this.clickQr = false;
            this.kuanGuZjMethod = true;
            this.zJselection = true;
            this.changeName();
          }
        }
      }
    });
  },
};
</script>

<style scoped lang="stylus">
.container {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 28px;
  margin-top: 16px;

  .label.required:before {
    content: "*";
    color: red;
    position: relative;
    right: 4px;
    top: 1px;
  }

  .user {
    display flex
    align-items center
    margin: 24px 0 0 32px;

    .label {
      width 158px
      display inline-block
      text-align right
      padding-right 5px
      position relative
    }

    .input {
      width: 50%;
    }
  }
}

.title {
  color: rgba(0, 0, 0, 0.8);
  padding-left: 16px;
  line-height: 54px;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #eeeeee;
}

.read {
  display: flex;
  align-items: center;
  margin: 20px 0 0 180px;
}

.read .el-checkbox {
  display: flex;
  align-items: center;
}

.active {
  color: #2f54eb;
  cursor: pointer;
}

#selectInfo .el-select {
  width: 50%;
}

.baseImage {
  width: 250px;
  height: 250px;
  margin-left: 156px;
}

.baseImage img {
  width: 100%;
  height: 100%;
}

#step2 {
  margin: 16px 0 0 195px;
}

#step2 .el-input {
  width: 50%;
}

.clickQr {
  width: 104px;
  height: 36px;
  line-height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(47, 84, 235, 1);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
  margin-left: 195px;
  margin-top: 24px;
}

.clickQr:hover {
  background: rgba(47, 84, 235, 1);
  color: #fff;
}

.ease {
  transition: all 0.3s;
}

#code .input {
  width: calc(50% - 126px);
}

.mobile-code {
  position: relative;
  left: 178px;
}

.code-flex {
  display: flex;
  align-items: center;
}

.code-btn {
  width: 116px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  margin-left: 8px;
  cursor: not-allowed;
  border: 1px solid #dcdfe6;
  color: #dcdfe6;
}

.actives {
  width: 116px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid rgba(47, 84, 235, 1);
  color: rgba(47, 84, 235, 1);
  margin-left: 8px;
  cursor: pointer;
}

.actives:hover {
  background: rgba(47, 84, 235, 1);
  color: #fff;
}

.code-text {
  /* position: relative;
  right: 6px; */
}

.baseImage-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 8px;
}

.baseImage-icon img {
  width: 100%;
  height: 100%;
}

.baseImage-c {
  display: flex;
  align-items: center;
}

.baseImage-icon-c {
  display: flex;
  align-items: center;
}

.selection {
  margin: 16px 0 0 113px;
}

.read >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #2f54eb;
}

.cq-mobile-input {
  position: relative;
  left: 0;
}

.mobile-input-nm {
  left: 0px;
}

.input-label-flex {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.icon-zq {
  position: absolute;
  top: 13px;
  left: 158px;
  color: #d9d9d9;
  font-size: 16px;
  cursor: pointer;
}

.input-label {
  width: 300px;
  text-align: right;
}

.get-selectInfo {
  color: #2f54eb;
  cursor: pointer;
  margin-left: 16px;
}

.labelQcode {
  width: 250px;
  height: 250px;

  img {
    width: 100%;
  }
}

.container .selectInfoQr {
  margin-top: 0;
}
</style>
