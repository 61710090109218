import {cloneDeep} from "lodash";

/**
 * 根据indexes找到data里对应的子节点
 * @param {TreeDataNode[]} treeData
 * @param {number[]} indexes
 * @returns {*}
 */
export function findTheNode(treeData, indexes) {
    if (treeData == null) {
        return null
    }
    indexes = [...indexes]
    let node

    function findNode(data, indexes) {
        const index = indexes.shift()
        if (indexes.length >= 0 && index !== null) {
            node = data[index]
            if (!node) {
                return null
            } else if (node.children && indexes.length > 0) {
                findNode(node.children, indexes)
            }
        }
    }

    findNode(treeData, indexes)
    return node
}

/**
 * @description 判断两个对象是否相同
 * @param {Object} val0
 * @param {Object} val1
 */
export function isSame(val0, val1) {


    return JSON.stringify(val0) === JSON.stringify(val1)
}

/**
 * 根据属性名和属性值查找节点
 * @param treeData
 * @param attr
 * @param value
 * @returns {*}
 */
export function findNode(treeData, attr, value) {
    let resultNode = null

    function walk(theNode) {
        for (const theChildNode of theNode.children) {
            if (theChildNode[attr] == value) {
                resultNode = theChildNode
                break
            } else {
                walk(theChildNode)
            }
        }
    }

    for (const theNode of treeData) {
        if (theNode[attr] == value) {
            resultNode = theNode
            break
        } else {
            walk(theNode)
        }
    }
    return resultNode
}
