<template>
  <div>
    <div class="wrapper">
      <div class="tabs">
        <!-- <div class="tab-home">
          <i class="el-icon-s-home"></i>
        </div>-->
        <div
          class="tab"
          :class="{ active: activeTab == 0 }"
          @click="switchTab(0)"
          v-if="reportType != 3"
        >
          综合风险评估
        </div>
        <!-- <div
          class="tab"
          :class="{ active: activeTab == 1 }"
          @click="switchTab(1)"
        >
          优惠政策提醒
        </div> -->
      </div>
      <div class="btns-wrapper" :class="{ reportTypeClass: reportType == 3 }">
        <!-- <AwaitButton
          @click="previewPdf"
          v-if="$store.state.ResultEditor.openMode === 'threeYears'"
          class="btn btn-primary"
          >预览(新)
        </AwaitButton> -->

        <!--        <AwaitButton :icon="true" @click="handleBtnStatusChange" data-status-to="1019"-->
        <!--          ><i class="icon iconfont iconNuclear"></i> 一键还原(测试用)-->
        <!--        </AwaitButton>-->
        <!--        <AwaitButton :icon="true" @click="handleBtnStatusChange" data-status-to="1024"-->
        <!--          ><i class="icon iconfont iconNuclear"></i> 审批通过(测试用)-->
        <!--        </AwaitButton>-->

        <!--1.项目经理未保存     1019-->
        <template v-if="ReviewStatus == 1019">
          <!--审核老师-->
          <template v-if="action == 'censor'">
            <button class="btn disabled">
              <i class="icon iconfont iconicon_chulizhong"></i> 项目经理处理中...
            </button>
          </template>
          <!--/审核老师-->
          <!--项目经理-->
          <template v-else-if="action == 'edit'">
            <AwaitButton
              :icon="true"
              @click="handleBtnSaveClick"
              :disabled="!isContentModified"
              data-status-to="1020"
            >
              <i class="icon iconfont iconicon_baocun"></i>保存
            </AwaitButton>

            <AwaitButton
              :icon="true"
              @click="handleBtnDownloadWithoutCensor"
              :disabled="isContentModified"
              data-status-to="1026"
              ><i class="icon iconfont iconicon_download"></i> 无需审核/下载
            </AwaitButton>
          </template>
          <!--/项目经理-->
        </template>
        <!--/1.项目经理未保存     1019-->
        <!--2.项目经理已保存未提交   1020-->
        <template v-else-if="ReviewStatus == 1020">
          <!--审核老师-->
          <template v-if="action == 'censor'">
            <button class="btn disabled">
              <i class="icon iconfont iconicon_chulizhong"></i> 项目经理处理中...
            </button>
          </template>
          <!--/审核老师-->
          <!--项目经理-->
          <template v-else-if="action == 'edit'">
            <AwaitButton
              :icon="true"
              @click="handleBtnSaveClick"
              data-status-to="1020"
              :disabled="!isContentModified"
            >
              <i class="icon iconfont iconicon_baocun"></i>保存
            </AwaitButton>
            <AwaitButton :icon="true" @click="handleBtnStatusChange" data-status-to="1021"
              ><i class="icon iconfont iconicon_tjxg"></i> 提交修改
            </AwaitButton>
          </template>
          <!--/项目经理-->
        </template>
        <!--/2.项目经理已保存未提交   1020-->
        <!--3.项目经理已提交,审核老师未保存，未提交  1021-->
        <template v-else-if="ReviewStatus == 1021">
          <!--审核老师-->
          <template v-if="action == 'censor'">
            <AwaitButton
              :icon="true"
              @click="handleBtnSaveClick"
              :disabled="!isContentModified"
              data-status-to="1025"
            >
              <i class="icon iconfont iconicon_baocun"></i>保存
            </AwaitButton>

            <AwaitButton
              :icon="true"
              @click="handleSensorPassChange"
              :disabled="isContentModified"
              data-status-to="1024"
              ><i class="icon iconfont iconicon_queren"></i>确认
            </AwaitButton>
          </template>
          <!--/审核老师-->
          <!--项目经理-->
          <template v-else-if="action == 'edit'">
            <button class="btn disabled">
              <i class="icon iconfont iconicon_chulizhong"></i> 审核老师处理中...
            </button>
          </template>
          <!--/项目经理-->
        </template>
        <!--/3.项目经理已提交,审核老师未保存，未提交  1021-->
        <!--4.审核老师已保存，未提交   1025-->
        <template v-else-if="ReviewStatus == 1025">
          <!--审核老师-->
          <template v-if="action == 'censor'">
            <AwaitButton
              :icon="true"
              @click="handleBtnSaveClick"
              :disabled="!isContentModified"
              data-status-to="1025"
            >
              <i class="icon iconfont iconicon_baocun"></i>保存
            </AwaitButton>
            <AwaitButton :icon="true" @click="handleBtnStatusChange" data-status-to="1022"
              ><i class="icon iconfont iconicon_tjxg"></i> 提交修改
            </AwaitButton>
          </template>
          <!--/审核老师-->
          <!--项目经理-->
          <template v-else-if="action == 'edit'">
            <button class="btn disabled">
              <i class="icon iconfont iconicon_chulizhong"></i> 审核老师处理中...
            </button>
          </template>
          <!--/项目经理-->
        </template>
        <!--/4.审核老师已保存，未提交   1025-->
        <!--5.审核老师已提交，项目经理待确认或者未保存未提交 1022-->
        <template v-else-if="ReviewStatus == 1022">
          <!--审核老师-->
          <template v-if="action == 'censor'">
            <button class="btn disabled">
              <i class="icon iconfont iconicon_chulizhong"></i> 项目经理处理中...
            </button>
          </template>
          <!--/审核老师-->
          <!--项目经理-->
          <template v-else-if="action == 'edit'">
            <AwaitButton
              :icon="true"
              @click="handleBtnSaveClick"
              :disabled="!isContentModified"
              data-status-to="1027"
            >
              <i class="icon iconfont iconicon_baocun"></i>保存
            </AwaitButton>
            <AwaitButton
              :icon="true"
              @click="handleBtnStatusChange"
              :disabled="isContentModified"
              data-status-to="1023"
            >
              <i class="icon iconfont iconicon_queren"></i>确认
            </AwaitButton>
          </template>
          <!--/项目经理-->
        </template>
        <!--/5.审核老师已提交，项目经理待确认或者未保存未提交 1022-->
        <!--6.审核老师已提交，项目经理已保存未提交   1027-->
        <template v-else-if="ReviewStatus == 1027">
          <!--审核老师-->
          <template v-if="action == 'censor'">
            <button class="btn disabled">
              <i class="icon iconfont iconicon_chulizhong"></i> 项目经理处理中...
            </button>
          </template>
          <!--/审核老师-->
          <!--项目经理-->
          <template v-else-if="action == 'edit'">
            <AwaitButton
              :icon="true"
              @click="handleBtnSaveClick"
              data-status-to="1027"
              :disabled="!isContentModified"
            >
              <i class="icon iconfont iconicon_baocun"></i>保存
            </AwaitButton>
            <AwaitButton :icon="true" @click="handleBtnStatusChange" data-status-to="1021"
              ><i class="icon iconfont iconicon_tjxg"></i> 提交修改
            </AwaitButton>
          </template>
          <!--/项目经理-->
        </template>
        <!--/6.审核老师已提交，项目经理已保存未提交   1027-->

        <!--7.项目经理已确认。审核老师最终确认   1023-->
        <template v-else-if="ReviewStatus == 1023">
          <!--审核老师-->
          <template v-if="action == 'censor'">
            <AwaitButton
              :icon="true"
              @click="handleBtnSaveClick"
              :disabled="!isContentModified"
              data-status-to="1025"
            >
              <i class="icon iconfont iconicon_baocun"></i>保存
            </AwaitButton>
            <AwaitButton :icon="true" @click="handleSensorPassChange" data-status-to="1024"
              >审核通过
            </AwaitButton>
          </template>
          <!--/审核老师-->
          <!--项目经理-->
          <template v-else-if="action == 'edit'">
            <button class="btn disabled" :disabled="true">审核老师审核中...</button>
          </template>
          <!--/项目经理-->
        </template>
        <!--/7.项目经理已确认。审核老师最终确认   1023-->
        <!--8.完成  1024||1026-->
        <template v-else-if="ReviewStatus == 1024 || ReviewStatus == 1026">
          <!--审核老师-->
          <template v-if="action == 'censor'">
            <AwaitButton
              class="cxsh"
              style="background: #2663f6"
              :icon="true"
              @click="handleBtnStatusChange"
              data-status-to="1019"
            >
              <i class="icon iconfont iconicon_cxsh"></i>
              重新审核
            </AwaitButton>

            <!-- +++++ -->
            <AwaitButton class="btn btn-primary">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="icon iconfont iconicon_download"></i>
                  预览/下载
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  v-if="$store.state.ResultEditor.openMode === 'threeYears'"
                >
                  <el-dropdown-item>
                    <span @click="dowloadOldPdfCheck">
                      <i class="icon iconfont iconicon_jianjieban"></i>简洁版</span
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span @click="dowloadPdfCheck">
                      <i class="icon iconfont iconicon_quanliangban"></i>全量版</span
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
                <el-dropdown-menu slot="dropdown" v-else>
                  <el-dropdown-item>
                    <span @click="dowloadOldPdfCheck">
                      <i class="icon iconfont iconicon_jianjieban"></i>简洁版</span
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </AwaitButton>
            <!-- <template v-if="$store.state.ResultEditor.openMode === 'threeYears'">
              <button @click="dowloadPdfCheck" class="btn btn-primary">下载(新)</button>
              <button @click="dowloadOldPdfCheck" class="btn btn-primary">下载(旧)</button>
            </template>
            <template v-else>
              <button @click="dowloadPdfCheck" class="btn btn-primary">下载</button>
            </template> -->
          </template>
          <!--/审核老师-->
          <!--项目经理-->
          <template v-else-if="action == 'edit'">
            <AwaitButton
              class="cxsh"
              :icon="true"
              @click="handleBtnStatusChange"
              data-status-to="1019"
              style="background: #fff; color: #2663f6; border: 1px solid #2663f6"
              v-if="isSuperRole"
            >
              <i class="icon iconfont iconicon_cxsh" style="color: #2663f6"></i>
              重新审核
            </AwaitButton>
            <!-- +++++ -->
            <AwaitButton class="btn btn-primary">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="icon iconfont iconicon_download"></i>
                  预览/下载
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  v-if="$store.state.ResultEditor.openMode === 'threeYears'"
                >
                  <el-dropdown-item>
                    <span @click="dowloadOldPdfCheck"
                      ><i class="icon iconfont iconicon_jianjieban"></i>简洁版</span
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span @click="dowloadPdfCheck">
                      <i class="icon iconfont iconicon_quanliangban"></i>全量版</span
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
                <el-dropdown-menu slot="dropdown" v-else>
                  <el-dropdown-item>
                    <span @click="dowloadOldPdfCheck">
                      <i class="iconfont iconicon_jianjieban"></i>简洁版</span
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </AwaitButton>
            <!-- <template v-if="$store.state.ResultEditor.openMode === 'threeYears'">
              <button @click="dowloadPdfCheck" class="btn btn-primary">下载(新)</button>
              <button @click="dowloadOldPdfCheck" class="btn btn-primary">下载(旧)</button>
            </template>
            <template v-else>
              <button @click="dowloadPdfCheck" class="btn btn-primary">下载</button>
            </template> -->
          </template>
          <!--/项目经理-->
        </template>
        <!--/8.完成  1024||1026-->
        <button @click="dowloadPdfCheck" v-if="$route.query.fromAdmin == 1" class="btn btn-primary">
          管理员直接下载
        </button>
      </div>
    </div>
    <DesensitizationDialog
      ref="DesensitizationDialog"
      @confirm="handleDesensitizationDialogConfirm"
    ></DesensitizationDialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { saveAs } from "file-saver";
import { mapState, mapActions } from "vuex";
import { updatePdfCatalogData, createPdf } from "@/api/shiwusuo";
import AwaitButton from "await-button";
import DesensitizationDialog from "./DesensitizationDialog";
import { getCheckLogList } from "../../../api/shiwusuo";
import { byTokenGetUser, getDesensitizationStatus, getIndex } from "../../../api/api";
import "mdn-polyfills/MouseEvent";

const pdfBase = process.env.VUE_APP_PDF_ROOT;
export default {
  props: {
    reportType: [Number, String],
  },
  components: {
    DesensitizationDialog,
    AwaitButton,
  },
  async mounted() {
    // setTimeout(() => {
    //  this.changeStatus(1019);
    // }, 2000);
    //载入用户信息
    const res = await byTokenGetUser();
    this.userInfo = res.data.user;
    console.log(this.ReviewStatus);
    console.log(this.action);
    // setTimeout(() => {
    //   debugger;
    // }, 2000);
  },
  data: function () {
    return {
      activeTab: 0,
      desensitizationAvailable: false,
      userInfo: null,
      forceOldDownload: false,
      statistics: null,
      isold: true,
    };
  },
  methods: {
    ...mapActions("ResultEditor", ["updateReviewStatus", "updateUnReviewStatus", "refresh"]),
    async previewPdf() {
      this.downloadPdf(true);
    },
    //无需审核下载PDF
    async dowloadPdfCheck(isold, isPreview = false) {
      /**
       * 是否修改了
       * @type {*}
       */
      getIndex(localStorage.getItem("authorization")).then((res) => {
        this.statistics = res.data;
        // if (this.statistics.checkNumber == 0 || !this.statistics.checkNumber) {
        //   this.$confirm(
        //     "您的检测次数已用完，暂无下载权限，请联系您的专属客户经理续费后即可下载报告！",
        //     "提示",
        //     {
        //       confirmButtonText: "确定",
        //       type: "warning",
        //     }
        //   );
        // } else {
          this.dowloadPdfCheckNew(isold);
        // }
      });
    },
    async dowloadPdfCheckNew(e) {
      const resData = await getCheckLogList({
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
      });
      //脱敏报告是否可用
      const desensitizationRes = await getDesensitizationStatus({
        uuid: this.$route.params.uuid,
      });
      if (e == "old") {
        this.isold = true;
      } else {
        this.isold = false;
      }
      if (desensitizationRes.data && resData.data && resData.data.length == 0) {
        await this.$refs["DesensitizationDialog"].open();
      } else {
        await this.downloadPdf(e);
      }
    },

    handleDesensitizationDialogConfirm(isDesensitization) {
      if (this.isold) {
        this.downloadPdf("old");
      } else {
        this.downloadPdf("new");
      }
      this.$refs["DesensitizationDialog"].close();
    },
    //无需审核下载PDF/下载（旧）
    async dowloadOldPdfCheck(e) {
      // this.forceOldDownload = true;
      this.isold = true;
      await this.dowloadPdfCheck("old", false);
    },
    async downloadOldPdf() {
      const loading = this.$loading({
        lock: true,
        text: "PDF下载中,请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const resCreatePdf = await createPdf({
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
      });
      loading.close();
      const a = document.createElement("a");
      a.href = this.downloadUrl;
      if (this.$refs["DesensitizationDialog"].form.desensitization) {
        a.href = a.href + "&desensitizationStatus=1";
      } else {
        a.href = a.href + "&desensitizationStatus=0";
      }

      const event = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      a.dispatchEvent(event);
    },
    async downloadPdf(isold, isPreview = false) {
      //三年期打开报告的方式
      if (this.$store.state.ResultEditor.openMode === "threeYears" && !this.forceOldDownload) {
        const isDesensitization = this.$refs["DesensitizationDialog"].form.desensitization ? 1 : 0;

        var token = "";
        if (window.location.href.indexOf("fromHouTai") > -1) {
          token = localStorage.getItem("authorizationFromHouTai");
        } else {
          token = localStorage.getItem("authorization");
        }
        let pdfDownloadUrl;
        if (isold == "new" || isold == undefined) {
          // pdfDownloadUrl = `http://192.168.0.192:80/#/new/${this.$route.params.uuid}/${this.$route.params.nsrsbh}/${token}/${isDesensitization}`;
          pdfDownloadUrl = `${pdfBase}/#/new/${this.$route.params.uuid}/${this.$route.params.nsrsbh}/${token}/${isDesensitization}`;
        } else if (isold == "old") {
          // pdfDownloadUrl = `http://192.168.0.192:80/#/old/${this.$route.params.uuid}/${this.$route.params.nsrsbh}/${token}/${isDesensitization}`;
          pdfDownloadUrl = `${pdfBase}/#/old/${this.$route.params.uuid}/${this.$route.params.nsrsbh}/${token}/${isDesensitization}`;
        } else {
          pdfDownloadUrl = `${pdfBase}/#/new/${this.$route.params.uuid}/${this.$route.params.nsrsbh}/${token}/${isDesensitization}`;
        }
        if (isPreview) {
          pdfDownloadUrl = `${pdfDownloadUrl}/1`;
        }

        window.open(
          pdfDownloadUrl,
          "_blank" // <- This is what makes it open in a new window.
        );
        // this.$emit("previewThreeYearsPdf",{
        //   desensitization:this.$refs["DesensitizationDialog"].form.desensitization
        // })
      } else {
        const loading = this.$loading({
          lock: true,
          text: "PDF下载中,请稍后",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        const resCreatePdf = await createPdf({
          nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          uuid: this.$route.params.uuid,
        });
        loading.close();
        const a = document.createElement("a");
        a.href = this.downloadUrl;
        if (this.$refs["DesensitizationDialog"].form.desensitization) {
          a.href = a.href + "&desensitizationStatus=1";
        } else {
          a.href = a.href + "&desensitizationStatus=0";
        }

        const event = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        a.dispatchEvent(event);
      }
      this.forceOldDownload = false;
    },
    /* 无需审核下载 */
    async handleBtnDownloadWithoutCensor(e) {
      getIndex(localStorage.getItem("authorization")).then((res) => {
        this.statistics = res.data;
        // if (this.statistics.checkNumber == 0 || !this.statistics.checkNumber) {
        //   this.$confirm(
        //     "您的检测次数已用完，暂无下载权限，请联系您的专属客户经理续费后即可下载报告！",
        //     "提示",
        //     {
        //       confirmButtonText: "确定",
        //       type: "warning",
        //     }
        //   );
        // } else {
          this.handleBtnDownloadWithoutCensorOk(e);
        // }
      });
    },
    async handleBtnDownloadWithoutCensorOk(e) {
      //审核通过则无法修改报告内容,是否确定通过
      try {
        await this.$confirm("无需审核下载将会越过审核流程,是否确定下载", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        //更新审核状态

        const status = e.target.dataset.statusTo;
        const resData = await this.changeUnReviewStatus(status);
        //debugger
        if (resData.msgCode == 1) {
          await this.dowloadPdfCheck("new", e);
        } else {
          this.$alert(resData.msgInfo);
        }
      } catch (error) {
        if (error != "cancel") {
          throw error;
        }
      }
    },

    async handleBtnSaveClick(e) {
      let data = this.convertTreeToList();

      data.push(this.overall);

      try {
        const resData = await updatePdfCatalogData(data, this.$route.params.action);

        if (resData.msgCode == "1") {
          //更新审核状态
          const status = e.target.dataset.statusTo;

          await this.changeStatus(status);

          this.$alert("保存成功", "提示", {
            type: "success",
          }).finally(() => {
            this.reload();
            //location.reload();
          });
        } else {
          this.$alert(resData.msgInfo, "提示", {
            type: "error",
          });
        }
      } catch (error) {
        throw error;
      }
    },
    async handleBtnStatusChange(e) {
      const status = e.target.dataset.statusTo;
      await this.changeStatus(status);
    },
    async handleSensorPassChange(e) {
      try {
        await this.$confirm("审核通过则无法修改报告内容,是否确定通过", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        await this.handleBtnStatusChange(e);
      } catch (error) {
        if (error != "cancel") {
          throw error;
        }
      }
    },
    //无需审核更改状态
    async changeUnReviewStatus(status) {
      const action = this.$route.params.action;
      if (!status) {
        alert("error");
      }
      const resData = await this.updateUnReviewStatus({
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        status: status,
        action: action,
      });

      return resData;
    },
    async changeStatus(status) {
      const action = this.$route.params.action;
      if (!status) {
        alert("error");
      }
      await this.updateReviewStatus({
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        status: status,
        action: action,
      });
    },
    convertTreeToList() {
      let ret = [];

      function convert(list) {
        list.forEach((item) => {
          ret.push(item);
          if (item.children && item.children.length > 0) {
            convert(item.children);
          }
          delete item.children;
        });
      }

      convert(cloneDeep(this.treeData));
      //convert(cloneDeep(this.discountPolicyTree));
      ret = cloneDeep(ret).map((v) => {
        if (v.id < 0) {
          v.id = null;
        }
        return v;
      });

      return ret;
    },
    /*重新加载数据*/
    async reload() {
      await this.refresh({
        uuid: this.$route.params.uuid,
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        action: this.$route.params.action,
        isSimple: 0,
      });
    },
    switchTab(id) {
      this.activeTab = id;
      this.$emit("switchTab", id);
    },
  },

  computed: {
    ...mapState("ResultEditor", [
      "treeData",
      "overall",
      "isDataModified",
      "ReviewStatus",
      "discountPolicyTree",
      "downloadUrl",
    ]),
    isSuperRole() {
      return (
        this.userInfo &&
        this.userInfo.authorities &&
        this.userInfo.authorities.findIndex((v) => v.remark == 1) >= 0
      );
    },
    action() {
      return this.$route.params.action;
    },

    isContentModified() {
      return this.isDataModified;
    },
  },
};
</script>

<style lang="stylus" scoped>
.tabs {
  display: flex;

  .tab {
    width: 180px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
  }

  .tab-home {
    width: 30px;
    font-size: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active {
    color: rgba(47, 84, 235, 1);
    border-bottom: 4px solid rgba(47, 84, 235, 1);
    box-sizing: border-box;
  }
}


.wrapper {
  z-index: 209;
  display: flex;
  justify-content: space-between;
  //box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;

  .btns-wrapper {
    //padding: 12px 0;
    display: flex;

    .btn {
      background: rgba(38, 99, 246, 1);
      border-radius: 4px;
      padding: 8px 24px;
      font-size: 14px;
      line-height: 1.5;
      color: #fff;
      margin: 0 8px;
      display flex
      align-items center
    }

    .btn:hover {
      background #0f3bec;
    }

    .btn.disabled {
      background: rgba(217, 217, 217, 1);
      cursor: not-allowed;
    }
  }
}

.reportTypeClass {
  position: fixed;
  background: none;
  box-shadow: 0 0px 0px;
  right: 170px;
  //top: -10px;
  margin: 9px auto
}

.btn .cxsh {
  background: #fff;
  color: #2663F6;
  //border-color: #2663F6
}

.el-dropdown {
  color: #fff;
}

>>> .el-dropdown-menu .el-popper {

}

>>> .el-dropdown-menu {
  padding: 8px
  top: 38px
}

>>> .el-dropdown-menu__item {
  color: #1D2129
}

>>> .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #F6F8FA;
  color: #1D2129;
}

>>> .wrapper .btns-wrapper .btn {
  padding: 8px 16px;
}

>>> .iconfont {
  font-size: 14px;
}

.btn:hover {

}

.icon {
  margin-right 5px
}
</style>
