import axios, {
  get,
  post
} from "@/axios";
import store from "@/store";

const base = process.env.VUE_APP_API_ROOT;

/**
 * 企业基本信息
 * @param {{
 *   programId:string
 * }} params
 * @returns {Promise<*>}
 */
export const getCompanyInfo = async (params) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getCompanyInfo`;
  const res = await get(url, params);
  return res.data;
};
//评分
export const getCompositeScoreFun = async ({programId}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getCompositeScore`;
  const res = await get(url, {programId});
  return res.data;
};

/**
 * 获取风险公司地区集合
 * @param {{
 * endDate?:string,
 * programId:string,
 * startDate?:string
 * }} params
 * @returns {Promise<*>}
 */
export const getCompanyAreaList = async (params) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getCompanyAreaList`;
  const res = await post(url, params);
  return res.data;
};

/**
 * 获取上下游网格图片数据
 * {{
 *   endDate?: string,
 *   endMoney?: number,
 *   programId: string,
 *   showCompanyRisk?: boolean,
 *   showRiskListSize?: number,
 *   startDate?: string,
 *   startMoney?: 0
 * }}
 * @returns {Promise<*>}
 */
export const getCompanyRiskImage = async ({
                                            endDate,
                                            endMoney,
                                            programId,
                                            showCompanyRisk,
                                            showRiskListSize,
                                            startDate,
                                            startMoney,
                                            desensitizationStatus
                                          }) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getCompanyRiskImage`;
  const res = await post(url, {
    endDate,
    endMoney,
    programId,
    showCompanyRisk,
    showRiskListSize,
    startDate,
    startMoney,
    desensitizationStatus
  });
  return res.data;
};

export const getKeyFinancialRatios = async ({programId}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getKeyFinancialRatios`;
  const res = await get(url, {
    programId
  });
  return res.data;
}

/**
 * 获取风险公司集合
 * @param {string} programId
 * @param {number} companyType
 * @param {string} companyName
 * @param {string} endDate
 * @param {number} endMoney
 * @param {number} endMoneyRanking
 * @param {Array<string>>}judicialRiskList
 * @param {Array<string>>} managementRiskList
 * @param {Array<string>>} companyArea
 * @param {string} startDate
 * @param {string} startMoney
 * @param {number} startMoneyRanking
 * @param {Array<string>>} taxationRiskList
 * @returns {Promise<*>}
 */
export const getCompanyRiskList = async ({
                                           programId,
                                           companyType,
                                           companyName,
                                           endDate,
                                           endMoney,
                                           endMoneyRanking,
                                           judicialRiskList,
                                           managementRiskList,
                                           companyArea,
                                           startDate,
                                           startMoney,
                                           startMoneyRanking,
                                           taxationRiskList,
                                           desensitizationStatus
                                         }) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getCompanyRiskList`;
  const res = await post(url, {
    programId,
    companyType,
    companyName,
    endDate,
    endMoney,
    endMoneyRanking,
    judicialRiskList,
    managementRiskList,
    companyArea,
    startDate,
    startMoney,
    startMoneyRanking,
    taxationRiskList,
    desensitizationStatus
  });
  return res.data;

}

/**
 * 单独企业风险信息
 * @param taxNo
 * @returns {Promise<*>}
 */
export const getCompanyRiskInfo = async ({taxNo}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getCompanyRiskInfo`;
  const res = await get(url, {
    taxNo
  });
  return res.data;

}
/**
 * 获取财税风险
 * @param programId
 * @returns {Promise<*>}
 */
export const getFinancialRisk = async ({programId,grade}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getFinancialRisk`;
  const res = await get(url, {
    programId,grade
  });
  return res.data;
}

/**
 * 购销金额对比
 * @param taxNo
 * @param zq
 * @returns {Promise<*>}
 */
export const getGXAmountCompare = async ({taxNo, zq, programId}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getGXAmountCompare`;
  const res = await get(url, {
    taxNo, zq, programId
  });
  return res.data;
}


/**
 * 购销品类对比
 * @param taxNo
 * @param zq
 * @param topCount
 * @returns {Promise<*>}
 */
export const getGXCategoryAnalysis = async ({taxNo, zq, topCount, programId}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getGXCategoryAnalysis`;
  const res = await get(url, {
    taxNo, zq, topCount, programId
  });
  return res.data;
}

/**
 * 获取公司风险品类
 * @param taxNo
 * @param year
 * @param showSize
 * @param companyType
 * @returns {Promise<*>}
 */
export const getCompanyTopClassRisk = async ({taxNo, year, showSize, companyType, zq}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getCompanyTopClassRisk`;
  const res = await get(url, {
    taxNo, year, showSize, companyType, zq
  });
  return res.data;
}

/**
 * 获取财务杜邦分析
 * @param programId
 * @returns {Promise<*>}
 */
export const getDupont = async ({programId}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getDupont`;
  const res = await get(url, {
    programId
  });
  return res.data;
}

/**
 * 获得风险公司下拉列表
 * @param companyType
 * @param programId
 * @param companyName
 * @returns {Promise<*>}
 */
export const getRiskCompanyList = async ({companyType, programId, companyName}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getRiskCompanyList`;
  const res = await get(url, {companyType, programId, companyName});
  return res.data;
}


/**
 * 得到报告参数
 * @param programId
 * @returns {Promise<*>}
 */
export const getReportPara = async ({programId}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getPtRuleList`;
  const res = await get(url, {programId});
  return res.data;
}
/**
 * 历史纳税情况
 * @param companyType
 * @param programId
 * @param companyName
 * @returns {Promise<*>}
 */
export const getTaxPayment = async ({programId}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/getTaxPayment`;
  const res = await get(url, {programId});
  return res.data;
}

// 获取关联方交易图表
export const getRelatedPartyApi = async ({programId,relatedPartyCode,relatedYear}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/relatedPartyApi/getRelatedParty`;
  const res = await get(url, {programId,relatedPartyCode,relatedYear});
  return res.data;
}

// 企业关联方债资比指标
export const getDEBTApi = async ({programId,relatedPartyCode}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/relatedPartyApi/getRelatedPartyWarning/DEBT`;
  const res = await get(url, {programId,relatedPartyCode});
  return res.data;
}

// 企业关联方进项指标
export const getENTRYApi = async ({programId,relatedPartyCode}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/relatedPartyApi/getRelatedPartyWarning/ENTRY`;
  const res = await get(url, {programId,relatedPartyCode});
  return res.data;
}

// 企业关联方其他指标
export const getOTHERApi = async ({programId,relatedPartyCode}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/relatedPartyApi/getRelatedPartyWarning/OTHER`;
  const res = await get(url, {programId,relatedPartyCode});
  return res.data;
}

// 企业关联方销项指标
export const getSALESApi = async ({programId,relatedPartyCode}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/relatedPartyApi/getRelatedPartyWarning/SALES`;
  const res = await get(url, {programId,relatedPartyCode});
  return res.data;
}

// 企业关联方申报预警
export const getWARNINGApi = async ({programId}) => {
  const url = `${base}/taxfirm/longPeriodFiscal/relatedPartyApi/getRelatedPartyWarning/WARNING`;
  const res = await get(url, {programId});
  return res.data;
}