<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          <el-breadcrumb-item>检测记录</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">检测记录</template>
      </main-header>
    </div>
    <el-form class="filter-body" @submit.native.prevent="search">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">客户名称：</div>
            <el-input
              class="standard-input input"
              v-model="filter.consumer"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeConsumer"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">项目名称：</div>
            <el-input
              class="input"
              v-model="filter.projectName"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeProject"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">审核状态：</div>
            <div class="form-control">
              <el-select
                class="input"
                v-model="filter.reviewStatus"
                clearable
                placeholder="请选择"
                @change="search"
              >
                <el-option
                  v-for="item in sencorStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="form-item">
            <div class="label">生成状态：</div>
            <div class="form-control">
              <el-select
                class="input"
                v-model="filter.showStatus"
                clearable
                placeholder="请选择"
                @change="search"
              >
                <el-option
                  v-for="item in generateStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="form-item large">
            <div class="label">检测时间：</div>
            <el-date-picker
              class="input"
              v-model="filter.submitTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="search"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <AwaitButton class="btn btn-primary btn-look-up" @click="search" style="width: 82px">
          查询
        </AwaitButton>

        <!-- <button class="btn btn-primary btn-look-up" @click="reset">重置</button> -->
      </div>
    </el-form>
    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column width="70px" prop="id" label="#"></el-table-column>
          <el-table-column width="10"></el-table-column>
          <el-table-column
            prop="exe_time"
            label="检测时间"
            min-width="12%"
            :formatter="dateFormatter"
            :show-overflow-tooltip="true"
            sortable
          ></el-table-column>

          <el-table-column
            prop="project_name"
            label="项目名称"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="company_name"
            label="客户名称"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column prop="zqlx" label="检测类型" min-width="10%"></el-table-column>
          <el-table-column prop="zq_mc" label="报表征期" min-width="10%"></el-table-column>
          <!-- <el-table-column prop="tag" label="检测版本"></el-table-column> -->
          <el-table-column prop="reviewer" label="生成状态" min-width="10%">
            <template slot-scope="scope">
              <div v-if="scope.row.show_status == 1" class="text-blue">
                <span class="circle blue"></span> 已生成
              </div>
              <div v-if="scope.row.show_status == 2" class="text-gray-darker">
                <span class="circle gray-darker"></span> 生成中
              </div>
              <div v-if="scope.row.show_status == 3" class="text-gray">
                <span class="circle gray"></span> 已删除
              </div>
              <div v-if="scope.row.show_status == 4" class="text-yellow">
                <span class="circle yellow"></span> 待完善
              </div>
              <div v-if="scope.row.show_status == 5" class="text-red">
                <span class="circle red"></span> 生成出错
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="review_status" label="审核状态" sortable min-width="13%">
            <template slot-scope="scope">
              <div v-if="scope.row.review_status == 1019"></div>
              <div v-if="scope.row.review_status == 1020">
                <span class="circle yellow"></span> 编辑中
              </div>
              <div v-if="scope.row.review_status == 1021">
                <span class="circle purple"></span> 待审核
              </div>
              <div v-if="scope.row.review_status == 1025">
                <span class="circle yellow"></span> 审核中
              </div>
              <div v-if="scope.row.review_status == 1022">
                <span class="circle cyan"></span> 待处理
              </div>
              <div v-if="scope.row.review_status == 1023">
                <span class="circle red"></span> 待审核
              </div>
              <div v-if="scope.row.review_status == 1024">
                <span class="circle green"></span> 审核通过
              </div>
              <div v-if="scope.row.review_status == 1026">
                <span class="circle green"></span> 审核通过
              </div>
              <div v-if="scope.row.review_status == 1027">
                <span class="circle yellow"></span> 编辑中
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="viewReport" label="操作" width="240" v-if="adminStatusNum != 1">
            <template slot-scope="scope" v-if="haveShowAuth">
              <template v-if="scope.row.review_status == 1019">
                <!--                <router-link-->
                <!--                  tag="a"-->
                <!--                  target="_blank"-->
                <!--                  :to="{-->
                <!--                    name: 'SimpleResultEditor',-->
                <!--                    params: {-->
                <!--                      action: 'edit',-->
                <!--                      uuid: scope.row.uuid,-->
                <!--                      nsrsbh: scope.row.nsrsbh,-->
                <!--                    },-->
                <!--                  }"-->
                <!--                  class="manipulator-btn"-->
                <!--                  >简版</router-link-->
                <!--                >-->

                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1020">
                <!--                <router-link-->
                <!--                  tag="a"-->
                <!--                  target="_blank"-->
                <!--                  :to="{-->
                <!--                    name: 'SimpleResultEditor',-->
                <!--                    params: {-->
                <!--                      action: 'edit',-->
                <!--                      uuid: scope.row.uuid,-->
                <!--                      nsrsbh: scope.row.nsrsbh,-->
                <!--                    },-->
                <!--                  }"-->
                <!--                  class="manipulator-btn"-->
                <!--                  >简版</router-link-->
                <!--                >-->

                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1021">
                <!--                <router-link-->
                <!--                  tag="a"-->
                <!--                  target="_blank"-->
                <!--                  :to="{-->
                <!--                    name: 'SimpleResultEditor',-->
                <!--                    params: {-->
                <!--                      action: 'edit',-->
                <!--                      uuid: scope.row.uuid,-->
                <!--                      nsrsbh: scope.row.nsrsbh,-->
                <!--                    },-->
                <!--                  }"-->
                <!--                  class="manipulator-btn"-->
                <!--                  >简版</router-link-->
                <!--                >-->

                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1025">
                <!--                <router-link-->
                <!--                  tag="a"-->
                <!--                  target="_blank"-->
                <!--                  :to="{-->
                <!--                    name: 'SimpleResultEditor',-->
                <!--                    params: {-->
                <!--                      action: 'edit',-->
                <!--                      uuid: scope.row.uuid,-->
                <!--                      nsrsbh: scope.row.nsrsbh,-->
                <!--                    },-->
                <!--                  }"-->
                <!--                  class="manipulator-btn"-->
                <!--                  >简版</router-link-->
                <!--                >-->

                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1022">
                <!--                <router-link-->
                <!--                  tag="a"-->
                <!--                  target="_blank"-->
                <!--                  :to="{-->
                <!--                    name: 'SimpleResultEditor',-->
                <!--                    params: {-->
                <!--                      action: 'edit',-->
                <!--                      uuid: scope.row.uuid,-->
                <!--                      nsrsbh: scope.row.nsrsbh,-->
                <!--                    },-->
                <!--                  }"-->
                <!--                  class="manipulator-btn"-->
                <!--                  >简版</router-link-->
                <!--                >-->

                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1023">
                <!--                <router-link-->
                <!--                  tag="a"-->
                <!--                  target="_blank"-->
                <!--                  :to="{-->
                <!--                    name: 'SimpleResultEditor',-->
                <!--                    params: {-->
                <!--                      action: 'edit',-->
                <!--                      uuid: scope.row.uuid,-->
                <!--                      nsrsbh: scope.row.nsrsbh,-->
                <!--                    },-->
                <!--                  }"-->
                <!--                  class="manipulator-btn"-->
                <!--                  >简版</router-link-->
                <!--                >-->

                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1024">
                <!--                <router-link-->
                <!--                  tag="a"-->
                <!--                  target="_blank"-->
                <!--                  :to="{-->
                <!--                    name: 'SimpleResultEditor',-->
                <!--                    params: {-->
                <!--                      action: 'edit',-->
                <!--                      uuid: scope.row.uuid,-->
                <!--                      nsrsbh: scope.row.nsrsbh,-->
                <!--                    },-->
                <!--                  }"-->
                <!--                  class="manipulator-btn"-->
                <!--                  >简版</router-link-->
                <!--                >-->

                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1026">
                <!--                <router-link-->
                <!--                  tag="a"-->
                <!--                  target="_blank"-->
                <!--                  :to="{-->
                <!--                    name: 'SimpleResultEditor',-->
                <!--                    params: {-->
                <!--                      action: 'edit',-->
                <!--                      uuid: scope.row.uuid,-->
                <!--                      nsrsbh: scope.row.nsrsbh,-->
                <!--                    },-->
                <!--                  }"-->
                <!--                  class="manipulator-btn"-->
                <!--                  >简版</router-link-->
                <!--                >-->

                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1027">
                <!--                <router-link-->
                <!--                  tag="a"-->
                <!--                  target="_blank"-->
                <!--                  :to="{-->
                <!--                    name: 'SimpleResultEditor',-->
                <!--                    params: {-->
                <!--                      action: 'edit',-->
                <!--                      uuid: scope.row.uuid,-->
                <!--                      nsrsbh: scope.row.nsrsbh,-->
                <!--                    },-->
                <!--                  }"-->
                <!--                  class="manipulator-btn"-->
                <!--                  >简版</router-link-->
                <!--                >-->

                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template>
                <!-- <div
                  v-if="scope.row.show_status == 2"
                  class="showProce"
                  @click="showProcess(scope.row)"
                >
                  查看进度
                </div> -->
              </template>
              <template>
                <a
                  class="showProce"
                  target="_blank"
                  v-if="scope.row.show_status == 1 && isShowDownloadInvoice"
                  :href="getInvoiceUrl(scope.row.taxNo)"
                >
                  发票
                </a>
              </template>
              <template>
                <div
                  class="showProce"
                  v-if="scope.row.show_status == 4"
                  @click="showFixFlow(scope.row)"
                >
                  去完善
                </div>
              </template>
              <template>
                <button
                  class="manipulator-btn"
                  v-if="scope.row.show_status == 5"
                  @click="retryAdd(scope.row)"
                >
                  重试
                </button>
              </template>
              <!--              <router-link-->
              <!--                v-if="scope.row.show_status == 1"-->
              <!--                :to="{-->
              <!--                  name: 'AdminUploadedTables',-->
              <!--                  params: {-->
              <!--                    bankId: scope.row.bankId,-->
              <!--                    version: scope.row.tag,-->
              <!--                    nsrsbm: scope.row.nsrsbh,-->
              <!--                    zq: scope.row.zq_id,-->
              <!--                    uuid: scope.row.uuid,-->
              <!--                    dataPeriod: 0,-->
              <!--                  },-->
              <!--                }"-->
              <!--                class="manipulator-btn"-->
              <!--                >报表</router-link-->
              <!--              >-->
              <!--              <template v-if="scope.row.show_status == 1">-->
              <!--                <button-->
              <!--                  v-if="!canChangeBillData"-->
              <!--                  @click="reportInvoice(scope.row.taxNo)"-->
              <!--                  class="manipulator-btn"-->
              <!--                >-->
              <!--                  发票-->
              <!--                </button>-->
              <!--                &lt;!&ndash; 22 29 49 &ndash;&gt;-->
              <!--                <button-->
              <!--                  @click="reportInvoiceBill(scope.row)"-->
              <!--                  class="manipulator-btn"-->
              <!--                  v-if="canChangeBillData"-->
              <!--                >-->
              <!--                  发票-->
              <!--                </button>-->
              <!--              </template>-->
            </template>
          </el-table-column>
          <el-table-column prop="viewReport" label="操作" width="240" v-else>
            <template slot-scope="scope" v-if="haveShowAuth">
              <template v-if="scope.row.review_status == 1019">
                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
                <button
                  v-if="scope.row.show_status == 1 || scope.row.show_status == 5"
                  @click="handleDeleteClick(scope.row.ruleId)"
                  class="manipulator-btn"
                >
                  删除
                </button>
              </template>
              <template v-if="scope.row.review_status == 1020">
                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
                <button @click="handleDeleteClick(scope.row.ruleId)" class="manipulator-btn">
                  删除
                </button>
              </template>
              <template v-if="scope.row.review_status == 1021">
                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
                <button @click="handleDeleteClick(scope.row.ruleId)" class="manipulator-btn">
                  删除
                </button>
              </template>
              <template v-if="scope.row.review_status == 1025">
                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
                <button @click="handleDeleteClick(scope.row.ruleId)" class="manipulator-btn">
                  删除
                </button>
              </template>
              <template v-if="scope.row.review_status == 1022">
                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
                <button @click="handleDeleteClick(scope.row.ruleId)" class="manipulator-btn">
                  删除
                </button>
              </template>
              <template v-if="scope.row.review_status == 1023">
                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
                <button @click="handleDeleteClick(scope.row.ruleId)" class="manipulator-btn">
                  删除
                </button>
              </template>
              <template v-if="scope.row.review_status == 1024">
                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
                <button @click="handleDeleteClick(scope.row.ruleId)" class="manipulator-btn">
                  删除
                </button>
              </template>
              <template v-if="scope.row.review_status == 1026">
                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
                <button @click="handleDeleteClick(scope.row.ruleId)" class="manipulator-btn">
                  删除
                </button>
              </template>
              <template v-if="scope.row.review_status == 1027">
                <router-link
                  v-if="scope.row.show_status != 5"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                    query: {
                      desensitization: scope.row.desensitizationStatus,
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <router-link
                  v-if="scope.row.show_status == 5 && scope.row.invoice_access_status == 2010"
                  tag="a"
                  :to="{
                    name: 'test-noData',
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
                <button @click="handleDeleteClick(scope.row.ruleId)" class="manipulator-btn">
                  删除
                </button>
              </template>
              <template> </template>
              <template>
                <a
                  class="showProce"
                  target="_blank"
                  v-if="scope.row.show_status == 1 && isShowDownloadInvoice"
                  :href="getInvoiceUrl(scope.row.taxNo)"
                >
                  发票
                </a>
              </template>
              <template>
                <div
                  class="showProce"
                  v-if="scope.row.show_status == 4"
                  @click="showFixFlow(scope.row)"
                >
                  去完善
                </div>
              </template>
              <template>
                <button
                  class="manipulator-btn"
                  v-if="scope.row.show_status == 5"
                  @click="retryAdd(scope.row)"
                >
                  重试
                </button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="pagination-wrapper">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[2, 10, 20, 100]"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="totalSize"
      ></el-pagination>
    </div>
    <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false">
      <CensorHistory :uuid="uuid" :nsrsbh="nsrsbh"></CensorHistory>
    </el-drawer>
    <perfect-info
      :checkParts="checkParts"
      @cancel="cancel"
      :fixData="fixData"
      :formData="formData"
      @fixSuccess="fixSuccess"
    ></perfect-info>
    <div class="process-statusC" v-if="locks">
      <processStatus
        :uuid="uuid"
        :nsrsbh="nsrsbh"
        v-loading="coverLoading"
        class="process-statuss"
        :uploadStatus="uploadStatus"
        :classObject="classObject"
        :processInfoStatus="processInfoStatus"
        :processFalse="processFalse"
        @closeProcess="closeProcess"
        :showStatusPro="showStatusPro"
      ></processStatus>
    </div>
  </div>
</template>

<script>
import AwaitButton from "await-button";
import filterCacheMixin from "@/mixins/filterCacheMixin";
import { getPdfDataList, progressBar, retryFlowAdd, getDelRuleIdApi } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import CensorHistory from "../../components/CensorHistory/CensorHistory";
import { veriFiItem, veriFiCustom, area } from "@/pages/product/utils/limit.js";
import processStatus from "./components/processStatus";

import perfectInfo from "./components/perfectInfo.vue";
import { getInvoiceUrl } from "../../../api/api";

const moment = require("moment");

export default {
  mixins: [filterCacheMixin],
  components: {
    AwaitButton,
    MainHeader,
    BreadCrumb,
    CensorHistory,
    perfectInfo,
    processStatus,
  },

  mounted() {
    this.getPdfDataList();
    sessionStorage.removeItem("isSuccessUpload");
    // 查看是否有查看报告权限
    this.checkShowIndex(17);
    //
    if (this.canChangeBillList.includes(this.canChangeBill)) {
      this.canChangeBillData = true;
    }

    sessionStorage.removeItem("threeActiveIndex");
    sessionStorage.removeItem("dataPeriod");

    const ac = JSON.parse(localStorage.getItem("user"));
    if (ac.adminStatus != 1) {
      this.adminStatusNum = false;
    }
  },

  data() {
    return {
      //
      canChangeBillList: [22, 29, 49],
      canChangeBill: JSON.parse(localStorage.getItem("user")).firmId,
      canChangeBillData: false,
      //
      showStatusPro: false,
      checkParts: false,
      dataVal: null,
      uploadStatus: null,
      classObject: "",
      processInfoStatus: false,
      processFalse: false,
      isSuccessUpload: sessionStorage.getItem("isSuccessUpload") || false,
      timestamp: "",
      locks: false,
      drawer: false,
      totalSize: 10, //总数
      pageSize: 10,
      pageNo: sessionStorage.getItem("testPageNo")
        ? Number(sessionStorage.getItem("testPageNo"))
        : 0,
      uuid: "",
      nsrsbh: "",
      sencorStatusOptions: [
        {
          value: "",
          label: "全部",
        },
        // {
        //   value: 1019,
        //   label: ""
        // },
        {
          value: "1020,1027",
          label: "编辑中",
        },
        {
          value: "1021,1023",
          label: "待审核",
        },
        {
          value: 1022,
          label: "待处理",
        },
        {
          value: "1024,1026",
          label: "审核通过",
        },
      ],
      generateStatusOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "已生成",
        },
        {
          value: 2,
          label: "生成中",
        },
        // {
        //   value: 3,
        //   label: "已删除"
        // },
        {
          value: 4,
          label: "待完善",
        },
        {
          value: 5,
          label: "生成出错",
        },
      ],
      filter: {
        projectName: "",
        consumer: "",
        submitTime: [],
        reviewStatus: "",
        showStatus: "",
      },
      tableDataList: null,
      fixData: null,
      // 是否需要补全
      formData: {
        areaBoolean: false,
        kjzdBoolean: false,
        hyBoolean: false,
      },
      closeProcessTag: true,
      coverLoading: false,
      haveShowAuth: true,
      adminStatusNum: true,
    };
  },
  methods: {
    reportInvoice(taxNo) {
      this.$router.push({
        path: "/test/test-reportBill",
        query: {
          taxNo: taxNo,
        },
      });
    },
    reportInvoiceBill(val) {
      this.$router.push({
        path: "/tools/billDetail",
        query: {
          taxNo: val.taxNo,
          type: 2,
          // companyName: val.companyName,
          // createTime: val.updateTime,
        },
      });
    },
    getInvoiceUrl(taxNo) {
      return getInvoiceUrl({ taxNo });
    },
    // 权限方法
    checkAuthMethod(id) {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).authorities;
      if (getUserAuth.length == 0) {
        return "none";
      }
      this.roleList = JSON.parse(localStorage.getItem("user")).authorities[0].roleList;
      let roleListId = this.roleList.map((item) => {
        return item.id;
      });
      if (roleListId.indexOf(id) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 判断是否有查看报告权限
    checkShowIndex(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveShowAuth = false;
      }
    },
    retryAdd(data) {
      //
      this.$confirm("是否重试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            nsrsbh: data.nsrsbh,
            uuid: data.uuid,
            invoiceAccessStatus: data.invoice_access_status,
            taxAccessStatus: data.tax_access_status,
            taxNo: data.taxNo,
          };
          retryFlowAdd(params).then((res) => {
            if (res.msgCode == 1) {
              this.$message({
                message: "重试成功，请耐心等待！",
                type: "success",
              });
              this.getPdfDataList();
            } else {
              this.$message({
                message: res.msgInfo,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
      //
    },
    fixSuccess() {
      this.checkParts = false;
      this.reset();
    },
    showFixFlow(data) {
      if (!data.city_code && !data.province_code && !data.district_code) {
        this.formData.areaBoolean = true;
      }
      if (!data.kjzd) {
        this.formData.kjzdBoolean = true;
      }
      if (!data.industry) {
        this.formData.hyBoolean = true;
      }
      this.fixData = data;
      this.checkParts = true;
    },
    // 查看进度
    showProcess(val) {
      this.isProcess = false;
      // showStatusPro防止有一个旧状态的显示，每次切换显示进度条的时候先讲旧的隐藏掉，然后接口返回之后再显示进度条
      this.showStatusPro = false;
      this.dataVal = val;
      this.locks = true;
      this.progressbar(
        val.taxNo,
        val.nsrsbh,
        val.zq_id,
        val.get_data_way,
        val.exe_time,
        val.isAutoData,
        val.uuid
      );
    },
    cancel() {
      this.checkParts = false;
    },
    closeProcess() {
      if (!this.closeProcessTag) return;
      this.showStatusPro = false;
      this.locks = false;
      clearTimeout(this.n);
    },
    progressbar(taxPayerID, nsrsbh, zq, userInfo, time, isAutoData, uuid) {
      var that = this;
      this.coverLoading = true;
      this.n = setTimeout(function cb() {
        that.closeProcessTag = false;
        let params = {
          taxpayerId: taxPayerID,
          nsrsbm: nsrsbh,
          zq: zq,
          type: userInfo,
          isAutoData: isAutoData,
          uuid: uuid,
        };
        progressBar(params, localStorage.getItem("authorization")).then((res) => {
          that.closeProcessTag = true;
          that.coverLoading = false;
          if (res.msgCode == -1) {
            that.locks = false;
            that.$alert("查询进度出错，请稍后重试！", "温馨提示", {
              confirmButtonText: "确定",
              callback: (action) => {},
            });
            return;
          }
          that.showStatusPro = true;
          that.uploadStatus = res.data;
          if (that.isSuccessUpload) {
            that.uploadStatus.sb = 3;
          }
          let timestamp = Date.parse(new Date());
          that.timestamp = time;
          // 利用当前时间减去完成提交的时间，再除于40分钟，显示百分比
          let add = Number(timestamp) - Number(that.timestamp);
          let data2 = ((add / 2400000) * 100).toFixed(2);
          that.classObject = data2;
          // 当超过90%的时候，显示90%
          if (that.classObject > 90) {
            that.classObject = 90;
          }
          if (that.uploadStatus.fp == 2 && that.uploadStatus.sb == 2) {
            // 申报发票都成功
            that.processInfoStatus = true;
          } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 0) {
            // 申报发票都报错
          } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 3) {
            // 发票报错，申报已上传
          } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 4) {
            // 发票报错，申报未上传
          } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 2) {
            // 发票报错，申报取数成功
          } else if (that.uploadStatus.fp == 2 && that.uploadStatus.sb == 0) {
            // 申报报错，发票成功
          } else if (that.uploadStatus.fp == 2 && that.uploadStatus.sb == 3) {
            // 申报已上传，发票成功
          } else if (that.uploadStatus.fp == 2 && that.uploadStatus.sb == 4) {
            // 申报未上传，发票成功
          } else {
            //  除了
            // 1、发票报错0，申报已上传3
            // 2、发票报错0，申报未上传4
            // 3、发票报错0，申报报错0
            // 4、发票成功2, 申报成功2
            // 其他情况继续轮询,关闭弹窗则关闭轮询
            that.n = setTimeout(cb, 6000);
          }
        });
      }, 0);
    },

    handleCensorBtnLogClick({ uuid, nsrsbh }) {
      this.uuid = uuid;
      this.nsrsbh = nsrsbh;
      this.drawer = true;
    },
    /* 删除功能 */
    async handleDeleteClick(e) {
      this.$confirm("此操作将永久删除该报告，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getDelRuleIdApi(e).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.getPdfDataList();
            } else {
              this.$message({
                message: res.message,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
    },

    search() {
      this.pageNo = 0;
      this.saveFilterToCache(this.filter);
      this.getPdfDataList();
    },
    changeConsumer() {
      if (!this.filter.consumer) {
        this.search();
      }
    },
    changeProject() {
      if (!this.filter.projectName) {
        this.search();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPdfDataList();
    },
    handleCurrentChange(val) {
      //debugger
      this.pageNo = val;
      sessionStorage.setItem("testPageNo", this.pageNo);

      this.getPdfDataList();
    },
    reset() {
      this.filter = {
        projectName: "",
        consumer: "",
        submitTime: [],
      };
      this.getPdfDataList();
    },
    dateFormatter(row, column) {
      return moment(row.exe_time).format("YYYY-MM-DD HH:mm:ss");
    },
    async getPdfDataList() {
      let params = {
        ...this.filter,
        startTime:
          this.filter.submitTime && this.filter.submitTime[0]
            ? moment(this.filter.submitTime[0]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        endTime:
          this.filter.submitTime && this.filter.submitTime[1]
            ? moment(this.filter.submitTime[1]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };

      const res = await getPdfDataList(params);
      const resData = res.data;

      this.tableDataList = resData.data.content;
      this.totalSize = resData.data.totalSize;
      //this.pageNo = resData.data.pageNo;
    },
  },
  computed: {
    //某些账号ID显示下载按钮
    isShowDownloadInvoice() {
      return false;
      const userInfo = JSON.parse(localStorage.getItem("user"));
      if (process.env.NODE_ENV === "production") {
        return userInfo && (userInfo.id === 249 || userInfo.id === 215);
      } else {
        return userInfo && userInfo.id === 208;
      }
    },
  },
  destroyed() {
    this.locks = false;
    sessionStorage.removeItem("timestamp");
    sessionStorage.removeItem("taxpayerIdProcess");
    clearTimeout(this.n);
  },
};
</script>

<style lang="stylus" scoped>
.pagination-wrapper {
  text-align: right;
  align-items: center;
  height: 36px;
  line-height: 36px;
  margin: 20px 30px 20px 0;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}

.delete {
  background: rgba(245, 34, 45, 1);
}

.perfect {
  background: rgba(250, 173, 20, 1);
}

.paginations {
  float: right;
  margin-right: 20px;
  margin-bottom: 30px;
}

.fixed-item {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1000;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.ease {
  transition: all 0.3s;
}

.cursion {
  cursor: pointer;
}

.content-isEdit {
  margin-bottom: 16px;
}

>>> #content-isEdit .el-input {
  width: 416px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #content-isEdit .el-cascader {
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input__icon {
  line-height: 36px;
}

>>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.paginationNew {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 40px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(238, 238, 238, 1);
  display: inline-block;
}

.circle.gray {
  background: rgba(0, 0, 0, 0.2);
}

.circle.gray-darker {
  background: rgba(0, 0, 0, 0.4);
}

.circle.blue {
  background: #2F54EB;
}

.circle.cyan {
  background: rgba(24, 144, 255, 1);
}

.circle.green {
  background: rgba(82, 196, 26, 1);
}

.circle.red {
  background: rgba(245, 34, 45, 1);
}

.circle.purple {
  background: rgba(153, 47, 235, 1);
}

.circle.yellow {
  background: rgba(250, 173, 20, 1);
}

.text-gray {
  color: rgba(0, 0, 0, 0.2);
}

.text-gray-darker {
  color: rgba(0, 0, 0, 0.4);
}

.text-blue {
  color: rgba(98, 97, 242, 1);
}

.text-cyan {
  color: rgba(24, 144, 255, 1);
}

.text-green {
  color: rgba(82, 196, 26, 1);
}

.text-red {
  color: rgba(245, 34, 45, 1);
}

.text-purple {
  color: rgba(153, 47, 235, 1);
}

.text-yellow {
  color: rgba(250, 173, 20, 1);
}

.manipulator-btn {
  color: rgba(47, 84, 235, 1);
  background: none;
  border: 0 none;
  cursor: pointer;
  outline-style: none;
  box-shadow: none;
  display: inline-block;
  padding: 0 12px;
  padding-left: 0;
}

.manipulator-btn:hover {
  text-decoration: underline;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-input__icon {
  line-height: 28px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

.showProce {
  color: #2f54eb;
  cursor: pointer;
}

.process-statuss {
  position: fixed;
  top: 20vh;
  left: 50%;
  margin-left: -400px;
}

.process-statusC {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2001;
}

.showProce {
  color: #2f54eb;
  cursor: pointer;
}

.gener {
  color: #2F54EB;
}
.filter-body{
  margin:20px;
}
>>>.filter-body .inputs-wrapper .inputs-wraper-row .form-item .label{
  width:85px;
}
</style>
